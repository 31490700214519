<template>
  <div class="connection">
    <div class="login-box">
      <h2>Votre nouveau mot de passe :</h2>
      <div class="parts">
        <label class="labels" for="password">Mot de passe :</label><br>
        <input id="password" name="password" type="password" placeholder="Entrez votre nouveau mot de passe" required>
      </div>
      <input class="valid" @click="submitNewPassword" type="submit" value="Envoyer">
    </div>
  </div>
</template>

<script>
// import the library
import { createToast } from 'mosha-vue-toastify';
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'

export default {
  name: 'HomeContainer',
  data() {
    return {
      link: "https://api.heavenflix.fr",
      code: this.$route.params.code,
    }
  },
  setup() {
    const toast = () => {
      createToast('Wow, easy')
    }
    return { toast }
  },
  methods: {
    showNotification(type, text, timing) {
      createToast(text, {
        position: 'top-center',
        type: type,
        transition: 'zoom',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: timing,
      })
    },
    submitNewPassword: async function submitNewPassword() {
      const password = document.getElementById('password').value;
      if (password === '') {
        this.showNotification('danger', 'Veuillez remplir saisir un mot de passe', 5000);
        return;
      }
      const data = {
        password: password,
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };
      await fetch(this.link + '/changeUserPasswordByResetCode/' + this.code, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return this.showNotification('danger', data.msg, 5000);
        if (data == true) {
          this.showNotification('success', 'Votre mot de passe a bien été modifié', 5000);
          setTimeout(() => {
            this.$router.push('/login')
          }, 5000);
        }
      }).catch(error => {
        console.error(error)
      });
    }
  },
  created: function () {

  },
  mounted: function () {

  },
}
</script>

<style scoped lang="scss">
$dark-color: #1b151a;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;

* {
  padding: 0;
  margin: 0;
}



.parts {
  margin: 5px 0;
  width: 100%;
}

.connection {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: $back-color;
}

.login-box {
  width: 50%;
  height: auto;
  padding: 50px;
  background: white;
  color: black;
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
}

.login-box h2 {
  margin: 10px 0;
  color: $dark-red;
  font-weight: bold;
  padding-bottom: 2px;
  padding-right: 10px;
}

a {
  color: $dark-red;
  font-weight: bold;
}

input {
  width: 100%;
  height: 35px;
  border: none;
  padding: 3px 8px;
  border-bottom: 1px solid black;
  margin: 5px 0;
  outline: none;

  &:focus {
    background: rgba(0, 0, 0, 0.2);
  }
}

.valid {
  margin: 20px 0;
  border: none;
  border-radius: 5px;
  background: $dark-red;
  color: white;
  font-size: 18px;
  transition-duration: 0.3s;
}

.valid:hover {
  transform: scale(1.05);
  cursor: pointer;
  transition-duration: 0.3s;
}

@media screen and (max-width : 1024px) {
  .login-box {
    width: 40%;
  }
}

@media screen and (max-width : 768px) {
  .login-box {
    width: 50%;
  }
}

@media screen and (max-width : 426px) {
  .login-box {
    width: 90%;
    padding: 50px 10px;

    .parts {
      width: 100%;

      input {
        width: 95%;
      }
    }
  }
}
</style>
