<template>
  <div class="loginContainer" >
    <div class="background" v-if="backgroundImageSrc != undefined">
        <img :src="backgroundImageSrc" alt="background" />
        <div class="gradient-left-to-right"></div>
        <p v-if="backgroundName != ''" class="backgroundName">Regardez <b>{{ backgroundName }}</b></p>
    </div>
    <div class="connection" v-if="backgroundImageSrc != undefined">
      <div class="login-box">
        <img class="top-logo" src="@/assets/round_logo.png" alt="logo">
        <h2>Se connecter</h2>
        <div class="parts">
          <label class="labels" for="name">Nom d'utilisateur :</label><br>
          <input id="name" name="name" type="text" placeholder="Entrez votre nom" required>
          <p class="msg" id="messageEmail"></p>
        </div>
        <div class="parts">
          <label class="labels" for="password">Mot de passe : </label><br>
          <input id="password" name="password" type="password" placeholder="Entrez votre mot de passe" required>
          <p class="msg" id="messagePassword"></p>
        </div>
        <div class="parts warning" v-show="warningShow">
          <p>{{ warningMessage }}</p>
        </div>
        <input class="valid" v-on:click="login" type="submit" value="Se connecter">
        <div class="propose">
          <a href=""><router-link to="/resetPassword">Mot de passe oublié</router-link></a>
        </div>
      </div>
    </div>
    <div v-else-if="!isLoaded && !backgroundImageSrc" class="loading">
      <img class="logo" src="@/assets/round_logo.png" alt="logo">
      <p>Chargement...</p>
    </div>
    <div v-else-if="isLoaded && !backgroundImageSrc" class="down">
      <img class="logo" src="@/assets/round_logo.png" alt="logo">
      <p>Le service est indisponible. Revenez plus tard.</p>
    </div>
  </div>
</template>

<script>
// import the library
import { createToast } from 'mosha-vue-toastify';
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'

export default {
  name: 'HomeContainer',
  data() {
    return {
      link: "https://api.heavenflix.fr",
      warningShow: false,
      warningMessage: '',
      backgroundImageSrc: undefined,
      backgroundName: '',
      isLoaded: false
    }
  },
  setup() {
    const toast = () => {
      createToast('Wow, easy')
    }
    return { toast }
  },
  methods: {
    showNotification(type, text, timing) {
      createToast(text, {
        position: 'top-center',
        type: type,
        transition: 'zoom',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: timing,
        //toastBackgroundColor: '#2D2E32',
      })
    },
    login: async function login() {
      this.warningShow = false;
      this.warningMessage = '';
      var name = document.getElementById('name').value
      var password = document.getElementById('password').value
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          "name": name,
          "password": password
        })
      };
      await fetch(this.link + '/login', requestOptions).then((res) => res.json()).then(data => {
        if (data.token) localStorage.setItem('token', data.token);
        if (data && data.userId) {
          localStorage.setItem('userId', data.userId);
          localStorage.setItem('token', data.token);
          localStorage.setItem('limit', data.limitCards || 50);
          localStorage.setItem('darkMode', false);
          localStorage.setItem('wantCarrousel', data.wantCarrousel);
          localStorage.setItem('wantWideCards', data.wantWideCards);
          if (data.darkMode) localStorage.setItem('darkMode', true);
        }
        if (data.code == 200) this.$router.push('/home');
        if (data.code == 201 || data.code == 202) {
          this.showNotification('warning', data.msg, 4000);
        }
      }).catch(error => {
        console.error(error)
        this.warningShow = true;
        this.warningMessage = 'Le serveur ne répond pas, désolé. Bisous.';
      });
    },
    async getBackgroundImage() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  await fetch(this.link + '/getRandomMovieBackground', requestOptions)
      .then((res) => {
        return res.json();
      })
      .then(data => {
        if (data && data.backgroundURL) {
          this.backgroundImageSrc = data.backgroundURL;
          this.backgroundName = data.name;
        }
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.isLoaded = true;
      });
    },
  },
  created: function () {
    this.getBackgroundImage();
  },
  mounted: function () {
    if (localStorage.getItem('forceDisconnected')) {
      this.showNotification('warning', 'Vous avez été déconnecté automatiquement, veuillez vous reconnecter.', 4000);
      localStorage.removeItem('forceDisconnected');
    }
  },
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;
$soft-grey: #2D2E32;

* {
  padding: 0;
  margin: 0;
}

.loginContainer {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 100dvh;
  width: 100dvw;

  .loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    height: 100%;
    width: 100%;
    background: $dark-color;
    color: white;
    font-family: 'Montserrat';

    .logo {
      width: 100px;
    }
  }

  .down {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    height: 100%;
    width: 100%;
    background: $dark-color;
    color: white;
    font-family: 'Montserrat';

    .logo {
      width: 100px;
    }
  }

  .background {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      z-index: 3;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .gradient-left-to-right {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 4;
      width: 100dvw;
      height: 100dvh;
      background: linear-gradient(to right, #15161b 0%, #15161b 40%, rgba(21, 22, 27, 0.9) 50%, rgba(21, 22, 27, 0.8) 60%, rgba(21, 22, 27, 0.7) 70%, rgba(21, 22, 27, 0.6) 80%, rgba(21, 22, 27, 0.4) 90%, rgba(21, 22, 27, 0.2) 100%);

    }

    .backgroundName {
      position: absolute;
      z-index: 5;
      bottom: 10%;
      right: 5%;
      font-size: 1.5em;
      color: white;
      font-family: 'Montserrat';

      b {
        margin-left: 5px;
        font-size: 1.52em;
        font-weight: bold;
      }
    }
  }

  .connection {
    z-index: 5;
    margin-left: 10%;

    .login-box {
      position: relative;
      z-index: 6;
      width: 100%;
      padding: 50px 30px;
      background: rgba(21, 22, 27, 0.8);
      color: white;
      text-align: center;

      .top-logo {
        width: 50%;
        margin-bottom: 20px;
      }

      h2 {
        margin-bottom: 20px;
        font-size: 1.5em;
        font-family: 'Montserrat';
      }

      .parts {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: start;

        input {
          width: 95%;
          padding: 10px;
          border-radius: 5px;
          border: none;
          background: rgba(255, 255, 255, 0.1);
          color: white;
          font-size: 1em;
          font-family: 'Montserrat';
        }

        .labels {
          font-size: 1.2em;
          font-family: 'Montserrat';
          color: white;
        }

        .msg {
          color: $dark-red;
          font-size: 0.8em;
          font-family: 'Montserrat';
        }

        .warning {
          background: $dark-red;
          padding: 10px;
          border-radius: 5px;
          margin-bottom: 20px;
        }
      }

      .valid {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: none;
        background: $dark-red;
        color: white;
        font-size: 1em;
        font-family: 'Montserrat';
        margin-top: 5px;
        cursor: pointer;
      }

      .propose {
        margin-top: 20px;
        font-size: 0.8em;
        font-family: 'Montserrat';

        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
}

// Version PC 1024px
@media screen and (max-width: 1024px) {
  .loginContainer {

    .background {
      .gradient-left-to-right {
        background: linear-gradient(to right, #15161b 0%, #15161b 55%, rgba(21, 22, 27, 0.9) 65%, rgba(21, 22, 27, 0.8) 70%, rgba(21, 22, 27, 0.7) 75%, rgba(21, 22, 27, 0.6) 80%, rgba(21, 22, 27, 0.4) 90%, transparent 100%);
      }
    }

    .connection {
      margin-left: 5%;

      .login-box {
        background: transparent;

        .top-logo {
          width: 30%;
        }

        h2 {
          font-size: 1.2em;
        }

        .parts {
          .labels {
            font-size: 1.15em;
          }

          input {
            font-size: 1em;
          }

          .msg {
            font-size: 0.6em;
          }

          .propose {
            
            a {
              font-size: 1.2em;
            }
          }
        }
      }
    }
  }
}

// Version tablette 768px
@media screen and (max-width: 768px) {
  .loginContainer {
    
    .background {
      .gradient-left-to-right {
        width: 100dvw;
        background: linear-gradient(to top, #15161b 0%, #15161b 60%, rgba(21, 22, 27, 0.9) 70%, rgba(21, 22, 27, 0.8) 75%, rgba(21, 22, 27, 0.7) 80%, rgba(21, 22, 27, 0.6) 85%, rgba(21, 22, 27, 0.4) 90%, rgba(21, 22, 27, 0.15) 100%);
      }

      img {
        max-height: 40dvh;
        object-fit: cover;
        object-position: center;
      }

      .backgroundName {
        display: none;
      }
    }

    .connection {
      margin: 0 auto;

      .login-box {
        padding: 0;

        .top-logo {
          width: 30%;
        }

        h2 {
          font-size: 1.2em;
        }

        .parts {
          .labels {
            font-size: 1.15em;
          }

          input {
            font-size: 1em;
          }

          .msg {
            font-size: 0.6em;
          }

          .propose {
            a {
              font-size: 1.2em;
            }
          }
        }
      }
    }
  }
}

// Version mobile 425px
@media screen and (max-width: 425px) {
  .loginContainer {
    
  }
}

</style>
