<template>
    <div class="homePage">
        <NavbarTop />
        <div class="noResult" v-if="!articles || !articles.length">
            <p id="loaderText">Aucun résultat <span>😕</span></p>
            <span>Sur chaque film, utilisez le bouton "+ Ma liste" pour confectionner votre liste de films favoris ou
                ceux
                que vous
                souhaitez voir plus tard.</span>
        </div>
        <div class="cardContainer" v-if="articles && articles.length > 0 && wantWideCards == true">
            <CardMovieContainer v-for="item in articles" v-bind:key="item.id" :movie="item" :type="'list'"
                data-aos="fade-up" data-aos-anchor-placement="center" data-aos-duration="2000" v-cloak
                @click="seenMovie(item.id)" />
        </div>
        <div class="cardContainer" v-else-if="articles && articles.length > 0 && wantWideCards == false">
            <CardMoviePosterContainer v-for="item in articles" v-bind:key="item.id" :movie="item" :type="'list'"
                data-aos="fade-up" data-aos-anchor-placement="center" data-aos-duration="2000" v-cloak
                @click="seenMovie(item.id)" />
        </div>
        <div class="displayer" id="displayer" v-if="selectedArticle != undefined">
            <DisplayerMovieContainer :movie="selectedArticle" :isAdmin="isAdmin" @close-modal="closeSeenMovie()"
                @change-movie="seenMovie($event)" @watch-later-removed="handleWatchLaterRemoved()" />
        </div>
    </div>
</template>

<script>
// import the library
import { createToast } from 'mosha-vue-toastify';
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'
import NavbarTop from './NavbarTop.vue'
import AOS from 'aos';
import DisplayerMovieContainer from './DisplayerMovieContainer.vue'
import CardMovieContainer from './CardMovieContainer.vue'
import CardMoviePosterContainer from './CardMoviePosterContainer.vue';

export default {
    name: 'ListContainer',
    components: {
        NavbarTop,
        DisplayerMovieContainer,
        CardMovieContainer,
        CardMoviePosterContainer,
    },
    data() {
        return {
            isAdmin: 0,
            articles: [],
            selectedArticle: undefined,
            link: "https://api.heavenflix.fr",
            listOfUser: [],
            wantWideCards: JSON.parse(localStorage.getItem('wantWideCards')) == true ? true : false,
        }
    },
    setup() {
        const toast = () => {
            createToast('Wow, easy')
        }
        return { toast }
    },
    methods: {
        handleWatchLaterRemoved() {
            this.getAllList();
        },
        playMovie(id) {
            this.$router.push("/player/" + id);
        },
        showNotificationValidation(text) {
            createToast(text, {
                position: 'top-center',
                type: 'success',
                transition: 'slide',
                showIcon: 'false',
                hideProgressBar: 'false',
                swipeClose: 'true',
                showCloseButton: 'false',
                timeout: 3000,
                toastBackgroundColor: '#2D2E32',
            })
        },
        removeWatchLater(id, name) {
            const requestOptions = {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
            };
            fetch(this.link + '/deleteFromWatchlater/' + localStorage.getItem('userId') + '/' + id, requestOptions).then((res) => res.json()).then(data => {
                if (data.msg) return console.log(data.msg);
                this.getListOfUser();
                this.getAllList();
                this.selectedArticle = [];
                this.closeSeenMovie();
                this.showNotificationValidation(name + ' a été retiré de votre liste');
            })
        },
        isInWatchLaterList(id) {
            let isFound = false;
            this.articles.forEach(element => {
                if (element.id == id) isFound = true;
            });
            return isFound;
        },
        closeSeenMovie: async function closeSeenMovie() {
            document.body.style.overflow = 'auto';
            this.selectedArticle = undefined;
        },
        seenMovie: async function seenMovie(value) {
            this.selectedArticle = undefined;
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + '/getMovie/' + value, requestOptions).then((res) => res.json()).then(data => {
                if (data.msg) return console.log(data.msg);
                document.body.style.overflow = 'hidden';
                data[0].cat = JSON.parse(data[0].cat);
                if (data[0].collection) data[0].collection = JSON.parse(data[0].collection);
                this.selectedArticle = data[0];
            })
        },
        admin: async function admin() {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            };
            await fetch(this.link + '/getAdmin/' + localStorage.getItem('userId'), requestOptions)
                .then((res) => {
                    if (res.status == 401) {
                        this.$router.push("/login");
                    }
                    return res.json()
                })
                .then(data => {
                    if (data.msg) return console.log(data.msg);
                    this.isAdmin = data[0].isAdmin;
                })
        },
        getListOfUser: async function getListOfUser() {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + '/getListFromUserId/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
                if (data.msg) return console.log(data.msg);
                this.listOfUser = data;
            })
        },
        getAllList: async function getAllList() {
            this.articles = [];
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + '/getListFromUserId/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
                if (data.msg) return console.log(data.msg);
                data.forEach(element => {
                    element.cat = JSON.parse(element.cat);
                    element.cat = element.cat.join(' & ');
                });
                this.articles = data;
            })
        },
        infos: async function infos() {
            let string = "Tous les films sont en français (il se peut qu'un changement de langue soit nécessaire). La qualité est réglable directement sur le lecteur.\n\nAfin de profiter du PREMIUM sur le lecteur, entrez le compte suivant : \n\nNom d'utilisateur: ValhallaBarbare\nMot de passe: forqueen\n\nPour avoir la possibilité de caster les films, il est nécessaire d'être sur Google Chrome peu importe la plateforme.\n\nEn cas de problème, pensez à le signaler en utilisant le drapeau sur le film qui pose problème.";
            alert(string);
        },
    },
    created: function () {
        this.admin();
    },
    mounted: function () {
        AOS.init();
        this.getAllList();
        this.getListOfUser();
    }
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;

* {
    padding: 0;
    margin: 0;
}

.homePage {
    width: 100%;
    min-height: 100vh;
    background: $dark-color;

    .noResult {
        width: 100%;
        min-height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 25px;

        p {
            font-size: 27px;
            color: white;
            font-family: 'Montserrat';
            font-weight: bold;
            display: flex;
            align-items: center;
            gap: 5px;

            span {
                color: white;
            }
        }

        span {
            text-align: center;
            max-width: 50%;
            font-size: 20px;
            color: rgba(255, 255, 255, 0.8);
            font-family: 'Lato';
        }
    }

    .cardContainer {
        margin: auto;
        width: 90%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        padding: 50px 0;

    }

    .displayer {
        width: 100%;
        height: 100vh;
        background: rgba(20, 22, 28, 0.95);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: none;

    }
}

@media screen and (max-width: 1025px) {
    .homePage {

        .displayer {
            .displayerContainer {
                width: 90%;
            }
        }

        .cardContainer {
            width: 95%;


        }
    }
}

@media screen and (max-width: 769px) {
    .homePage {

        .displayer {}

        .cardContainer {
            width: 100%;


        }
    }
}

@media screen and (max-width: 426px) {
    .homePage {

        .displayer {
            width: 100%;
            align-items: flex-start;
            background: #15161bc5;

            .displayerContainer {
                width: 100%;
            }
        }

        .cardContainer {
            width: 100%;
        }
    }
}
</style>