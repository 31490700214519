<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss">
$dark-color: #1b151a;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;

* {
  padding: 0;
  margin: 0;
  border-radius: 0;
}

body {
  background-color: #fff;
}

html {

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: $dark-red $dark-color;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    background: $dark-color;
  }

  *::-webkit-scrollbar-thumb {
    background-color: $dark-red;
    border-radius: 5px;
    border: 0px none #ffffff;
  }
}</style>
