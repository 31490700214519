<template>
  <div class="mainContainer">
    <NavbarTop />
    <AddMenuNavigation />
    <div class="form">
      <div class="entry">
        <label for="posterURL">Affiche </label>
        <input id="posterURL" name="posterURL" type="text" placeholder="http://www.pornhub.com/galery" />
      </div>
      <div class="entry">
        <label for="background">Background </label>
        <input id="background" name="background" type="text" placeholder="http://www.pornhub.com/galery" />
      </div>
      <div class="entry">
        <label for="name">Titre </label>
        <input id="name" name="name" type="text" placeholder="Titre" />
      </div>
      <div class="entry">
        <label for="description">Description </label>
        <input id="description" name="question" type="text" placeholder="Description" />
      </div>
      <div class="entry">
        <label for="annee">Année de sortie</label>
        <input id="annee" name="annee" type="number" placeholder="Année de sortie" />
      </div>
      <div class="entry">
        <label for="link">Lien (Si émission unique comme par exemple Wrestlemania sinon laisser vide)</label>
        <input id="link" name="link" type="text" placeholder="Lien darkibox de l'émission" />
      </div>
      <div class="entry">
        <label for="category1">Catégories</label>
        <select name="category1" id="categories1">
          <option v-bind:key="element.id" v-for="element in categories" :value="element.name">{{ element.name }}
          </option>
        </select>
      </div>
      <div class="entry">
        <select name="category2" id="categories2">
          <option v-bind:key="element.id" v-for="element in categories" :value="element.name">{{ element.name }}
          </option>
        </select>
      </div>
      <div class="entry">
        <button :onclick="() => addShowTV()">Ajouter cette émission TV</button>
      </div>
    </div>
  </div>
</template>

<script>
// import the library
import { createToast } from 'mosha-vue-toastify'
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'
import NavbarTop from '../components/NavbarTop.vue'
import AddMenuNavigation from './AddMenuNavigation.vue'
import io from "socket.io-client"

export default {
  name: 'AddMovie',
  components: {
    NavbarTop, AddMenuNavigation
  },
  data() {
    return {
      link: "https://api.heavenflix.fr",
      categories: undefined,
    }
  },
  setup() {
    const toast = () => {
      createToast('Wow, easy')
    }
    return { toast }
  },
  methods: {
    showNotification(type, text) {
      createToast(text, {
        position: 'top-center',
        type: type,
        transition: 'zoom',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: 3000,
        //toastBackgroundColor: '#2D2E32',
      })
    },
    showNotificationWarning(text) {
      createToast(text, {
        position: 'top-center',
        type: 'warning',
        transition: 'zoom',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: 3000,
        //toastBackgroundColor: '#2D2E32',
      })
    },
    admin: async function admin() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      };
      await fetch(this.link + '/getAdmin/' + localStorage.getItem('userId'), requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then(data => {
          if (data.msg) return console.log(data.msg);
          if (data[0].isAdmin == 0) return this.$router.push('/home');
        })
    },
    getCategories: async function getCategories() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getShowTVCategories', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.categories = data;
      })
    },
    addShowTV: async function addShowTV() {
      var posterURL = document.getElementById('posterURL').value;
      var name = document.getElementById('name').value;
      var description = document.getElementById('description').value;
      var background = document.getElementById('background').value;
      var annee = document.getElementById('annee').value;
      var link = document.getElementById('link').value;
      var categories1 = document.getElementById("categories1").value;
      var categories2 = document.getElementById("categories2").value;
      if (!posterURL) return this.showNotificationWarning('Lien de l\'affiche de la série manquant!');
      if (!background) return this.showNotificationWarning('Lien de l\'image de fond manquant!');
      if (!name) return this.showNotificationWarning('Titre de la série manquant!');
      if (!description) return this.showNotificationWarning('Description de la série manquante!');
      if (!annee) return this.showNotificationWarning('Année de sortie manquante!');
      if (!categories1 || !categories2) return this.showNotificationWarning('Catégorie manquante!');
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: name,
          description: description,
          annee: annee,
          posterURL: posterURL,
          backgroundURL: background,
          link: link,
          category1: categories1,
          category2: categories2,
        })
      };
      await fetch(this.link + '/addShowTV', requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        } else {
          this.showNotification('success', 'Show TV ajouté!');
        }
      });
    },
  },
  created: function () {
    this.admin();
    this.getCategories();
    // Établissez la connexion WebSocket avec votre serveur
    this.socket = io("https://api.heavenflix.fr")

    // Écoutez l'événement "message" pour recevoir des messages WebSocket
    this.socket.on("message", (message) => {
      this.loadingMessage = message;
      if (message == 'Fichier reçu par le serveur') this.step++;
      if (message == 'Répertoire temporaire créé') this.step++;
      if (message == 'Extraction du fichier') this.step++;
      if (message == 'Conversion des images') this.step++;
      if (message == 'Suppression du fichier d\'origine') this.step++;
      if (message == 'Sauvegarde de la couverture') this.step++;
      if (message == 'Ajout en base de données') this.step++;
    });
  },
  beforeUnmount() {
    // Fermez la connexion WebSocket lorsque le composant est détruit
    if (this.socket) {
      this.socket.close()
    }
  },
  mounted: function () {
    document.body.style.overflowY = "auto";
  }
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;
$soft-grey: #2D2E32;

@mixin background-shadow($main-color) {
  background: $main-color;
  box-shadow: 0 0 10px $main-color;
}

.buton {

  border: none;
  padding: 15px;
  border-radius: 50%;

  &:first-child {
    @include background-shadow(dodgerblue);
  }

  &:last-child {
    @include background-shadow(red);
  }
}

.mainContainer {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: $back-color;

  label {
    margin-left: 5px;
    font-size: 18px;
    font-family: 'Lato';
    color: $light-color;
  }

  .loadingContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);

    .loading {
      background: $dark-color;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-direction: column;
      padding: 20px 25px;

      img {
        width: 100px;
      }

      p {
        color: $light-color;
        font-family: 'Montserrat';
        margin: 5px 0;
      }

      span {
        color: rgba(255, 255, 255, 0.6);
        font-family: 'Montserrat';
        font-size: 14px;
      }
    }
  }

  .selector {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;

    .selectorContainer {
      display: flex;
      border-radius: 5px;
      overflow: hidden;

      button {
        background: rgba(255, 255, 255, 0.2);
        border: none;
        font-size: 20px;
        font-family: 'Lato';
        color: white;
        padding: 10px 20px;

        &:hover {
          cursor: pointer;
        }

        &:last-child {
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .form {
    margin: auto;
    width: 60%;
    padding: 0 0 50px 0;

    .boxes {
      padding: 20px 5px;
      margin-top: 10px;
      background: $soft-grey;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      border-radius: 10px;

      .box {
        list-style: none;
        column-count: 4;

        li {
          display: flex;
          justify-content: space-between;
          padding: 2.5px 10px;
          border-radius: 5px;

          label {
            margin: 0 4px;
            font-family: 'Montserrat';
          }

          input {
            cursor: pointer;
          }

          &:hover {
            transition-duration: 0.4s;
            background: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }

    .entry {
      width: 100%;
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 1;

      label {
        margin-bottom: 5px;
        font-size: 20px;
        font-family: 'Lato';
        color: $light-color;
      }

      select {
        width: 100%;
        background: $dark-red;
        border: none;
        color: white;
        font-family: 'Montserrat';
        font-size: 16px;
        padding: 10px 10px;
        outline: none;
        border-radius: 10px;

        option {
          background: $light-color;
          color: $back-color;
        }

        &:hover {
          cursor: pointer;
        }
      }

      input[type="text"] {
        width: 100%;
        height: 40px;
        outline: none;
        border: none;
        padding-left: 10px;
        background: #ffffff;
        font-size: 16px;
        font-family: 'Montserrat';
        border-radius: 10px;
      }

      input[type="number"] {
        width: 100%;
        height: 40px;
        outline: none;
        border: none;
        padding-left: 10px;
        background: #ffffff;
        font-size: 16px;
        font-family: 'Montserrat';
        border-radius: 10px;
      }

      .inputFileName {
        display: flex;
        align-items: center;
        gap: 10px;

        div {
          color: $light-color;
          font-family: 'Montserrat';
          font-size: 16px;
        }
      }

      .label-file {
        margin-top: 5px;
        cursor: pointer;
        background: $dark-color;
        width: fit-content;
        padding: 10px 15px;
        border-radius: 10px;
        transition-duration: 0.4s;

        &:hover {
          background: $dark-red;
          transition-duration: 0.4s;
        }
      }

      input[type="file"] {
        display: none;
      }

      button {
        margin: auto;
        width: 50%;
        border: none;
        background: rgb(194, 194, 194);
        color: $back-color;
        padding: 10px 0;
        transition-duration: 0.7s;
        font-size: 16px;
        font-family: 'Lato';
        border-radius: 10px;

        &:hover {
          cursor: pointer;
          transition-duration: 0.7s;
          background: $dark-red;
          color: white;
        }
      }

      ul {
        list-style: none;
        padding: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        li {
          background: $dark-color;
          padding: 10px 15px;
          border-radius: 10px;
          transition: 0.4s;

          a {
            text-decoration: none;
            color: $light-color;
            font-family: 'Montserrat';
          }

          &:hover {
            cursor: pointer;
            background: $dark-red;
            transition: 0.4s;
          }
        }
      }

      &:last-child {
        margin-top: 20px;
      }
    }

    .boxesContainer {
      width: 100%;
      margin-top: 30px;

      .boxesDisplayer {
        width: 98%;
        color: white;
        background: $soft-grey;
        border: none;
        font-size: 17px;
        font-family: 'Lato';
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 15px;
        cursor: pointer;
      }
    }
  }
}

//Affichage mobile
@media screen and (max-width: 426px) {
  .mainContainer {
    .form {
      width: 90%;

      .entry {
        input {
          max-width: 98%;
        }
      }

      .boxes {
        padding: 20px 0;

        .box {
          columns: 1;
          width: 100%;

          li {
            margin: auto;
            width: 92%;
            padding: 2.5px 0;
          }
        }
      }

      .boxesContainer {
        .boxesDisplayer {
          width: 92% !important;
        }
      }
    }
  }
}
</style>
