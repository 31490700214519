<template>
  <footer>
    <div class="footerContainer">
      <div class="footerSection">
        <img src="../assets/round_logo.png" alt="logo" />
        <p>© <span>{{ currentYear }}</span> No rights reserved at all.</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterContainer',
  data() {
    return {
      isAdmin: 0,
      link: "https://api.heavenflix.fr",
      currentYear: new Date().getFullYear(),
    }
  },
  methods: {
    admin: async function admin() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      };
      await fetch(this.link + '/getAdmin/' + localStorage.getItem('userId'), requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then(data => {
          if (data.msg) return console.log(data.msg);
          this.isAdmin = data[0].isAdmin;
        })
    },
  },
  created: function () {
    this.admin();
  },
  mounted: function () {

  }
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$back-color: #15161b;
$dark-red: #E60036;
$soft-grey: #2D2E32;

footer {
  background-color: $back-color;
  color: $light-color;
  padding: 20px 0;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;

  .footerContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;

    .footerSection {
      padding: 50px 0;
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 0 auto;
      margin-bottom: 20px;
      gap: 20px;

      img {
        width: 100px;
      }

      p {
        font-size: 1.3rem;
        font-weight: 300;
        font-family: 'Lato';
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  footer {
    .footerContainer {
      .footerSection {
        width: 50%;

        p {
          font-size: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  footer {
    .footerContainer {
      .footerSection {
        width: 100%;

        p {
          font-size: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {}
</style>
