<template>
  <div class="home">
    <CheckerContainer/>
  </div>
</template>

<script>
import CheckerContainer from '../components/CheckerContainer.vue';

export default {
  name: 'HomeView',
  components: {
    CheckerContainer
  }
}
</script>