<template>
  <div class="home">
    <ShowTVContainer />
  </div>
</template>

<script>
import ShowTVContainer from '../components/ShowTVContainer.vue';

export default {
  name: 'ShowTVView',
  components: {
    ShowTVContainer
  }
}
</script>