<template>
  <div class="home">
    <SettingsContainer/>
  </div>
</template>

<script>
import SettingsContainer from '../components/SettingsContainer.vue';

export default {
  name: 'SettingsView',
  components: {
    SettingsContainer
  }
}
</script>