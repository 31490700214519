<template>
    <div v-if="reports != undefined && reports.length > 0" class="usersContent">
        <div class="usersTab" v-if="reports != undefined && reports.length != 0">
            <Table :columns="columns" :data="reports" @update="getReports()" @sort="sort" />
        </div>
    </div>
    <div v-if="isLoaded && !reports" class="usersContent">
        <div class="usersTab">
            <h1>Aucun signalement trouvé..</h1>
        </div>
    </div>
</template>

<script>
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

import Table from './ReportsTableComponent.vue';

export default {
    name: "UsersDashboardComponent",
    components: {
        Table,
    },
    data() {
        return {
            link: "https://api.heavenflix.fr",
            reports: undefined,
            columns: [
                { field: "created_at", label: "Signalé le" },
                { field: "username", label: "Rapporteur" },
                { field: "reason", label: "Raison" },
                { field: "request", label: "Article" },
                { field: "isSeen", label: "Vu" },
                { field: "isDone", label: "Traité" },
                { field: "delete", label: "Supprimer" }
            ],
            isAdmin: false,
            isLoaded: false,
        };
    },
    setup() {
        const toast = () => {
            createToast('Wow, easy')
        }
        return {
            toast,
        };
    },
    methods: {
        sort(order_by, sort_by) {
            let reportsToSort = this.reports;
            let column = order_by;
            let order = sort_by;
            if (order == null) return this.getReports();
            reportsToSort.sort((a, b) => {
                if (order === 'ASC') {
                    if (a[column] < b[column]) return -1;
                    if (a[column] > b[column]) return 1;
                    return 0;
                } else if (order === 'DESC') {
                    if (a[column] > b[column]) return -1;
                    if (a[column] < b[column]) return 1;
                    return 0;
                }
            });
        },
        factoryReports(reports) {
            reports.forEach((report) => {
                report.isSeen = report.isSeen == 1 ? true : false;
                report.isDone = report.isDone == 1 ? true : false;
            });
            this.reports = reports;
            this.$emit("loading-ended");
            this.isLoaded = true;
        },
        async getReports() {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + "/allRequests", requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    if (data && data.length > 0) return this.factoryReports(data);
                    this.$emit("loading-ended");
                    this.isLoaded = true;
                });
        },
        showNotification(type, text) {
            createToast(text, {
                position: 'top-center',
                type: type,
                showIcon: 'true',
                swipeClose: 'true',
                showCloseButton: 'false',
                hideProgressBar: 'false',
                transition: 'slide',
                timeout: 3000,
                //toastBackgroundColor: '#2D2E32',
            })
        },
        admin: async function admin() {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            };
            await fetch(this.link + "/getAdmin/" + localStorage.getItem("userId"), requestOptions)
                .then((res) => {
                    if (res.status == 401) {
                        this.$router.push("/login");
                    }
                    return res.json()
                })
                .then((data) => {
                    if (data.msg) return console.log(data.msg);
                    if (!data[0].isAdmin || data[0].isAdmin == 0) return this.$router.push("/home");
                    this.isAdmin = true;
                });
        },
    },
    watch: {
        '$route': 'onRouteChange',
    },
    created: function () {
        this.admin();
        this.getReports();
    },
    mounted: function () {

    }
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$back-color: #15161b;
$dark-red: #E60036;
$soft-grey: #2D2E32;

.usersContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;

    .usersTab {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 50px;

        h1 {
            color: rgba(255, 255, 255, 0.74);
            font-size: 1.5em;
            font-weight: 400;
            font-family: 'Lato';
        }
    }
}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 480px) {}
</style>