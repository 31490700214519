<template>
    <div class="displayerContainer" data-aos="fade-in" data-aos-duration="1500">
        <div class="exit">
            <button @click="closeSeenMovie()" title="Fermer l'aperçu"><i class="fa-solid fa-xmark"></i></button>
        </div>
        <div class="upper">
            <img class="back" :src="movie.backgroundURL" alt="Image de fond" loading="lazy">
            <div class="poster">
                <img :src="movie.posterURL" alt="Affiche du film" loading="lazy"
                    onerror="this.src='https\://api.heavenflix.fr/images/error.webp'">
                <!--<div class="cardLogos" v-if="movie.trailerURL || movie.link && movie.link.includes('heavenflix')">
                    <span class="trailer" v-if="movie.trailerURL">
                        <svg class="svg-icon" viewBox="0 0 20 20">
                            <path
                                d="M18.175,4.142H1.951C1.703,4.142,1.5,4.344,1.5,4.592v10.816c0,0.247,0.203,0.45,0.451,0.45h16.224c0.247,0,0.45-0.203,0.45-0.45V4.592C18.625,4.344,18.422,4.142,18.175,4.142 M4.655,14.957H2.401v-1.803h2.253V14.957zM4.655,12.254H2.401v-1.803h2.253V12.254z M4.655,9.549H2.401V7.747h2.253V9.549z M4.655,6.846H2.401V5.043h2.253V6.846zM14.569,14.957H5.556V5.043h9.013V14.957z M17.724,14.957h-2.253v-1.803h2.253V14.957z M17.724,12.254h-2.253v-1.803h2.253V12.254zM17.724,9.549h-2.253V7.747h2.253V9.549z M17.724,6.846h-2.253V5.043h2.253V6.846z">
                            </path>
                        </svg>
                    </span>
                    <span class="hosted" v-if="movie.link && movie.link.includes('heavenflix')">
                        <svg class="svg-icon" viewBox="0 0 20 20">
                            <path
                                d="M10.219,1.688c-4.471,0-8.094,3.623-8.094,8.094s3.623,8.094,8.094,8.094s8.094-3.623,8.094-8.094S14.689,1.688,10.219,1.688 M10.219,17.022c-3.994,0-7.242-3.247-7.242-7.241c0-3.994,3.248-7.242,7.242-7.242c3.994,0,7.241,3.248,7.241,7.242C17.46,13.775,14.213,17.022,10.219,17.022 M15.099,7.03c-0.167-0.167-0.438-0.167-0.604,0.002L9.062,12.48l-2.269-2.277c-0.166-0.167-0.437-0.167-0.603,0c-0.166,0.166-0.168,0.437-0.002,0.603l2.573,2.578c0.079,0.08,0.188,0.125,0.3,0.125s0.222-0.045,0.303-0.125l5.736-5.751C15.268,7.466,15.265,7.196,15.099,7.03">
                            </path>
                        </svg>
                    </span>
                </div>-->
            </div>
            <div class="section" v-if="isAdmin > 0">
                <!--<button class="buttons" :onclick="() => infos()" title="Informations"><i
                class="fa-solid fa-circle-question"></i></button>-->
                <div v-if="isAdmin > 0"><button :onclick="() => disableMovie(movie.id, movie.name)" title="Désactiver"
                        class="buttons"><i class="fa-solid fa-square-xmark"></i></button></div>
                <div v-if="isAdmin > 0"><button :onclick="() => editMovie(movie.id)" title="Éditer"><i
                            class="fa-regular fa-pen-to-square"></i></button></div>
                <div v-if="isAdmin > 0"><button :onclick="() => deleteMovie(movie.id)" title="Supprimer"><i
                            class="fa-regular fa-trash-can"></i></button></div>
            </div>
        </div>
        <div class="lower">
            <h3>{{ movie.name }}</h3>
            <div class="cat">
                <span v-for="item in movie.cat" v-bind:key="item.id">{{ item }}</span>
            </div>
            <p class="randomDuration">
                <span v-if="movie.quality && movie.quality == '4K'">{{ movie.quality }} (UHD) - </span>
                <span v-else-if="movie.quality && movie.quality == '1080p'">{{ movie.quality }} (HD) - </span>
                <span v-else-if="movie.quality && movie.quality == '720p'">{{ movie.quality }} (DVD) - </span>
                <span v-else-if="movie.quality && movie.quality != '1080p' && movie.quality != '4K' && movie.quality
                != '720p'">{{ movie.quality }}
                    (SD)
                    - </span>
                <i class="fa-regular fa-clock"></i> {{ movie.duration }} <span class="randomDuration"
                    v-if="movie.annedesortie"> - <i class="fa-regular fa-calendar-days"></i> {{
                movie.annedesortie }}</span><span v-if="movie.creation_date != '2023-12-04T23:17:49.000Z'"> - Ajouté
                    le
                    {{ formatDate(movie.creation_date) }}</span>
            </p>
            <p class="randomDuration" v-if="movie && movie.quality">
                <span class="warn" v-if="movie.quality == '4K'">Attention, ce film est en 4K, veillez à ce
                    que votre
                    appareil accepte la 4K. En cas de cast sur un écran, Apple Play supporte ce format,
                    mais pour
                    un Chromecast, il vous faudra la version 4K du boitier.</span>
                <span class="warn"
                    v-else-if="movie.quality != '1080p' && movie.quality != '4K' && movie.quality != '720p'">Ce
                    film est
                    épinglé comme ayant une qualité inférieure à la norme. (Les vieux films peuvent être épinglés
                    comme de mauvaise qualité ayant un format ancien)</span>
                
            </p>
            <p class="randomDuration" v-if="movie && movie.isCanadian">
             <span class="warn" v-if="movie.isCanadian">
                    Ce film est en version canadienne, certaines expressions peuvent être différentes de la version française. Nous faisons en sorte d'avoir les versions françaises, mais il peut arriver que nous n'ayons pas le choix que de mettre la version canadienne surtout concernant les films récents. Bisous.
                </span>
            </p>
            <div class="players" v-if="movie">
                <!--<button :onclick="() => playMovie(this.getCodeFromLink(movie.link), movie.id)" title="Voir le film"><i
                        class="fa-regular fa-circle-play"></i> Lire</button>-->
                <span v-if="movie && movie.link && movie.link.includes('heavenflix.fr')"
                    @click="addToHistorical(movie.id, true)"><router-link
                        :to="{ name: 'player', params: { type: 'movie', index: 'noindex', id: movie.id, season: 'noseason', } }"
                        title="Voir le film"><i class="fa-regular fa-circle-play"></i>
                        Lire</router-link></span>
                <span v-else><a :href="movie.link" target="_blank">Voir</a></span>
                <div v-if="isInWatchLaterList(movie.id)">
                    <button :onclick="() => removeWatchLater(movie.id, movie.name)"
                        title="Retirer ce film de votre liste"><i class="fa-solid fa-minus"></i>
                        Ma liste</button>
                </div>
                <div v-else-if="!isInWatchLaterList(movie.id)">
                    <button :onclick="() => addWatchLater(movie.id, true, movie.name)"
                        title="Ajouter aux films à regarder plus tard"><i class="fa-solid fa-plus"></i>
                        Ma liste</button>
                </div>
            </div>
            <p class="resume" v-html="movie.description"></p>
            <div class="movieInfosContainer"
                v-if="infos || movie.trailerURL && movie.trailerURL.length > 0 || bandeAnnonce">
                <div class="bandeAnnonce" v-if="movie.trailerURL || bandeAnnonce">
                    <iframe width="560" height="455"
                        :src="`https://www.youtube.com/embed/${movie.trailerURL ? movie.trailerURL : bandeAnnonce}`"
                        frameborder="0" allowfullscreen></iframe>
                </div>
                <div class="movieInfos" v-if="infos">
                    <a :href="`https://www.imdb.com/title/${movie.imdb_id}/`" target="_blank"><svg id="home_img"
                            class="ipc-logo" xmlns="http://www.w3.org/2000/svg" width="64" height="32"
                            viewBox="0 0 64 32" version="1.1">
                            <g fill="#F5C518">
                                <rect x="0" y="0" width="100%" height="100%" rx="4"></rect>
                            </g>
                            <g transform="translate(8.000000, 7.000000)" fill="#000000" fill-rule="nonzero">
                                <polygon points="0 18 5 18 5 0 0 0"></polygon>
                                <path
                                    d="M15.6725178,0 L14.5534833,8.40846934 L13.8582008,3.83502426 C13.65661,2.37009263 13.4632474,1.09175121 13.278113,0 L7,0 L7,18 L11.2416347,18 L11.2580911,6.11380679 L13.0436094,18 L16.0633571,18 L17.7583653,5.8517865 L17.7707076,18 L22,18 L22,0 L15.6725178,0 Z">
                                </path>
                                <path
                                    d="M24,18 L24,0 L31.8045586,0 C33.5693522,0 35,1.41994415 35,3.17660424 L35,14.8233958 C35,16.5777858 33.5716617,18 31.8045586,18 L24,18 Z M29.8322479,3.2395236 C29.6339219,3.13233348 29.2545158,3.08072342 28.7026524,3.08072342 L28.7026524,14.8914865 C29.4312846,14.8914865 29.8796736,14.7604764 30.0478195,14.4865461 C30.2159654,14.2165858 30.3021941,13.486105 30.3021941,12.2871637 L30.3021941,5.3078959 C30.3021941,4.49404499 30.272014,3.97397442 30.2159654,3.74371416 C30.1599168,3.5134539 30.0348852,3.34671372 29.8322479,3.2395236 Z">
                                </path>
                                <path
                                    d="M44.4299079,4.50685823 L44.749518,4.50685823 C46.5447098,4.50685823 48,5.91267586 48,7.64486762 L48,14.8619906 C48,16.5950653 46.5451816,18 44.749518,18 L44.4299079,18 C43.3314617,18 42.3602746,17.4736618 41.7718697,16.6682739 L41.4838962,17.7687785 L37,17.7687785 L37,0 L41.7843263,0 L41.7843263,5.78053556 C42.4024982,5.01015739 43.3551514,4.50685823 44.4299079,4.50685823 Z M43.4055679,13.2842155 L43.4055679,9.01907814 C43.4055679,8.31433946 43.3603268,7.85185468 43.2660746,7.63896485 C43.1718224,7.42607505 42.7955881,7.2893916 42.5316822,7.2893916 C42.267776,7.2893916 41.8607934,7.40047379 41.7816216,7.58767002 L41.7816216,9.01907814 L41.7816216,13.4207851 L41.7816216,14.8074788 C41.8721037,15.0130276 42.2602358,15.1274059 42.5316822,15.1274059 C42.8031285,15.1274059 43.1982131,15.0166981 43.281155,14.8074788 C43.3640968,14.5982595 43.4055679,14.0880581 43.4055679,13.2842155 Z">
                                </path>
                            </g>
                        </svg></a>
                    <p v-if="infos && infos.Title">Titre international : <span>{{ infos.Title }} - {{ infos.Year }}</span></p>
                    <p v-if="infos && infos.Released && infos.Released != 'N/A'">Sortie officielle : <span>{{ infos.Released }}</span></p>
                    <p v-if="infos && infos.BoxOffice && infos.BoxOffice != 'N/A'">Box-Office :
                        <span v-if="convertedBoxOffice">{{ convertedBoxOffice }} ({{ infos.BoxOffice }})</span>
                        <span v-else>
                            {{ infos.BoxOffice }}
                        </span>
                    </p>
                    <p v-if="infos && infos.Production && infos.Production != 'N/A'">Production : <span>{{ infos.Production }}</span></p>
                    <p v-if="infos && infos.Director && infos.Director != 'N/A'">Réalisateur(s) : <span>{{ infos.Director }}</span></p>
                    <p v-if="infos && infos.Writer && infos.Writer != 'N/A'">Scénariste(s) : <span>{{ infos.Writer }}</span></p>
                    <p v-if="infos && infos.Actors">Acteur(s) : <span>{{ infos.Actors }}</span></p>
                    <p v-if="infos && infos.Rated && infos.Rated != 'N/A' && infos.Rated != 'PG' && infos.Rated != 'R'">Classification : <span>{{ infos.Rated }}</span></p>
                    <p v-if="infos && infos.Ratings && infos.Ratings.length > 0">Evaluation(s) :</p>
                </div>
                <div class="rating" v-if="infos && infos.Ratings && infos.Ratings.length > 0">
                    <div class="ratingCard" v-for=" element  in  infos.Ratings " v-bind:key="element">
                        <div v-if="element.Source == 'Internet Movie Database'">
                            <img src="https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/171_Imdb_logo_logos-512.png"
                                :alt="'logo de ' + element.Source" loading="lazy">
                        </div>
                        <div v-else-if="element.Source == 'Rotten Tomatoes'">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5b/Rotten_Tomatoes.svg/1200px-Rotten_Tomatoes.svg.png"
                                :alt="'logo de ' + element.Source" loading="lazy">
                        </div>
                        <div v-else-if="element.Source == 'Metacritic'">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Metacritic.svg/1200px-Metacritic.svg.png"
                                :alt="'logo de ' + element.Source" loading="lazy">
                        </div>
                        <p>{{ element.Source }}</p>
                        <span>{{ element.Value }}</span>
                    </div>
                </div>
            </div>
            <div class="suggestions" v-if="recommendationFilmsCollection && recommendationFilmsCollection.length > 0">
                <h3>{{ movie.collection[0] }}</h3>
                <div class="suggestionsContainer">
                    <div class="suggestionsCard" v-for=" movie  in  recommendationFilmsCollection "
                        v-bind:key="movie.id">
                        <img :onclick="() => changeSeenMovie(movie.id, movie.name)" :src="movie.posterURL"
                            alt="Affiche du film" loading="lazy"
                            onerror="this.src='https\://api.heavenflix.fr/images/error.webp'">
                        <span class="suggestName">{{ movie.name }}</span>
                    </div>
                </div>
            </div>
            <div class="suggestions" v-if="recommendationFilms && recommendationFilms.length > 0">
                <h3>{{ movie.cat[0] }}</h3>
                <div class="suggestionsContainer">
                    <div class="suggestionsCard" v-for=" movie  in  recommendationFilms " v-bind:key="movie.id">
                        <img :onclick="() => changeSeenMovie(movie.id, movie.name)" :src="movie.posterURL"
                            alt="Affiche du film" loading="lazy"
                            onerror="this.src='https\://api.heavenflix.fr/images/error.webp'">
                        <span class="suggestName">{{ movie.name }}</span>
                    </div>
                </div>
            </div>
            <div class="suggestions" v-if="recommendationFilmsCollection2 && recommendationFilmsCollection2.length > 0">
                <h3>{{ movie.collection[1] }}</h3>
                <div class="suggestionsContainer">
                    <div class="suggestionsCard" v-for=" movie  in  recommendationFilmsCollection2 "
                        v-bind:key="movie.id">
                        <img :onclick="() => changeSeenMovie(movie.id, movie.name)" :src="movie.posterURL"
                            alt="Affiche du film" loading="lazy"
                            onerror="this.src='https\://api.heavenflix.fr/images/error.webp'">
                        <span class="suggestName">{{ movie.name }}</span>
                    </div>
                </div>
            </div>
            <div class="suggestions" v-if="recommendationFilms2 && recommendationFilms2.length > 0">
                <h3>{{ movie.cat[1] }}</h3>
                <div class="suggestionsContainer">
                    <div class="suggestionsCard" v-for=" movie  in recommendationFilms2" v-bind:key="movie.id">
                        <img :onclick="() => changeSeenMovie(movie.id, movie.name)" :src="movie.posterURL"
                            alt="Affiche du film" loading="lazy"
                            onerror="this.src='https\://api.heavenflix.fr/images/error.webp'">
                        <span class="suggestName">{{ movie.name }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import the library
import { createToast } from 'mosha-vue-toastify';
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'
import AOS from 'aos';

export default {
    name: 'DisplayerMovieContainer',
    props: {
        movie: Object,
        isAdmin: Number,
    },
    data() {
        return {
            link: "https://api.heavenflix.fr",
            listOfUser: undefined,
            bandeAnnonce: undefined,
            API_KEY: 'AIzaSyBliI_rrX6jueTuKE5M2glCprJ6UZojs4g',
            hosterLink: 'https://darkibox.com/',
            recommendationFilms: undefined,
            recommendationFilms2: undefined,
            recommendationFilmsCollection: undefined,
            recommendationFilmsCollection2: undefined,
            infos: undefined,
            convertedBoxOffice: undefined,
            exchangeRateApiKey: '380c6ec2439ac58f95465c2f',
            exchangeRateApiLink: 'https://v6.exchangerate-api.com/v6/380c6ec2439ac58f95465c2f/latest/USD',
        }
    },
    setup() {
        const toast = () => {
            createToast('Wow, easy')
        }
        return {
            toast,
        };
    },
    methods: {
        // Fonction pour convertir des dollars en euros
        convertUSDToEUR: async function convertUSDToEUR(amountUSD) {
            try {
                // Supprimer le signe dollar et convertir le montant en entier
                const amountInteger = parseInt(amountUSD.replace('$', '').replace(/,/g, ''));

                // Récupérer les taux de change actuels
                const response = await fetch(this.exchangeRateApiLink);
                const data = await response.json();

                // Vérifier si la requête a réussi
                if (response.ok) {
                    // Obtenir le taux de change USD vers EUR
                    const usdToEurRate = data.conversion_rates.EUR;

                    // Convertir le montant de dollars en euros
                    const amountEUR = amountInteger * usdToEurRate;

                    // Arrondir le montant vers le bas pour retirer les centimes
                    const roundedAmountEUR = Math.floor(amountEUR);

                    // Formater le montant avec des virgules pour les milliers et sans décimales
                    const formattedAmountEUR = roundedAmountEUR.toLocaleString('en-US', { maximumFractionDigits: 0 });

                    this.convertedBoxOffice = formattedAmountEUR + '€';
                } else {
                    console.error('Erreur lors de la récupération des taux de change');
                    return null;
                }
            } catch (error) {
                console.error('Une erreur s\'est produite lors de la conversion:', error);
                return null;
            }
        },

        getOMDBInfos: async function getOMDBInfos() {
            if (!this.movie.imdb_id) return;
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + '/getToOMBD/' + this.movie.imdb_id + '/movie',
                requestOptions).then((res) => res.json()).then(data => {
                    if (data.msg) return console.log(data.msg);
                    this.infos = data;
                    if (this.infos.BoxOffice && this.infos.BoxOffice != 'N/A') this.convertUSDToEUR(this.infos.BoxOffice);
                })
        },
        formatDate(dateString) {
            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric", // Ajout de l'heure
                minute: "numeric", // Ajout des minutes
                hour12: false,
                timeZone: "Europe/Paris"
            };
            const date = new Date(dateString);
            const formattedDate = new Intl.DateTimeFormat("fr-FR", options).format(date);
            return formattedDate;
        },
        deleteMovie: async function deleteMovie(value) {
            let text = "Etes vous sur de vouloir supprimer ce film ?";
            if (confirm(text) == false) return alert('Vous avez annulé la suppresion du film.');
            const requestOptions = {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + '/deleteMovie/' + value, requestOptions).then((res) => res.json()).then(data => {
                if (data.msg) return console.log(data.msg);
                location.reload();
            })
        },
        editMovie: async function editMovie(value) {
            this.$router.push({
                name: 'edit',
                params: {
                    type: 'movie',
                    id: value,
                }
            });
        },
        disableMovie: async function disableMovie(value, name) {
            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + '/disableMovie/' + value, requestOptions).then((res) => res.json()).then(data => {
                if (data.msg) return console.log(data.msg);
                this.closeSeenMovie();
                this.showNotificationValidation('Le film ' + name + ' a été désactivé.');
                this.getAllRandom();
                this.randomMovie();
            })
        },
        addToHistorical: async function addToHistorical(id, isMovie) {
            let movieVerification;
            if (isMovie) movieVerification = 1;
            const date = new Date();
            let time = date.toLocaleString('fr-FR');
            let newTime = JSON.stringify(time);
            newTime = newTime.replace(/\//g, '-');
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    movieID: id,
                    time: newTime,
                    userID: localStorage.getItem('userId'),
                    isMovie: movieVerification,
                })
            };
            await fetch(this.link + '/addHistory', requestOptions).then((res) => res.json()).then(data => {
                if (data.error) return console.log(data.error);
            });
        },
        playMovie: async function playMovie(link, id) {
            this.addToHistorical(id, true);
            window.open(this.hosterLink + link, '_blank');
        },
        getCodeFromLink: function getCodeFromLink(link) {
            // Créez un nouvel objet URL
            const urlObject = new URL(link);
            // Accédez à la partie du chemin après le dernier "/"
            const chemin = urlObject.pathname;
            // Divisez le chemin en segments en utilisant "/"
            const segments = chemin.split('/');
            // Le code se trouve dans le dernier segment
            const code = segments[segments.length - 1];
            return code;
        },
        changeSeenMovie: async function changeSeenMovie(value) {
            this.$emit('change-movie', value); // Émet un événement 'change-movie' avec la valeur de la propriété 'value'
        },
        showNotificationValidation(text) {
            createToast(text, {
                position: 'top-center',
                type: 'success',
                transition: 'slide',
                showIcon: 'false',
                hideProgressBar: 'false',
                swipeClose: 'true',
                showCloseButton: 'false',
                timeout: 3000,
                //toastBackgroundColor: '#2D2E32',
            })
        },
        showNotificationWarning(text) {
            createToast(text, {
                position: 'top-center',
                type: 'warning',
                transition: 'slide',
                showIcon: 'false',
                hideProgressBar: 'false',
                swipeClose: 'true',
                showCloseButton: 'false',
                timeout: 3000,
                toastBackgroundColor: '#2D2E32',
            })
        },
        showNotificationInfo(text) {
            createToast(text, {
                position: 'top-center',
                type: 'info',
                transition: 'slide',
                showIcon: 'false',
                hideProgressBar: 'false',
                swipeClose: 'true',
                showCloseButton: 'false',
                timeout: -1,
                toastBackgroundColor: '#2D2E32',
            })
        },
        getBandeAnnonceFromTitle: async function getBandeAnnonceFromTitle(title, year, trailer) {
            if (trailer && trailer.length > 0) return;
            try {
                const requestOptions = {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                };
                const clearTitle = title.replace(/ /g, '+');
                const requestString = 'https://www.googleapis.com/youtube/v3/search?part=snippet&q=' + clearTitle + '+' + year + '+film+hd' + '+bande+annonce+vf&type=video&key=' + this.API_KEY;
                console.log(requestString);
                const response = await fetch(requestString, requestOptions);

                if (!response.ok) {

                    return;
                }

                const data = await response.json();
                console.log(data)

                if (data.msg) {

                    return;
                }

                if (data && data.items && data.items.length && data.items[0] && data.items[0].id && data.items[0].id.videoId) {
                    this.bandeAnnonce = data.items[0].id.videoId;
                }
            } catch (error) {
                return
            }
        },
        removeWatchLater: function removeWatchLater(id, name) {
            const requestOptions = {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
            };
            fetch(this.link + '/deleteFromWatchlater/' + localStorage.getItem('userId') + '/' + id, requestOptions).then((res) => res.json()).then(data => {
                if (data.msg) return console.log(data.msg);
                this.getListOfUser();
                this.showNotificationValidation(name + ' a été retiré de votre liste');
                // Émettre un événement vers le parent
                this.$emit('watch-later-removed', 'close-modal');
            })
        },
        addWatchLater: async function addWathchLater(id, isMovie, name) {
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    user_id: localStorage.getItem('userId'),
                    article_id: id,
                    isMovie: isMovie,
                }),
            };
            await fetch(this.link + '/addToWatchlater', requestOptions).then((res) => res.json()).then(data => {
                if (data.error != null) {
                    console.log(data.error);
                }
                this.showNotificationValidation(name + ' a été ajouté à votre liste.');
                this.getListOfUser()
            });
        },
        isInWatchLaterList: function isInWatchLaterList(id) {
            if (this.listOfUser && Array.isArray(this.listOfUser)) {
                return this.listOfUser.some(element => element.id === id);
            }
            return false; // retourne false si this.listOfUser n'est pas défini ou n'est pas un tableau
        },
        getListOfUser: async function getListOfUser() {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + '/getListFromUserId/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
                if (data.msg) return console.log(data.msg);
                this.listOfUser = data;
            })
        },
        getRecommandationsMovies: async function getRecommandationsMovies(category, id) {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + '/allMoviesRandomFromCategory/' + category + '/' + id, requestOptions).then((res) => res.json()).then(data => {
                if (data.msg) return console.log(data.msg);
                data = data.splice(0, 8);
                this.recommendationFilms = data;
            })
        },
        getRecommandationsMovies2: async function getRecommandationsMovies2(category, id) {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + '/allMoviesRandomFromCategory/' + category + '/' + id, requestOptions).then((res) => res.json()).then(data => {
                if (data.msg) return console.log(data.msg);
                data = data.splice(0, 8);
                this.recommendationFilms2 = data;
            })
        },
        getRecommandationsMoviesCollection: async function getRecommandationsCollection(collection, id) {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + '/allMoviesRandomFromCollection/' + collection + '/' + id, requestOptions).then((res) => res.json()).then(data => {
                if (data.msg) return console.log(data.msg);
                data = data.splice(0, 8);
                this.recommendationFilmsCollection = data;
            })
        },
        getRecommandationsMoviesCollection2: async function getRecommandationsCollection2(collection, id) {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + '/allMoviesRandomFromCollection/' + collection + '/' + id, requestOptions).then((res) => res.json()).then(data => {
                if (data.msg) return console.log(data.msg);
                data = data.splice(0, 8);
                this.recommendationFilmsCollection2 = data;
            })
        },
        closeSeenMovie() {
            this.$emit('close-modal'); // Émet un événement 'close-modal'
        },
    },
    created: function () {
        this.getListOfUser();
        this.getOMDBInfos();
        AOS.init();
    },
    mounted: function () {
        this.getBandeAnnonceFromTitle(this.movie.name, this.movie.annedesortie, this.movie.trailerURL);
        this.getRecommandationsMovies(this.movie.cat[0], this.movie.id);
        this.getRecommandationsMovies2(this.movie.cat[1], this.movie.id);
        if (this.movie.collection[0]) this.getRecommandationsMoviesCollection(this.movie.collection[0], this.movie.id);
        if (this.movie.collection[1]) this.getRecommandationsMoviesCollection2(this.movie.collection[1], this.movie.id);
    },
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;
$soft-grey: #2D2E32;

.displayerContainer {
    width: 60%;
    height: 100%;
    background: $dark-color;
    color: white;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;

    h3 {
        margin: 10px 0;
    }

    span {
        font-family: 'Montserrat';
        font-display: swap;
    }

    p {
        overflow-y: auto;
        margin-top: 10px;
        font-family: 'Montserrat';
        font-display: swap;

        &:first-child {
            color: white;
        }

        &:last-child {
            margin-top: 20px;
            width: 95%;
            height: fit-content;
        }

        color: rgba(255, 255, 255, 0.61);
    }

    .cat {
        width: fit-content;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        gap: 10px;

        span {
            width: fit-content;
            height: auto;
            background: $dark-red;
            font-size: 15px;
            padding: 10px 15px;
            border-radius: 50px;
            font-weight: bold;
            color: $light-color;
            font-family: 'Lato';
            font-display: swap;
        }
    }

    .exit {
        position: absolute;
        top: 10px;
        right: 20px;
        z-index: 3;
        background-color: transparent;

        button {
            border: none;
            background: transparent;
            color: $light-color;
            font-size: 30px;

            &:hover {
                cursor: pointer;
                transform-origin: center center;
                animation: spin 4s forwards;
            }

            i {
                color: white;
                filter: brightness(200%);
                filter: drop-shadow(0px 0px 0 black) drop-shadow(0px 0px 0 black);
            }
        }
    }

    .upper {
        width: 100%;
        height: 70vh;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        gap: 10px;

        .back {
            z-index: 0;
            width: 100%;
            height: 100%;
            filter: brightness(40%);
            object-fit: cover;
            animation: appear 2s;
        }

        .poster {
            width: 260px;
            aspect-ratio: 2/3;
            z-index: 2;
            position: absolute;
            bottom: 20px;
            left: 50px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                animation: appear 2s;
                border-radius: 10px;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.808);
            }

            .cardLogos {
                position: absolute;
                top: 10px;
                right: 10px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 10px;

                .trailer {
                    svg {
                        width: 30px;
                        height: 30px;
                        fill: $dark-red;
                        z-index: 1;
                        background: rgba(255, 255, 255, 0.2);
                        border-radius: 50%;
                        padding: 2px;
                        backdrop-filter: blur(5px);
                    }
                }

                .hosted {
                    svg {
                        width: 30px;
                        height: 30px;
                        fill: $dark-red;
                        z-index: 1;
                        background: rgba(255, 255, 255, 0.2);
                        border-radius: 50%;
                        padding: 2px;
                        backdrop-filter: blur(5px);

                    }
                }
            }
        }

        .section {
            position: absolute;
            bottom: 0;
            right: 0;
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateY(-15px);
            margin-right: 20px;

            button {
                margin: 0 10px;
                border: none;
                background: transparent;
                color: $light-color;
                font-size: 32px;
                transition-duration: 1s;
                filter: drop-shadow(0px 0px 0 black) drop-shadow(0px 0px 0 black);

                &:hover {
                    cursor: pointer;
                    transition-duration: 1s;
                    color: $dark-red;
                    filter: drop-shadow(0px 0px 0 transparent) drop-shadow(0px 0px 0 transparent);
                }
            }
        }
    }

    h3 {
        font-family: 'Lato';
        font-display: swap;
        font-size: 24px;
    }

    .lower {
        width: 95%;
        padding: 15px;
        margin: auto;

        .warn {
            border: 1px solid $dark-red;
            border-radius: 10px;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            color: white;
            background: #ff003c65;
        }

        .players {
            margin-top: 10px;
            display: flex;
            gap: 15px;

            span {
                display: flex;
                justify-content: center;
                align-items: center;

                a {
                    padding: 8px 0;
                    width: 140px;
                    font-family: 'Montserrat';
                    font-display: swap;
                    font-size: 16px;
                    border: none;
                    font-weight: bold;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                    background: $light-color;
                    border-radius: 10px;
                    color: $dark-color;
                    transition-duration: 0.2s;

                    i {
                        margin-right: 3px;
                        font-size: 18px;
                    }

                    &:hover {
                        cursor: pointer;
                        background: $dark-color;
                        color: $light-color;
                    }
                }
            }

            div {
                display: flex;
                justify-content: center;
                align-items: center;

                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 7px;
                    padding: 8px 0;
                    width: 140px;
                    font-family: 'Montserrat';
                    font-display: swap;
                    font-size: 16px;
                    border: none;
                    font-weight: bold;
                    border-bottom: 1px solid $dark-red;
                    background: transparent;
                    color: $light-color;
                    transition-duration: 0.4s;

                    i {
                        font-size: 18px;
                    }

                    &:hover {
                        cursor: pointer;
                        background: $dark-red;
                        border-radius: 10px;
                    }
                }
            }
        }

        .resume {
            margin-top: 10px;
            font-family: 'Montserrat';
            font-display: swap;
            font-size: 16px;
            line-height: 1.3;
            text-align: justify;
            text-justify: inter-word;
            overflow-y: auto;
            height: 100%;
            max-height: 100%;
        }

        .movieInfosContainer {
            width: 100%;
            max-width: 100%;
            background: $dark-color;
            border-radius: 10px;
            margin: 10px 0 35px 0;

            .bandeAnnonce {
                width: 95%;
                margin: 10px auto;
                padding-top: 35px;

                iframe {
                    width: 100%;
                    aspect-ratio: 3/2;
                    border-radius: 10px;
                }
            }

            .movieInfos {
                width: 90%;
                max-width: 90%;
                height: auto;
                padding: 10px 20px 25px 20px;
                gap: 5px;
                display: flex;
                flex-direction: column;


                p {
                    margin: 0;
                    font-family: 'Montserrat';
                    font-display: swap;
                    font-size: 16px;
                    font-weight: bold;
                    color: $light-color;

                    span {
                        font-weight: normal;
                        color: rgba(240, 240, 240, 0.863);
                    }
                }
            }

            .rating {
                width: 100%;
                height: auto;
                margin: 10px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                gap: 10px;
                padding-bottom: 30px;

                .ratingCard {
                    width: fit-content;
                    height: fit-content;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 33%;

                    div {
                        img {
                            width: 50px;
                            height: 50px;
                            object-fit: cover;
                        }
                    }

                    p {
                        padding-bottom: 3px;
                        font-family: 'Montserrat';
                        font-display: swap;
                        font-size: 18px;
                        font-weight: bold;
                        text-align: center;
                    }

                    span {
                        font-family: 'Montserrat';
                        font-display: swap;
                        font-size: 16px;
                        font-weight: bold;
                        text-align: center;

                    }
                }
            }
        }

        .connect {
            max-width: 100%;
            padding: 10px 10px;
            background: $soft-grey;
            border-radius: 10px;
            margin: 20px 0;

            p {
                margin: 0;
                font-size: 18px;
            }

            ul {
                list-style: none;

                margin: 0 0 0 10px;

                li {
                    font-size: 16px;
                    font-family: 'Montserrat';
                    font-display: swap;

                    b {
                        font-size: 18px;
                    }
                }
            }

        }

        .suggestions {
            width: 100%;
            height: auto;
            margin: auto;
            padding: 0px 0 40px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            h3 {
                margin: auto;
                font-size: 37px;
                font-family: 'Lato';
                font-display: swap;
                font-weight: 0;
                padding: 10px 0 30px 0;
            }

            .suggestionsContainer {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                gap: 40px;

                .suggestionsCard {
                    width: 260px;
                    height: auto;

                    img {
                        width: 100%;
                        height: 350px;
                        object-fit: cover;
                        z-index: 1;
                        box-shadow: 0px 0px 25px -1px rgba(0, 0, 0, 0.7);
                        border-radius: 10px;
                        transition-duration: 0.5s;

                        &:hover {
                            transition-duration: 0.5s;
                            filter: brightness(0.4);
                            cursor: pointer;
                        }
                    }

                    .suggestName {
                        display: block;
                        margin-top: 5px;
                        font-family: 'Montserrat';
                        font-weight: 600;
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1025px) {
    .displayerContainer {
        width: 90%;
    }
}

@media screen and (max-width: 769px) {
    .displayerContainer {
        width: 90%;
    }
}

@media screen and (max-width: 426px) {
    .displayerContainer {
        width: 100%;
        height: 100vh;
        border-radius: 0;
        overflow-y: auto;

        .upper {
            width: 100%;
            min-height: 450px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;

            .poster {
                width: 260px;
                aspect-ratio: 2/3;
                z-index: 2;
                position: absolute;
                bottom: 20px;
                left: 20px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    animation: appear 2s;
                    border-radius: 10px;
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.808);
                }

                .cardLogos {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 10px;

                    .trailer {
                        svg {
                            width: 30px;
                            height: 30px;
                            fill: $dark-red;
                            z-index: 1;
                            background: rgba(255, 255, 255, 0.2);
                            border-radius: 50%;
                            padding: 2px;
                            backdrop-filter: blur(5px);
                        }
                    }

                    .hosted {
                        svg {
                            width: 30px;
                            height: 30px;
                            fill: $dark-red;
                            z-index: 1;
                            background: rgba(255, 255, 255, 0.2);
                            border-radius: 50%;
                            padding: 2px;
                            backdrop-filter: blur(5px);

                        }
                    }
                }
            }

            .section {
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 1;
                transform: translateY(50px);
                margin-right: 5px;

                button {
                    width: fit-content;
                    margin: 0 6px;

                    i {
                        font-size: 30px;
                    }
                }
            }
        }

        .lower {
            margin-top: 50px;
            height: fit-content;
            padding: 0;
            margin-left: 15px;
            padding-bottom: 30px;

            p {
                width: 90%;
                overflow-y: auto;
                margin-top: 10px;
                font-family: 'Montserrat';
                font-display: swap;

                &:first-child {
                    color: white;
                    height: fit-content;
                }

                &:last-child {
                    height: 90%;
                    max-height: 90%;
                }

                color: rgba(255, 255, 255, 0.61);

                i {
                    margin-right: 3px;
                }
            }

            .connect {
                max-width: 100%;
                padding: 10px 10px;
                background: $soft-grey;
                border-radius: 10px;
                margin: 20px 0;

                p {
                    margin: 0;
                    font-size: 16px;
                }

                ul {
                    list-style: none;
                    margin: 5px 0 0 10px;

                    li {
                        font-size: 14px;
                        font-family: 'Montserrat';
                        font-display: swap;

                        b {
                            font-size: 16px;
                        }
                    }
                }

            }

            .bandeAnnonce {
                padding-top: 10px !important;

                iframe {
                    min-height: 200px;
                    height: 225px;
                }
            }

            .movieInfosContainer {
                width: 98%;
            }

            .suggestions {
                width: 100%;
                height: auto;
                margin: auto;
                padding: 20px 0;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                h3 {
                    margin: auto;
                    font-size: 34px;
                    font-family: 'Lato';
                    font-display: swap;
                    font-weight: 0;
                    padding: 10px 0 30px 0;
                }

                .suggestionsContainer {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 40px;

                    .suggestionsCard {
                        width: 90%;
                        height: auto;

                        img {
                            width: 100%;
                            height: 90%;
                            object-fit: cover;
                            z-index: 1;
                            box-shadow: 0px 0px 25px -1px rgba(0, 0, 0, 0.7);
                            border-radius: 10px;
                            transition-duration: 0.5s;

                            &:hover {
                                transition-duration: 0.5s;
                                filter: brightness(0.5);
                                cursor: pointer;
                            }
                        }

                        span {
                            width: 80%;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-family: 'Montserrat';
                            font-display: swap;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}
</style>
