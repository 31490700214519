<template>
    <div v-if="users != undefined" class="usersContent">
        <div class="usersTab" v-if="users != undefined">
            <Table :columns="columns" :data="users" @update="getUsers()" @sort="sort" />
        </div>
    </div>
</template>

<script>
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

import Table from './TableComponent.vue';

export default {
    name: "UsersDashboardComponent",
    components: {
        Table,
    },
    data() {
        return {
            link: "https://api.heavenflix.fr",
            users: undefined,
            columns: [
                { field: "lastLogin", label: "Dernière connexion" },
                //{ field: "id", label: "ID" },
                { field: "name", label: "Username" },
                { field: "email", label: "Email" },
                { field: "isAdmin", label: "Admin" },
                { field: "isban", label: "Banni" },
                { field: "delete", label: "Supprimer" }
            ],
            isAdmin: false,
        };
    },
    setup() {
        const toast = () => {
            createToast('Wow, easy')
        }
        return {
            toast,
        };
    },
    methods: {
        sort(order_by, sort_by) {
            let usersToSort = this.users;
            let column = order_by;
            let order = sort_by;
            if (order == null) return this.getUsers();
            usersToSort.sort((a, b) => {
                if (order === 'ASC') {
                    if (a[column] < b[column]) return -1;
                    if (a[column] > b[column]) return 1;
                    return 0;
                } else if (order === 'DESC') {
                    if (a[column] > b[column]) return -1;
                    if (a[column] < b[column]) return 1;
                    return 0;
                }
            });
        },
        async factoryUsers(users) {
            users.forEach((user) => {
                user.email = user.email ? true : false;
                user.isAdmin = user.isAdmin ? true : false;
                user.isban = user.isban ? true : false;
                user.delete = 'delete';
            });
            this.users = users;
            this.$emit("loading-ended");
        },
        async getUsers() {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + "/getUsers/" + localStorage.getItem('userId'), requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    this.factoryUsers(data);
                });
        },
        showNotification(type, text) {
            createToast(text, {
                position: 'top-center',
                type: type,
                showIcon: 'true',
                swipeClose: 'true',
                showCloseButton: 'false',
                hideProgressBar: 'false',
                transition: 'slide',
                timeout: 3000,
                //toastBackgroundColor: '#2D2E32',
            })
        },
        admin: async function admin() {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            };
            await fetch(this.link + "/getAdmin/" + localStorage.getItem("userId"), requestOptions)
                .then((res) => {
                    if (res.status == 401) {
                        this.$router.push("/login");
                    }
                    return res.json()
                })
                .then((data) => {
                    if (data.msg) return console.log(data.msg);
                    if (!data[0].isAdmin || data[0].isAdmin == 0) return this.$router.push("/home");
                    this.isAdmin = true;
                });
        },
    },
    watch: {
        '$route': 'onRouteChange',
    },
    created: function () {
        this.admin();
        this.getUsers();
    },
    mounted: function () {

    }
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$back-color: #15161b;
$dark-red: #E60036;
$soft-grey: #2D2E32;

.usersContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;

    .usersTab {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 50px;
    }
}

@media screen and (max-width: 768px) {}

@media screen and (max-width: 480px) {}
</style>