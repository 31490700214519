<template>
  <div class="home">
    <PlayerContainer />
    <FooterContainer />
  </div>
</template>

<script>
import PlayerContainer from '../components/PlayerContainer.vue';
import FooterContainer from '../components/FooterContainer.vue';

export default {
  name: 'HomeView',
  components: {
    PlayerContainer,
    FooterContainer
  }
}
</script>