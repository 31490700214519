<template>
  <div class="home">
    <PlayerEbooksContainer />
  </div>
</template>

<script>
import PlayerEbooksContainer from '../components/PlayerEbooksContainer.vue';

export default {
  name: 'HomeView',
  components: {
    PlayerEbooksContainer
  }
}
</script>