<template>
  <nav class="navMobile">
    <button class="burger" @click="() => { this.showBurger = !this.showBurger }">
      <i class="fas fa-bars"></i>
    </button>
    <div class="nav" v-if="showBurger">
      <div @click="() => { this.showBurger = false; }" class="linkContainer">
        <div class="link">
          <i class="fa-solid fa-chevron-up"></i>
          <p>Fermer</p>
        </div>
      </div>
      <router-link to="/movies" class="linkContainer">
        <div class="link">
          <i class="fas fa-home"></i>
          <p>Home</p>
        </div>
      </router-link>
      <router-link to="/dashboard/server" class="linkContainer">
        <div class="link">
          <i class="fas fa-server"></i>
          <p>Serveur</p>
        </div>
      </router-link>
      <router-link to="/dashboard/movies" class="linkContainer">
        <div class="link">
          <i class="fas fa-film"></i>
          <p>Films</p>
        </div>
      </router-link>
      <router-link to="/dashboard/series" class="linkContainer">
        <div class="link">
          <i class="fas fa-camera"></i>
          <p>Séries</p>
        </div>
      </router-link>
      <router-link to="/dashboard/ebooks" class="linkContainer">
        <div class="link">
          <i class="fas fa-book"></i>
          <p>Ebooks</p>
        </div>
      </router-link>
      <router-link to="/dashboard/showtv" class="linkContainer">
        <div class="link">
          <i class="fas fa-tv"></i>
          <p>Shows TV</p>
        </div>
      </router-link>
      <router-link to="/dashboard/users" class="linkContainer">
        <div class="link">
          <i class="fas fa-users"></i>
          <p>Utilisateurs</p>
        </div>
      </router-link>
      <router-link to="/dashboard/reports" class="linkContainer">
        <div class="link">
          <i class="fas fa-exclamation-triangle"></i>
          <p>Signalements</p>
          <span class="badge" v-if="signalementsCount != 0">{{ signalementsCount }}</span>
        </div>
      </router-link>
      <router-link to="/dashboard/requests" class="linkContainer">
        <div class="link">
          <i class="fas fa-question-circle"></i>
          <p>Demandes</p>
          <span class="badge" v-if="demandesCount != 0">{{ demandesCount }}</span>
        </div>
      </router-link>
    </div>
  </nav>
  <div class="homePage">
    <nav class="leftNav">
      <div class="logo">
        <img src="../assets/round_logo.png" alt="logo" @click="homeRedirect()" />
      </div>
      <div class="nav">
        <router-link to="/movies" class="linkContainer">
          <div class="link">
            <i class="fas fa-home"></i>
            <p>Home</p>
          </div>
        </router-link>
        <router-link to="/dashboard/server" class="linkContainer">
          <div class="link">
            <i class="fas fa-server"></i>
            <p>Serveur</p>
          </div>
        </router-link>
        <router-link to="/dashboard/movies" class="linkContainer">
          <div class="link">
            <i class="fas fa-film"></i>
            <p>Films</p>
          </div>
        </router-link>
        <router-link to="/dashboard/series" class="linkContainer">
          <div class="link">
            <i class="fas fa-camera"></i>
            <p>Séries</p>
          </div>
        </router-link>
        <router-link to="/dashboard/ebooks" class="linkContainer">
          <div class="link">
            <i class="fas fa-book"></i>
            <p>Ebooks</p>
          </div>
        </router-link>
        <router-link to="/dashboard/showtv" class="linkContainer">
          <div class="link">
            <i class="fas fa-tv"></i>
            <p>Shows TV</p>
          </div>
        </router-link>
        <router-link to="/dashboard/users" class="linkContainer">
          <div class="link">
            <i class="fas fa-users"></i>
            <p>Utilisateurs</p>
          </div>
        </router-link>
        <router-link to="/dashboard/reports" class="linkContainer">
          <div class="link">
            <i class="fas fa-exclamation-triangle"></i>
            <p>Signalements</p>
            <span class="badge" v-if="signalementsCount != 0">{{ signalementsCount }}</span>
          </div>
        </router-link>
        <router-link to="/dashboard/requests" class="linkContainer">
          <div class="link">
            <i class="fas fa-question-circle"></i>
            <p>Demandes</p>
            <span class="badge" v-if="demandesCount != 0">{{ demandesCount }}</span>
          </div>
        </router-link>
      </div>
    </nav>
    <div class="rightContent">
      <div class="loader" v-if="isLoading">
        <div class="loaderSpin">
          <img src="@/assets/loader.svg" alt="loader">
          <p>Chargement..</p>
        </div>
      </div>
      <div class="movieContainer" v-if="type == 'movies'" v-cloak>
        <div v-if="type == 'movies' && !isLoading && noData" class="serverError">
          <h1>Les données n'ont pu être récupérées..</h1>
        </div>
        <MoviesDashboardComponent v-if="type == 'movies'" @loading-ended="loadingEnd()" @no-data="setNoData()" />
      </div>
      <div class="serverContainer" v-if="type == 'server'" v-cloak>
        <ServerDashboardComponent v-if="type == 'server'" @loading-ended="loadingEnd()" @no-data="setNoData()" />
      </div>
      <div class="usersContainer" v-if="type == 'users'" v-cloak>
        <UsersDashboardComponent v-if="type == 'users'" @loading-ended="loadingEnd()" @no-data="setNoData()" />
      </div>
      <div class="seriesContainer" v-if="type == 'series'" v-cloak>
        <SeriesDashboardComponent v-if="type == 'series'" @loading-ended="loadingEnd()" @no-data="setNoData()" />
      </div>
      <div class="reportsContainer" v-if="type == 'reports'" v-cloak>
        <ReportsDashboardComponent v-if="type == 'reports'" @loading-ended="loadingEnd()" @no-data="setNoData()" />
      </div>
      <div class="demandesContainer" v-if="type == 'requests'" v-cloak>
        <DemandesDashboardComponent v-if="type == 'requests'" @loading-ended="loadingEnd()" @no-data="setNoData()" />
      </div>
      <div class="showtvContainer" v-if="type == 'showtv'" v-cloak>
        <ShowTVDashboardComponent v-if="type == 'showtv'" @loading-ended="loadingEnd()" @no-data="setNoData()" />
      </div>
      <div class="ebooksContainer" v-if="type == 'ebooks'" v-cloak>
        <EbooksDashboardComponent v-if="type == 'ebooks'" @loading-ended="loadingEnd()" @no-data="setNoData()" />
      </div>
    </div>
  </div>
</template>

<script>
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import AOS from 'aos';
import MoviesDashboardComponent from './MoviesDashboardComponent.vue';
import ServerDashboardComponent from './ServerDashboardComponent.vue';
import UsersDashboardComponent from './UsersDashboardComponent.vue';
import SeriesDashboardComponent from './SeriesDashboardComponent.vue';
import ReportsDashboardComponent from './ReportsDashboardComponent.vue';
import DemandesDashboardComponent from './DemandesDashboardComponent.vue';
import ShowTVDashboardComponent from './ShowTVDashboardComponent.vue';
import EbooksDashboardComponent from './EbooksDashboardComponent.vue';

export default {
  name: "DashboardContainer",
  components: {
    MoviesDashboardComponent,
    ServerDashboardComponent,
    UsersDashboardComponent,
    SeriesDashboardComponent,
    ReportsDashboardComponent,
    DemandesDashboardComponent,
    ShowTVDashboardComponent,
    EbooksDashboardComponent,
  },
  data() {
    return {
      link: "https://api.heavenflix.fr",
      darkiboxAPIKey: "4287et9m2dyhtmvmz2f8",
      darkiboxInfos: undefined,
      type: undefined,
      isLoading: true,
      signalementsCount: 0,
      demandesCount: 0,
      isAdmin: false,
      showBurger: false,
      noData: false,
    };
  },
  watch: {
    // Observer le changement de la route pour déclencher la logique appropriée
    '$route': 'onRouteChange',
  },
  setup() {
    const toast = () => {
      createToast('Wow, easy')
    }
    return {
      toast,
    };
  },
  methods: {
    setNoData() {
      this.noData = true;
    },
    loadingEnd() {
      this.isLoading = false;
    },
    homeRedirect() {
      this.$router.push("/home");
    },
    onRouteChange() {
      this.isLoading = true;
      this.initializeDashboard();
    },
    async getReports() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/allRequests", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.signalementsCount = data.length;
        });
    },
    async getDemandes() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/getAllDemandes", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.demandesCount = data.length;
        });
    },
    admin: async function admin() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      };
      await fetch(this.link + "/getAdmin/" + localStorage.getItem("userId"), requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          if (!data[0].isAdmin || data[0].isAdmin == 0) return this.$router.push("/home");
          this.isAdmin = true;
        });
    },
    showNotification(type, text) {
      createToast(text, {
        position: 'top-center',
        type: type,
        showIcon: 'true',
        swipeClose: 'true',
        showCloseButton: 'false',
        hideProgressBar: 'false',
        transition: 'slide',
        timeout: 3000,
        //toastBackgroundColor: '#2D2E32',
      })
    },
    initializeDashboard() {
      this.type = this.$route.params.type;
      this.getReports();
      this.getDemandes();
      if (this.$route.params.type == undefined) this.$router.push("/dashboard/server");
    }
  },
  created: function () {
    this.admin();
    AOS.init();
    this.initializeDashboard();
  },
  mounted: function () {
    AOS.init();
  }
};
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$back-color: #15161b;
$dark-red: #E60036;
$soft-grey: #2D2E32;
$left-nav-width: 230px;

* {
  padding: 0;
  margin: 0;
}

.navMobile {
  display: none;
  width: 100%;
  background: $back-color;

  .burger {
    all: unset;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: end;
    background: $back-color;
    margin-right: 20px;

    i {
      font-size: 20px;
      color: $light-color;
    }

  }

  .nav {
    z-index: 5;
    background: $soft-grey;
    position: absolute;
    top: 50px;
    right: 0;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    padding: 10px 0;

    .linkContainer {
      margin: 0 auto;
      width: 90%;
      max-width: 100%;
      background: rgba(0, 0, 0, 0.15);
      padding: 10px 5px 10px 10px;
      border-radius: 10px;
      position: relative;
      text-decoration: none;
      transition: 0.3s;

      .link {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        color: $light-color;
        gap: 8px;

        i {
          font-size: 20px;
        }

        p {
          font-size: 16px;
          font-family: 'Montserrat';
        }

        .badge {
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          position: absolute;
          background: $dark-red;
          color: $light-color;
          width: 20px;
          height: 20px;
          padding: 1px;
          text-align: center;
          border-radius: 50%;
          margin-left: 10px;
          font-family: 'Open Sans';
        }
      }

      &:hover {
        background: $soft-grey;
        color: $dark-red;
      }
    }
  }
}

.homePage {
  width: 100%;
  height: 100vh;
  background: $dark-color;
  display: flex;
  align-items: center;
  justify-content: start;

  .displayer {
    width: 100%;
    height: 100vh;
    background: rgba(20, 22, 28, 0.95);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: none;
  }

  .leftNav {
    width: $left-nav-width;
    height: 100vh;
    background: $back-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    .logo {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      border-bottom: 1px solid $soft-grey;

      img {
        width: 100px;
        cursor: pointer;
      }
    }

    .nav {
      width: 90%;
      margin-top: 10px;
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: start;
      margin-left: 5px;
      gap: 8px;

      .linkContainer {
        width: 90%;
        max-width: 100%;
        background: rgba(0, 0, 0, 0.15);
        padding: 10px 5px 10px 10px;
        border-radius: 10px;
        position: relative;
        text-decoration: none;
        transition: 0.3s;

        .link {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: start;
          color: $light-color;
          gap: 8px;


          i {
            font-size: 20px;
          }

          p {
            font-size: 16px;
            font-family: 'Montserrat';
          }

          .badge {
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            position: absolute;
            background: $dark-red;
            color: $light-color;
            width: 20px;
            height: 20px;
            padding: 1px;
            text-align: center;
            border-radius: 50%;
            margin-left: 10px;
            font-family: 'Open Sans';
          }
        }

        &:hover {
          background: $soft-grey;
          color: $dark-red;
        }
      }
    }
  }

  .rightContent {
    width: calc(100% - $left-nav-width);
    height: 100vh;
    background: $dark-color;
    display: flex;
    align-items: start;
    justify-content: start;
    overflow: auto;

    .serverError {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      h1 {
        font-size: 30px;
        color: $light-color;
        font-family: 'Open Sans';
      }
    }

    .loader {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba(0, 0, 0, 0.7);
      z-index: 5;

      .loaderSpin {
        background: $dark-color;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px 30px;
        border-radius: 10px;

        img {
          width: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 50%;
        }

        p {
          font-family: 'Lato';
          font-size: 20px;
          color: white;
        }
      }
    }

    .movieContainer {
      width: 90%;
      margin: 0 auto;
    }

    .serverContainer {
      width: 90%;
      margin: 0 auto;
    }

    .usersContainer {
      width: 90%;
      margin: 0 auto;
    }

    .seriesContainer {
      width: 90%;
      margin: 0 auto;
    }

    .reportsContainer {
      width: 90%;
      margin: 0 auto;
    }

    .demandesContainer {
      width: 90%;
      margin: 0 auto;
    }

    .showtvContainer {
      width: 90%;
      margin: 0 auto;
    }

    .ebooksContainer {
      width: 90%;
      margin: 0 auto;
    }
  }

}

@media screen and (max-width: 769px) {
  .navMobile {
    display: flex;
  }

  .homePage {
    .leftNav {
      display: none;
    }

    .rightContent {
      width: 100%;

    }
  }

}

@media screen and (max-width: 426px) {
  .navMobile {
    display: flex;

    .nav {
      width: 100%;
    }
  }

  .homePage {
    .leftNav {
      display: none;
    }

    .rightContent {
      width: 100%;

    }
  }
}
</style>
