<template>
    <div class="serverContent">
        <swiper class="statsContainer" :navigation="false" @swiper="onSwiper" :modules="modules" id="swiper"
            slidesPerView="auto" spaceBetween="20"
            v-if="serverStats != undefined || getServerStatus() || getApiStatus()">
            <swiper-slide class="stats" v-if="getServerStatus()">
                <h2>Serveur</h2>
                <p>{{ getServerStatus() ? 'Running 🟢' : 'Stopped 🔴' }}</p>
            </swiper-slide>
            <swiper-slide class="stats" v-if="getApiStatus()">
                <h2>API</h2>
                <p>{{ getApiStatus() ? 'Running 🟢' : 'Stopped 🔴' }}</p>
            </swiper-slide>
            <swiper-slide class="stats" v-if="serverStats && serverStats.memory">
                <h2>RAM</h2>
                <p>{{ serverStats != undefined && serverStats.memory ? serverStats.memory.usage : '..' }}%</p>
            </swiper-slide>
            <swiper-slide class="stats" v-if="serverStats && serverStats.cpu">
                <h2>CPU</h2>
                <p>{{ serverStats != undefined && serverStats.cpu ? serverStats.cpu.usage : '..' }}%</p>
            </swiper-slide>
            <swiper-slide class="stats" v-if="serverStats && serverStats.disk">
                <h2>Storage</h2>
                <p>{{ serverStats != undefined && serverStats.disk ? serverStats.disk.usage : '..' }}% ({{
                bytesFactory(serverStats.disk.used) + '/' + bytesFactory(serverStats.disk.total) }})</p>
            </swiper-slide>
            <swiper-slide class="stats" v-if="serverStats && serverStats.infos">
                <h2>Uptime</h2>
                <p>{{ serverStats != undefined ? serverStats.infos.uptime : '..' }}</p>
            </swiper-slide>
            <swiper-slide class="stats" v-if="serverStats && serverStats.infos">
                <h2>Plateforme</h2>
                <p>{{ serverStats != undefined ? (serverStats.infos.platform.charAt(0).toUpperCase() +
                serverStats.infos.platform.slice(1)) : '..' }}</p>
            </swiper-slide>
            <swiper-slide class="stats" v-if="serverStats && serverStats.infos">
                <h2>Node</h2>
                <p>{{ serverStats != undefined ? serverStats.infos.version : '..' }}</p>
            </swiper-slide>
        </swiper>
        <div class="actionnersContainer">
            <div class="actionner" v-if="darkiboxInfos">
                <p>{{ darkiboxInfos.result.premium ? 'Darkibox PREMIUM' : 'Darkibox à renouveler' }}</p>
                <p>{{ `Expiration : ${formatDate(darkiboxInfos.result.premium_expire)}` }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Zoom, Virtual } from 'swiper/modules';

export default {
    name: "ServerDashboardComponent",
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            link: "https://api.heavenflix.fr",
            serverStats: undefined,
            serverLink: "https://server.heavenflix.fr",
            darkiboxAPIKey: "4287et9m2dyhtmvmz2f8",
            darkiboxInfos: undefined,
        };
    },
    setup() {
        const onSwiper = (Swiper) => {
            console.log(Swiper)
        };
        const onSlideChange = (swiper) => {
            console.log(swiper)
        };
        const toast = () => {
            createToast('Wow, easy')
        }
        return {
            toast,
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, Zoom, Virtual],
        };
    },
    methods: {
        formatDate(dateString) {
            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric", // Ajout de l'heure
                minute: "numeric", // Ajout des minutes
                hour12: false,
                timeZone: "Europe/Paris"
            };
            const date = new Date(dateString);
            const formattedDate = new Intl.DateTimeFormat("fr-FR", options).format(date);
            return formattedDate;
        },
        getDarkiboxInfos: async function getDarkiboxInfos() {
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(" https://darkibox.com/api/account/info?key=" + this.darkiboxAPIKey, requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    if (data.msg == 'Wrong auth' || data.msg == 'Invalid key') return this.showNotification('danger', 'Erreur API Darkibox');
                    this.darkiboxInfos = data;
                });
        },
        bytesFactory(bytes) {
            // Renvoie les octets dans une unité lisible
            if (bytes === 0) return '0 octets';
            const sizes = ['octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];
            const i = Math.floor(Math.log(bytes) / Math.log(1024));
            const value = (bytes / Math.pow(1024, i)).toFixed(2);
            return `${value} ${sizes[i]}`;
        },
        async getApiStatus() {
            // Get server status by requesting to serverLink
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            };
            fetch(this.serverLink, requestOptions)
                .then((res) => {
                    if (res.status === 200) {
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((error) => {
                    // Handle error
                    console.error(error);
                });
        },
        async getServerStatus() {
            // Get server status by requesting to serverLink
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            };
            fetch(this.serverLink, requestOptions)
                .then((res) => {
                    if (res.status === 200) {
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((error) => {
                    // Handle error
                    console.error(error);
                });
        },
        async getServerStats() {
            // Get server stats by requesting to serverLink
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            };
            fetch(this.serverLink + '/server/stats', requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    this.$emit("loading-ended");
                    this.serverStats = data;
                })
                .catch((error) => {
                    // Handle error
                    console.error(error);
                });
        },
        showNotification(type, text) {
            createToast(text, {
                position: 'top-center',
                type: type,
                showIcon: 'true',
                swipeClose: 'true',
                showCloseButton: 'false',
                hideProgressBar: 'false',
                transition: 'slide',
                timeout: 3000,
                //toastBackgroundColor: '#2D2E32',
            })
        },
        admin: async function admin() {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            };
            await fetch(this.link + "/getAdmin/" + localStorage.getItem("userId"), requestOptions)
                .then((res) => {
                    if (res.status == 401) {
                        this.$router.push("/login");
                    }
                    return res.json()
                })
                .then((data) => {
                    if (data.msg) return console.log(data.msg);
                    if (!data[0].isAdmin || data[0].isAdmin == 0) return this.$router.push("/home");
                    this.isAdmin = true;
                });
        },
    },
    watch: {
        '$route': 'onRouteChange',
    },
    created: function () {
        this.admin();
        this.getServerStats();
        this.getDarkiboxInfos();
        this.intervalId = setInterval(this.getServerStats, 10000); // Appelle getServerStats toutes les 10 secondes
    },
    beforeUnmount: function () {
        clearInterval(this.intervalId); // Assurez-vous d'arrêter l'intervalle lorsque le composant est détruit
    },
    mounted: function () {

    }
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$back-color: #15161b;
$dark-red: #E60036;
$soft-grey: #2D2E32;


.serverContent {
    width: 100%;

    .statsContainer {
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 20px;
        cursor: pointer;

        .stats {
            max-width: 250px;
            min-width: 150px;
            width: fit-content;
            height: 75px;
            background: $back-color;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 10px;
            padding: 5px 10px;
            border: 1px solid $soft-grey;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

            h2 {
                font-size: 18px;
                color: $light-color;
                font-family: 'Open Sans';
            }

            p {
                font-size: 18px;
                color: rgba(255, 255, 255, 0.699);
                font-family: 'Roboto';
                margin-top: 5px;
            }
        }
    }

    .actionnersContainer {
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;

        .actionner {
            width: fit-content;
            min-width: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            cursor: pointer;
            gap: 10px;

            p {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 190px;
                width: fit-content;
                padding: 0 15px;
                height: 40px;
                background: $back-color;
                color: $light-color;
                border: none;
                border-radius: 20px;
                font-size: 16px;
                font-family: 'Open Sans';
                cursor: pointer;
                transition: 0.3s;
                border: 1px solid $soft-grey;
            }

            input {
                width: 300px;
                height: 40px;
                padding: 0 15px;
                background: $back-color;
                color: $light-color;
                border: none;
                border-radius: 20px;
                font-size: 16px;
                font-family: 'Open Sans';
                transition: 0.3s;
                border: 1px solid $soft-grey;
                outline: none;

                &:hover {
                    background: $back-color;
                    color: $light-color;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .serverContent {}
}

@media screen and (max-width: 480px) {
    .serverContent {}
}
</style>