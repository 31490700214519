<template>
    <div class="tableContainer">
        <table>
            <thead>
                <tr>
                    <!-- Table headers -->
                    <th v-for="column in columns" :key="column.field" @click="factorySort(column.field)">
                        <p>{{ column.label }}</p>
                        <span v-if="order_by === column.field">
                            <span v-if="sort_by === 'ASC'">▲</span>
                            <span v-else-if="sort_by === 'DESC'">▼</span>
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in data" :key="item.id">
                    <!-- Table cells -->
                    <td v-for="column in columns" :key="column.field">
                        <div v-if="column.field == 'lastLogin'">{{ formatDate(item[column.field]) }}</div>
                        <div v-else-if="typeof item[column.field] == 'boolean'">
                            <input type="checkbox" :checked="item[column.field]"
                                :id="'checkbox-' + column.field + '-' + item.id"
                                @click="booleansDispatch(column.field, item.id, item[column.field], item.name)"
                                :disabled="column.field === 'email'" />
                            <label :for="'checkbox-' + column.field + '-' + item.id"></label>
                        </div>
                        <span v-else-if="column.field == 'delete'">
                            <button @click="deleteUser(element.id, element.name)"><i
                                    class="fa-solid fa-trash-can"></i></button>
                        </span>
                        <span v-else-if="typeof item[column.field] !== 'boolean'">{{ item[column.field] }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';

export default {
    name: 'TableComponent',
    props: {
        columns: {
            type: Array,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            link: "https://api.heavenflix.fr",
            order_by: null,
            sort_by: null,
        };
    },
    setup() {
        const toast = () => {
            createToast('Wow, easy')
        }
        return {
            toast,
        };
    },
    mounted() {

    },
    methods: {
        formatDate(dateString) {
            const options = {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric", // Ajout de l'heure
                minute: "numeric", // Ajout des minutes
                hour12: false,
                timeZone: "Europe/Paris"
            };
            const date = new Date(dateString);
            const formattedDate = new Intl.DateTimeFormat("fr-FR", options).format(date);
            return formattedDate;
        },
        factorySort(order_by) {
            console.log(order_by)
            if (this.order_by != order_by) this.sort_by = null;
            this.order_by = order_by;
            if (this.sort_by == null) {
                this.sort_by = 'ASC';
            } else if (this.sort_by == 'ASC') {
                this.sort_by = 'DESC';
            } else if (this.sort_by == 'DESC') {
                this.sort_by = null;
            }
            this.$emit('sort', this.order_by, this.sort_by)
        },
        booleansDispatch(type, id, value, name) {
            if (type === 'isAdmin') {
                if (value) {
                    this.unsetAdmin(id, name)
                } else {
                    this.setAdmin(id, name)
                }
            } else if (type === 'isban') {
                if (value) {
                    this.unbanUser(id, name)
                } else {
                    this.banUser(id, name)
                }
            }
        },
        deleteUser: async function deleteUser(id, name) {
            const requestOptions = {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + "/delete/" + id + "/" + localStorage.getItem('userId'), requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    if (data.msg) return this.showNotification('danger', data.msg);
                    this.showNotification('success', name + ' a été supprimé avec succès.')
                    this.$emit('update');
                });
        },
        banUser: async function banUser(id, name) {
            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + "/ban/" + id + "/" + localStorage.getItem('userId'), requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    this.$emit('update');
                    if (data.msg) return this.showNotification('danger', data.msg);
                    this.showNotification('success', name + ' a été banni avec succès.')
                });
        },
        unbanUser: async function unbanUser(id, name) {
            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + "/unban/" + id + "/" + localStorage.getItem('userId'), requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    this.$emit('update');
                    if (data.msg) return this.showNotification('danger', data.msg);
                    this.showNotification('success', name + ' a été débanni avec succès.')
                });
        },
        setAdmin: async function setAdmin(id, name) {
            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + "/setAdmin/" + id + "/" + localStorage.getItem('userId'), requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    this.$emit('update');
                    if (data.msg) return this.showNotification('danger', data.msg);
                    this.showNotification('success', name + ' a été promu avec succès.')
                });
        },
        unsetAdmin: async function unsetAdmin(id, name) {
            const requestOptions = {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + "/unsetAdmin/" + id + "/" + localStorage.getItem('userId'), requestOptions)
                .then((res) => res.json())
                .then((data) => {
                    this.$emit('update');
                    if (data.msg) return this.showNotification('danger', data.msg);
                    this.showNotification('success', name + ' a été rétrogradé avec succès.')
                });
        },
        showNotification(type, text) {
            createToast(text, {
                position: 'top-center',
                type: type,
                showIcon: 'true',
                swipeClose: 'true',
                showCloseButton: 'false',
                hideProgressBar: 'false',
                transition: 'slide',
                timeout: 3000,
                //toastBackgroundColor: '#2D2E32',
            })
        },
    },
};
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$back-color: #15161b;
$dark-red: #E60036;
$soft-grey: #2D2E32;

.tableContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-y: auto;

    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        background: $back-color;
        border-radius: 15px;
        overflow: hidden;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);

        thead {
            background-color: $back-color;
            color: $light-color;

            th {
                padding: 15px 20px;
                border: 1px solid $dark-color;
                text-align: left;
                cursor: pointer;

                p {
                    display: inline-block;
                    font-family: 'Lato';
                    font-size: 18px;
                    cursor: pointer;
                }

                span {
                    font-size: 12px;
                    color: rgba(255, 255, 255, 0.5);
                    float: right;
                    transform: translateY(2px);
                }
            }
        }

        tbody {
            tr {
                &:nth-child(even) {
                    background-color: $back-color;
                }

                td {
                    padding: 10px 15px;
                    border: 1px solid $dark-color;
                    color: $light-color;
                    font-family: 'Montserrat';

                    input[type="checkbox"] {
                        display: none;
                    }

                    input[type="checkbox"]:disabled+label {
                        cursor: not-allowed;
                    }

                    input[type="checkbox"]+label {
                        position: relative;
                        padding-left: 25px;
                        cursor: pointer;
                    }

                    input[type="checkbox"]+label:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 16px;
                        height: 16px;
                        border: 1px solid #aaa;
                        background-color: $back-color;
                        border-radius: 3px;
                    }

                    input[type="checkbox"]:checked+label:before {
                        background-color: $light-color;
                        border-color: $dark-color;
                    }

                    input[type="checkbox"]:checked:disabled+label:before {
                        background-color: $dark-red;
                        border-color: #aaa;
                    }

                    button {
                        background-color: $dark-red;
                        color: $light-color;
                        border: none;
                        padding: 10px 20px;
                        border-radius: 5px;
                        font-family: 'Montserrat';
                        font-size: 16px;
                        cursor: pointer;
                        transition: background-color 0.3s;

                        &:hover {
                            background-color: $dark-color;
                        }
                    }
                }

                &:hover {
                    background-color: $soft-grey;
                    cursor: pointer;
                }
            }
        }
    }
}


@media (max-width: 768px) {
    .tableContainer {
        table {
            thead {
                th {
                    p {
                        font-size: 14px;
                    }

                    span {
                        font-size: 10px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .tableContainer {
        table {
            thead {
                th {
                    p {
                        font-size: 12px;
                    }

                    span {
                        font-size: 10px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
</style>