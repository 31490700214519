<template>
  <div class="homePage">
    <NavbarTop />
    <div class="historyMain">
      <h2>Votre historique</h2>
      <div class="noResult" v-if="!films.length">
        <img src="@/assets/loader.svg" id="loader">
        <p id="loaderText">Chargement..</p>
      </div>
      <div class="historyContainer" v-for="element in films" v-bind:key="element.id">
        <div class="historyLine" v-for="item in element" v-bind:key="item.id" :id="'line-' + item.historyID">
          <div class="history">
            <div class="details">
              <button class="suppressor" title="Supprimer de l'historique"
                :onclick="() => deleteFromHistory(item.historyID)"><i class="fa-solid fa-ban"></i></button>
              <a>{{ item.time }}</a>
              <div v-if="item.episode">
                <p>{{ item.name }} <span v-if="item.season">- Saison {{ item.season }}</span> (Episode {{ item.episode
                  }})
                </p>
              </div>
              <div v-else>
                <p>{{ item.name }}</p>
              </div>
            </div>
            <div class="interactions">
              <div v-if="isAdmin > 0"> <button :onclick="() => editMovie(item.id, 'movie')" title="Éditer"
                  class="buttons"> <i class="fa-solid fa-pen-to-square"></i></button></div>
              <!--<button class="buttons" :onclick="()=> infos()" title="Informations" > <i class="fa-solid fa-circle-question"></i></button>-->
              <div v-if="!item.episode"><button class="buttons player" @click="playMovie(item.id)" title="Revoir">
                  <i class="fa-solid fa-circle-play"></i> Reprendre</button></div>
              <div v-else><button class="buttons player" :onclick="() => playSerie(item.season, item.episode, item.id)"
                  title="Revoir"> <i class="fa-solid fa-circle-play"></i> Revoir</button></div>
            </div>
          </div>
        </div>
      </div>
      <div class="delete">
        <button :onclick="() => deleteHistory()">Vider l'historique</button>
      </div>
    </div>
  </div>
</template>
<script>
import NavbarTop from "./NavbarTop.vue";
export default {
  name: "HistoryContainer",
  components: {
    NavbarTop,
  },
  data() {
    return {
      link: "https://api.heavenflix.fr",
      films: [],
      isAdmin: 0,
    };
  },
  methods: {
    playSerie: async function playSerie(season, episode, id) {
      this.$router.push({ name: 'player', params: { type: 'serie', index: episode - 1, season: season, id: id } });
    },
    admin: async function admin() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      };
      await fetch(this.link + "/getAdmin/" + localStorage.getItem("userId"), requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.isAdmin = data[0].isAdmin;
        });
    },
    playMovie: async function playMovie(id) {
      this.$router.push({ name: 'player', params: { type: 'movie', index: 'noindex', id: id, season: 'noseason', } });
    },
    request: async function request(id) {
      localStorage.setItem("request", id);
      this.$router.push("/request");
    },
    deleteMovie: async function deleteMovie(value) {
      let text = "Etes vous sur de vouloir supprimer ce film ?";
      if (confirm(text) == false)
        return alert("Vous avez annulé la suppresion du film.");
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/deleteMovie/" + value, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          location.reload();
        });
    },
    editMovie: async function editMovie(value, type) {
      localStorage.setItem("edit", value);
      localStorage.setItem("editType", type);
      this.$router.push("/edit");
    },
    infos: async function infos() {
      let string = "Tous les films sont en français (il se peut qu'un changement de langue soit nécessaire). La qualité est réglable directement sur le lecteur.\n\nAfin de profiter du PREMIUM sur le lecteur, entrez le compte suivant : \n\nNom d'utilisateur: Huskies\nMot de passe: forqueen\n\nPour avoir la possibilité de caster les films, il est nécessaire d'être sur Google Chrome peu importe la plateforme.\n\nEn cas de problème, pensez à le signaler en utilisant le drapeau sur le film qui pose problème.";
      alert(string);
    },
    getHistory: async function getHistory() {
      this.films = [];
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      try {
        const res = await fetch(
          this.link + "/getHistory/" + localStorage.getItem("userId"),
          requestOptions
        );
        const data = await res.json();
        if (data.msg) return console.log(data.msg);
        for (const element of Array.from(data)) {
          if (element.isMovie) {
            await this.getHistoryMovies(element.watched_id, element.time, element.id);
          } else {
            await this.getHistorySeries(element.watched_id, element.time, element.id, element.episode, element.season);
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    getHistorySeries: async function getHistorySeries(value, time, id, episode, season) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      try {
        const res = await fetch(
          this.link + "/getHistorySerie/" + value,
          requestOptions
        );
        const data = await res.json();
        if (!data.length) return;
        if (data.msg) return console.log(data.msg);
        data[0].time = JSON.parse(time);
        data[0].historyID = id;
        data[0].episode = episode;
        data[0].season = season;
        this.films.push(data);
      } catch (err) {
        console.error(err);
      }
    },
    getHistoryMovies: async function getHistoryMovies(value, time, id) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      try {
        const res = await fetch(
          this.link + "/getHistoryMovie/" + value,
          requestOptions
        );
        const data = await res.json();
        if (data.msg) return console.log(data.msg);
        data[0].time = JSON.parse(time);
        data[0].historyID = id;
        this.films.push(data);
      } catch (err) {
        console.error(err);
      }
    },
    deleteHistory: function deleteHistory() {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      fetch(
        this.link + "/deleteHistoryOfUser/" + localStorage.getItem("userId"),
        requestOptions
      )
        .then((res) => {
          if (res.ok) {
            // Si le code de statut de la réponse est compris entre 200 et 299
            return res.json();
          } else {
            throw new Error(`Erreur lors de la requête DELETE : ${res.status}`);
          }
        })
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          location.reload();
        })
        .catch((error) => {
          // Gérez les erreurs ici
          console.error(error);
        });
    },
    deleteFromHistory: async function deleteFromHistory(value) {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/deleteFromHistory/" + value, requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          let line = document.getElementById('line-' + value);
          let parent = line.parentElement;
          parent.style.display = "none";
          this.getHistory();
        });
    },
    checkerLoading: async function checkerLoading() {
      let loader = document.getElementById('loader');
      let loaderText = document.getElementById('loaderText');
      if (!this.films.length) {
        loader.style.display = "none";
        loaderText.innerHTML = "Aucun résultat";
      }
    }
  },
  created: function () {
    this.getHistory();
    this.admin();
    setTimeout(() => this.checkerLoading(), 300);
  },
  mounted: function () { },
};
</script>
<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;

* {
  padding: 0;
  margin: 0;
}

.homePage {
  width: 100%;
  min-height: 100vh;
  background: $back-color;

  .noResult {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 60px;
      height: 60px;
    }

    p {
      font-size: 20px;
      color: rgba(255, 255, 255, 0.5);
      font-family: 'Lato';
    }
  }

  .historyMain {
    margin: auto;
    padding: 50px 0;
    width: 75%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;

    h2 {
      color: white;
      font-size: 30px;
      font-family: "Lato";
      margin-left: 15px;
      padding-bottom: 30px;
    }

    .delete {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 25px 10px 25px 0;

      button {
        width: fit-content;
        border: none;
        background: transparent;
        color: rgba(255, 255, 255, 0.8);
        border-bottom: $dark-red 2px solid;
        padding-bottom: 3px;
        font-size: 20px;
        font-family: "Montserrat";
        transition-duration: 0.5s;

        &:hover {
          cursor: pointer;
          transition-duration: 0.5s;
          color: $dark-red;
        }
      }
    }

    .historyContainer {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;

      .historyLine {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        border-bottom: #090a0e 2px solid;
        padding-bottom: 10px;

        .history {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 15px;

          .details {
            width: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;

            a {
              width: 170px;
              font-size: 16px;
              color: rgba(255, 255, 255, 0.5);
              font-family: "Montserrat";
            }

            p {
              color: white;
              font-size: 20px;
              font-family: "Lato";
            }

            .suppressor {
              background: transparent;
              font-size: 18px;
              margin-right: 10px;
              border: none;
              color: white;
              transition-duration: 0.5s;

              &:hover {
                cursor: pointer;
                color: $dark-red;
                transition-duration: 0.5s;
              }
            }
          }

          .interactions {
            width: max-content;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px;

            button {
              font-size: 18px;
              color: white;
              background: transparent;
              border: none;
              transition-duration: 0.5s;

              &:hover {
                cursor: pointer;
                color: $dark-red;
                transition-duration: 0.5s;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .homePage {
    .historyMain {
      width: 90%;
    }
  }
}

@media screen and (max-width: 769px) {
  .homePage {
    .historyMain {
      width: 95%;

      .historyContainer {
        .historyLine {
          .history {
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .details {
              width: 90%;
              gap: 10px;

              p {
                font-size: 16px;
                width: fit-content;
              }

              a {
                font-size: 14px;
                width: fit-content;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .homePage {
    .historyMain {
      width: 95%;

      .historyContainer {
        .historyLine {
          .history {
            flex-wrap: wrap;
            flex-direction: column;

            .details {
              p {
                font-size: 16px;
              }

              a {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
</style>