<template>
  <div class="home">
    <AddMovie/>
  </div>
</template>

<script>
import AddMovie from '../components/AddMovie.vue';

export default {
  name: 'AddView',
  components: {
    AddMovie
  }
}
</script>