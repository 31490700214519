<template>
    <div class="card" :id="'card-' + movie.id">
        <img :src="movie.backgroundURL" alt="Affiche du film"
            @error="this.src = 'https\://api.heavenflix.fr/images/error.webp'">
        <div class="delete" @click="wantDeleteWatchtime(watchtime)">
            <i class="fa-solid fa-trash"></i>
        </div>
        <div class="bigZone" v-if="type == 'movie'">
            <div class="watchingTime" :style="{ 'width': getDurationPercent(movie.watchtime, movie.movie_duration) }">
            </div>
            <div class="pres">
                <h4 class="titles">{{ movie.name }}</h4>
                <!--<span class="categories" v-if="movie.cat">{{ movie.cat }}</span>-->
            </div>
            <div class="duration" @click="wantToPlayMovie(movie.id)">
                <!--<span><i class="fa-regular fa-clock"></i> {{ movie.duration }}</span>-->
                <span><i class="fa-solid fa-circle-play"></i> Reprendre</span>
            </div>
        </div>
    </div>
</template>

<script>
import AOS from 'aos';

export default {
    name: 'CardMovieContainer',
    props: {
        movie: Object,
        type: String,
        watchtime: Number,
    },
    data() {
        return {

        }
    },
    methods: {
        wantToPlayMovie(id) {
            this.$emit('play-movie', id);
        },
        wantDeleteWatchtime(id) {
            this.$emit('delete-watchtime', id);
        },
        isOwnHosted(link) {
            if (link.includes('heavenflix')) {
                return true
            }
        },
        //Calculer le pourcentage entre le watchedTime et la durée du film
        getDurationPercent(watchtime, duration) {
            if (!watchtime || !duration) {
                return '0%'; // Ajoutez une unité à la valeur retournée
            }
            let percent = (watchtime / duration) * 100;
            // Réduire le pourcentage à un INT pour éviter les problèmes d'affichage
            percent = Math.round(percent);
            return percent + '%'; // Ajoutez une unité à la valeur retournée
        }
    },
    created() {
        // Your created hook code here
        AOS.init;
    },
    mounted() {
        // Your mounted hook code here
    }
}
</script>

<style scoped lang="scss">
$dark-color: #1b1b1b;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;
$soft-grey: #2D2E32;
$card-width: 400px;

.card {
    position: relative;
    background: transparent;
    width: $card-width;
    height: 220px;
    //width: 275px;
    margin: 10px 0;
    font-family: 'Lato';
    font-display: swap;
    border-radius: 15px;
    overflow: hidden;
    color: white;

    &:hover {
        transition-duration: 0.3s;
        cursor: pointer;

        img {
            filter: brightness(0.5);
        }
    }

    img {
        width: 100%;
        height: 220px;
        object-fit: cover;
        border-radius: 10px 10px 0 0;
        transition-duration: 0.3s;
    }

    .delete {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 22px;
        transition-duration: 0.3s;
        color: $dark-red;
    }

    .bigZone {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 45px;
        background-color: #13151de8;
        //background-color: #13151d;

        .watchingTime {
            position: absolute;
            top: 0;
            height: 5px;
            background: $dark-red;
            width: 0;
            transition: width 0.5s ease-in-out;
        }

        .pres {
            padding: 10px;
            max-width: 65%;
            height: fit-content;
            position: relative;

            h4 {
                max-width: 300px;
                font-size: 18px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: 'Open Sans';
            }

        }

        .duration {
            position: absolute;
            right: 10px;
            bottom: 0;
            height: 100%;
            border-radius: 10px;
            font-size: 16px;
            font-family: 'Open Sans';
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 5px;
                gap: 5px;
            }

            &:hover {
                color: $dark-red;
                font-weight: bold;
            }
        }
    }

}

//media query tablette + 1px (pour éviter les bugs)
@media screen and (max-width: 1025px) {
    .card {
        min-width: 25%;
        margin: 20px;

        img {
            width: 100%;
            max-height: 410px;
            object-fit: cover;
            image-rendering: auto;
        }
    }
}

@media screen and (max-width: 769px) {
    .card {
        width: 450px;
        margin: 20px;

        img {
            width: 100%;
            aspect-ratio: 16/9;
            object-fit: cover;
            image-rendering: auto;
        }
    }
}

@media screen and (max-width: 426px) {
    .card {
        max-width: 370px;
        margin: 20px;

        img {
            width: 100%;
            aspect-ratio: 16/9;
            object-fit: cover;
            image-rendering: auto;
        }
    }
}
</style>
