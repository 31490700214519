<template>
  <div class="mainContainer">
    <NavbarTop />
    <AddMenuNavigation />
    <div v-if="uploading" class="loadingContainer">
      <div class="loading">
        <img src="@/assets/loader.svg">
        <p>{{ loadingMessage }}</p>
        <span>Ne fermez pas cette fenêtre</span>
      </div>
    </div>
    <div class="form">
      <div class="entry">
        <label for="posterURL">Affiche </label>
        <input id="posterURL" name="posterURL" type="text" placeholder="http://www.pornhub.com/galery" />
      </div>
      <div class="entry">
        <label for="background">Background </label>
        <input id="background" name="background" type="text" placeholder="http://www.pornhub.com/galery" />
      </div>
      <div class="entry">
        <label for="imdb">IMDB ID </label>
        <input id="imdb" name="imdb" type="text" :placeholder="'Entrer un ID ou le lien de la page'" />
        <div v-if="name != undefined">
          <a :href="`http://www.imdb.com/find?s=all&q=${encodeURIComponent(name)}`" target="_blank">IMBD
            page</a>
        </div>
      </div>
      <div class="entry">
        <label for="name">Titre </label>
        <input id="name" name="name" type="text" placeholder="Titre" @change="getImdbPage()" v-model="movieName"/>
      </div>
      <div class="entry">
        <label for="description">Description </label>
        <input id="description" name="question" type="text" placeholder="Description" />
      </div>
      <div class="entry">
        <label for="duration">Durée </label>
        <input id="duration" name="duration" type="text" placeholder="1h20" />
      </div>
      <div class="entry">
        <label for="lien">Lien de streaming </label>
        <input id="lien" name="lien" type="text" placeholder="Lien de streaming" />
      </div>
      <div class="entry">
        <label for="download">Lien de téléchargement (Darkibox ou 1Fichier)</label>
        <input id="download" name="download" type="text" placeholder="Lien de téléchargement (Darkibox ou 1fichier)" @change="getDownloadLink()"/>
      </div>
      <div class="entry">
        <label for="trailer">Lien Youtube du trailer </label>
        <input id="trailer" name="trailer" type="text" placeholder="Lien Youtube du trailer"
          @change="getYoutubeCode()" />
      </div>
      <div class="entry">
        <label for="annee">Année de sortie </label>
        <input id="annee" name="annee" type="number" placeholder="Année de sortie" v-model="movieYear" />
      </div>
      <div class="entry">
        <label for="category1">Catégories</label>
        <select name="category1" id="categories1">
          <option v-bind:key="element.id" v-for="element in categories" :value="element.name">{{ element.name }}
          </option>
        </select>
      </div>
      <div class="entry">
        <select name="category2" id="categories2">
          <option v-bind:key="element.id" v-for="element in categories" :value="element.name">{{ element.name }}
          </option>
        </select>
      </div>
      <div class="entry checkbox">
        <input type="checkbox" id="canadian" name="canadian" />
        <label for="canadian"><i class="fa-brands fa-canadian-maple-leaf"></i></label>
      </div>
      <div class="file">
        <label for="file" class="label-file">{{ fileName }}</label>
        <input type="file" id="file" name="file" ref="file" accept=".mkv" @change="handleFileUpload()"/>
        <select name="speed" id="speed">
          <option selected>Vitesse</option>
          <option value="ultrafast">Ultrafast</option>
          <option value="superfast">Superfast</option>
          <option value="veryfast">Veryfast</option>
          <option value="faster">Faster</option>
          <option value="fast">Fast</option>
          <option value="medium">Medium</option>
          <option value="slow">Slow</option>
          <option value="slower">Slower</option>
          <option value="veryslow">Veryslow</option>
        </select>
      </div>
      <div class="boxesContainer">
        <div class="boxesDisplayer" @click="toggleShowCollections">
          <div v-if="showCollections">
            <p>Cacher les collections</p>
          </div>
          <div v-else-if="!showCollections">
            <p>Afficher les collections</p>
          </div>
          <div v-if="showCollections"><span><i class="fa-solid fa-minus"></i></span></div>
          <div v-else><span><i class="fa-solid fa-plus"></i></span></div>
        </div>
      </div>
      <div v-show="showCollections" class="boxes">
        <ul class="box">
          <li v-for="element in collections" v-bind:key="element.id">
            <label class="label" :id="'label-' + element.id">{{ element.name }}</label>
            <input class="collection" :id="'check-' + element.id" type="checkbox">
          </li>
        </ul>
      </div>
      <div class="entry">
        <button @click="addMovie()">Ajouter ce film</button>
      </div>
    </div>
  </div>
</template>

<script>
// import the library
import { createToast } from 'mosha-vue-toastify'
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'
import NavbarTop from '../components/NavbarTop.vue'
import AddMenuNavigation from './AddMenuNavigation.vue'
import io from "socket.io-client"
import slugify from 'slugify';

export default {
  name: 'AddMovie',
  components: {
    NavbarTop, AddMenuNavigation
  },
  data() {
    return {
      link: "https://api.heavenflix.fr",
      categories: undefined,
      collections: undefined,
      showCollections: true,
      isLoading: false,
      name: undefined,
      file: '',
      fileName: 'Choisir un fichier',
      serverLink: "https://server.heavenflix.fr",
      uploading: false,
      userId: localStorage.getItem('userId'),
      loadingMessage: undefined,
      movieName: undefined,
      movieYear: undefined,
    }
  },
  setup() {
    const toast = () => {
      createToast('Wow, easy')
    }
    return { toast }
  },
  //watcher sur movieLink et movieYear pour changer le lien de streaming avec la fonction changeLink les deux variables doivent être remplies pour call la fonction
  watch: {
    movieName: function () {
      if (this.movieName && this.movieYear) {
        this.changeLink(this.movieYear, this.movieName);
      }
    },
    movieYear: function () {
      if (this.movieName && this.movieYear) {
        this.changeLink(this.movieYear, this.movieName);
      }
    }
  },
  methods: {
    changeLink(year, name) {
      const sluggedName = slugify(name, {
        replacement: '_',
        lower: true,
        remove: /[^a-zA-Z0-9\s]/g
      });
      const newLink = 'https://server.heavenflix.fr/videos/movies/' + sluggedName + '_' + year + '.mp4';
      const streamingLinkInput = document.getElementById('lien');
      streamingLinkInput.value = newLink;
    },
    // Fonction pour upload un fichier vers le serveur Heavenflix via un formdata
    uploadFile: async function uploadFile(filename, year) {
            const file = document.getElementById("file").files[0];
            const speed = document.getElementById("speed").value;
            //Récupérer l'extension du fichier avec file.name et récupérer le dernier split à partir du point pour avoir '.avi', '.mkv', '.mp4
            const fileExtension = '.' + file.name.split('.').pop();
            const formData = new FormData();
            formData.append("extension", fileExtension);
            formData.append("speed", speed);
            formData.append("year", year);
            formData.append("name", filename);
            formData.append("file", file);
            if(!file) return this.showNotification('danger', 'Veuillez sélectionner un fichier');
            const requestOptions = {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: formData,
            };
            await fetch(this.serverLink + "/upload/file", requestOptions)
                .then((res) => {
                    if (res.status == 401) {
                        this.$router.push("/login");
                    }
                    return res.json()
                })
                .then((data) => {
                    if(data.code == 200) {
                        this.showNotification('success', 'Fichier uploadé avec succès');
                    } else {
                        this.showNotification('danger', 'Erreur lors de l\'upload du fichier');
                    }
                });
        },
    handleFileUpload(){
      this.file = this.$refs.file.files[0];
      this.fileName = this.file ? this.file.name : 'Choisir un fichier';
    },
    getYoutubeCode: function getYoutubeCode() {
      const linkInput = document.getElementById('trailer');
      let link = linkInput.value;
      if (link && !link.includes('youtube')) return;
      if (!link) return this.showNotification('warning', 'Le code Youtube n\'a pu être récupérer');
      link = link.split('=')[1];
      linkInput.value = link;
    },
    getImdbPage: async function getImdbPage() {
      var name = document.getElementById('name').value;
      if (!name) return this.name = undefined;
      this.name = name;
    },
    displayFileName: function displayFileName() {
      var fileInput = document.getElementById('file');
      if (!fileInput.files[0]) return this.fileName = undefined;
      this.fileName = fileInput.files[0].name;
    },
    toggleShowCollections() {
      this.showCollections = !this.showCollections;
    },
    showNotification(type, text) {
      createToast(text, {
        position: 'top-center',
        type: type,
        transition: 'zoom',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: 3000,
        //toastBackgroundColor: '#2D2E32',
      })
    },
    showNotificationWarning(text) {
      createToast(text, {
        position: 'top-center',
        type: 'warning',
        transition: 'zoom',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: 3000,
        //toastBackgroundColor: '#2D2E32',
      })
    },
    getCollections: async function getCollections() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getCollections', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.collections = data;
      })
    },
    admin: async function admin() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      };
      await fetch(this.link + '/getAdmin/' + localStorage.getItem('userId'), requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then(data => {
          if (data.msg) return console.log(data.msg);
          if (data[0].isAdmin == 0) return this.$router.push('/home');
        })
    },
    getCategories: async function getCategories() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getCategories', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.categories = data;
      })
    },
    getDownloadLink: async function getDownloadLink() {
      const downloadLink = document.getElementById('download').value;
      if (!downloadLink && downloadLink.length <= 0) return this.showNotification('warning', 'Le lien de téléchargement est manquant');
      if (downloadLink.includes('darkibox')) {
        let darkiCode = downloadLink.split('/')[3];
        const requestOptions = {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };
        await fetch('https://darkibox.com/api/file/direct_link?key=4287et9m2dyhtmvmz2f8&file_code=' + darkiCode, requestOptions).then((res) => res.json()).then(data => {
          if (data && data.result && data.result.versions && data.result.versions[1] && data.result.versions[1].url) {
            document.getElementById('download').value = data.result.versions[1].url;
          } else if (data && data.result && data.result.versions && data.result.versions[0] && data.result.versions[0].url) {
            document.getElementById('download').value = data.result.versions[0].url;
          } else {
            this.showNotification('danger', 'Erreur lors de la récupération du lien');
          }
        })
      } else if (downloadLink.includes('1fichier')) {
        let code = downloadLink.split('/')[3];
        if (!code) return this.showNotification('danger', 'Le lien n\'est pas valide');
        if (code.includes('&')) code = code.split('&')[0];
        if (code.includes('?')) code = code.replace('?', '');
        const requestOptions = {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };
        await fetch(this.serverLink + '/server/1fichier/' + code, requestOptions).then((res) => res.json()).then(data => {
          if (data && data.downloadLink) {
            document.getElementById('download').value = data.downloadLink;
          } else {
            this.showNotification('danger', 'Erreur lors de la récupération du lien de téléchargement');
          }
        })
      }
    },
    addMovie: async function addMovie() {
      let collectionsChecked = [];
      let boxes = document.getElementsByClassName('collection');
      var posterURL = document.getElementById('posterURL').value;
      var name = document.getElementById('name').value;
      var description = document.getElementById('description').value;
      var duration = document.getElementById('duration').value;
      var lien = document.getElementById('lien').value;
      var background = document.getElementById('background').value;
      var annee = document.getElementById('annee').value;
      var categories1 = document.getElementById("categories1").value;
      var categories2 = document.getElementById("categories2").value;
      var imdb = document.getElementById('imdb').value;
      var download = document.getElementById('download').value;
      var trailer = document.getElementById('trailer').value;
      var canadian = document.getElementById('canadian').checked;
      if (!posterURL) return this.showNotificationWarning('Lien de l\'affiche manquant!');
      if (!background) return this.showNotificationWarning('Lien de l\'image de fond manquant!');
      if (!name) return this.showNotificationWarning('Titre manquant!');
      if (!description) return this.showNotificationWarning('Description manquante!');
      if (!duration) return this.showNotificationWarning('Durée du film manquante!');
      if (!lien && !this.file) return this.showNotificationWarning('Lien vers le film manquant!');
      if (!annee) return this.showNotificationWarning('Année de sortie du film manquante!');
      if (categories1 == 'Action' && categories2 == 'Action') return this.showNotificationWarning('Catégorie dupliquée ou non renseignée!');
      if (!trailer) return this.showNotificationWarning('Lien Youtube du trailer manquant!');
      for (let i = 0; i < boxes.length + 40; i++) {
        let label = document.getElementById('label-' + i);
        let check = document.getElementById('check-' + i);
        if (check && check.checked) {
          collectionsChecked.push(label.innerText);
        }
      }
      if(!lien && this.file) lien = 'https://server.heavenflix.fr/videos/movies/' + slugify(name, {
        replacement: '_',
        lower: true,
        remove: /[^a-zA-Z0-9\s]/g
      }) + '_'+ annee + '.mp4';
      let strArray = JSON.stringify(collectionsChecked);
      strArray = strArray.replace("[", '');
      strArray = strArray.replace("]", '');
      strArray = strArray.replace(/"/g, "'");
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          posterURL: posterURL,
          name: name,
          description: description,
          duration: duration,
          lien: lien,
          annee: annee,
          imdb_id: imdb,
          category1: document.getElementById("categories1").value,
          category2: document.getElementById("categories2").value,
          backgroundURL: background,
          collections: strArray,
          downloadLink: download,
          trailerURL: trailer,
          isCanadian: canadian,
          uploaderId: localStorage.getItem('userId')
        })
      };
      await fetch(this.link + '/addMovie', requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        } else {
          if(data.code == 503) {
            this.showNotification('danger', data.message);
          }
          if(data.code == 200) {
            if(!this.file) this.uploading = false;
            const id = data.id;
            this.showNotification('success', data.message);
            if(this.file) this.uploadFile(name, annee, id);
          } else {
            console.error(data);
            this.showNotification('danger', 'Erreur lors de l\'ajout du film');
          }
        }
      });
    },
    toggleShowLoading() {
      this.isLoading = !this.isLoading;
    },
  },
  created: function () {
    this.admin();
    this.getCategories();
    this.getCollections();
    // Établissez la connexion WebSocket avec votre serveur
    this.socket = io("https://server.heavenflix.fr")

    this.socket.on(`conversion-movie-${this.userId}`, (res) => {
      if(res.ended) {
        this.uploading = false;
      }
      this.uploading = true;
      this.loadingMessage = undefined;
      this.loadingMessage = `${res.name} - ${res.message}`;
    });
  },
  beforeUnmount() {
    // Fermez la connexion WebSocket lorsque le composant est détruit
    if (this.socket) {
      this.socket.close()
    }
  },
  mounted: function () {
    document.body.style.overflowY = "auto";
  }
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;
$soft-grey: #2D2E32;

@mixin background-shadow($main-color) {
  background: $main-color;
  box-shadow: 0 0 10px $main-color;
}

.buton {

  border: none;
  padding: 15px;
  border-radius: 50%;

  &:first-child {
    @include background-shadow(dodgerblue);
  }

  &:last-child {
    @include background-shadow(red);
  }
}

.mainContainer {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: $back-color;

  label {
    margin-left: 5px;
    font-size: 18px;
    font-family: 'Lato';
    color: $light-color;
  }

  .loadingContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);

    .loading {
      background: $dark-color;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-direction: column;
      padding: 20px 25px;

      img {
        width: 100px;
      }

      p {
        color: $light-color;
        font-family: 'Montserrat';
        margin: 5px 0;
      }

      span {
        color: rgba(255, 255, 255, 0.6);
        font-family: 'Montserrat';
        font-size: 14px;
      }
    }
  }

  .selector {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;

    .selectorContainer {
      display: flex;
      border-radius: 5px;
      overflow: hidden;

      button {
        background: rgba(255, 255, 255, 0.2);
        border: none;
        font-size: 20px;
        font-family: 'Lato';
        color: white;
        padding: 10px 20px;

        &:hover {
          cursor: pointer;
        }

        &:last-child {
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .form {
    margin: auto;
    width: 60%;
    padding: 0 0 50px 0;

    .boxes {
      padding: 20px 5px;
      margin-top: 10px;
      background: $soft-grey;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      border-radius: 10px;

      .box {
        list-style: none;
        column-count: 4;

        li {
          display: flex;
          justify-content: space-between;
          padding: 2.5px 10px;
          border-radius: 5px;

          label {
            margin: 0 4px;
            font-family: 'Montserrat';
          }

          input {
            cursor: pointer;
          }

          &:hover {
            transition-duration: 0.4s;
            background: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }

    .checkbox {
      margin-top: 20px !important;
      display: block !important;

      label {
        margin-left: 15px !important;
        font-size: 20px !important;
        font-family: 'Lato' !important;
        background: $light-color !important;
        color: $dark-red !important;
        padding: 5px 15px !important;
        border-radius: 5px !important;
        cursor: pointer !important;
        box-shadow: 0 0 10px $dark-color !important;
      }
    }

    .file {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      select {
        width: 40%;
        background: $dark-red;
        border: none;
        color: white;
        font-family: 'Montserrat';
        font-size: 16px;
        padding: 10px 10px;
        outline: none;
        border-radius: 10px;

        option {
          background: $light-color;
          color: $back-color;
        }

        &:hover {
          cursor: pointer;
        }
      }

      input[type="file"] {
        display: none;
      }

      .label-file {
        margin-top: 5px;
        cursor: pointer;
        background: $dark-color;
        width: fit-content;
        max-width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        padding: 10px 15px;
        border-radius: 10px;
        transition-duration: 0.4s;

        &:hover {
          background: $dark-red;
          transition-duration: 0.4s;
        }
      }
    }

    .entry {
      width: 100%;
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 1;

      div {
        margin-top: 20px;

        a {
          width: fit-content;
          background: $dark-color;
          color: $light-color;
          text-decoration: none;
          padding: 10px 20px;
          border-radius: 10px;
          transition-duration: 0.7s;
          font-size: 16px;
          font-family: 'Montserrat';

          &:hover {
            cursor: pointer;
            transition-duration: 0.7s;
            background: $dark-red;
            color: white;
          }
        }
      }

      label {
        margin-bottom: 5px;
        font-size: 20px;
        font-family: 'Lato';
        color: $light-color;
      }

      select {
        width: 100%;
        background: $dark-red;
        border: none;
        color: white;
        font-family: 'Montserrat';
        font-size: 16px;
        padding: 10px 10px;
        outline: none;
        border-radius: 10px;

        option {
          background: $light-color;
          color: $back-color;
        }

        &:hover {
          cursor: pointer;
        }
      }

      input[type="checkbox"] {
        width: 15px;
        height: 15px;
        cursor: pointer;
        margin-top: 5px;
      }

      input[type="text"] {
        width: 100%;
        height: 40px;
        outline: none;
        border: none;
        padding-left: 10px;
        background: #ffffff;
        font-size: 16px;
        font-family: 'Montserrat';
        border-radius: 10px;
      }

      input[type="number"] {
        width: 100%;
        height: 40px;
        outline: none;
        border: none;
        padding-left: 10px;
        background: #ffffff;
        font-size: 16px;
        font-family: 'Montserrat';
        border-radius: 10px;
      }

      .inputFileName {
        display: flex;
        align-items: center;
        gap: 10px;

        div {
          color: $light-color;
          font-family: 'Montserrat';
          font-size: 16px;
        }
      }

      .label-file {
        margin-top: 5px;
        cursor: pointer;
        background: $dark-color;
        width: fit-content;
        padding: 10px 15px;
        border-radius: 10px;
        transition-duration: 0.4s;

        &:hover {
          background: $dark-red;
          transition-duration: 0.4s;
        }
      }

      input[type="file"] {
        display: none;
      }

      button {
        margin: auto;
        width: 50%;
        border: none;
        background: rgb(194, 194, 194);
        color: $back-color;
        padding: 10px 0;
        transition-duration: 0.7s;
        font-size: 16px;
        font-family: 'Lato';
        border-radius: 10px;

        &:hover {
          cursor: pointer;
          transition-duration: 0.7s;
          background: $dark-red;
          color: white;
        }
      }

      ul {
        list-style: none;
        padding: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        li {
          background: $dark-color;
          padding: 10px 15px;
          border-radius: 10px;
          transition: 0.4s;

          a {
            text-decoration: none;
            color: $light-color;
            font-family: 'Montserrat';
          }

          &:hover {
            cursor: pointer;
            background: $dark-red;
            transition: 0.4s;
          }
        }
      }

      &:last-child {
        margin-top: 20px;
      }
    }

    .boxesContainer {
      width: 100%;
      margin-top: 30px;

      .boxesDisplayer {
        width: 98%;
        color: white;
        background: $soft-grey;
        border: none;
        font-size: 17px;
        font-family: 'Lato';
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 15px;
        cursor: pointer;
      }
    }
  }
}

//Affichage mobile
@media screen and (max-width: 426px) {
  .mainContainer {
    .form {
      width: 90%;

      .entry {
        input {
          max-width: 98%;
        }
      }

      .boxes {
        padding: 20px 0;

        .box {
          columns: 1;
          width: 100%;

          li {
            margin: auto;
            width: 92%;
            padding: 2.5px 0;
          }
        }
      }

      .boxesContainer {
        .boxesDisplayer {
          width: 92% !important;
        }
      }
    }
  }
}
</style>
