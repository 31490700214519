<template>
  <div class="home">
    <EbooksContainer />
  </div>
</template>

<script>
import EbooksContainer from '../components/EbooksContainer.vue';

export default {
  name: 'HomeView',
  components: {
    EbooksContainer
  }
}
</script>