<template>
  <div class="home">
    <AdminContainer/>
  </div>
</template>

<script>
import AdminContainer from '../components/AdminContainer.vue';

export default {
  name: 'AdminView',
  components: {
    AdminContainer
  }
}
</script>