<template>
  <div class="home">
    <AddShowTV />
  </div>
</template>

<script>
import AddShowTV from '../components/AddShowTV.vue';

export default {
  name: 'AddShowTVView',
  components: {
    AddShowTV
  }
}
</script>