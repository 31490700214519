<template>
  <div class="home">
    <AddSerie />
  </div>
</template>

<script>
import AddSerie from '../components/AddSerieEpisode.vue';

export default {
  name: 'AddSerieEpisodeView',
  components: {
    AddSerie
  }
}
</script>