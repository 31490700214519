<template>
  <div class="home">
    <AddEbook />
  </div>
</template>

<script>
import AddEbook from '../components/AddEbook.vue';

export default {
  name: 'AddView',
  components: {
    AddEbook
  }
}
</script>