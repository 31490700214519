<template>
  <div class="home">
    <HistoryContainer/>
  </div>
</template>

<script>
import HistoryContainer from '../components/HistoryContainer.vue';

export default {
  name: 'HistoryView',
  components: {
    HistoryContainer
  }
}
</script>