<template>
  <div class="homePage">
    <NavbarTop />
    <div class="explainContainer">
      <div class="explain">
        <h2>Comment fonctionne <b>Checker</b> ?</h2>
        <p>C'est un outil permettant de détecter les liens morts dans la séléction complète des films. Lancer un scan
          interrogera l'hébergeur Uptobox sur la validité du fichier et donc, du streaming.</p>
        <p>Un scan dure environ 2 minutes 30 secondes durant lesquelles, votre appareil questionnera l'hébergeur à la
          vitesse de 100 films pour 20 secondes soit 1 film toutes les 200 millisecondes. Vérifiez que votre appareil soit
          suffisamment puissant pour éviter un crash ou une surchauffe. Pour que le scan se déroule le mieux possible,
          restez sur cet onglet durant toute la durée de l'analyse.</p>
        <p>Les films ayant un lien mort seront affichés en dessous et seront désactivés de la liste de films disponibles
          jusqu'à l'ajout d'un lien valide.</p>
      </div>
    </div>
    <div class="checkerWrap">
      <button :onclick="() => deadlinksCounter()"><span id="textLoadingBtn">Lancer un scan</span><img id="loader"
          src="@/assets/loader_dark.svg"></button>
      <div class="loading" v-if="this.isScanning">
        <a>Films scannés: <b>{{ scanningNumber }}</b>/{{ totalFilms }}</a>
        <a>Votre analyse a détecté <b>{{ deadFilms.length }}</b> film(s) invalide(s)</a>
      </div>
    </div>
    <div class="cardContainer">
      <div class="card" v-for="element in deadFilms" v-bind:key="element.id" data-aos="fade-up"
        data-aos-anchor-placement="center-bottom" data-aos-duration="2000">
        <div class="cardInner" v-for="item in element" v-bind:key="item.id" :id="'card-' + element.id">
          <img :src="item.posterURL">
          <h3>{{ item.name }}</h3>
          <a>Lien invalide: <span>Désactivé</span></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarTop from './NavbarTop.vue';
export default {
  name: 'CheckerContainer',
  components: {
    NavbarTop
  },
  data() {
    return {
      totalFilms: 0,
      films: undefined,
      isAdmin: 0,
      codes: [],
      link: "https://api.heavenflix.fr",
      scanningNumber: 0,
      deadFilms: [],
      isScanning: false,
    }
  },
  methods: {
    getTotalMovies: async function getTotalMovies() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/maxMovies', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.totalFilms = data[0].count;
      })
    },
    getAll: async function getAll() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/allMoviesEnableOrNot', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.films = data;
        for (let i = 0; i < data.length; i++) {
          let code = data[i].link.replace('https://uptostream.com/', '');
          let self = this.codes;
          self.push(code);
        }
      })
    },
    getMovieFromLink: async function getMovieFromLink(link) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getMovieSearchFromLink/' + link, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        let self = this.deadFilms;
        self.push(data);
        this.disableMovie(data[0].id);
      })
    },
    disableMovie: async function disableMovie(id) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/disableMovie/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
      })
    },
    deadlinksCounter: async function deadlinksCounter() {
      if (this.isScanning) return alert('Vous avez déjà lancé une analyse');
      let loader = document.getElementById('loader');
      let text = document.getElementById('textLoadingBtn');
      text.innerHTML = "Chargement..";
      loader.style.display = "block";
      this.isScanning = true;
      for (let i = 0; i < this.codes.length; i++) {
        setTimeout(() => this.deadlinksVerification(this.codes[i]), i * 200);
      }
    },
    deadlinksVerification: async function deadlinksVerification(index) {
      if (this.scanningNumber == this.totalFilms - 1) {
        let loader = document.getElementById('loader');
        let text = document.getElementById('textLoadingBtn');
        text.innerHTML = "Merci!";
        loader.style.display = "none";
      }
      this.scanningNumber++;
      if (index.includes("youtube")) return console.log("Vidéo Youtube: à vérifier manuellement!");
      if (index.includes("https://uptobox.com/")) return index = index.replace('https://uptobox.com/', '');
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        data: {
          token: 'f2a9bd8000b0b570ea81ea8d906ab94b4p8jt'
        }
      };
      await fetch('https://uptobox.com/api/link/info?fileCodes=' + index, requestOptions).then((res) => res.json()).then(data => {
        if (data.data.list[0].error) { this.getMovieFromLink(index); }
      });
    },
  },
  created: function () {
    this.getAll();
    this.getTotalMovies();
  },
  mounted: function () {

  }
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;

* {
  padding: 0;
  margin: 0;
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.homePage {
  width: 100%;
  min-height: 100vh;
  background: $back-color;

  .explainContainer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;

    .explain {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      h2 {
        color: white;
        font-size: 35px;
        font-family: 'Lato';
        margin: 10px 0;
      }

      p {
        color: rgba(255, 255, 255, 0.5);
        font-size: 20px;
        font-family: 'Montserrat';
        margin: 10px 0;
      }
    }
  }

  .checkerWrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    gap: 20px;

    .loading {
      display: flex;
      flex-direction: column;
    }

    button {
      width: fit-content;
      border: none;
      padding: 10px 20px;
      background: $dark-red;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      transition: all 1s;
      animation: appear 1s forwards;
      font-size: 20px;
      font-family: 'Lato';

      &:hover {
        cursor: pointer;
      }

      img {
        display: none;
        width: 30px;
        height: 30px;
        animation: appear 1s forwards;
      }
    }

    a {
      font-family: 'Montserrat';
      color: white;
      animation: appear 1s forwards;
    }
  }

  .cardContainer {
    margin: auto;
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 60px 0;
    gap: 40px;

    .card {
      width: fit-content;
      height: auto;
      animation: appear 1s forwards;

      .cardInner {
        background: transparent;
        width: 275px;
        margin: 10px 0;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        font-family: 'Lato';
        animation: slide 2s;
        border-radius: 15px;
        color: white;
        margin: 20px;
        animation: appear 1s forwards;

        img {
          width: 100%;
          height: 410px;
          object-fit: cover;
          image-rendering: auto;
          border-radius: 15px;
          box-shadow: 4px 4px 17px 5px rgba(0, 0, 0, 0.72);
        }

        h3 {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 10px 0 0 7px;
        }

        a {
          color: white;
          font-size: 16px;
          font-family: 'Montserrat';

          span {
            color: $dark-red;
            font-weight: bold;
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .homePage {
    .explainContainer {

      .explain {
        width: 70%;

        h2 {
          font-size: 20px;
        }

        p {
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .homePage {
    .checkerWrap {
      flex-direction: column;
      text-align: center;
    }

    .explainContainer {

      .explain {
        width: 80%;

        h2 {
          font-size: 25px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .homePage {
    .checkerWrap {
      flex-direction: column;
      text-align: center;
    }

    .explainContainer {

      .explain {
        width: 80%;

        h2 {
          font-size: 25px;
        }

        p {
          font-size: 16px;
        }
      }
    }

    .cardContainer {
      width: 95%;
      flex-direction: column;

      .card {
        width: 80%;
      }
    }
  }
}
</style>
