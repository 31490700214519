<template>
  <div class="home">
    <HomeContainer />
  </div>
</template>

<script>
import HomeContainer from '../components/HomeContainer.vue';

export default {
  name: 'HomeView',
  components: {
    HomeContainer
  }
}
</script>