<template>
    <div v-if="seriesStats != undefined" class="serieContent">
        <swiper class="statsContainer" :navigation="false" @swiper="onSwiper" v-if="seriesStats != undefined"
            :modules="modules" id="swiper" slidesPerView="auto" spaceBetween="20">
            <swiper-slide class="stats">
                <h2>Total</h2>
                <p>{{ seriesStats.total != 0 ? seriesStats.total : '..' }}</p>
            </swiper-slide>
            <swiper-slide class="stats">
                <h2>Activés</h2>
                <p>{{ seriesStats.enabled != 0 ? seriesStats.enabled : '..' }}</p>
            </swiper-slide>
            <swiper-slide class="stats">
                <h2>Désactivés</h2>
                <p>{{ seriesStats.disabled != 0 ? seriesStats.disabled : '..' }}</p>
            </swiper-slide>
        </swiper>
        <div class="actionsBoardContainer">
            <div class="actionsBoard">
                <div class="action" @click="goToAddSerie()">
                    <i class="fa-solid fa-circle-plus"></i>
                    <p>Ajouter</p>
                </div>
                <div class="action">
                    <i class="fa-regular fa-circle-xmark"></i>
                    <p>Tout désactiver</p>
                </div>
                <div class="action">
                    <i class="fa-regular fa-circle-check"></i>
                    <p>Tout activer</p>
                </div>
                <div class="action" @click="scanUncheckedEpisodes()">
                    <i class="fa-solid fa-magnifying-glass-location"></i>
                    <p>Lancer un scan</p>
                </div>
                <div class="action" @click="evaluteFiles()">
                    <i class="fa-regular fa-circle-question"></i>
                    <p>Evaluer les fichiers</p>
                </div>
            </div>
        </div>
        <div class="actionnersContainer">
            <div class="actionner">
                <button @click="getUncheckedEpisodes()">Episodes non vérifiés</button>
                <button @click="getDisabledSeries()">Séries désactivés</button>
                <button @click="resetDisplay()">Libérer l'affichage</button>
                <input type="search" id="searchDisabled" placeholder="Rechercher un film désactivé"
                    @change="searchDisabled()" />
            </div>
        </div>
        <div class="filmsSection" v-if="series && series.length > 0 && wantWideCards == true">
            <CardMovieContainer v-for="item in series" v-bind:key="item.id" :movie="item" v-cloak
                @click="editSerie(item.id)" :type="'serie'" />
        </div>
        <div class="filmsSection" v-else-if="series && series.length > 0 && wantWideCards == false">
            <CardMoviePosterContainer v-for="item in series" v-bind:key="item.id" :movie="item" v-cloak
                @click="editSerie(item.id)" :type="'serie'" />
        </div>
    </div>
</template>

<script>
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Zoom, Virtual } from 'swiper/modules';
import CardMovieContainer from './CardMovieContainer.vue';
import CardMoviePosterContainer from './CardMoviePosterContainer.vue';

export default {
    name: "SeriesDashboardComponent",
    components: {
        Swiper,
        SwiperSlide,
        CardMovieContainer,
        CardMoviePosterContainer,
    },
    data() {
        return {
            link: "https://api.heavenflix.fr",
            seriesStats: undefined,
            isLoading: true,
            series: [],
            wantWideCards: JSON.parse(localStorage.getItem('wantWideCards')) == true ? true : false,
            selectedSerie: undefined,
            episodes: undefined,
            serverLink: "https://server.heavenflix.fr",
        };
    },
    setup() {
        const onSwiper = (Swiper) => {
            console.log(Swiper)
        };
        const onSlideChange = (swiper) => {
            console.log(swiper)
        };
        const toast = () => {
            createToast('Wow, easy')
        }
        return {
            toast,
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, Zoom, Virtual],
        };
    },
    methods: {
        async scanUncheckedEpisodes() {
            //Faire la requête au serveur pour scanner les épisodes
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };
            await fetch(this.serverLink + "/series/check/episodes/unchecked", requestOptions)
                .then((res) => {
                    if (res.status == 401) {
                        this.$router.push("/login");
                    }
                    return res.json()
                })
                .then((data) => {
                    console.log(data);
                    if (data.message) return console.log(data.message);
                    if (data && data.code == 200) return this.showNotification('success', 'Le scan a été a réussi');
                });
        },
        async getUncheckedEpisodes() {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };
            await fetch(this.link + "/get/unchecked/episodes", requestOptions)
                .then((res) => {
                    if (res.status == 401) {
                        this.$router.push("/login");
                    }
                    return res.json()
                })
                .then((data) => {
                    console.log(data);
                    if (data.message) return console.log(data.message);
                    if (data.length == 0) return this.showNotification('info', 'Aucun épisode non vérifié');
                    this.episodes = [];
                    data.forEach(element => {
                        this.episodes.push(element);
                    });
                });
        },
        editSerie(id) {
            this.$router.push("/edit/serie/" + id);
        },
        goToAddSerie() {
            this.$router.push("/addSerie");
        },
        //Converti la durée de secondes en heures et minutes
        convertToHoursMinutes(duration) {
            // Obtenez la durée en secondes
            const durationInSeconds = duration;

            // Convertissez la durée en heures et minutes
            const hours = Math.floor(durationInSeconds / 3600);
            const minutes = Math.floor((durationInSeconds % 3600) / 60);

            // Formatez la durée
            const formattedDuration = `${hours}h${minutes.toString().padStart(2, '0')}`;
            return formattedDuration;

        },
        async evaluteFiles() {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };
            this.showNotification('info', 'Les fichiers sont en cours d\'évaluation');
            await fetch("https://server.heavenflix.fr/movies/allfiles/infos", requestOptions)
                .then((res) => {
                    if (res.status == 401) {
                        this.$router.push("/login");
                    }
                    return res.json()
                })
                .then((data) => {
                    if (data.msg) return console.log(data.msg);
                    if (data && data.done) return this.showNotification('success', 'Les fichiers ont été évalués');
                });
        },
        searchDisabled() {
            this.collections = [];
            const search = document.getElementById('searchDisabled').value;
            if (search == '') return;
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };
            fetch(this.link + "/getDisabledSerieSearch/" + search, requestOptions)
                .then((res) => {
                    if (res.status == 401) {
                        this.$router.push("/login");
                    }
                    return res.json()
                })
                .then((data) => {
                    if (data.msg) return console.log(data.msg);
                    if (data.length == 0) return this.showNotification('info', 'Aucun film trouvé');
                    this.series = [];
                    data.forEach(element => {
                        element.categories = JSON.parse(element.categories);
                        element.categories = element.categories.join(' & ');
                        this.series.push(element);
                    });
                });
        },
        resetDisplay() {
            this.series = [];
        },
        async getDisabledSeries() {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };
            await fetch(this.link + "/allSeriesDisable", requestOptions)
                .then((res) => {
                    if (res.status == 401) {
                        this.$router.push("/login");
                    }
                    return res.json()
                })
                .then((data) => {
                    console.log(data)
                    if (data.msg) return console.log(data.msg);
                    this.series = [];
                    if (data.length == 0) return this.showNotification('info', 'Aucun film trouvé');
                    data.forEach(element => {
                        element.categories = JSON.parse(element.categories);
                        element.categories = element.categories.join(' & ');
                        this.series.push(element);
                    });
                });
        },
        seenMovie: async function seenMovie(value) {
            this.selectedMovie = undefined;
            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" },
            };
            await fetch(this.link + '/getMovie/' + value, requestOptions).then((res) => res.json()).then(data => {
                if (data.msg) return console.log(data.msg);
                data[0].cat = JSON.parse(data[0].cat);
                if (data[0].collection) data[0].collection = JSON.parse(data[0].collection);
                this.selectedMovie = data[0];
            })
            document.body.style.overflow = 'hidden';
        },
        closeSeenMovie: async function closeSeenMovie() {
            document.body.style.overflow = 'auto';
            this.selectedMovie = undefined;
        },
        convertToGigabytes(filesize) {
            const gigabytes = filesize / (1024 * 1024 * 1024);
            // Formatez la taille en Go avec deux décimales
            return gigabytes.toFixed(2);
        },
        getSeriesStats: async function getSeriesStats() {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            };
            await fetch(this.link + "/getSeriesStats", requestOptions)
                .then((res) => {
                    if (res.status == 401) {
                        this.$router.push("/login");
                    }
                    return res.json()
                })
                .then((data) => {
                    console.log(data)
                    this.$emit("loading-ended");
                    this.isLoading = false;
                    if (data.msg) return console.log(data.msg);
                    if (data && data.length <= 0) {
                        this.$emit("no-data");
                        this.showNotification('danger', 'Les données n\'ont pas pu être récupérées');
                        return;
                    }
                    this.seriesStats = data[0];
                });
        },
        showNotification(type, text) {
            createToast(text, {
                position: 'top-center',
                type: type,
                showIcon: 'true',
                swipeClose: 'true',
                showCloseButton: 'false',
                hideProgressBar: 'false',
                transition: 'slide',
                timeout: 3000,
                //toastBackgroundColor: '#2D2E32',
            })
        },
        admin: async function admin() {
            const requestOptions = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            };
            await fetch(this.link + "/getAdmin/" + localStorage.getItem("userId"), requestOptions)
                .then((res) => {
                    if (res.status == 401) {
                        this.$router.push("/login");
                    }
                    return res.json()
                })
                .then((data) => {
                    if (data.msg) return console.log(data.msg);
                    if (!data[0].isAdmin || data[0].isAdmin == 0) return this.$router.push("/home");
                    this.isAdmin = true;
                });
        },
    },
    watch: {
        '$route': 'onRouteChange',
    },
    created: function () {
        this.admin();
        this.getSeriesStats();
    },
    mounted: function () {

    }
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$back-color: #15161b;
$dark-red: #E60036;
$soft-grey: #2D2E32;

.serieContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;

    .displayer {
        width: 100%;
        height: 100vh;
        background: rgba(20, 22, 28, 0.95);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: none;
    }

    .filmsSection {
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        padding-bottom: 75px;
        margin-top: 20px;
    }

    .statsContainer {
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 20px;
        cursor: pointer;

        .stats {
            max-width: 200px;
            min-width: 150px;
            width: fit-content;
            height: 75px;
            background: $back-color;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 10px;
            padding: 5px 10px;
            border: 1px solid $soft-grey;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

            h2 {
                font-size: 18px;
                color: $light-color;
                font-family: 'Open Sans';
            }

            p {
                font-size: 18px;
                color: rgba(255, 255, 255, 0.699);
                font-family: 'Roboto';
                margin-top: 5px;
            }
        }
    }

    .actionsBoardContainer {
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 20px;

        .actionsBoard {
            padding: 20px 0;
            width: 100%;
            background: $back-color;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            flex-wrap: wrap;
            border-radius: 10px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
            gap: 20px;
            border: 1px solid $soft-grey;

            .action {
                width: fit-content;
                min-width: 100px;
                height: 100px;
                background: $soft-grey;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-radius: 10px;
                padding: 5px 10px;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
                transition: 0.3s;
                cursor: pointer;

                i {
                    font-size: 30px;
                    color: $light-color;
                }

                p {
                    font-size: 16px;
                    color: $light-color;
                    font-family: 'Open Sans';
                    margin-top: 10px;
                    min-width: 110px;
                    text-align: center;
                }

                &:hover {
                    background: $dark-red;
                }
            }

        }
    }

    .actionnersContainer {
        width: 100%;
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;

        .actionner {
            width: fit-content;
            min-width: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            cursor: pointer;
            gap: 10px;

            button {
                min-width: 190px;
                width: fit-content;
                padding: 0 15px;
                height: 40px;
                background: $back-color;
                color: $light-color;
                border: none;
                border-radius: 20px;
                font-size: 16px;
                font-family: 'Open Sans';
                cursor: pointer;
                transition: 0.3s;
                border: 1px solid $soft-grey;

                &:hover {
                    background: $back-color;
                    color: $dark-red;
                }
            }

            input {
                width: 300px;
                height: 40px;
                padding: 0 15px;
                background: $back-color;
                color: $light-color;
                border: none;
                border-radius: 20px;
                font-size: 16px;
                font-family: 'Open Sans';
                transition: 0.3s;
                border: 1px solid $soft-grey;
                outline: none;

                &:hover {
                    background: $back-color;
                    color: $light-color;
                }
            }
        }
    }

    .collectionsSection {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        padding-bottom: 75px;
        margin-top: 50px;

        .collection {
            width: 90%;
            height: fit-content;
            background: $back-color;
            display: flex;
            align-items: center;
            justify-content: start;
            border-radius: 10px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
            transition: 0.3s;
            overflow: hidden;
            border: 1px solid $soft-grey;
            gap: 20px;

            img {
                width: 20%;
                aspect-ratio: 16/9;
                object-fit: cover;
                border-right: 1px solid $soft-grey;
            }

            .collectionDetails {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: center;
                width: 100%;

                h2 {
                    width: fit-content;
                    font-size: 24px;
                    color: $light-color;
                    font-family: 'Open Sans';
                    margin: 0 0 10px 5px;
                }

                #add-collection-name {
                    all: unset;
                    width: fit-content;
                    font-size: 24px;
                    color: $light-color;
                    font-family: 'Open Sans';
                    margin: 0 0 10px 5px;
                }

                .collectionOptions {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    gap: 10px;
                    width: 90%;

                    input {
                        width: 80%;
                        min-width: 300px;
                        height: 40px;
                        padding: 0 15px;
                        background: $back-color;
                        color: $light-color;
                        border: none;
                        border-radius: 20px;
                        font-size: 16px;
                        font-family: 'Open Sans';
                        transition: 0.3s;
                        border: 1px solid $soft-grey;
                        outline: none;

                        &:hover {
                            background: $back-color;
                            color: $light-color;
                        }
                    }

                    button {
                        width: fit-content;
                        height: 40px;
                        padding: 0 15px;
                        background: $back-color;
                        color: $light-color;
                        border: none;
                        border-radius: 20px;
                        font-size: 16px;
                        font-family: 'Open Sans';
                        cursor: pointer;
                        transition: 0.3s;
                        border: 1px solid $soft-grey;

                        &:hover {
                            background: $dark-red;
                            border: 1px solid $dark-red;
                        }
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 768px) {
    .movieContent {
        .actionnersContainer {
            .actionner {
                flex-wrap: wrap;

                button {
                    min-width: 150px;
                }
            }
        }

        .actionsBoardContainer {
            .actionsBoard {
                .action {
                    height: auto;
                    padding: 10px 20px;

                    i {
                        font-size: 20px;
                    }

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .movieContent {
        .actionnersContainer {
            .actionner {
                flex-wrap: wrap;

                button {
                    min-width: 150px;
                }
            }
        }

        .actionsBoardContainer {
            .actionsBoard {
                .action {
                    height: auto;
                    padding: 10px 20px;

                    i {
                        font-size: 20px;
                    }

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
</style>