<template>
  <div class="home">
    <EditMovie/>
  </div>
</template>

<script>
import EditMovie from '../components/EditContainer.vue';

export default {
  name: 'EditView',
  components: {
    EditMovie
  }
}
</script>