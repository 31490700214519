<template>
  <div class="mainContainer">
    <NavbarTop />
    <div class="loader" v-if="isLoading">
      <div class="loaderSpin">
        <img src="@/assets/loader.svg" alt="loader">
        <p>Chargement..</p>
      </div>
    </div>
    <div v-if="uploadingMovie && type == 'movie'" class="loadingContainer">
      <div class="loading">
        <img src="@/assets/loader.svg">
        <p>{{ uploadingMessage }}</p>
        <span>Ne fermez pas cette fenêtre</span>
      </div>
    </div>
    <div class="noArticle" v-if="!isLoading && !filmToEdit && !serieToEdit && !ebookToEdit && !showTVToEdit">
      <h1>Article introuvable</h1>
      <div class="noArticleOptions">
        <button @click="firstMovie()">Précédent</button>
        <button @click="nextMovie()">Suivant</button>
      </div>
    </div>
    <div class="movieEditor" v-if="type == 'movie'">
      <div class="fullForm" v-for="element in filmToEdit" v-bind:key="element.id">
        <div class="images">
          <img :src="element.posterURL" onerror="this.src='https\://api.heavenflix.fr/images/error.webp'">
          <img :src="element.backgroundURL">
        </div>
        <div class="changer">
          <button @click="firstMovie()">Précédent</button>
          <button class="upload" @click="upload()">Upload</button>
          <span v-if="element.link && element.link.includes('heavenflix')">
            <router-link class="watch" :to="`/player/movie/${element.id}/noseason/noindex`">Voir</router-link>
          </span>
          <span v-else><a class="watch" :href="element.link" target="_blank">Voir</a></span>
          <span v-if="!element.enabled"><button class="activation" @click="enableMovie()">Activer</button></span>
          <span v-else><button class="activation" @click="disableMovie()">Désactiver</button></span>
          <button @click="deleteMovie(element.id)" class="delete">Supprimer</button>
          <button @click="estimateFile(element.link, element.id)" class="activation">Evaluer</button>
          <button @click="manualHostedChangeLink()" class="watch">Changer le lien</button>
          <button @click="nextMovie()">Suivant</button>
        </div>
        <div class="form">
          <div class="entry">
            <label>Numéro: <b>{{ element.id }}</b> - Fichier : <b>{{ convertToGigabytes(element.filesize) +
              'Go' }}</b> - Qualité : <b>{{ element.quality ? element.quality : 'Indéfini' }}</b></label>
          </div>
          <div class="entry">
            <label for="imdb">IMDB ID </label>
            <input id="imdb" name="imdb" type="text" :value="element.imdb_id"
              :placeholder="element.imdb_id ? element.imdb_id : 'Entrer un ID'" />
            <a :href="'https://www.imdb.com/title/' + element.imdb_id + '/'" target="_blank">IMBD
              page</a>
          </div>
          <div class="entry">
            <label for="posterURL">Image </label>
            <input id="posterURL" name="posterURL" type="text" :value="element.posterURL"
              placeholder="http://www.pornhub.com/galery" />
          </div>
          <div class="entry">
            <label for="background">Background </label>
            <input id="background" name="background" type="text" :value="element.backgroundURL"
              placeholder="http://www.pornhub.com/galery" />
          </div>
          <div class="entry">
            <label for="name">Titre </label>
            <input id="name" name="name" type="text" :value="element.name" placeholder="Titanic" />
          </div>
          <div class="entry">
            <label for="description">Description </label>
            <input id="description" name="question" type="text" :value="element.description" placeholder="Yaya" />
          </div>
          <div class="entry">
            <label for="duration">Durée </label>
            <input id="duration" name="duration" type="text" :value="element.duration" placeholder="1h20" />
          </div>
          <div class="entry">
            <label for="lien">Lien de streaming </label>
            <input id="lien" name="lien" type="text" :value="element.link" placeholder="Lien de streaming" />
          </div>
          <div class="entry">
            <label for="download">Lien de téléchargement </label>
            <input id="download" name="download" type="text" :value="element.downloadLink"
              placeholder="Lien de téléchargement" />
          </div>
          <div class="entry">
            <label for="trailer">Code ou lien Youtube du trailer </label>
            <input id="trailer" name="trailer" type="text" :value="element.trailerURL"
              placeholder="Ex: https://www.youtube.com/watch?v=aGxyOwkwJyc ou aGxyOwkwJyc" />
          </div>
          <div class="entry">
            <label for="annee">Année de sortie </label>
            <input id="annee" name="annee" type="number" :value="element.annedesortie" placeholder="Année de sortie" />
          </div>
          <div class="entry checkbox">
            <input type="checkbox" id="canadian" name="canadian" :checked="element.isCanadian" />
            <label for="canadian"><i class="fa-brands fa-canadian-maple-leaf"></i></label>
          </div>
          <div class="entry">
            <label for="category">Catégories</label>
            <select name="category" id="categories1">
              <option v-bind:key="element.id" v-for="element in categories" :value="element.name">{{ element.name }}
              </option>
            </select>
          </div>
          <div class="entry">
            <select name="category" id="categories2">
              <option v-bind:key="element.id" v-for="element in categories" :value="element.name">{{ element.name }}
              </option>
            </select>
          </div>
          <div class="boxes">
            <ul class="box">
              <li v-for="element in collections" v-bind:key="element.id" class="little-box"
                :id="'little-box-' + element.id">
                <label class="label" :id="'label-' + element.id">{{ element.name }}</label>
                <input class="collection" :id="'check-' + element.id" type="checkbox">
              </li>
            </ul>
          </div>
          <div class="entry">
            <button :onclick="() => {
              editMovie(element.id);
            }">Éditer ce film</button>
          </div>
        </div>
        <div class="form movieForm">
          <div class="movieFileUpload">
            <label for="file-upload-movie">{{ movieFileName }}</label>
            <input type="file" id="file-upload-movie" @change="updateLabel"/>
            <button @click="uploadMovieFile()">Upload</button>
          </div>
          <div class="movieUrlUpload">
            <input type="text" id="url-upload-movie" placeholder="Lien Darkibox ou 1fichier (Cliquer à coté avant d'uploader)"
              @change="getDownloadLink()" />
            <button @click="uploadMovieURL()">Upload URL</button>
          </div>
        </div>
      </div>
    </div>
    <!--Formulaire d'ajout d'une série-->
    <div class="serieEditor" v-if="type == 'serie'">
      <div class="fullForm" v-for="element in serieToEdit" v-bind:key="element.id">
        <div class="images">
          <img :src="element.posterURL">
          <img :src="element.backgroundURL">
        </div>
        <div class="changer">
          <button :onclick="() => firstMovie()">Précédent</button>
          <span v-if="!element.enabled"><button class="activation" @click="activateSerie()">Activer</button></span>
          <span v-else><button class="activation" @click="desactivateSerie()">Désactiver</button></span>
          <button :onclick="() => nextMovie()">Suivant</button>
        </div>
        <div class="form">
          <div class="entry">
            <label>Numéro: <b>{{ element.id }}</b></label>
          </div>
          <div class="entry">
            <label for="posterURL">Affiche </label>
            <input id="posterURL" name="posterURL" type="text" :value="element.posterURL"
              placeholder="http://www.pornhub.com/galery" />
          </div>
          <div class="entry">
            <label for="background">Background </label>
            <input id="background" name="background" type="text" :value="element.backgroundURL"
              placeholder="http://www.pornhub.com/galery" />
          </div>
          <div class="entry">
            <label for="imdb">IMDB ID </label>
            <input id="imdb" name="imdb" type="text" :value="element.imdb_id"
              :placeholder="element.imdb_id ? element.imdb_id : 'Entrer un ID'" />
            <a :href="'https://www.imdb.com/title/' + element.imdb_id + '/'" target="_blank">IMBD
              page</a>
          </div>
          <div class="entry">
            <label for="name">Titre </label>
            <input id="name" name="name" type="text" :value="element.name" placeholder="Titanic" />
          </div>
          <div class="entry">
            <label for="description">Description </label>
            <input id="description" name="question" type="text" :value="element.description" placeholder="Yaya" />
          </div>
          <div class="entry">
            <label for="annee">Année de sortie </label>
            <input id="annee" name="annee" type="number" :value="element.anneedesortie" placeholder="Année de sortie" />
          </div>
          <div class="entry">
            <label for="anneedefin">Année de fin </label>
            <input id="anneedefin" name="anneedefin" type="number" :value="element.annedefin"
              placeholder="Année de fin" />
          </div>
          <div class="entry">
            <label for="category1">Catégories</label>
            <select name="category1" id="categories1">
              <option v-bind:key="element.id" v-for="element in categories" :value="element.name">{{ element.name }}
              </option>
            </select>
          </div>
          <div class="entry">
            <select name="category2" id="categories2">
              <option v-bind:key="element.id" v-for="element in categories" :value="element.name">{{ element.name }}
              </option>
            </select>
          </div>
          <div class="entry">
            <button :onclick="() => editSerie(element.id)">Editer cette série</button>
          </div>
          <div class="entry">
            <router-link to="/addSerieEpisode">Ajouter une saison</router-link>
          </div>
        </div>
        <div class="form seasonForm" v-for="(element) in seasonsToEdit" v-bind:key="element.id">
          <h2>Saison {{ element.season }}</h2>
          <div class="entry" v-for="(link, index) in element.episodesLinks" v-bind:key="link">
            <label for="lien">Episode {{ index + 1 }} </label>
            <input type="text" :class="'episodes liens-season-' + element.id"
              :id="'lien-episode-' + index + 1 + '-' + 'season-' + element.id" :value="link"
              placeholder="Lien de streaming"
              @change="getEpisodeDownloadLink('lien-episode-' + index + 1 + '-' + 'season-' + element.id)" />
          </div>
          <div class="entryOptions">
            <button @click="deleteSeasonById(element.id, element.season)">Supprimer cette saison</button>
          </div>
        </div>
        <h2>Ajouter tous les épisodes d'une saison au format fichier en une fois</h2>
        <div class="entryEpisodes globalUpload">
          <input type="file" :id="`files`" multiple />
          <select :id="`seasons`">
            <option v-for="season in uniqueSeasons" :key="season" :value="season">Saison {{ season }}</option>
          </select>
          <select :id="`speeds`">
            <option selected>Vitesse</option>
            <option value="ultrafast">Ultrafast</option>
            <option value="superfast" selected>Superfast</option>
            <option value="veryfast">Veryfast</option>
            <option value="faster">Faster</option>
            <option value="fast">Fast</option>
            <option value="medium">Medium</option>
            <option value="slow">Slow</option>
            <option value="slower">Slower</option>
            <option value="veryslow">Veryslow</option>
          </select>
          <button @click="uploadFileEpisodes(element.season)">Upload Episodes</button>
        </div>
        <div class="seasonSelector" v-if="episodesBySeason && episodesBySeason.length > 0">
          <h2>Choisir une saison</h2>
          <select v-model="selectedSeason" @change="sortEpisodesBySeason(selectedSeason)">
            <option v-for="season in uniqueSeasons" :key="season" :value="season">Saison {{ season }}</option>
          </select>
        </div>
        <!-- Nouveaux épisodes -->
        <div class="form seasonForm" v-for="element in episodesBySeason" v-bind:key="element.id"
          :id="'new-' + element.id">
          <h2 :id="`S${element.season}E${element.episode_number}`">Saison {{ element.season }} - Episode {{
            element.episode_number }}</h2>
          <div v-if="element.episode_poster_url">
            <img :src="element.episode_poster_url" :alt="'Image de l\'épisode ' + element.episode_number"
              loading="lazy" />
          </div>
          <p><span v-if="!element.duration && !element.quality"><b>Scan à effectuer</b></span><span v-else>Durée : {{
            convertDuration(element.duration) }} - Qualité : {{ element.quality }} - Taille du fichier : {{
                convertToGigabytes(element.filesize) +
              'Go' }}</span></p>
          <p class="conversion" :id="`conversion-S${element.season}E${element.episode_number}`"></p>
          <div class="entry">
            <label for="title">Titre</label>
            <input type="text" :class="'episodes titres-episode-' + element.id" :value="element.title"
              placeholder="Titre de l'épisode" :id="'episode-title-' + element.id" />
            <label for="description">Description</label>
            <input type="text" :class="'episodes descriptions-episode-' + element.id" :value="element.description"
              placeholder="Description de l'épisode" :id="'episode-description-' + element.id" />
            <label for="lien">Lien</label>
            <input type="text" :class="'episodes liens-episode-' + element.id" :value="element.link"
              placeholder="Lien de streaming" :id="'episode-link-' + element.id" />
            <label for="poster">Poster</label>
            <input type="text" :class="'episodes posters-episode-' + element.id" :value="element.episode_poster_url"
              placeholder="Lien de l'image" :id="'episode-poster-' + element.id" />
            <label for="season">Saison</label>
            <input type="number" :class="'episodes saison-episode-' + element.id" :value="element.season"
              placeholder="Saison" :id="'episode-season-' + element.id" />
          </div>
          <div class="entryOptions">
            <input type="text" :id="`url-S${element.season}E${element.episode_number}`" placeholder="Lien Darkibox ou 1fichier (Cliquer à coté avant d'uploader)"
              @change="getEpisodeDownloadLink(`url-S${element.season}E${element.episode_number}`)" />
            <button @click="uploadURLEpisode(element.season, element.episode_number)">Upload URL</button>
          </div>
          <div class="entryOptions">
            <input type="file" :id="`file-S${element.season}E${element.episode_number}`" />
            <select :id="`speed-S${element.season}E${element.episode_number}`">
              <option selected>Vitesse</option>
              <option value="ultrafast">Ultrafast</option>
              <option value="superfast" selected>Superfast</option>
              <option value="veryfast">Veryfast</option>
              <option value="faster">Faster</option>
              <option value="fast">Fast</option>
              <option value="medium">Medium</option>
              <option value="slow">Slow</option>
              <option value="slower">Slower</option>
              <option value="veryslow">Veryslow</option>
            </select>
            <button @click="uploadFileEpisode(element.episode_number, element.season, element.id, element.link)">Upload
              Fichier</button>
          </div>
          <div class="entryOptions">
            <input type="file" :id="`file-converted-S${element.season}E${element.episode_number}`" accept=".mp4"/>
            <button @click="uploadConvertedFileEpisode(element.episode_number, element.season, element.id, element.link)">Upload
              Fichier Converti</button>
          </div>
          <div class="entryOptions">
            <button @click="scanEpisodeById(element.id, element.link)"
              title="Scanner pour récupérer l'image, la durée et la qualité">Scanner</button>
            <button @click="watchEpisodeById(this.serieToEdit[0].id, element.season, element.episode_number)"
              title="Regarder l'épisode">Regarder</button>
            <button @click="editEpisodeById(element)" title="Editer l'épisode">Editer cet épisode</button>
            <button @click="deleteEpisodeById(element.id)" title="Supprimer l'épisode">Supprimer cet épisode</button>
          </div>
        </div>
      </div>
    </div>
    <div class="movieEditor" v-if="type == 'ebook'">
      <div class="fullForm" v-for="element in ebookToEdit" v-bind:key="element.id">
        <div class="images">
          <img :src="element.coverURL">
          <img :src="element.backgroundURL">
        </div>
        <div class="changer">
          <button :onclick="() => firstMovie()">Précédent</button>
          <button :onclick="() => nextMovie()">Suivant</button>
        </div>
        <div class="form">
          <div class="entry">
            <label>Numéro: <b>{{ element.id }}</b></label>
          </div>
          <div class="entry">
            <label for="coverURL">Couverture </label>
            <input id="coverURL" name="coverURL" type="text" :value="element.coverURL"
              placeholder="http://www.pornhub.com/galery" />
          </div>
          <div class="entry">
            <label for="title">Titre </label>
            <input id="title" name="title" type="text" :value="element.title" placeholder="Titanic" />
          </div>
          <div class="entry">
            <label for="description">Description </label>
            <input id="description" name="question" type="text" :value="element.description" placeholder="Yaya" />
          </div>
          <div class="entry">
            <label for="author">Auteur </label>
            <input id="author" name="author" type="text" :value="element.author" placeholder="Auteur" />
          </div>
          <div class="entry">
            <label for="tome">Tome </label>
            <input id="tome" name="tome" type="number" :value="element.tome" placeholder="1" />
          </div>
          <div class="entry">
            <label for="folder">Folder </label>
            <input id="folder" name="folder" type="text" :value="element.folder" placeholder="1" />
          </div>
          <div class="entry">
            <label for="annee">Année</label>
            <input id="annee" name="annee" type="number" :value="element.anneedesortie" placeholder="Année de sortie" />
          </div>
          <div class="entry">
            <label for="type">Type</label>
            <select name="type" id="type">
              <option v-bind:key="element" v-for="element in types" :value="element">{{ element }}
              </option>
            </select>
          </div>
          <div class="entry">
            <label for="category">Catégories</label>
            <select name="category" id="categories1">
              <option v-bind:key="element.id" v-for="element in ebookCategories" :value="element.name">{{ element.name
                }}
              </option>
            </select>
          </div>
          <div class="entry">
            <select name="category" id="categories2">
              <option v-bind:key="element.id" v-for="element in ebookCategories" :value="element.name">{{ element.name
                }}
              </option>
            </select>
          </div>
          <div class="entry">
            <button :onclick="() => editEbook(element.id)">Éditer ce film</button>
          </div>
        </div>
      </div>
    </div>
    <!--Formulaire d'ajout d'un Show TV-->
    <div class="serieEditor" v-if="type == 'showTV'">
      <div class="fullForm" v-for="element in showTVToEdit" v-bind:key="element.id">
        <div class="images">
          <img :src="element.posterURL">
          <img :src="element.backgroundURL">
        </div>
        <div class="changer">
          <button :onclick="() => firstMovie()">Précédent</button>
          <button :onclick="() => nextMovie()">Suivant</button>
        </div>
        <div class="form">
          <div class="entry">
            <label for="posterURL">Affiche </label>
            <input id="posterURL" name="posterURL" type="text" :value="element.posterURL"
              placeholder="http://www.pornhub.com/galery" />
          </div>
          <div class="entry">
            <label for="background">Background </label>
            <input id="background" name="background" type="text" :value="element.backgroundURL"
              placeholder="http://www.pornhub.com/galery" />
          </div>
          <div class="entry">
            <label for="name">Titre </label>
            <input id="name" name="name" type="text" :value="element.name" placeholder="Titanic" />
          </div>
          <div class="entry">
            <label for="description">Description </label>
            <input id="description" name="question" type="text" :value="element.description" placeholder="Yaya" />
          </div>
          <div class="entry">
            <label for="annee">Année de sortie </label>
            <input id="annee" name="annee" type="number" :value="element.annee" placeholder="Année de sortie" />
          </div>
          <div class="entry">
            <label for="link">Lien : </label>
            <input id="link" name="link" type="text" :value="element.link" placeholder="Lien de streaming" />
          </div>
          <div class="entry">
            <label for="category1">Catégories</label>
            <select name="category1" id="categories1">
              <option v-bind:key="element.id" v-for="element in categories" :value="element.name">{{ element.name }}
              </option>
            </select>
          </div>
          <div class="entry">
            <select name="category2" id="categories2">
              <option v-bind:key="element.id" v-for="element in categories" :value="element.name">{{ element.name }}
              </option>
            </select>
          </div>
          <div class="entry">
            <button :onclick="() => editShowTV(element.id)">Editer ce show TV</button>
          </div>
          <div class="entry" v-if="!element.link">
            <router-link to="/addShowTVSeason">Ajouter une saison</router-link>
            <button @click="wipeEpisodes()">Vider les épisodes</button>
          </div>
        </div>
        <div class="form seasonForm" v-for="(element, index) in showTVSeasonsToEdit" v-bind:key="element.id">
          <h2>Saison {{ element.season }}</h2>
          <div class="entry" v-for="(link, index) in element.episodesLinks" v-bind:key="link">
            <label for="lien">Episode {{ index + 1 }} </label>
            <input type="text" :class="'episodes lien-' + element.id" :value="link" placeholder="Lien de streaming" />
          </div>
          <div class="entryOptions">
            <button @click="addEpisode(index)">Ajouter un épisode</button>
            <button @click="editShowTVSeasonById(element.id, element.season)">Editer cette saison</button>
            <button @click="deleteSHowTVSeasonById(element.id, element.season)">Supprimer cette saison</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarTop from './NavbarTop.vue'
// import the library
import { createToast } from 'mosha-vue-toastify';
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'
import slugify from 'slugify';
import io from "socket.io-client"

export default {
  name: 'EditMovie',
  components: {
    NavbarTop,
  },
  data() {
    return {
      link: "https://api.heavenflix.fr",
      filmToEdit: undefined,
      serieToEdit: undefined,
      seasonsToEdit: undefined,
      ebookToEdit: undefined,
      showTVToEdit: undefined,
      showTVSeasonsToEdit: undefined,
      categories: undefined,
      collections: undefined,
      articleId: this.$route.params.id,
      type: this.$route.params.type,
      types: ['Autre', 'BD', 'Comics', 'Magazine', 'Manga', 'Nouvelle', 'Roman'],
      ebookCategories: undefined,
      canSend: false,
      isLoading: true,
      episodes: undefined,
      episodesBySeason: undefined,
      selectedSeason: undefined,
      serverLink: "https://server.heavenflix.fr",
      uploadingMovie: false,
      movieFileName: 'Choisir un fichier...',
      uploadingMessage: undefined,
      userId: localStorage.getItem('userId'),
    }
  },
  setup() {
    const toast = () => {
      createToast('Wow, easy')
    }
    return { toast }
  },
  computed: {
    uniqueSeasons() {
      if (!this.episodes) return;
      const seasons = this.episodes.map(episode => episode.season);
      return [...new Set(seasons)].sort((a, b) => a - b); // Retourne un tableau de saisons uniques triées
    }
  },
  methods: {
    async uploadMovieURL() {
      const url = document.getElementById(`url-upload-movie`).value;
      if (!url) return this.showNotification('danger', 'Veuillez entrer un lien');
      const requestOptions = {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          url: url,
          name: this.filmToEdit[0].name,
          year: this.filmToEdit[0].annedesortie,
          id: this.filmToEdit[0].id,
          uploaderId: this.userId
        }),
      };
      await fetch(this.serverLink + "/upload/url", requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json();
        })
        .then((data) => {
          if (data && data.code == 200) {
            if(this.uploadingMovie) this.uploadingMovie = false;
            this.showNotification('success', 'Lien uploadé avec succès');
          }
        });
    },
    async uploadMovieFile() {
      const file = document.getElementById("file-upload-movie").files[0];
      //Récupérer l'extension du fichier avec file.name et récupérer le dernier split à partir du point pour avoir '.avi', '.mkv', '.mp4
      const fileExtension = '.' + file.name.split('.').pop();
      const formData = new FormData();
      formData.append("extension", fileExtension);
      formData.append("year", this.filmToEdit[0].annedesortie);
      formData.append("name", this.filmToEdit[0].name);
      formData.append("file", file);
      if (!file) return this.showNotification('danger', 'Veuillez sélectionner un fichier');
      const requestOptions = {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formData,
      };
      await fetch(this.serverLink + "/upload/file", requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then((data) => {
          if (data.code == 200) {
            if(this.uploadingMovie) this.uploadingMovie = false;
            this.showNotification('success', 'Fichier uploadé avec succès');
          } else {
            this.showNotification('danger', 'Erreur lors de l\'upload du fichier');
          }
        });
    },
    updateLabel(event) {
      const input = event.target;
      if (input.files && input.files.length > 0) {
        // Mettre à jour le nom du fichier
        this.movieFileName = input.files[0].name + ' - ' + this.convertToGigabytes(input.files[0].size) + 'Go';
        console.log(input.files[0])
      } else {
        // Aucun fichier sélectionné, texte par défaut
        this.movieFileName = 'Choisir un fichier...';
      }
    },
    // Upload un fichier déjà converti à /upload/converted/episode
    uploadConvertedFileEpisode: async function uploadConvertedFileEpisode(episode, season, id, link) {
      const fileInput = document.getElementById(`file-converted-S${season}E${episode}`);
      const file = fileInput.files[0];
      //Récupérer l'extension du fichier avec file.name et récupérer le dernier split à partir du point pour avoir '.avi', '.mkv', '.mp4
      const fileExtension = '.' + file.name.split('.').pop();
      if(fileExtension != '.mp4') return this.showNotification('danger', 'Veuillez uploader un fichier .mp4');
      const formData = new FormData();
      formData.append("extension", fileExtension);
      formData.append("name", this.serieToEdit[0].name);
      formData.append("episode", episode);
      formData.append("season", season);
      formData.append("file", file);
      if (!file) return this.showNotification('danger', 'Veuillez sélectionner un fichier');
      this.showNotification('info', 'Upload en cours...');
      const requestOptions = {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formData,
      };
      await fetch(this.serverLink + "/upload/converted/episode", requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json();
        })
        .then((data) => {
          if (data && data.code == 200) {
            this.showNotification('success', 'Episode uploadé avec succès');
            this.scanEpisodeById(id, link);
            // Reset l'input file
            fileInput.value = '';
          }
        });
    },
    // Delete episode by id to /episodes/unique/delete/:id
    deleteEpisodeById: async function deleteEpisodeById(id) {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/episodes/unique/delete/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return this.showNotification('danger', data.msg);
        this.showNotification('success', 'Episode supprimé avec succès');
        this.getEpisodesFromSerieId(this.serieToEdit[0].id);
      })
    },
    sortEpisodesBySeason(season) {
      this.episodesBySeason = this.episodes.filter(episode => episode.season == season);
    },
    getEpisodesFromSerieId: async function getEpisodesFromSerieId(id) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/get/episodes/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.episodes = undefined;

        // Attribuer les épisodes récupérées à this.episodes rangés dans l'ordre de saison et d'épisodes
        this.episodes = data.sort((a, b) => a.season - b.season);

        if (!this.selectedSeason) {
          // Attribuer le premier numéro de saison disponible à selectedSeason
          this.selectedSeason = this.uniqueSeasons[0];
        }

        this.sortEpisodesBySeason(this.selectedSeason);
      })
    },
    watchEpisodeById: function watchEpisodeById(id, season, episode) {
      this.$router.push(`/player/serie/${id}/${season}/${episode - 1}`);
    },
    // Fonction pour upload un épisode via un URL Darkibox
    async uploadURLEpisode(season, episode) {
      const url = document.getElementById(`url-S${season}E${episode}`).value;
      if (!url) return this.showNotification('danger', 'Veuillez entrer un lien');
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          season: season,
          name: this.serieToEdit[0].name,
          url: url,
          episode: episode
        })
      }
      await fetch('https://server.heavenflix.fr/upload/url/episode', requestOptions).then((res) => res.json()).then(data => {
        if (!data.code) return this.showNotification('danger', 'Erreur lors de l\'upload');
        this.showNotification('success', 'Episode uploadé avec succès');
        this.scanEpisodeById(data.id, url);
        this.getEpisodesFromSerieId(this.serieToEdit[0].id);
      })
    },
    // Fonction pour upload un fichier vers le serveur Heavenflix via un formdata pour plusieurs épisodes
    uploadFileEpisodes: async function uploadFileEpisode() {
      const fileInput = document.getElementById(`files`);
      const files = fileInput.files;
      if (!files || files.length === 0) return this.showNotification('danger', 'Veuillez sélectionner un fichier');
      const serieName = this.serieToEdit[0].name;
      const speed = document.getElementById(`speeds`).value;
      const season = document.getElementById(`seasons`).value;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append("files", file); // Utilisez le même nom de champ pour tous les fichiers
      }
      // Ajoutez les autres champs une seule fois, pas dans la boucle
      formData.append("speed", speed);
      formData.append("name", serieName);
      formData.append("season", season);
      this.showNotification('info', 'Upload en cours...');
      const requestOptions = {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formData,
      };
      await fetch(this.serverLink + "/upload/episodes", requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json();
        })
        .then((data) => {
          if (data && data.code == 200) {
            this.showNotification('success', 'Episode uploadé avec succès');
            fileInput.value = '';
            this.getEpisodesFromSerieId(this.serieToEdit[0].id);
          }
        });
    },
    // Fonction pour upload un fichier vers le serveur Heavenflix via un formdata pour un épisode
    uploadFileEpisode: async function uploadFileEpisode(episode, season, id, link) {
      const fileInput = document.getElementById(`file-S${season}E${episode}`);
      const file = fileInput.files[0];
      const serieName = this.serieToEdit[0].name;
      const speed = document.getElementById(`speed-S${season}E${episode}`).value;
      //Récupérer l'extension du fichier avec file.name et récupérer le dernier split à partir du point pour avoir '.avi', '.mkv', '.mp4
      const fileExtension = '.' + file.name.split('.').pop();
      const formData = new FormData();
      formData.append("extension", fileExtension);
      formData.append("speed", speed);
      formData.append("name", serieName);
      formData.append("episode", episode);
      formData.append("season", season);
      formData.append("file", file);
      if (!file) return this.showNotification('danger', 'Veuillez sélectionner un fichier');
      this.showNotification('info', 'Upload en cours...');
      const requestOptions = {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formData,
      };
      await fetch(this.serverLink + "/upload/episode", requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json();
        })
        .then((data) => {
          if(data && data.code == 503) return this.showNotification('danger', 'Le serveur est déjà occupé en conversion, veuillez réessayer plus tard');
          if (data && data.code == 200) {
            this.showNotification('success', 'Episode uploadé avec succès');
            this.scanEpisodeById(id, link);
            // Reset l'input file
            fileInput.value = '';
          }
        });
    },
    convertDuration: function convertDuration(duration) {
      // Convertir la durée en minutes
      const minutes = Math.floor(duration / 60);

      // Renvoyer la durée en minutes en tant que chaîne
      return minutes.toString() + 'min';
    },
    scanEpisodeById: async function scanEpisodeById(id, link) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: id,
          path: link
        })
      };
      await fetch('https://server.heavenflix.fr/series/episode/check', requestOptions).then((res) => res.json()).then(data => {
        if (data.error) return this.showNotification('danger', data.error);
        this.showNotification('success', 'Episode scanné avec succès');
        this.getEpisodesFromSerieId(this.serieToEdit[0].id);
      })
    },
    //Désactiver la série
    async desactivateSerie() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/disableSerie/' + this.serieToEdit[0].id, requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        } else {
          this.showNotification('success', this.serieToEdit[0].name + ' a été désactivé');
          this.getSerieToEdit();
        }
      });
    },
    // Activer la série
    async activateSerie() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/enableSerie/' + this.serieToEdit[0].id, requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        } else {
          this.showNotification('success', this.serieToEdit[0].name + ' a été activé');
          this.getSerieToEdit();
        }
      });
    },
    manualHostedChangeLink() {
      const id = this.filmToEdit[0].id;
      const year = this.filmToEdit[0].annedesortie;
      const name = this.filmToEdit[0].name;
      this.changeLink(id, year, name);
    },
    async estimateFile(link, id) {
      if (!link) return this.showNotification('danger', 'Le lien est vide');
      if (!link.includes('server.heavenflix')) return this.showNotification('danger', 'Le lien n\'est pas valide');
      if (link.includes('server.heavenflix')) link = link.replace('https://server.heavenflix.fr/videos/movies/', '');
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch('https://server.heavenflix.fr/movies/' + link + '/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.error) return this.showNotification('danger', data.error);
        if (data && data.code && data.code == 200) {
          this.showNotification('success', 'Le fichier a été évalué avec succés.');
          this.filmToEdit = [];
          this.getMovieToEdit();
        }
      })
    },
    async deleteMovie(id) {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/deleteMovie/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        } else {
          this.showNotification('success', this.filmToEdit[0].name + ' a été supprimé');
          this.getCategories();
          this.getMovieToEdit()
          this.getCollections();
        }
      });
    },
    async enableMovie() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/enableMovie/' + this.filmToEdit[0].id, requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        } else {
          this.showNotification('success', this.filmToEdit[0].name + ' a été activé');
          this.getCategories();
          this.getMovieToEdit()
          this.getCollections();
        }
      });
    },
    async disableMovie() {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/disableMovie/' + this.filmToEdit[0].id, requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        } else {
          this.showNotification('success', this.filmToEdit[0].name + ' a été désactivé');
          this.getCategories();
          this.getMovieToEdit()
          this.getCollections();
        }
      });
    },
    async getEpisodeDownloadLink(id) {
      const downloadInput = document.getElementById(id);
      let downloadLink = document.getElementById(id).value;
      if (downloadLink == '' || downloadLink == undefined) return;
      if (downloadLink.includes('heavenflix')) return;
      if (!downloadLink.includes('https://darkibox.com') && !downloadLink.includes('https://1fichier.com/')) return this.showNotification('warning', 'Le lien n\'est pas valide, Darkibox ou 1fichier uniquement');
      if (downloadLink.includes('darkibox')) {
        downloadLink = downloadLink.split('/')[3];
        const requestOptions = {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };
        await fetch('https://darkibox.com/api/file/direct_link?key=4287et9m2dyhtmvmz2f8&file_code=' + downloadLink, requestOptions).then((res) => res.json()).then(data => {
          if (data && data.result && data.result.versions && data.result.versions[1] && data.result.versions[1].url) {
            downloadInput.value = data.result.versions[1].url;
          } else if (data && data.result && data.result.versions && data.result.versions[0] && data.result.versions[0].url) {
            downloadInput.value = data.result.versions[0].url;
          } else {
            this.showNotification('danger', 'Erreur lors de la récupération du lien de téléchargement');
          }
        })
      } else if (downloadLink.includes('1fichier')) {
        let code = downloadLink.split('/')[3];
        if(!code) return this.showNotification('danger', 'Le lien n\'est pas valide');
        if(code.includes('&')) code = code.split('&')[0];
        if(code.includes('?')) code = code.replace('?', '');
        const requestOptions = {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };
        await fetch(this.serverLink + '/server/1fichier/' + code, requestOptions).then((res) => res.json()).then(data => {
          if (data && data.downloadLink) {
            downloadInput.value = data.downloadLink;
          } else {
            this.showNotification('danger', 'Erreur lors de la récupération du lien de téléchargement');
          }
        })
      }

    },
    getDownloadLink: async function getDownloadLink() {
      const downloadLink = document.getElementById('url-upload-movie').value;
      if (!downloadLink && downloadLink.length <= 0) return this.showNotification('warning', 'Le lien de téléchargement est manquant');
      if (downloadLink.includes('darkibox')) {
        let darkiCode = downloadLink.split('/')[3];
        const requestOptions = {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };
        await fetch('https://darkibox.com/api/file/direct_link?key=4287et9m2dyhtmvmz2f8&file_code=' + darkiCode, requestOptions).then((res) => res.json()).then(data => {
          if (data && data.result && data.result.versions && data.result.versions[1] && data.result.versions[1].url) {
            document.getElementById('url-upload-movie').value = data.result.versions[1].url;
          } else if (data && data.result && data.result.versions && data.result.versions[0] && data.result.versions[0].url) {
            document.getElementById('url-upload-movie').value = data.result.versions[0].url;
          } else {
            this.showNotification('danger', 'Erreur lors de la récupération du lien');
          }
        })
      } else if (downloadLink.includes('1fichier')) {
        let code = downloadLink.split('/')[3];
        if (!code) return this.showNotification('danger', 'Le lien n\'est pas valide');
        if (code.includes('&')) code = code.split('&')[0];
        if (code.includes('?')) code = code.replace('?', '');
        const requestOptions = {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        };
        await fetch(this.serverLink + '/server/1fichier/' + code, requestOptions).then((res) => res.json()).then(data => {
          if (data && data.downloadLink) {
            document.getElementById('url-upload-movie').value = data.downloadLink;
          } else {
            this.showNotification('danger', 'Erreur lors de la récupération du lien de téléchargement');
          }
        })
      }
    },
    async uploadSeason(id, season, title) {
      let episodesInputs = document.getElementsByClassName('liens-season-' + id);
      let episodesLinks = [];
      Array.from(episodesInputs).forEach(element => {
        episodesLinks.push(element.value);
      });
      const sluggedName = slugify(title, {
        replacement: '_',
        lower: true,
        remove: /[^a-zA-Z0-9\s]/g
      });
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          season: season,
          title: title,
          episodes: episodesLinks,
        })
      }
      await fetch('https://server.heavenflix.fr/upload/serie', requestOptions).then((res) => res.json()).then(data => {
        if (!data.code) return this.showNotification('danger', 'Erreur lors de l\'upload');
        for (let i = 0; i < episodesInputs.length; i++) {
          if (episodesInputs[i].value == '' || episodesInputs[i].value == undefined || episodesInputs[i].value == 'vide') return;
          episodesInputs[i].value = `https://server.heavenflix.fr/videos/series/${sluggedName}/S${season}E${i + 1}_${sluggedName}.mp4`;
        }

      })
    },
    async upload() {
      const movie = this.filmToEdit[0];
      const initialLink = movie.link;
      let movieCode = undefined;
      if (initialLink.includes('darkibox')) movieCode = initialLink.split('/')[3];
      if (initialLink.includes('server.heavenflix')) return this.showNotification('warning', 'Le lien est déjà uploadé');
      if (movieCode == undefined) return this.showNotification('warning', 'Le lien n\'est pas valide');
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch('https://darkibox.com/api/file/direct_link?key=4287et9m2dyhtmvmz2f8&file_code=' + movieCode, requestOptions).then((res) => res.json()).then(data => {
        console.log(data)
        if (data && data.result && data.result.versions && data.result.versions[0] && data.result.versions[0].url) {
          this.sendUpload(data.result.versions[0].url, movie.name, movie.annedesortie);
        } else {
          this.showNotification('danger', 'Erreur lors de la récupération du lien');
        }
      })
    },
    async addToQueue(article_id, type, name) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          article_id: article_id,
          type: type,
        })
      };
      await fetch('https://api.heavenflix.fr/downloads', requestOptions).then((res) => res.json()).then(data => {
        if (data.code == 201) this.showNotification('success', name + ' a été ajouté à la file d\'attente');
        this.showNotification('danger', data.message);
      });
    },
    async sendUpload(url, name, year) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          url: url,
          filename: name,
          year: year,
        })
      };

      try {
        const res = await fetch('https://server.heavenflix.fr/upload/url', requestOptions);
        const data = await res.json();

        if (res.status === 400) {
          this.showNotification('danger', data.message);
        } else {
          this.changeLink(this.filmToEdit[0].id, year, name);
        }
      } catch (error) {
        console.error('Une erreur s\'est produite lors de la requête :', error.message);
      }
    },
    async changeLink(id, year, name) {
      const sluggedName = slugify(name, {
        replacement: '_',
        lower: true,
        remove: /[^a-zA-Z0-9\s]/g
      });
      const newLink = 'https://server.heavenflix.fr/videos/movies/' + sluggedName + '_' + year + '.mp4';

      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          link: newLink,
        })
      };
      await fetch(this.link + '/changeLink/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        } else {
          this.enableMovie();
          this.getCategories();
          this.getMovieToEdit()
          this.getCollections();
        }
      });
    },
    addEpisodeSeason: function addEpisodeSeason(index) {
      let self = this.seasonsToEdit[index].episodesLinks;
      self.push('');
    },
    addEpisode: function addEpisode(index) {
      let self = this.showTVSeasonsToEdit[index].episodesLinks;
      self.push('');
    },
    wipeEpisodes() {
      const episodesInputs = document.getElementsByClassName('episodes');
      Array.from(episodesInputs).forEach(element => {
        element.value = '';
      });
    },
    handleKeyPress: function (event) {
      if (event.keyCode === 37) {
        // Touche gauche (code 37)
        this.firstMovie();
      } else if (event.keyCode === 39) {
        // Touche droite (code 39)
        this.nextMovie();
      }
    },
    showNotification(type, text) {
      createToast(text, {
        position: 'top-center',
        type: type,
        transition: 'slide',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: 3000,
        //toastBackgroundColor: '#2D2E32',
      })
    },
    showNotificationConversion(type, text) {
      createToast(text, {
        position: 'top-center',
        type: type,
        transition: 'slide',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: 8000,
        //toastBackgroundColor: '#2D2E32',
      })
    },
    async oneEpisodeMore() {
      let self = this.serieToEdit[0].episodesLinks;
      self.push('');
    },
    async oneEpisodeLess() {
      let self = this.serieToEdit[0].episodesLinks;
      self.pop();
    },
    getCollections: async function getCollections() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getCollections', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.collections = data;
      })
    },
    firstMovie: async function firstMovie() {
      this.$router.push({
        name: 'edit',
        params: {
          type: this.type,
          id: parseInt(this.articleId) - 1,
        }
      });
      setTimeout(() => {
        location.reload();
      }, 100);
    },
    nextMovie: async function firstMovie() {
      this.$router.push({
        name: 'edit',
        params: {
          type: this.type,
          id: parseInt(this.articleId) + 1,
        }
      });
      setTimeout(() => {
        location.reload();
      }, 100);
    },
    getCategories: async function getCategories() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getCategories', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.categories = data;
        setTimeout(() => {
          this.setupCategory();
        }, 1000);
      })
    },
    getEbooksCategories: async function getEbooksCategories() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getEbooksCategories', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.ebookCategories = data;
        setTimeout(() => {
          this.setupCategory();
        }, 1000);
      })
    },
    admin: async function admin() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      };
      await fetch(this.link + '/getAdmin/' + localStorage.getItem('userId'), requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then(data => {
          if (data.msg) return console.log(data.msg);
          if (data[0].isAdmin == 0) return this.$router.push('/home');
        })
    },
    editEbook: async function editEbook(id) {
      if (!this.canSend) return this.showNotification('warning', 'Veuillez attendre que les liens soient chargés');
      var type = document.getElementById('type').value;
      var coverURL = document.getElementById('coverURL').value;
      var title = document.getElementById('title').value;
      var description = document.getElementById('description').value;
      var author = document.getElementById('author').value;
      var tome = document.getElementById('tome').value;
      var annee = document.getElementById('annee').value;
      var folder = document.getElementById('folder').value;
      var categories1 = document.getElementById("categories1").value;
      var categories2 = document.getElementById("categories2").value;
      if (!type && type == 'Choisissez un type') return this.showNotification('warning', 'Le type n\'est pas renseigné!');
      if (!coverURL) return this.showNotification('warning', 'Le lien de la couverture n\'est pas renseigné!');
      if (!title) return this.showNotification('warning', 'Titre manquant!');
      if (!description) return this.showNotification('warning', 'Description manquante!');
      if (!author) return this.showNotification('warning', 'Auteur manquant!');
      if (!tome) return this.showNotification('warning', 'Numéro de tome manquant!');
      if (!folder) return this.showNotification('warning', 'Folder manquant!');
      if (!annee) return this.showNotification('warning', 'Année de sortie du film manquante!');
      if (!categories1 || !categories2) return this.showNotification('warning', 'Catégorie non renseignée!');
      try {
        const response = await fetch(this.link + '/editEbook/' + id, {
          method: 'POST',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            coverURL: coverURL,
            title: title,
            description: description,
            author: author,
            tome: tome,
            id: id,
            annee: annee,
            type: type,
            folder: folder,
            category1: document.getElementById("categories1").value,
            category2: document.getElementById("categories2").value,
          }),
        });
        if (response.ok) {
          this.showNotification('success', title + ' a été édité');
        } else {
          this.showNotification('danger', 'Erreur lors de l\'édition de l\'ebook : ' + title)
        }
      } catch (error) {
        this.showNotification('danger', 'Erreur lors de l\'édition de l\'ebook : ' + title)
      }
    },
    editMovie: async function editMovie(id) {
      if (!this.canSend) return this.showNotification('warning', 'Veuillez attendre que les liens soient chargés');
      let collectionsChecked = [];
      let boxes = document.getElementsByClassName('little-box');
      var posterURL = document.getElementById('posterURL').value;
      var name = document.getElementById('name').value;
      var description = document.getElementById('description').value;
      var duration = document.getElementById('duration').value;
      var lien = document.getElementById('lien').value;
      var background = document.getElementById('background').value;
      var annee = document.getElementById('annee').value;
      var imdb = document.getElementById('imdb').value;
      var download = document.getElementById('download').value;
      var trailer = document.getElementById('trailer').value;
      var canadian = document.getElementById('canadian').checked;
      if (!imdb) this.showNotification('warning', 'L\'ID IMDB est manquant');
      if (!name) return this.showNotification('warning', 'Le titre est manquant');
      if (!description) return this.showNotification('warning', 'La description est manquante');
      if (!duration) return this.showNotification('warning', 'La durée est manquante');
      if (!lien) return this.showNotification('warning', 'Le lien est manquant');
      if (!annee) return this.showNotification('warning', 'L\'Année de sortie est manquante');
      //if (!trailer) return this.showNotification('warning', 'Code ou lien Youtube du trailer manquant');
      if (trailer.includes('youtube.com')) trailer = trailer.split('=')[1];
      for (let i = 0; i < boxes.length + 40; i++) {
        let label = document.getElementById('label-' + i);
        let check = document.getElementById('check-' + i);
        if (check && check.checked) {
          collectionsChecked.push(label.innerText);
        }
      }
      let strArray = JSON.stringify(collectionsChecked);
      strArray = strArray.replace("[", '');
      strArray = strArray.replace("]", '');
      strArray = strArray.replace(/"/g, "'");
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          posterURL: posterURL,
          name: name,
          description: description,
          duration: duration,
          lien: lien,
          id: id,
          annee: annee,
          category1: document.getElementById("categories1").value,
          category2: document.getElementById("categories2").value,
          backgroundURL: background,
          collections: strArray,
          imdb_id: imdb,
          downloadLink: download,
          trailerURL: trailer,
          isCanadian: canadian,
        })
      };
      await fetch(this.link + '/editMovie', requestOptions).then((res) => res.json()).then(data => {
        if (data.error) return console.log(data.error);
        this.showNotification('success', name + ' a été édité');
        this.getCategories();
        this.getMovieToEdit()
        this.getCollections();
      });
    },
    editSerie: async function editSerie(id) {
      if (!this.canSend) return this.showNotification('warning', 'Veuillez attendre que les liens soient chargés');
      var posterURL = document.getElementById('posterURL').value;
      var name = document.getElementById('name').value;
      var description = document.getElementById('description').value;
      var anneedefin = document.getElementById('anneedefin').value;
      var background = document.getElementById('background').value;
      var annee = document.getElementById('annee').value;
      var imdb = document.getElementById('imdb').value;
      if (!imdb) this.showNotification('warning', 'L\'ID IMDB est manquant');
      if (!name) return this.showNotification('warning', 'Le titre est manquant');
      if (!description) return this.showNotification('warning', 'La description est manquante');
      if (!anneedefin) return this.showNotification('warning', 'L\'année de fin est manquante');
      if (!annee) return this.showNotification('warning', 'L\'Année de sortie est manquante');
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: id,
          imdb_id: imdb,
          posterURL: posterURL,
          name: name,
          description: description,
          anneedefin: anneedefin,
          annee: annee,
          category1: document.getElementById("categories1").value,
          category2: document.getElementById("categories2").value,
          backgroundURL: background,
        })
      };
      await fetch(this.link + '/editSerie', requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        } else {
          this.showNotification('success', name + ' a été édité');
        }
      });
    },
    editShowTV: async function editShowTV(id) {
      if (!this.canSend) return this.showNotification('warning', 'Veuillez attendre que les liens soient chargés');
      var posterURL = document.getElementById('posterURL').value;
      var name = document.getElementById('name').value;
      var description = document.getElementById('description').value;
      var background = document.getElementById('background').value;
      var annee = document.getElementById('annee').value;
      var link = document.getElementById('link').value;
      if (!name) return this.showNotification('warning', 'Le titre est manquant');
      if (!description) return this.showNotification('warning', 'La description est manquante');
      if (!annee) return this.showNotification('warning', 'L\'Année de sortie est manquante');
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: id,
          posterURL: posterURL,
          name: name,
          description: description,
          annee: annee,
          link: link,
          category1: document.getElementById("categories1").value,
          category2: document.getElementById("categories2").value,
          backgroundURL: background,
        })
      };
      await fetch(this.link + '/editShowTV', requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        } else {
          this.showNotification('success', name + ' a été édité');
        }
      });
    },
    deleteSeasonById: async function deleteSeasonById(id, season) {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/deleteSeason/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        } else {
          this.showNotification('success', 'La saison ' + season + ' a été supprimée');
        }
      });
    },
    editSeasonById: async function editSeasonById(id, season) {
      let links = document.getElementsByClassName('liens-season-' + id);
      let linksArray = [];
      Array.from(links).forEach(element => {
        linksArray.push(element.value);
      });
      let strArray = JSON.stringify(linksArray);
      strArray = strArray.replace("[", '');
      strArray = strArray.replace("]", '');
      strArray = strArray.replace(/"/g, "'");
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: id,
          liens: strArray,
        })
      };
      await fetch(this.link + '/editSeason/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        } else {
          this.showNotification('success', 'La saison ' + season + ' a été éditée');
        }
      });
    },
    editShowTVSeasonById: async function editShowTVSeasonById(id, season) {
      let links = document.getElementsByClassName('lien-' + id);
      let linksArray = [];
      Array.from(links).forEach(element => {
        linksArray.push(element.value);
      });
      let strArray = JSON.stringify(linksArray);
      strArray = strArray.replace("[", '');
      strArray = strArray.replace("]", '');
      strArray = strArray.replace(/"/g, "'");
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: id,
          liens: strArray,
        })
      };
      await fetch(this.link + '/editShowTVSeasons/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        } else {
          this.showNotification('success', 'La saison ' + season + ' a été éditée');
        }
      });
    },
    convertToGigabytes(filesize) {
      const gigabytes = filesize / (1024 * 1024 * 1024);
      // Formatez la taille en Go avec deux décimales
      return gigabytes.toFixed(2);
    },
    getMovieToEdit: async function getMovieToEdit() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getMovie/' + this.articleId, requestOptions).then((res) => res.json()).then(data => {
        this.isLoading = false;
        if (data.msg) return console.log(data.msg);
        if (data.length == 0) return;
        this.filmToEdit = data;
      })
    },
    getSerieToEdit: async function getSerieToEdit() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getSerie/' + this.articleId, requestOptions).then((res) => res.json()).then(data => {
        this.isLoading = false;
        if (data.msg) return console.log(data.msg);
        //data[0].episodesLinks = JSON.parse(data[0].episodesLinks);
        this.serieToEdit = data;
        this.getSeasonsFromSerieId(data[0].id);
        this.getEpisodesFromSerieId(data[0].id);
      })
    },
    editEpisodeById: async function editEpisodeById(episode) {
      // Récupérer le lien de l'épisode
      let link = document.getElementById('episode-link-' + episode.id).value;
      let season = document.getElementById('episode-season-' + episode.id).value;
      let poster = document.getElementById('episode-poster-' + episode.id).value;
      let title = document.getElementById('episode-title-' + episode.id).value;
      let description = document.getElementById('episode-description-' + episode.id).value;

      // Fetch pour modifier l'épisode
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: episode.id,
          serie_id: this.serieToEdit[0].id,
          episode_number: episode.episode_number,
          link: link,
          season: season,
          episode_poster_url: poster,
          intro_start: episode.intro_start,
          intro_end: episode.intro_end,
          credits_start: episode.credits_start,
          duration: episode.duration,
          quality: episode.quality,
          serie_name: this.serieToEdit[0].name,
          title: title ? title : episode.title,
          description: description ? description : episode.description,
          filesize: episode.filesize,
        })
      };
      await fetch(this.link + '/edit/episode', requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        } else {
          this.showNotification('success', 'L\'épisode a été édité');
          this.getEpisodesFromSerieId(this.serieToEdit[0].id);
        }
      });
    },
    getEbookToEdit: async function getEbookToEdit() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getEbookById/' + this.articleId, requestOptions).then((res) => res.json()).then(data => {
        this.isLoading = false;
        if (data.msg) return console.log(data.msg);
        this.ebookToEdit = data;
      })
    },
    getShowTVToEdit: async function getShowTVToEdit() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getShowTV/' + this.articleId, requestOptions).then((res) => res.json()).then(data => {
        this.isLoading = false;
        if (data.msg) return console.log(data.msg);
        this.showTVToEdit = data;
        this.getShowTVSeasonsFromSerieId(data[0].id);
      })
    },
    getShowTVSeasonsFromSerieId: async function getShowTVSeasonsFromSerieId(id) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/allShowTVSeasonsFromShowTVId/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        for (let i = -1; i < data.length; i++) {
          if (data[i]) {
            data[i].episodesLinks = JSON.parse(data[i].episodesLinks);
          }
          data = data.sort((a, b) => a.season - b.season);
        }
        this.showTVSeasonsToEdit = data;
      })
    },
    getShowTVCategories: async function getShowTVCategories() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getShowTVCategories', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.categories = data;
      })
    },
    getSeasonsFromSerieId: async function getSeasonsFromSerieId(id) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getSeasonsFromSerieId/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        for (let i = -1; i < data.length; i++) {
          if (data[i]) {
            data[i].episodesLinks = JSON.parse(data[i].episodesLinks);
          }
          data = data.sort((a, b) => a.season - b.season);
        }
        this.seasonsToEdit = data;
      })
    },
    setupCategory: async function setupCategory() {
      let cat = document.getElementById('categories1');
      let cat2 = document.getElementById('categories2');
      let catop = cat.options;
      let catop2 = cat2.options;
      for (let i = 0; i < catop.length; i++) {
        let list;
        if (this.type == 'movie') {
          list = JSON.parse(this.filmToEdit[0].cat);
        } else if (this.type == 'serie') {
          list = JSON.parse(this.serieToEdit[0].categories);
        } else if (this.type == 'ebook') {
          list = JSON.parse(this.ebookToEdit[0].categories);
        } else if (this.type == 'showTV') {
          list = JSON.parse(this.showTVToEdit[0].categories);
        }
        if (catop[i].value == list[0]) {
          catop[i].selected = "selected";
        }
        if (catop2[i].value == list[1]) {
          catop2[i].selected = "selected";
        }
      }
      if (this.filmToEdit != undefined) {
        let boxes = document.getElementsByClassName('little-box');
        let collection = JSON.parse(this.filmToEdit[0].collection);
        Array.from(boxes).forEach(element => {
          let id = parseInt(element.id.replace('little-box-', ''));
          if (collection && collection.length > 0) {
            for (let i = 0; i < collection.length; i++) {
              if (document.getElementById('label-' + id).innerHTML == collection[i]) {
                document.getElementById('check-' + id).checked = true;
              }
            }
          }
        });
      }
      if (this.ebookToEdit != undefined) {
        let type = document.getElementById('type');
        let typeop = type.options;
        for (let i = 0; i < typeop.length; i++) {
          if (typeop[i].value == this.ebookToEdit[0].type) {
            typeop[i].selected = "selected";
          }
        }
      }
      this.canSend = true;
    },
    loadEditable: function loadEditable() {
      if (this.type == 'movie') {
        this.getCategories();
        this.getMovieToEdit()
        this.getCollections();
      } else if (this.type == 'serie') {
        this.getSerieToEdit();
        this.getCategories();
      } else if (this.type == 'ebook') {
        this.getEbookToEdit();
        this.getEbooksCategories();
      } else if (this.type == 'showTV') {
        this.getShowTVToEdit();
        this.getShowTVCategories();
      }
    },
  },
  created: function () {
    this.admin();
    this.loadEditable();

    // Établissez la connexion WebSocket avec votre serveur
    this.socket = io("https://server.heavenflix.fr")

    // Écoutez l'événement "message" pour recevoir des messages WebSocket
    this.socket.on("message", (message) => {
      this.showNotification('info', message);
    });

    this.socket.on("conversion", (conversion) => {
      if(conversion.ended) {
        const season = conversion.season;
        const episode = conversion.episode;
        const messageContainer = document.getElementById(`conversion-S${season}E${episode}`);
        if (messageContainer) {
          messageContainer.style.display = 'none';
          messageContainer.innerText = '';
        }
      } else {
        if(!conversion.season || !conversion.episode) return;
        const season = conversion.season;
        const episode = conversion.episode;
        const message = conversion.message;
        const messageContainer = document.getElementById(`conversion-S${season}E${episode}`);
        if (messageContainer) {
          messageContainer.style.display = 'block';
          messageContainer.innerText = message;
        }
      }
    });

    this.socket.on(`conversion-movie-edit`, (res) => {
      if(res.ended) {
        this.uploadingMovie = false;
      }
      this.uploadingMovie = true;
      this.uploadingMessage = undefined;
      this.uploadingMessage = `${res.name} - ${res.message}`;
    });

    this.socket.on(`conversion-movie-${this.userId}`, (res) => {
      if(res.ended) {
        this.uploadingMovie = false;
      }
      this.uploadingMovie = true;
      this.uploadingMessage = undefined;
      this.uploadingMessage = `${res.name} - ${res.message}`;
    });
  },
  beforeUnmount() {
    // Fermez la connexion WebSocket lorsque le composant est détruit
    if (this.socket) {
      this.socket.close()
    }
  },
  mounted: function () {
    document.body.style.overflowY = "auto";
  }
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;
$soft-grey: #2D2E32;

.mainContainer {
  position: relative;
  width: 100%;
  min-height: 110vh;
  background: $back-color;
  padding-bottom: 50px;

  .loadingContainer {
    width: 100%;
    height: 100dvh;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);
    z-index: 5;

    .loading {
      background: $dark-color;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-direction: column;
      padding: 20px 25px;

      img {
        width: 100px;
      }

      p {
        color: $light-color;
        font-family: 'Montserrat';
        margin: 5px 0;
      }

      span {
        color: rgba(255, 255, 255, 0.6);
        font-family: 'Montserrat';
        font-size: 14px;
      }
    }
  }

  .loader {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.7);
    z-index: 5;

    .loaderSpin {
      background: $dark-color;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 20px 30px;
      border-radius: 10px;

      img {
        width: 60px;
        height: 60px;
        object-fit: cover;
        border-radius: 50%;
      }

      p {
        font-family: 'Lato';
        font-size: 20px;
        color: white;
      }
    }
  }

  .noArticle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Lato';
    font-size: 20px;
    color: white;
    margin-top: 20px;

    .noArticleOptions {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-top: 20px;

      button {
        padding: 10px 20px;
        border: none;
        background: $dark-red;
        color: white;
        font-family: 'Lato';
        font-size: 16px;
        border-radius: 10px;
        transition-duration: 0.4s;

        &:hover {
          cursor: pointer;
          transition-duration: 0.4s;
          background: $soft-grey;
        }
      }
    }
  }

  .changer {
    margin: auto;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 10px 0;

    button {
      color: white;
      background: $dark-red;
      border: none;
      padding: 10px;
      border-radius: 5px;
      font-family: 'Montserrat';
      font-size: 14px;

      &:hover {
        cursor: pointer;
      }
    }

    .upload {
      background: $soft-grey;
      color: white;
      padding: 10px 20px;
      font-family: 'Montserrat';
      font-weight: bold;
      font-size: 14px;
    }

    .queue {
      background: rgb(150, 33, 150);
      color: white;
      padding: 10px 20px;
      font-family: 'Montserrat';
      font-weight: bold;
      font-size: 14px;
    }

    .watch {
      all: unset;
      background: dodgerblue;
      color: white;
      padding: 10px 20px;
      font-family: 'Montserrat';
      font-weight: bold;
      border-radius: 5px;
      text-decoration: none;
      font-size: 14px;
      cursor: pointer;
    }

    .delete {
      background: rgb(231, 208, 0);
      color: black;
      padding: 10px 20px;
      font-family: 'Montserrat';
      font-weight: bold;
      font-size: 14px;
    }

    .activation {
      background: $light-color;
      color: $dark-color;
    }
  }

  .fullForm {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: 30px;
      font-family: 'Lato';
      color: white;
      margin: 10px 0;
    }

    .images {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;

      img {

        &:first-child {
          width: 300px;
          height: auto;
          position: absolute !important;
          bottom: 60px;
          left: 300px;
          z-index: 2;
          border-radius: 10px;
          box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.7);
        }

        &:last-child {
          width: 100%;
          height: 850px;
          object-fit: cover;
          filter: brightness(0.4) blur(2.5px);
        }
      }
    }

    .globalUpload {
      background: $soft-grey;
      padding: 20px 30px;
      border-radius: 15px;
      margin-top: 40px;
      align-items: center;
    }

    .entryEpisodes {
      width: 60%;
      margin: 20px 0;
      display: flex;
      gap: 20px;
      z-index: 1;

      //Input type file
      .file {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        label {
          width: 100%;
          background: $dark-red;
          color: white;
          padding: 10px 20px;
          border-radius: 10px;
          font-family: 'Montserrat';
          font-size: 16px;
          cursor: pointer;
          transition-duration: 0.7s;

          &:hover {
            background: $dark-color;
          }
        }

        input {
          display: none;
        }
      }


      select {
        width: 50%;
        background: $dark-red;
        border: none;
        color: white;
        font-family: 'Montserrat';
        font-size: 16px;
        padding: 10px 10px;
        outline: none;
        border-radius: 10px;

        option {
          background: $light-color;
          color: $back-color;
        }

        &:hover {
          cursor: pointer;
        }
      }

      button {
        width: 25%;
        border: none;
        background: rgb(194, 194, 194);
        color: $back-color;
        padding: 10px 0;
        transition-duration: 0.7s;
        font-size: 16px;
        font-family: 'Lato';
        border-radius: 10px;

        &:hover {
          cursor: pointer;
          transition-duration: 0.7s;
          background: $dark-red;
          color: white;
        }
      }
    }

    .seasonSelector {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin-top: 20px;

      select {
        width: 50%;
        background: $dark-red;
        border: none;
        color: white;
        font-family: 'Montserrat';
        font-size: 16px;
        padding: 10px 10px;
        outline: none;
        border-radius: 10px;

        option {
          background: $light-color;
          color: $back-color;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .seasonForm {
      background: $soft-grey;
      padding: 20px 30px;
      border-radius: 15px;
      margin-top: 40px;

      .conversion {
        display: none;
        color: $dark-red;
        font-family: 'Montserrat';
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.3px;
        opacity: 0.9;
      }

      img {
        width: 25%;
        height: auto;
        object-fit: cover;
        display: block;
      }

      p {
        font-family: 'Lato';
        font-size: 20px;
        color: white;
        margin-top: 20px;
      }
    }

    .sumbitButton {
      margin: 20px auto 0 auto;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        width: 50%;
        border: none;
        background: rgb(194, 194, 194);
        color: $back-color;
        padding: 10px 0;
        transition-duration: 0.7s;
        font-size: 16px;
        font-family: 'Lato';
        border-radius: 10px;

        &:hover {
          cursor: pointer;
          transition-duration: 0.7s;
          background: $dark-red;
          color: white;
        }
      }
    }

    .movieForm {
      width: 60%;
      position: relative;
      margin-top: 20px;
      background: $soft-grey;
      padding: 20px 30px;
      border-radius: 15px;


      .movieFileUpload {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        // Input type file with label as button
        input[type="file"] {
          display: none;
        }

        label {
          width: auto;
          background: $dark-red;
          color: white;
          padding: 10px 20px;
          border-radius: 10px;
          font-family: 'Montserrat';
          font-size: 16px;
          cursor: pointer;
          transition-duration: 0.7s;

          &:hover {
            background: $dark-color;
          }
        }

        button {
          width: 25%;
          border: none;
          background: rgb(194, 194, 194);
          color: $back-color;
          padding: 10px 0;
          transition-duration: 0.7s;
          font-size: 16px;
          font-family: 'Lato';
          border-radius: 10px;

          &:hover {
            cursor: pointer;
            transition-duration: 0.7s;
            background: $dark-red;
            color: white;
          }
        }
      }

      .movieUrlUpload {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        input[type="text"] {
          width: 50%;
          background: $light-color;
          border: none;
          color: black;
          font-family: 'Montserrat';
          font-size: 16px;
          padding: 10px 10px;
          outline: none;
          border-radius: 10px;
        }

        button {
          width: 25%;
          border: none;
          background: rgb(194, 194, 194);
          color: $back-color;
          padding: 10px 0;
          transition-duration: 0.7s;
          font-size: 16px;
          font-family: 'Lato';
          border-radius: 10px;

          &:hover {
            cursor: pointer;
            transition-duration: 0.7s;
            background: $dark-red;
            color: white;
          }
        }
      }
    }

    .form {
      width: 60%;
      position: relative;

      h2 {
        font-size: 30px;
        font-family: 'Lato';
        color: white;
        margin-bottom: 10px;
      }

      .boxes {
        padding: 20px 5px;
        margin-top: 10px;
        background: $soft-grey;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        border-radius: 10px;

        .box {
          list-style: none;
          column-count: 4;

          li {
            display: flex;
            justify-content: space-between;
            padding: 2.5px 10px;
            border-radius: 5px;
            color: white;

            label {
              margin: 0 4px;
              font-family: 'Montserrat';
            }

            input {
              cursor: pointer;
            }

            &:hover {
              transition-duration: 0.4s;
              background: rgba(255, 255, 255, 0.2);
            }
          }
        }
      }

      .entryOptions {
        width: 100%;
        margin: 20px 0;
        display: flex;
        gap: 20px;
        z-index: 1;

        //Input type file
        .file {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          label {
            width: 100%;
            background: $dark-red;
            color: white;
            padding: 10px 20px;
            border-radius: 10px;
            font-family: 'Montserrat';
            font-size: 16px;
            cursor: pointer;
            transition-duration: 0.7s;

            &:hover {
              background: $dark-color;
            }
          }

          input {
            display: none;
          }
        }

        //Input type text
        input[type="text"] {
          width: 75%;
          background: $light-color;
          border: none;
          color: black;
          font-family: 'Montserrat';
          font-size: 16px;
          padding: 10px 10px;
          outline: none;
          border-radius: 10px;
        }


        select {
          width: 50%;
          background: $dark-red;
          border: none;
          color: white;
          font-family: 'Montserrat';
          font-size: 16px;
          padding: 10px 10px;
          outline: none;
          border-radius: 10px;

          option {
            background: $light-color;
            color: $back-color;
          }

          &:hover {
            cursor: pointer;
          }
        }

        button {
          width: 25%;
          border: none;
          background: rgb(194, 194, 194);
          color: $back-color;
          padding: 10px 0;
          transition-duration: 0.7s;
          font-size: 16px;
          font-family: 'Lato';
          border-radius: 10px;

          &:hover {
            cursor: pointer;
            transition-duration: 0.7s;
            background: $dark-red;
            color: white;
          }
        }
      }

      .checkbox {
        margin-top: 20px !important;
        display: block !important;

        label {
          margin-left: 15px !important;
          font-size: 20px !important;
          font-family: 'Lato' !important;
          background: $light-color !important;
          color: $dark-red !important;
          padding: 5px 15px !important;
          border-radius: 5px !important;
          cursor: pointer !important;
          box-shadow: 0 0 10px $dark-color !important;
        }
      }

      .entry {
        width: 100%;
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        z-index: 1;

        a {
          width: fit-content;
          background: $dark-color;
          color: $light-color;
          text-decoration: none;
          padding: 10px 20px;
          border-radius: 10px;
          margin-top: 10px;
          transition-duration: 0.7s;
          font-size: 16px;
          font-family: 'Montserrat';

          &:hover {
            cursor: pointer;
            transition-duration: 0.7s;
            background: $dark-red;
            color: white;
          }
        }

        .moreOrLess {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 5px;

          button {
            width: 45%;
            background: rgb(206, 206, 206);

            &:nth-child(0) {
              padding-right: 10px;
            }

            &:hover {
              background: $dark-red;
            }
          }
        }

        label {
          margin-bottom: 5px;
          font-size: 20px;
          font-family: 'Lato';
          color: white;
        }

        input[type="checkbox"] {
          width: 15px;
          height: 15px;
          cursor: pointer;
          margin-top: 5px;
        }

        select {
          width: 100%;
          background: $dark-red;
          border: none;
          color: white;
          font-family: 'Montserrat';
          font-size: 16px;
          padding: 10px 10px;
          outline: none;
          border-radius: 10px;

          option {
            background: $light-color;
            color: $back-color;
          }

          &:hover {
            cursor: pointer;
          }
        }

        input {
          width: 100%;
          height: 40px;
          outline: none;
          border: none;
          padding-left: 10px;
          background: #ffffff;
          font-size: 16px;
          font-family: 'Montserrat';
          border-radius: 10px;
        }


        button {
          margin: auto;
          width: 50%;
          border: none;
          background: rgb(194, 194, 194);
          color: $back-color;
          padding: 10px 0;
          transition-duration: 0.7s;
          font-size: 16px;
          font-family: 'Lato';
          border-radius: 10px;

          &:hover {
            cursor: pointer;
            transition-duration: 0.7s;
            background: $dark-red;
            color: white;
          }
        }

        &:last-child {
          margin-top: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .mainContainer {

    .fullForm {
      .form {
        width: 90%;

        .entry {
          input {
            max-width: 98%;
          }
        }

        .boxes {
          padding: 20px 0;

          .box {
            columns: 1;
            width: 100%;

            li {
              margin: auto;
              width: 92%;
              padding: 2.5px 0;
            }
          }
        }

        .boxesContainer {
          .boxesDisplayer {
            width: 92% !important;
          }
        }
      }

      .images {
        img {
          &:first-child {
            left: 20px;
            width: 260px;
          }

          &:last-child {
            height: 650px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .mainContainer {

    .fullForm {
      .form {
        width: 90%;

        .entry {
          input {
            max-width: 98%;
          }
        }

        .boxes {
          padding: 20px 0;

          .box {
            columns: 1;
            width: 100%;

            li {
              margin: auto;
              width: 92%;
              padding: 2.5px 0;
            }
          }
        }

        .boxesContainer {
          .boxesDisplayer {
            width: 92% !important;
          }
        }
      }

      .images {
        img {
          &:first-child {
            left: 20px;
            width: 230px;
          }

          &:last-child {
            height: 500px;
          }
        }
      }
    }
  }
}
</style>
