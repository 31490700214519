<template>
    <div class="home">
        <ListComponent />
    </div>
</template>
  
<script>
import ListComponent from '../components/ListContainer.vue';

export default {
    name: 'AddView',
    components: {
        ListComponent
    }
}
</script>