import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import login from '../components/login.vue'
import add from '../views/AddView.vue'
import addSerie from '../views/AddSerieView.vue'
import addEbook from '../views/AddEbookView.vue'
import addShowTV from '../views/AddShowTVView.vue'
import addShowTVSeason from '../views/AddShowTVSeason.vue'
import addSerieEpisode from '../views/AddSerieEpisodeView.vue'
import ask from '../components/AskContainer.vue'
import admin from '../views/AdminView.vue'
import edit from '../views/EditView.vue'
import settings from '../views/SettingsView.vue'
import checker from '../views/checkerView.vue'
import Series from '../views/SeriesView.vue'
import ShowTV from '../views/ShowTVView.vue'
import player from '../views/playerView.vue'
import history from '../views/HistoryView.vue'
import maliste from '../views/ListView.vue'
import playerEbooks from '../views/playerEbooksView.vue'
import ebooks from '../views/EbooksView.vue'
import resetPassword from '../components/ForgotPassword.vue'
import reset from '../components/ResetPassword.vue'
import dashboard from '../components/DashboardContainer.vue'

const routes = [
  {
    path: '/movies',
    name: 'movies',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/add',
    name: 'add',
    component: add
  },
  {
    path: '/addSerie',
    name: 'addSerie',
    component: addSerie
  },
  {
    path: '/addEbook',
    name: 'addEbook',
    component: addEbook
  },
  {
    path: '/addShowTV',
    name: 'addShowTV',
    component: addShowTV
  },
  {
    path: '/addSerieEpisode',
    name: 'addSerieEpisode',
    component: addSerieEpisode
  },
  {
    path: '/ask',
    name: 'ask',
    component: ask
  },
  {
    path: '/admin',
    name: 'admin',
    component: admin
  },
  {
    path: '/edit/:type/:id',
    name: 'edit',
    component: edit
  },
  {
    path: '/settings',
    name: 'settings',
    component: settings
  },
  {
    path: '/checker',
    name: 'checker',
    component: checker
  },
  {
    path: '/series',
    name: 'series',
    component: Series
  },
  {
    path: '/player/:type/:id/:season/:index',
    name: 'player',
    component: player
  },
  {
    path: '/playerEbooks',
    name: 'playerEbooks',
    component: playerEbooks
  },
  {
    path: '/history',
    name: 'history',
    component: history
  }
  ,
  {
    path: '/maliste',
    name: 'maliste',
    component: maliste
  },
  {
    path: '/ebooks',
    name: 'ebooks',
    component: ebooks
  },
  {
    path: '/showtv',
    name: 'showtv',
    component: ShowTV
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/movies'
  },
  {
    path: '/addShowTVSeason',
    name: 'addShowTVSeason',
    component: addShowTVSeason
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: resetPassword
  },
  {
    path: '/reset/:code',
    name: 'reset',
    component: reset
  },
  {
    path: '/dashboard/:type',
    name: 'dashboard',
    component: dashboard
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


async function checkToken() {
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch("https://api.heavenflix.fr/checkToken/" + token, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if(response.status === 801) {
      localStorage.clear();
      resetToken();
      localStorage.setItem("forceDisconnected", true);
      this.$router.push("/login");
      return false;
    }
    if (response.ok) {
      return true;
    } else {
      const data = await response.json();
      if (data.msg) console.log('Votre session a expiré. Veuillez vous reconnecter.');
      localStorage.clear();
      resetToken();
      localStorage.setItem("forceDisconnected", true);
      this.$router.push("/login");
      return false;
    }
  } else {
    return false;
  }
}


// Supprime le token de l'utilisateur dans la BDD avec le token en paramètre
async function resetToken() {
  console.log('suppression token')
  const token = localStorage.getItem("token");
  if (token) {
    const response = await fetch("https://api.heavenflix.fr/logout/" + token, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

router.beforeEach(async (to, from, next) => {
  checkToken();
  const privatePages = [
    "/movies",
    "/add",
    "/addSerie",
    "/addSerieEpisode",
    "/addEbook",
    "/addShowTV",
    "/addShowTVSeason",
    "/admin",
    "/ask",
    "/edit",
    "/settings",
    "/checker",
    "/favoris",
    "/series",
    "/player",
    "/playerEbooks",
    "/history",
    "/maliste",
    "/ebooks",
    "/dashboard"
  ];
  const isLoggedIn = !!localStorage.getItem("token");

  if (privatePages.includes(to.path) && !isLoggedIn) {
    // L'utilisateur tente d'accéder à une page privée sans être connecté
    next("/login");
  } else if (!to.matched.length) {
    // La route n'existe pas
    if (isLoggedIn) {
      // L'utilisateur est connecté, redirigez-le vers la page d'accueil
      next("/movies");
    } else {
      // L'utilisateur n'est pas connecté, redirigez-le vers la page de connexion
      next("/login");
    }
  } else {
    // La route est valide, autorisez l'accès
    next();
  }
});


export default router
