<template>
  <div class="mainContainer">
    <NavbarTop />
    <AddMenuNavigation />
    <div class="loadingContainer" v-if="isLoading">
      <div class="loading">
        <div v-if="step != 7"><img src="@/assets/loader.svg"></div>
        <div v-else><img src="@/assets/partying-face.svg"></div>
        <span>Etape {{ step }} sur 7</span>
        <p>{{ loadingMessage }}</p>
      </div>
    </div>
    <div class="seriesContainer">
      <select id="serieId" @change="getSerieInfos()">
        <option value="0">Choisir une série</option>
        <option v-for="serie in series" v-bind:key="serie.id" :value="serie.id"> {{ serie.name }}</option>
      </select>
    </div>
    <div class="infosContainer" v-if="serieSeasons != 0">
      <p>Saisons existantes : <b>{{ serieSeasons }}</b></p>
      <p>Liste des saisons : <b>{{ serieSeasonsList.join(', ') }}</b></p>
      <p>Nombre d'épisodes : <b>{{ serieSeasonsTotalEpisodes }}</b></p>
    </div>
    <!--Formulaire d'ajout d'une saison-->
    <div class="form">
      <div class="entry">
        <label for="year">Année </label>
        <input id="year" name="year" type="number" placeholder="Numéro de saison" />
      </div>
      <div class="entry">
        <label for="season">Saison </label>
        <input id="season" name="season" type="number" placeholder="Numéro de saison" />
      </div>
      <div class="entry">
        <label for="totalEpisodes">Nombre d'épisodes </label>
        <input id="totalEpisodes" :oninput="() => this.adjustEpisodes()" type="number" name="totalEpisodes" value="1">
        <!--<button :onclick="() => oneEpisodeMore()">Ajouter un épisode</button>
        <button :onclick="() => oneEpisodeLess()">Retirer un épisode</button>-->
      </div>
      <div class="entry" v-for="n in episodes" v-bind:key="n">
        <label for="lien">Lien d'épisode {{ n }}</label>
        <input class="lien" :id="'lien-' + n" name="lien" type="text" placeholder="Lien de streaming" />
      </div>
      <div class="entry">
        <button :onclick="() => addSeason()">Ajouter cette saison</button>
      </div>
    </div>
  </div>
</template>

<script>
// import the library
import { createToast } from 'mosha-vue-toastify'
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'
import NavbarTop from '../components/NavbarTop.vue'
import AddMenuNavigation from './AddMenuNavigation.vue'

export default {
  name: 'AddMovie',
  components: {
    NavbarTop, AddMenuNavigation
  },
  data() {
    return {
      link: "https://api.heavenflix.fr",
      categories: undefined,
      collections: undefined,
      episodes: 1,
      showCollections: true,
      isLoading: false,
      fileName: undefined,
      loadingMessage: 'Envoi du fichier..',
      step: 0,
      series: [],
      serieSeasons: 0,
      serieSeasonsList: [],
      serieSeasonsTotalEpisodes: 0,
    }
  },
  setup() {
    const toast = () => {
      createToast('Wow, easy')
    }
    return { toast }
  },
  methods: {
    getSerieInfos: async function getSerieInfos() {
      const serieId = document.getElementById('serieId').value;
      this.serieSeasons = 0;
      this.serieSeasonsList = [];
      this.serieSeasonsTotalEpisodes = 0;
      if (serieId < 1) {
        this.showNotificationWarning('Veuillez choisir une série.');
        this.serieSeasons = 0;
        return
      }
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/allShowTVSeasonsFromShowTVId/' + serieId, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.serieSeasons = data ? data.length : 0;
        data.forEach(element => {
          element.episodesLinks = JSON.parse(element.episodesLinks);
          for (let i = 0; i < element.episodesLinks.length; i++) {
            this.serieSeasonsTotalEpisodes++;
          }
          if (element && element.length > 1) {
            element.forEach(season => {
              this.serieSeasonsList.push(season);
            });
          } else {
            this.serieSeasonsList.push(element.season);
          }
        });
        this.serieSeasonsList.sort((a, b) => a - b);
      })
    },
    showNotification(type, text) {
      createToast(text, {
        position: 'top-center',
        type: type,
        transition: 'zoom',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: 3000,
        //toastBackgroundColor: '#2D2E32',
      })
    },
    showNotificationWarning(text) {
      createToast(text, {
        position: 'top-center',
        type: 'warning',
        transition: 'zoom',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: 3000,
        //toastBackgroundColor: '#2D2E32',
      })
    },
    adjustEpisodes: async function adjustEpisodes() {
      const inputElement = document.getElementById('totalEpisodes');
      if (inputElement.value <= 1) return this.episodes = 1;
      this.episodes = parseInt(inputElement.value);
    },
    oneEpisodeMore: async function oneEpisodeMore() {
      this.episodes++;
    },
    oneEpisodeLess: async function oneEpisodeLess() {
      if (this.episodes > 1) return this.episodes--;
    },
    admin: async function admin() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      };
      await fetch(this.link + '/getAdmin/' + localStorage.getItem('userId'), requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then(data => {
          if (data.msg) return console.log(data.msg);
          if (data[0].isAdmin == 0) return this.$router.push('/movies');
        })
    },
    getAllSeries: async function getAllSeries() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getShowTVWithSeasons', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        data.forEach(element => {
          this.series.push(element);
        });
      })
    },
    addSeason: async function addSeason() {
      const serieId = document.getElementById('serieId').value;
      var season = document.getElementById('season').value;
      var liens = document.getElementsByClassName('lien');
      var year = document.getElementById('year').value;
      if (serieId < 1) return this.showNotificationWarning('Veuillez choisir une série.');
      if (!season) return this.showNotificationWarning('Numéro de saison manquant!');
      if (!year) return this.showNotificationWarning('Année manquante!');
      let linksArray = [];
      Array.from(liens).forEach(element => {
        linksArray.push(element.value);
      });
      let strArray = JSON.stringify(linksArray);
      strArray = strArray.replace("[", '');
      strArray = strArray.replace("]", '');
      strArray = strArray.replace(/"/g, "'");
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          season: season,
          serieId: serieId,
          liens: strArray,
          year: year,
        })
      };
      await fetch(this.link + '/addShowTVSeasons', requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        } else {
          this.showNotification('success', 'Saison ajoutée');
        }
      });
    },
    toggleShowLoading() {
      this.isLoading = !this.isLoading;
    },
  },
  created: function () {
    this.admin();
    this.getAllSeries();
  },
  mounted: function () {
    document.body.style.overflowY = "auto";
  }
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;
$soft-grey: #2D2E32;

@mixin background-shadow($main-color) {
  background: $main-color;
  box-shadow: 0 0 10px $main-color;
}

.buton {

  border: none;
  padding: 15px;
  border-radius: 50%;

  &:first-child {
    @include background-shadow(dodgerblue);
  }

  &:last-child {
    @include background-shadow(red);
  }
}

.mainContainer {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: $back-color;

  label {
    margin-left: 5px;
    font-size: 18px;
    font-family: 'Lato';
    color: $light-color;
  }

  .loadingContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);

    .loading {
      background: $dark-color;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-direction: column;
      padding: 20px 25px;

      img {
        width: 100px;
      }

      p {
        color: $light-color;
        font-family: 'Montserrat';
        margin: 5px 0;
      }

      span {
        color: rgba(255, 255, 255, 0.6);
        font-family: 'Montserrat';
        font-size: 14px;
      }
    }
  }

  .selector {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;

    .selectorContainer {
      display: flex;
      border-radius: 5px;
      overflow: hidden;

      button {
        background: rgba(255, 255, 255, 0.2);
        border: none;
        font-size: 20px;
        font-family: 'Lato';
        color: white;
        padding: 10px 20px;

        &:hover {
          cursor: pointer;
        }

        &:last-child {
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .seriesContainer {
    width: 100%;
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
      margin: 50px 0;
      color: $light-color;
      font-family: 'Lato';
      font-size: 30px;

    }

    #serieId {
      width: 60%;
      height: 40px;
      outline: none;
      border: none;
      padding-left: 10px;
      background: $dark-red;
      color: $light-color;
      font-size: 16px;
      font-family: 'Montserrat';
      border-radius: 10px;

      option {
        background: white;
        color: #090a0e;
        padding: 5px 0;
      }
    }
  }

  .infosContainer {
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    p {
      color: $light-color;
      font-family: 'Montserrat';
      font-size: 16px;
    }
  }

  .form {
    margin: 0 auto;
    width: 60%;
    padding: 0 0 50px 0;

    .boxes {
      padding: 20px 5px;
      margin-top: 10px;
      background: $soft-grey;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      border-radius: 10px;

      .box {
        list-style: none;
        column-count: 4;

        li {
          display: flex;
          justify-content: space-between;
          padding: 2.5px 10px;
          border-radius: 5px;

          label {
            margin: 0 4px;
            font-family: 'Montserrat';
          }

          input {
            cursor: pointer;
          }

          &:hover {
            transition-duration: 0.4s;
            background: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }

    .entry {
      width: 100%;
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 1;

      label {
        margin-bottom: 5px;
        font-size: 20px;
        font-family: 'Lato';
        color: $light-color;
      }

      select {
        width: 100%;
        background: $dark-red;
        border: none;
        color: white;
        font-family: 'Montserrat';
        font-size: 16px;
        padding: 10px 10px;
        outline: none;
        border-radius: 10px;

        option {
          background: $light-color;
          color: $back-color;
        }

        &:hover {
          cursor: pointer;
        }
      }

      input[type="text"] {
        width: 100%;
        height: 40px;
        outline: none;
        border: none;
        padding-left: 10px;
        background: #ffffff;
        font-size: 16px;
        font-family: 'Montserrat';
        border-radius: 10px;
      }

      input[type="number"] {
        width: 100%;
        height: 40px;
        outline: none;
        border: none;
        padding-left: 10px;
        background: #ffffff;
        font-size: 16px;
        font-family: 'Montserrat';
        border-radius: 10px;
      }

      .inputFileName {
        display: flex;
        align-items: center;
        gap: 10px;

        div {
          color: $light-color;
          font-family: 'Montserrat';
          font-size: 16px;
        }
      }

      .label-file {
        margin-top: 5px;
        cursor: pointer;
        background: $dark-color;
        width: fit-content;
        padding: 10px 15px;
        border-radius: 10px;
        transition-duration: 0.4s;

        &:hover {
          background: $dark-red;
          transition-duration: 0.4s;
        }
      }

      input[type="file"] {
        display: none;
      }

      button {
        margin: auto;
        width: 50%;
        border: none;
        background: rgb(194, 194, 194);
        color: $back-color;
        padding: 10px 0;
        transition-duration: 0.7s;
        font-size: 16px;
        font-family: 'Lato';
        border-radius: 10px;

        &:hover {
          cursor: pointer;
          transition-duration: 0.7s;
          background: $dark-red;
          color: white;
        }
      }

      ul {
        list-style: none;
        padding: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        li {
          background: $dark-color;
          padding: 10px 15px;
          border-radius: 10px;
          transition: 0.4s;

          a {
            text-decoration: none;
            color: $light-color;
            font-family: 'Montserrat';
          }

          &:hover {
            cursor: pointer;
            background: $dark-red;
            transition: 0.4s;
          }
        }
      }

      &:last-child {
        margin-top: 20px;
      }
    }

    .boxesContainer {
      width: 100%;
      margin-top: 30px;

      .boxesDisplayer {
        width: 98%;
        color: white;
        background: $soft-grey;
        border: none;
        font-size: 17px;
        font-family: 'Lato';
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 15px;
        cursor: pointer;
      }
    }
  }
}

//Affichage mobile
@media screen and (max-width: 426px) {
  .mainContainer {
    .form {
      width: 90%;

      .entry {
        input {
          max-width: 98%;
        }
      }

      .boxes {
        padding: 20px 0;

        .box {
          columns: 1;
          width: 100%;

          li {
            margin: auto;
            width: 92%;
            padding: 2.5px 0;
          }
        }
      }

      .boxesContainer {
        .boxesDisplayer {
          width: 92% !important;
        }
      }
    }
  }
}
</style>
