<template>
  <div class="homePage">
    <NavbarTop />
    <div class="goBack">
      <button @click="$router.push('/ebooks')"><i class="fa-solid fa-arrow-left"></i> Retour</button>
    </div>
    <!-- carroussel swiperJS-->
    <div class="details" v-if="ebook">
      <h1>{{ ebook.title }} <span v-if="ebook.tome != 0">- Tome {{ ebook.tome }}</span></h1>
      <span class="author">by <i>{{ ebook.author }}</i>, <i>{{ ebook.anneedesortie }}</i></span>
      <div class="categories">
        <span v-for="(category, index) in ebook.categories" :key="index" class="category">{{ category }}</span>
      </div>
      <p>{{ ebook.description }}</p>
    </div>
    <swiper :pagination="{ type: 'fraction', clickable: true }" :navigation="false" @swiper="onSwiper"
      @slideChange="onSlideChange" :modules="modules" class="mySwiper" id="swiper" :zoom="true" :zoomMax="3"
      :preloadImages="false" :virtual="{ slides: files }">
      <swiper-slide class="swiper-slide" v-for="(file, index) in files" :key="index" :id="index"
        :style="{ 'background-image': 'url(' + link + '/ebooks/' + ebook.folder + '/' + file + ')' }">
        <img loading="lazy" :src="link + '/ebooks/' + ebook.folder + '/' + file">
      </swiper-slide>
      <swiper-pagination class="swiper-pagination" id="pages"></swiper-pagination>
      <div class="fullscreen">
        <button class="fullscreenBtn" @click="toggleFullScreen" title="Plein écran"><i
            class="fa-solid fa-expand"></i></button>
      </div>
    </swiper>
    <div class="bookmark">
      <div v-if="bookmarkPage != undefined">
        <button class="bookmarkBtn" @click="goToBookmark"
          title="Reprendre votre lecture là vous avez déposé le marque-page"><i class="fa-solid fa-bookmark"></i>
          Reprendre
          au
          marque-page</button>
      </div>
      <div v-if="bookmarkPage != undefined">
        <button class="bookmarkBtn" @click="addOrEditBookmark"
          title="Déplacer un marque-page vous permet de revenir à cet endroit plus tard"><i
            class="fa-solid fa-bookmark"></i>
          Déplacer le
          marque-page</button>
      </div>
      <div v-else>
        <button class="bookmarkBtn" @click="addOrEditBookmark"
          title="Déposer un marque-page vous permet de revenir à cet endroit plus tard"><i
            class="fa-solid fa-bookmark"></i>
          Déposer le
          marque-page</button>
      </div>
      <div v-if="bookmarkPage != undefined">
        <button class="bookmarkBtn" @click="deleteBookmark"
          title="Retirer un marque-page supprime totalement la sauvegarde"><i class="fa-solid fa-bookmark"></i>
          Retirer le marque-page</button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// import the library
import { createToast } from 'mosha-vue-toastify';
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'
import NavbarTop from './NavbarTop.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
// importez les modules Swiper requis
import { Navigation, Pagination, Zoom, Virtual } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
  name: 'PlayerEbooksContainer',
  components: {
    NavbarTop, Swiper,
    SwiperSlide
  },
  data() {
    return {
      isAdmin: undefined,
      link: "https://api.heavenflix.fr",
      ebook: undefined,
      files: undefined,
      isFullscreen: false,
      currentIndex: 0,
      bookmarkPage: undefined,
      userId: localStorage.getItem('userId'),
    }
  },
  setup() {
    const onSwiper = (Swiper) => {

    };
    const onSlideChange = (swiper) => {
      const slides = document.getElementsByClassName('swiper-slide');
      let currentIndex = swiper.activeIndex;
    };
    const toast = () => {
      createToast('Wow, easy')
    }
    return {
      toast,
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Zoom, Virtual],
    };
  },
  methods: {
    async deleteBookmark() {
      await fetch(this.link + "/deleteBookmark/" + this.userId + '/' + this.ebook.id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.msg) {
            this.showNotification('success', data.msg)
            this.bookmarkPage = undefined;
            return
          }
          this.showNotification('danger', 'Une erreur s\'est produite. Veuillez réessayer plus tard.')
        })
        .catch((error) => {
          this.showNotification('danger', 'Une erreur s\'est produite à l\'envoi des données. Veuillez réessayer plus tard.')
        });
    },
    goToBookmark() {
      const swiper = document.getElementById('swiper');
      swiper.swiper.slideTo(this.bookmarkPage);
    },
    async getBookmark(id) {
      try {
        const response = await fetch(this.link + "/getBookmark/" + this.userId + '/' + id, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          // Vérifiez si la réponse n'est pas OK (statut HTTP autre que 200)
          throw new Error("La requête a échoué avec le statut " + response.status);
        }
        const data = await response.json();
        if (data[0] && data[0].indexSaved) {
          this.bookmarkPage = data[0].indexSaved;
        } else {
          return
        }
      } catch (error) {
        console.error(error);
        this.showNotification('danger', 'Une erreur s\'est produite à la récupération des données.');
      }
    },
    async addOrEditBookmark() {
      const index = parseInt(document.getElementsByClassName('swiper-slide-visible')[0].id);
      await fetch(this.link + "/addBookmark", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ebook_id: this.ebook.id,
          user_id: localStorage.getItem('userId'),
          indexSaved: index,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.msg) {
            this.showNotification('success', data.msg)
            this.bookmarkPage = index;
            return
          }
          this.showNotification('danger', 'Une erreur s\'est produite.')
        })
        .catch((error) => {
          this.showNotification('danger', 'Une erreur s\'est produite à l\'envoi des données.')
        });
    },
    showNotification(type, text) {
      createToast(text, {
        position: 'top-center',
        type: type,
        transition: 'zoom',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: 3000,
        //toastBackgroundColor: '#2D2E32',
      })
    },
    toggleFullScreen() {
      const largeurEcran = window.innerWidth;

      const swiper = document.getElementById('swiper');
      if (largeurEcran > 1024) return this.showNotification('warning', 'Le mode plein écran n\'est pas disponible pour votre écran.')
      if (!this.isFullscreen) {
        swiper.classList.add('swiperFullScreen');
        swiper.style.padding = '0';
        this.isFullscreen = true;
      } else {
        swiper.classList.remove('swiperFullScreen');
        swiper.style.padding = '40px 0';
        this.isFullscreen = false;
      }
    },
    async getEbook(id) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getEbookById/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        data.forEach(element => {
          element.categories = JSON.parse(element.categories);
          if (element.categories[0] == element.categories[1]) element.categories = element.categories.splice(1, 1);
          element.files = JSON.parse(element.files);
          element.folder = JSON.parse(element.folder);
          this.files = element.files;
          this.ebook = element;
        });
        this.getBookmark(this.ebook.id);
      })
    },
    admin: async function admin() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      };
      await fetch(this.link + '/getAdmin/' + this.userId, requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then(data => {
          if (data.msg) return console.log(data.msg);
          this.isAdmin = data[0].isAdmin;
        })
    },
  },
  created: function () {

  },
  mounted: function () {
    this.admin();
    this.getEbook(localStorage.getItem('ebook'));
  }
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;

* {
  padding: 0;
  margin: 0;
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.homePage {
  width: 100%;
  min-height: 100vh;
  background: $back-color;
  overflow-y: auto;
  overflow-x: auto;

  .goBack {
    max-width: 1024px;
    margin: 25px auto 0 auto;

    button {
      font-size: 16px;
      border: none;
      padding: 10px 20px;
      background: $dark-color;
      color: white;
      font-weight: bold;
      border-radius: 5px;
      transition-duration: 0.5s;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      i {
        font-size: 25px;
      }

      &:hover {
        cursor: pointer;
        background: $dark-red;
        transition-duration: 0.5s;
      }
    }
  }

  .details {
    max-width: 1024px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;


    h1 {
      color: $light-color;
      font-family: 'Lato';
      margin: 0;
    }

    .author {
      color: $light-color;
      font-family: 'Lato';
      font-size: 16px;
      margin: 0;

      i {
        font-weight: bold;
        font-size: 18px;
      }
    }

    .categories {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .category {
        padding: 10px 20px;
        background: $dark-red;
        color: white;
        border-radius: 15px;
        font-family: 'Lato';
        font-size: 17px;
      }
    }

    p {
      margin-top: 20px;
      color: rgba($color: #ffffff, $alpha: 0.6);
      font-family: 'Montserrat';
      font-size: 16px;
    }

    .infos {
      margin-top: 10px;
      color: $light-color;
      font-family: 'Lato';
      font-size: 20px;
    }
  }

  .swiper-pagination-total {
    font-family: 'Lato';
  }

  .swiperFullScreen {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: auto !important;
    z-index: 1;

    .swiper-pagination {
      margin-top: 50px;
    }
  }

  .swiper {
    margin: auto;
    width: 100%;
    max-width: 1024px;
    height: auto;
    z-index: 0;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    background: white;
    padding: 40px 0;

    .swiper-wrapper {
      width: 100%;
      height: 100%;

      .swiper-slide {
        width: 100%;
        height: 100%;
        background: red;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .swiper-pagination {
      font-family: 'Lato';
    }

    .fullscreen {
      position: absolute;
      bottom: 20px;
      right: 20px;
      z-index: 2;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background: rgba($color: #000000, $alpha: 0.5);
        padding: 8px;
        border-radius: 5px;
        border: none;
        font-size: 25px;
        transition: 0.4s;

        i {
          transition: 0.4s;
        }

        &:hover {
          cursor: pointer;

          i {
            transform: scale(1.1);
            transition: 0.4s;
          }
        }
      }
    }
  }

  .bookmark {
    max-width: 1024px;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    button {
      border: none;
      padding: 10px 20px;
      background: $dark-color;
      color: white;
      font-weight: bold;
      border-radius: 5px;
      font-size: 14px;
      transition-duration: 0.5s;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      i {
        font-size: 25px;
      }

      &:hover {
        cursor: pointer;
        background: $dark-red;
        transition-duration: 0.5s;
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .homePage {
    width: 100%;
    min-height: 100vh;
    background: $back-color;
    overflow-y: auto;
    overflow-x: auto;

    .goBack {
      width: 90%;
      margin: 25px auto 0 auto;

      button {
        font-size: 16px;
        border: none;
        padding: 10px 20px;
        background: $dark-color;
        color: white;
        font-weight: bold;
        border-radius: 5px;
        transition-duration: 0.5s;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        i {
          font-size: 25px;
        }

        &:hover {
          cursor: pointer;
          background: $dark-red;
          transition-duration: 0.5s;
        }
      }
    }

    .details {
      width: 90%;
      margin: 20px auto;
      display: flex;
      flex-direction: column;
      gap: 10px;


      h1 {
        color: $light-color;
        font-family: 'Lato';
      }

      p {
        color: $light-color;
        font-family: 'Montserrat';
        font-size: 18px;
      }
    }

    .swiperFullScreen {
      position: fixed !important;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100vh !important;
      border-radius: 0 !important;
      z-index: 1;

      .swiper-pagination {
        margin-top: 50px;
      }
    }

    .swiper {
      margin: auto;
      width: 90%;
      height: auto;
      z-index: 0;
      border-radius: 25px;
      overflow: hidden;
      position: relative;

      .swiper-wrapper {
        width: 100%;
        height: 100%;

        .swiper-slide {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .fullscreen {
        position: absolute;
        bottom: 20px;
        right: 20px;
        z-index: 2;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          background: rgba($color: #000000, $alpha: 0.5);
          padding: 8px;
          border-radius: 5px;
          border: none;
          font-size: 25px;
          transition: 0.4s;

          i {
            transition: 0.4s;
          }

          &:hover {
            cursor: pointer;

            i {
              transform: scale(1.1);
              transition: 0.4s;
            }
          }
        }
      }
    }

    .bookmark {
      max-width: 1024px;
      margin: 30px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      button {
        border: none;
        padding: 10px 20px;
        background: $dark-color;
        color: white;
        font-weight: bold;
        border-radius: 5px;
        font-size: 14px;
        transition-duration: 0.5s;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        i {
          font-size: 25px;
        }

        &:hover {
          cursor: pointer;
          background: $dark-red;
          transition-duration: 0.5s;
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .homePage {
    width: 100%;
    min-height: 100vh;
    background: $back-color;
    overflow-y: auto;
    overflow-x: auto;

    .details {
      width: 90%;
      margin: 20px auto;
      display: flex;
      flex-direction: column;
      gap: 10px;


      h1 {
        color: $light-color;
        font-family: 'Lato';
        font-size: 26px;
      }

      p {
        color: $light-color;
        font-family: 'Montserrat';
        font-size: 16px;
      }
    }

    .swiperFullScreen {

      position: fixed !important;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100vh !important;
      border-radius: none !important;
      z-index: 1;
      padding: 0 !important;

      .fullscreen {
        bottom: 10px !important;
        right: 10px !important;

        button {
          color: rgba($color: #ffffff, $alpha: 0.9) !important;
          background: rgba($color: #000000, $alpha: 0.2) !important;
          font-size: 16px !important;

          &:hover {
            cursor: pointer;

            i {
              transition: 0.4s;
            }
          }
        }
      }
    }

    .swiper {
      margin: auto;
      width: 95%;
      height: 100vh;
      z-index: 0;
      border-radius: 10px;
      overflow: hidden;
      position: relative;

      .swiper-wrapper {
        width: 100%;
        height: 100%;

        .swiper-slide {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .fullscreen {
        position: absolute;
        bottom: 20px;
        right: 20px;
        z-index: 2;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          background: rgba($color: #000000, $alpha: 0.5);
          padding: 8px;
          border-radius: 5px;
          border: none;
          font-size: 25px;
          transition: 0.4s;

          i {
            transition: 0.4s;
          }

          &:hover {
            cursor: pointer;

            i {
              transform: scale(1.1);
              transition: 0.4s;
            }
          }
        }
      }
    }

    .bookmark {
      max-width: 1024px;
      margin: 30px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      button {
        border: none;
        padding: 10px 20px;
        background: $dark-color;
        color: white;
        font-weight: bold;
        border-radius: 5px;
        font-size: 14px;
        transition-duration: 0.5s;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        i {
          font-size: 25px;
        }

        &:hover {
          cursor: pointer;
          background: $dark-red;
          transition-duration: 0.5s;
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .homePage {
    width: 100%;
    min-height: 100vh;
    background: $back-color;
    overflow-y: auto;
    overflow-x: auto;

    .details {
      width: 90%;
      margin: 20px auto;
      display: flex;
      flex-direction: column;
      gap: 10px;


      h1 {
        color: $light-color;
        font-family: 'Lato';
        font-size: 22px;
      }

      p {
        color: $light-color;
        font-family: 'Montserrat';
        font-size: 16px;
      }
    }

    .swiperFullScreen {
      position: fixed !important;
      top: 0;
      left: 0;
      width: 100% !important;
      height: auto !important;
      border-radius: none !important;
      padding: 41% 0;
      z-index: 1;

      .swiper-pagination {
        padding-top: 50px;
      }
    }

    .swiper {
      margin: auto;
      width: 95%;
      height: auto;
      z-index: 0;
      border-radius: 10px;
      overflow: hidden;
      position: relative;

      .swiper-wrapper {
        width: 100%;
        height: 100%;

        .swiper-slide {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .fullscreen {
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 2;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          background: rgba($color: #000000, $alpha: 0.5);
          padding: 8px;
          border-radius: 5px;
          border: none;
          font-size: 25px;
          transition: 0.4s;

          i {
            transition: 0.4s;
          }

          &:hover {
            cursor: pointer;

            i {
              transform: scale(1.1);
              transition: 0.4s;
            }
          }
        }
      }
    }

    .bookmark {
      width: 90%;
      margin: 30px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      button {
        border: none;
        padding: 10px 20px;
        background: $dark-color;
        color: white;
        font-weight: bold;
        border-radius: 5px;
        font-size: 14px;
        transition-duration: 0.5s;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        i {
          font-size: 25px;
        }

        &:hover {
          cursor: pointer;
          background: $dark-red;
          transition-duration: 0.5s;
        }
      }
    }
  }
}
</style>
