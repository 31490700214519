<template>
  <div class="notificationContainer" v-if="elements && elements.length > 0 || requests && requests.length > 0">
    <div class="notification">
      <h2>🔔 <b>{{ elements.length + requests.length }}</b> {{ (elements.length + requests.length) > 1 ? 'Notifications'
    :
    'Notification' }}</h2>
      <div class="notificationElement" v-for="element in elements" :key="element.id">
        <div v-if="element.status == 'Ajouté'">
          <p><b>{{ element.title }}</b> <span v-if="element.year">de <b>{{ element.year }}</b></span> que vous
            avez demandé a été <b>ajouté</b>, merci 🎊</p>
        </div>
        <div v-else-if="element.status == 'Indisponible'">
          <p><b>{{ element.title }}</b> <span v-if="element.year">de <b>{{ element.year }}</b></span> que vous avez
            demandé est pour l'instant <b>introuvable</b>, vous serez notifié
            quand l'article sera trouvable.</p>
        </div>
        <div v-else-if="element.status == 'Existe déjà'">
          <p><b>{{ element.title }}</b> <span v-if="element.year">de <b>{{ element.year }}</b></span> que vous avez
            demandé était <b>déjà existant</b> sur le site, si vous ne l'avez
            pas trouvé, il était sûrement désactivé car indisponible.</p>
        </div>
      </div>
      <div class="notificationElement" v-for="element in requests" :key="element.id">
        <p><b>{{ element.request }}</b> que tu as signalé à été traité, merci 🎊</p>
      </div>
      <button @click="setAsSeen()">Marquer comme lu</button>
    </div>
  </div>
</template>

<script>
// import the library
import { createToast } from 'mosha-vue-toastify';
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'

export default {
  name: 'NotificationsContainer',
  data() {
    return {
      isAdmin: 0,
      link: "https://api.heavenflix.fr",
      elements: [],
      requests: [],
    }
  },
  setup() {

    const toast = () => {
      createToast('Wow, easy')
    }
    return {
      toast,
    };
  },
  methods: {
    showNotification(type, text, duration) {
      createToast(text, {
        position: 'top-center',
        type: type,
        transition: 'slide',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: duration,
      })
    },
    setAsSeen: async function setAsSeen() {
      const elements = this.elements;
      const requests = this.requests;
      for (let i = 0; i < elements.length; i++) {
        await this.markedAsView(elements[i].id);
        // Suppression de l'élément de la liste
        this.elements.splice(i, 1);
        // Décrémenter i car le tableau a été réduit
        i--;

        if (i == elements.length - 1) this.showNotification('success', '🔔 Notification(s) marquée(s) comme lue(s)', 3000);
      }

      for (let i = 0; i < requests.length + 1; i++) {
        await this.setHasSeen(requests[i].id);
        this.requests.splice(i, 1);
        i--;
        if (i == requests.length - 1) this.showNotification('success', '🔔 Notification(s) marquée(s) comme lue(s)', 3000);
      }

    },
    setHasSeen: async function setHasSeen(id) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/setRequestSeen/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
      });
    },
    markedAsView: async function markedAsView(id) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/markAsViewed/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
      });
    },
    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        //hour: "numeric", // Ajout de l'heure
        //minute: "numeric", // Ajout des minutes
        hour12: false,
        timeZone: "Europe/Paris"
      };
      const date = new Date(dateString);
      const formattedDate = new Intl.DateTimeFormat("fr-FR", options).format(date);
      return formattedDate;
    },
    getDemandes: async function getDemandes(userId) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getUnseenDemandes/' + userId, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.elements = data;
      })
    },
    getRequests: async function getRequests(userId) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/unseenDoneRequests/' + userId, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.requests = data;
      })
    },
    admin: async function admin() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      };
      await fetch(this.link + '/getAdmin/' + localStorage.getItem('userId'), requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then(data => {
          if (data.msg) return console.log(data.msg);
          this.isAdmin = data[0].isAdmin;
        })
    },
  },
  created: function () {
    this.admin();
    this.getDemandes(localStorage.getItem('userId'));
    this.getRequests(localStorage.getItem('userId'));
  },
  mounted: function () {

  }
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$back-color: #15161b;
$dark-red: #E60036;
$soft-grey: #2D2E32;

.notificationContainer {
  width: 70%;
  margin: 20px auto;

  .notification {
    margin: auto;
    width: 100%;
    padding: 20px 20px 50px 20px;
    background-color: $back-color;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    color: $light-color;
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
    line-height: 1.5;
    border-radius: 15px;

    h2 {
      font-family: "Montserrat";
      font-size: 24px;
      font-weight: 500;
    }

    .description {
      font-size: 18px;
      font-family: 'Lato';
    }

    .notificationElement {
      margin: 10px 0;

      p {
        font-size: 18px;
        line-height: 1.3;
        font-family: 'Lato';
      }

      b {}
    }

    button {
      float: right;
      padding: 10px 20px;
      border-radius: 5px;
      border: none;
      background-color: $dark-red;
      color: $light-color;
      font-size: 14px;
      font-weight: 300;
      cursor: pointer;
      transition-duration: 0.3s;

      &:hover {
        background-color: $dark-red;
        filter: brightness(0.8);
      }
    }
  }
}

@media screen and (max-width: 1025px) {}

@media screen and (max-width: 769px) {}

@media screen and (max-width: 426px) {
  .notificationContainer {
    width: 90%;

    .notification {
      width: fit-content;

      p {
        font-size: 16px;
        line-height: 1;
      }
    }
  }
}
</style>
