<template>
  <div class="search">
    <div class="adminSection">
      <!--<div class="home"><router-link to="/checker" title="Vérifier tous les films">Checker</router-link></div>-->
      <div class="home"><router-link to="/settings" title="Accéder à ses paramètres">Paramètres</router-link></div>
      <div v-if="isAdmin > 0" class="home"><router-link to="/add" title="Ajouter un film">Ajouter</router-link></div>
      <div v-if="isAdmin > 0" class="home"><router-link to="/dashboard/server"
          title="Voir les films désactivés">Administrer</router-link></div>
      <div class="home"><router-link to="/history" title="Consulter votre historique">Historique</router-link>
      </div>
      <div class="home"><router-link to="/ask" title="Faire une demande et suivre vos demandes">Demandes</router-link>
      </div>
      <div class="home"><router-link to="/maliste" title="Voir tous vos films programmés">Ma liste</router-link></div>
      <div class="home"><router-link to="/ebooks" title="Voir les ebooks">Ebooks</router-link></div>
      <div class="home"><router-link to="/showtv" title="Voir les show TV">Show TV</router-link></div>
      <div class="home"><router-link to="/series" title="Voir les séries">Séries</router-link></div>
      <div class="home"><router-link to="/movies" title="Voir les films">Films</router-link></div>
      <button :onclick="() => disconnect()" title="Se déconnecter">Se déconnecter</button>
    </div>
    <div class="ops">
      <button class="opsBtn" title="Accéder aux options" @click="showOptions = !showOptions"><i
          class="fa-solid fa-gear"></i></button>
      <div class="dropdown" v-show="showOptions">
        <span v-if="isAdmin > 0"><router-link to="/add" title="Ajouter un film">Ajouter</router-link></span>
        <span v-if="isAdmin > 0"><router-link to="/dashboard/server"
            title="Voir les films désactivés">Administrer</router-link></span>
        <span><router-link to="/history" title="Consulter votre historique">Historique</router-link></span>
        <span><router-link to="/ask" title="Faire une demande et suivre vos demandes">Demandes</router-link></span>
        <span><router-link to="/maliste" title="Voir tous vos films programmés">Ma liste</router-link></span>
        <span><router-link to="/ebooks" title="Voir les ebooks">Ebooks</router-link></span>
        <span><router-link to="/showtv" title="Voir les show TV">Show TV</router-link></span>
        <span><router-link to="/series" title="Voir les séries">Séries</router-link></span>
        <span><router-link to="/movies" title="Voir les films">Films</router-link></span>
        <span><router-link to="/settings" title="Accéder à ses paramètres">Paramètres</router-link></span>
        <span><router-link to="/history" title="Accéder à votre historique">Historique</router-link></span>
        <span><button :onclick="() => disconnect()" title="Se déconnecter">Déconnexion</button></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GameContainer',
  data() {
    return {
      isAdmin: 0,
      link: "https://api.heavenflix.fr",
      isMobile: false,
      showOptions: false,
    }
  },
  methods: {
    mobileDetection: function mobileDetection() {
      if (window.innerWidth <= 426) return this.isMobile = true;
      this.isMobile = false;
    },
    disconnect: async function disconnect() {
      //requête pour logout l'utilisateur
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/logout/' + localStorage.getItem('token'), requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
      })
      localStorage.clear();
      this.$router.push('/login');
    },
    admin: async function admin() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      };
      await fetch(this.link + '/getAdmin/' + localStorage.getItem('userId'), requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then(data => {
          if (data.msg) return console.log(data.msg);
          this.isAdmin = data[0].isAdmin;
        })
    },
  },
  created: function () {
    this.admin();
  },
  mounted: function () {
    this.mobileDetection();
  }
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;

.search {
  width: 100%;
  padding: 30px 0;
  background: #0d0e12;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ops {
    display: none;
    position: relative;
    margin-right: 10px;

    .opsBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      padding: 5px 10px;
      background: transparent;
      color: $dark-red;
      font-weight: bold;
      border-radius: 5px;
      font-size: 14px;
      transition-duration: 0.5s;

      i {
        font-size: 25px;
      }

      &:hover {
        cursor: pointer;
        color: white;
        transition-duration: 0.5s;
      }
    }

    .dropdown {
      display: block;
      position: absolute;
      background-color: #f1f1f1;
      min-width: 160px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 3;
      top: 100%;
      right: 5%;
      animation: appear 1s forwards;

      span {
        color: black;
        text-decoration: none;
        display: block;
        padding: 5px;

        a {
          padding: 5px;
          font-size: 16px;
          text-decoration: none;
          color: black;
          height: 100%;
          font-family: 'Lato';
        }

        button {
          border: none;
          background: transparent;
          color: $dark-red;
          padding: 5px;
          height: 100%;
          font-size: 16px;

          &:hover {
            cursor: pointer;
          }
        }

        &:hover {
          background-color: #ddd;
        }
      }
    }
  }

  .adminSection {
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-family: 'Lato';
    padding: 0 5%;

    button {
      border: none;
      padding: 5px 10px;
      background: $dark-red;
      color: white;
      font-weight: bold;
      border-radius: 5px;
      font-size: 14px;

      &:hover {
        cursor: pointer;
      }
    }

    .home {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      padding: 5px 10px;
      background: transparent;
      font-weight: bold;
      border-radius: 5px;

      a {
        color: white;
        text-decoration: none;
        font-size: 14px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .search {
    .adminSection {
      margin-right: 3%;
    }
  }
}

@media screen and (max-width: 769px) {
  .search {
    .adminSection {
      flex-wrap: wrap;
      margin-right: 10px;
    }
  }
}

@media screen and (max-width: 426px) {
  .search {
    .adminSection {
      display: none;
    }

    .ops {
      display: inline-block;
    }
  }
}
</style>
