<template>
  <div class="home">
    <AddShowTVSeason />
  </div>
</template>

<script>
import AddShowTVSeason from '../components/AddShowTVSeason.vue';

export default {
  name: 'AddShowTVSeasonView',
  components: {
    AddShowTVSeason
  }
}
</script>