<template>
  <div class="homePage" id="home">
    <NavBar />
    <div class="goTop" v-show="scrollDistance > 775">
      <button :onclick="() => goTop()" title="Remonter" id="topButton"><svg xmlns="http://www.w3.org/2000/svg"
          width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevrons-up">
          <polyline points="17 11 12 6 7 11"></polyline>
          <polyline points="17 18 12 13 7 18"></polyline>
        </svg></button>
    </div>
    <div class="displayer" id="displayer" v-if="selectedEbook != null">
      <div class="displayerContainer" v-for="element in selectedEbook" v-bind:key="element.id" data-aos="fade-in"
        data-aos-duration="1500">
        <div class="exit">
          <button :onclick="() => closeSeenEbook()" title="Fermer l'aperçu"><i class="fa-solid fa-xmark"></i></button>
        </div>
        <div class="upper">
          <img :src="element.coverURL" alt="Affiche du ebook" loading="lazy">
        </div>
        <div class="lower">
          <div class="section">
            <button class="buttons" :onclick="() => infos()" title="Informations"><i
                class="fa-solid fa-circle-question"></i></button>
            <div v-if="isAdmin > 0"><button :onclick="() => editEbook(element.id)" title="Éditer"><i
                  class="fa-regular fa-pen-to-square"></i></button></div>
            <div v-if="isAdmin > 0"><button :onclick="() => deleteEbook(element.id)" title="Supprimer"><i
                  class="fa-regular fa-trash-can"></i></button></div>
          </div>
          <h3>{{ element.title }}</h3>
          <div class="cat">
            <span v-for="item in element.categories" v-bind:key="item.id">{{ item }}</span>
          </div>
          <p class="randomDuration">
            <span class="randomDuration" v-if="element.anneedesortie"><i class="fa-regular fa-calendar-days"></i> {{
      element.anneedesortie }}</span><span> - {{ element.author }}</span><span class="randomDuration"
              v-if="element.tome != 0"> - Tome {{
      element.tome }}</span>
          </p>
          <div class="players">
            <button :onclick="() => readEbook(element.id)" title="Lire ce ebook"><i class="fa-solid fa-book"></i>
              Lire</button>
          </div>
          <p v-html="element.description"></p>
        </div>
      </div>
    </div>
    <div class="order" id="top">
      <select name="triage" id="triage" v-once @change="getAllFromSort()">
        <option selected>Trier</option>
        <option value="title.asc">De A à Z</option>
        <option value="title.desc">De Z à A</option>
        <option value="id.desc">Les derniers ajouts</option>
        <option value="id.asc">Les premiers ajouts</option>
        <option value="anneedesortie.desc">Les plus récents</option>
        <option value="anneedesortie.asc">Les plus anciens</option>
      </select>
      <select name="category" id="categories" :onchange="() => getAllByCategory()">
        <option value="" selected>Catégorie</option>
        <option v-bind:key="element.id" v-for="element in categories" :value="element.name">{{ element.name }}</option>
      </select>
      <select name="type" id="types" :onchange="() => getAllByType()">
        <option value="" selected>Type</option>
        <option v-bind:key="element" v-for="element in types" :value="element">{{ element }}</option>
      </select>
      <div class="searchBarContainer">
        <div class="searchBar">
          <input type="search" id="searchBar" placeholder="Rechercher par titre ou auteur.." @change="getAllBySearch()">
          <svg :onclick="() => search()" alt="Icone de recherche" xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-search" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
            <path d="M21 21l-6 -6" />
          </svg>
        </div>
      </div>
    </div>
    <div class="noResult" v-if="ebooks.length <= 0">
      <p id="loaderText">Aucun résultat.</p>
    </div>
    <div class="filmsSection" v-if="ebooks">
      <div class="card" v-for="item in ebooks" v-bind:key="item.id" :id="'card-' + item.id" data-aos="fade-up"
        data-aos-anchor-placement="center" data-aos-duration="2000" v-cloak>
        <img :src="item.coverURL" alt="Affiche du film" :onclick="() => seenEbook(item.id)">
        <div class="bigZone">
          <div class="pres">
            <h4 class="titles">{{ item.title }}</h4>
            <span class="categories" v-if="item.categories">{{ item.categories }}</span>
            <span class="categories" v-if="item.tome != 0">Tome {{ item.tome }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="loaderWrapper">
      <div class="loader" v-if="limit < ebooks.length">
        <button class="loaderBtn" :onclick="() => loadMore()">Charger d'avantage <i
            class="fa-solid fa-arrow-turn-down"></i></button>
      </div>
    </div>
    <FooterContainer />
  </div>
</template>

<script>
import AOS from 'aos';
// import the library
import { createToast } from 'mosha-vue-toastify';
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'
import NavBar from './NavBarContainer.vue'
import FooterContainer from './FooterContainer.vue';

export default {
  name: 'HomeContainer',
  components: {
    NavBar,
    FooterContainer,
  },
  data() {
    return {
      selectedEbook: undefined,
      ebooks: [],
      types: ['Autre', 'BD', 'Comics', 'Magazine', 'Manga', 'Nouvelle', 'Roman'],
      categories: undefined,
      collections: undefined,
      isAdmin: 0,
      limit: localStorage.getItem('limit'),
      link: "https://api.heavenflix.fr",
      codes: [],
      loadingTime: 3000,
      scrollDistance: 0,
      listOfUser: undefined,
    }
  },
  setup() {
    const toast = () => {
      createToast('Wow, easy')
    }
    return { toast }
  },
  methods: {
    getAllBySearch: async function getAllBySearch() {
      const value = document.getElementById('searchBar').value;
      if (!value) return this.getAll();
      this.ebooks = [];
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getEbooksBySearch/' + value, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.ebooks = [];
        data.forEach(element => {
          element.categories = JSON.parse(element.categories);
          if (element.categories[0] == element.categories[1]) element.categories = element.categories.splice(1, 1);
          element.files = JSON.parse(element.files);
          element.categories = element.categories.join(' & ');
          this.ebooks.push(element);
        });
        setTimeout(() => { this.darkModeVerification(); }, 200);
      })
    },
    getAllFromSort: async function getAllFromSort() {
      const sortInput = document.getElementById('triage').value;
      if (sortInput == 'Trier') return this.getAll();
      const [sortBy, sortOrder] = sortInput.split('.');
      this.ebooks = [];
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(`${this.link}/getEbooksSorted?sort=${sortBy}&order=${sortOrder}`, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.ebooks = [];
        data.forEach(element => {
          element.categories = JSON.parse(element.categories);
          if (element.categories[0] == element.categories[1]) element.categories = element.categories.splice(1, 1);
          element.files = JSON.parse(element.files);
          element.categories = element.categories.join(' & ');
          this.ebooks.push(element);
        });
        setTimeout(() => { this.darkModeVerification(); }, 200);
      })
    },
    getAllByCategory: async function getAllByCategory() {
      this.ebooks = [];
      const categoryInput = document.getElementById('categories').value;
      if (!categoryInput) return this.getAll();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getEbooksByCategory/' + categoryInput, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.ebooks = [];
        data.forEach(element => {
          element.categories = JSON.parse(element.categories);
          if (element.categories[0] == element.categories[1]) element.categories = element.categories.splice(1, 1);
          element.files = JSON.parse(element.files);
          element.categories = element.categories.join(' & ');
          this.ebooks.push(element);
        });
        setTimeout(() => { this.darkModeVerification(); }, 200);
      })
    },
    getEbooksCategories: async function getEbooksCategories() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getEbooksCategories', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.categories = data;
      })
    },
    getAllByType: async function getAllByType() {
      this.ebooks = [];
      const typeInput = document.getElementById('types').value;
      if (!typeInput) return this.getAll();
      if (typeInput == 'Type') return this.getAll();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getEbooksByType/' + typeInput, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.ebooks = [];
        data.forEach(element => {
          element.categories = JSON.parse(element.categories);
          if (element.categories[0] == element.categories[1]) element.categories = element.categories.splice(1, 1);
          element.files = JSON.parse(element.files);
          element.categories = element.categories.join(' & ');
          this.ebooks.push(element);
        });
        setTimeout(() => { this.darkModeVerification(); }, 200);
      })
    },
    getListOfUser: async function getListOfUser() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getListFromUserId/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.listOfUser = data;
      })
    },
    removeWatchLater(id, name) {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      fetch(this.link + '/deleteFromWatchlater/' + localStorage.getItem('userId') + '/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.getListOfUser();
        this.showNotificationValidation(name + ' a été retiré de votre liste');
      })
    },
    isInWatchLaterList(id) {
      let isFound = false;
      const self = this.listOfUser;
      self.forEach(element => {
        if (element.id == id) isFound = true;
      });
      return isFound;
    },
    showNotificationValidation(text) {
      createToast(text, {
        position: 'top-center',
        type: 'success',
        transition: 'slide',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: 3000,
        toastBackgroundColor: '#2D2E32',
      })
    },
    showNotificationWarning(text) {
      createToast(text, {
        position: 'top-center',
        type: 'warning',
        transition: 'slide',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: 3000,
        toastBackgroundColor: '#2D2E32',
      })
    },
    showNotificationInfo(text) {
      createToast(text, {
        position: 'top-center',
        type: 'info',
        transition: 'slide',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: -1,
        toastBackgroundColor: '#2D2E32',
      })
    },
    addWatchLater: async function addWathchLater(id, isMovie, name) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: localStorage.getItem('userId'),
          article_id: id,
          isMovie: isMovie,
        }),
      };
      await fetch(this.link + '/addToWatchlater', requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        }
        this.showNotificationValidation(name + ' a été ajouté à votre liste.');
        this.getListOfUser()
      });
    },
    search: async function search() {
      let input = document.getElementById('searchBar').value;
      if (input.length < 2 || !input) return this.showNotificationWarning('Veuillez entrer au moins 2 caractères.');
      this.films = [];
      this.getFromSearch(input);
    },
    resetLoading: async function resetLoading() {
      this.limit = localStorage.getItem('limit');
    },
    loadMore: async function loadMore() {
      this.limit = parseInt(this.limit) + parseInt(localStorage.getItem('limit'));
      setTimeout(() => { this.darkModeVerification(); }, 200);
    },
    closeSeenEbook: async function closeSeenEbook() {
      this.selectedEbook = null;
      this.recommendationFilms = undefined;
      this.recommendationFilms2 = undefined;
      this.recommendationFilmsCollection = undefined;
      this.recommendationFilmsCollection2 = undefined;
    },
    randomMovie: async function randomMovie() {
      this.resetLoading();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getMovieRandom', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.randomFilm = [];
        data.forEach(element => {
          element.cat = JSON.parse(element.cat);
          this.randomFilm.push(element);
        });
        this.randomFilm = data;
      })
      setTimeout(() => { this.darkModeVerification(); }, 200);
    },
    randomMovieMore: async function randomMovieMore() {
      this.resetLoading();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getMovieRandomMore', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        data.forEach(element => {
          element.cat = JSON.parse(element.cat);
          this.randomFilm.push(element);
        });
      })
      setTimeout(() => { this.darkModeVerification(); }, 200);
    },
    addToHistorical: async function addToHistorical(id, isMovie) {
      let movieVerification;
      if (isMovie) movieVerification = 1;
      const date = new Date();
      let time = date.toLocaleString('fr-FR');
      let newTime = JSON.stringify(time);
      newTime = newTime.replace(/\//g, '-');
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          movieID: id,
          time: newTime,
          userID: localStorage.getItem('userId'),
          isMovie: movieVerification,
        })
      };
      await fetch(this.link + '/addHistory', requestOptions).then((res) => res.json()).then(data => {
        if (data.error) return console.log(data.error);
      });
    },
    seenEbook: async function seenEbook(value) {
      this.selectedEbook = [];
      this.isInWatchLaterList(value)
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getEbookById/' + value, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        data.forEach(element => {
          element.categories = JSON.parse(element.categories);
          if (element.categories[0] == element.categories[1]) element.categories = element.categories.splice(1, 1);
          this.selectedEbook.push(element);
        });

      })
    },
    goTop: async function goTop() {
      let anchor = document.getElementById('top');
      let top = anchor.offsetTop;
      window.scrollTo(0, top - 100);
    },
    readEbook: async function readEbook(id) {
      //this.addToHistorical(id, true);
      localStorage.setItem('ebook', id);
      this.$router.push('/playerEbooks')
    },
    request: async function request(id) {
      localStorage.setItem('request', id);
      this.$router.push('/request');
    },
    deleteEbook: async function deleteEbook(value) {
      let text = "Etes vous sur de vouloir supprimer ce ebook ?";
      if (confirm(text) == false) return alert('Vous avez annulé la suppresion du film.');
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/deleteEbook/' + value, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        location.reload();
      })
    },
    editEbook: async function editEbook(value) {
      localStorage.setItem('edit', value);
      localStorage.setItem('editType', 'ebook');
      this.$router.push('/edit');
    },
    getAll: async function getAll() {
      this.resetLoader();
      setTimeout(() => this.checkerLoading(), this.loadingTime);
      this.ebooks = [];
      this.resetLoading();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      const ebooksPromise = await fetch(this.link + '/getEbooks', requestOptions).then((res) => res.json());
      const ebooks = await ebooksPromise;
      ebooks.forEach(element => {
        element.categories = JSON.parse(element.categories);
        if (element.categories[0] == element.categories[1]) element.categories = element.categories.splice(1, 1);
        element.files = JSON.parse(element.files);
        element.categories = element.categories.join(' & ');
        this.ebooks.push(element);
      });
      setTimeout(() => {
        this.darkModeVerification();
      }, 200);
    },
    admin: async function admin() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      };
      if (!localStorage.getItem('userId')) {
        this.showNotificationWarning('Vous devez être connecté pour accéder à cette page.');
        setTimeout(() => {
          this.disconnect();
        }, 3000);
      }
      await fetch(this.link + '/getAdmin/' + localStorage.getItem('userId'), requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then(data => {
          if (data.msg) return console.log(data.msg);
          this.isAdmin = data[0].isAdmin;
        })
    },
    infos: async function infos() {
      let string = "Tous les films sont en français (il se peut qu'un changement de langue soit nécessaire). La qualité est réglable directement sur le lecteur.\n\nAfin de profiter du PREMIUM sur le lecteur, entrez le compte suivant : \n\nNom d'utilisateur: Huskies\nMot de passe: forqueen\n\nEn cas d'erreur pour arriver sur la page du lecteur, pensez à vous mettre sur votre WIFI et changer l'adresse DNS de l'appareil en manuel pour 8.8.8.8 ou 1.1.1.1 et contourner les blocages de votre fournisseur d'accés.\n\nPour avoir la possibilité de caster les films, il est nécessaire d'être sur Google Chrome peu importe la plateforme.\n\nEn cas de problème, pensez à le signaler en utilisant le drapeau sur le film qui pose problème.";
      alert(string);
    },
    disconnect: async function disconnect() {
      localStorage.clear();
      this.$router.push('/login');
    },
    lightMode: async function lightMode() {
      var titles = document.getElementsByClassName('titles');
      var categories = document.getElementsByClassName('categories');
      var background = document.getElementsByClassName('homePage');
      var loaderBtn = document.getElementsByClassName('loaderBtn');
      var searchBar = document.getElementById('searchBar');
      var classSearchBar = document.getElementsByClassName('searchBar');
      for (var i = 0; i < titles.length; i++) {
        titles[i].style.color = "#15161b";
      }
      for (var o = 0; o < categories.length; o++) {
        categories[o].style.color = "#515151";
      }
      for (var u = 0; u < loaderBtn.length; u++) {
        loaderBtn[0].style.color = "#ff003c";
      }
      for (var y = 0; y < loaderBtn.length; y++) {
        classSearchBar[0].style.backgroundColor = "#E0E0E0";
      }
      searchBar.style.backgroundColor = "#E0E0E0";
      background[0].style.backgroundColor = "#F0F0F0";
    },
    darkModeVerification: async function darkModeVerification() {
      if (JSON.parse(localStorage.getItem('darkMode')) == false) return this.lightMode();
    },
    checkerLoading: async function checkerLoading() {
      let loader = document.getElementById('loader');
      let loaderText = document.getElementById('loaderText');
      if (!this.ebooks || this.ebooks && this.ebooks.length < 1) {
        loader.style.display = "none";
        loaderText.innerHTML = "Aucun résultat";
      }
    },
    resetLoader: async function resetLoader() {
      let loader = document.getElementById('loader');
      let loaderText = document.getElementById('loaderText');
      if (loader) loader.style.display = "block";
      if (loaderText) loaderText.innerHTML = "Chargement...";
    },
    displayNoResult: async function displayNoResult() {
      let loader = document.getElementById('loader');
      let loaderText = document.getElementById('loaderText');
      if (loader) loader.style.display = "none";
      if (loaderText) loaderText.innerHTML = "Aucun résultat";
    },
    updateScrollDistance() {
      // Mettez à jour scrollDistance avec la distance entre le haut de la page et la position actuelle de l'utilisateur
      this.scrollDistance = window.scrollY;
    },
  },
  created: function () {
    this.admin();
    this.randomMovie();
    this.getListOfUser();
    this.getEbooksCategories();
    AOS.init();
  },
  mounted: function () {
    // Ajoutez un écouteur d'événement de défilement pour mettre à jour scrollDistance
    window.addEventListener('scroll', this.updateScrollDistance);
    // eslint-disable-next-line
    AOS.init();
    this.getAll();
    setTimeout(() => this.checkerLoading(), this.loadingTime);
    localStorage.removeItem('player');
    localStorage.removeItem('edit');
    localStorage.removeItem('request');
    document.body.style.overflowY = "auto";
    setTimeout(() => { this.darkModeVerification() }, 200);
    var searchBar = document.getElementById('searchBar');
    searchBar.addEventListener('change', () => {
      if (searchBar.value) return this.getFromSearch(searchBar.value);
      this.getAll();
    });
    // eslint-disable-next-line
    const swiper = new Swiper(".mySwiper", {
      /*pagination: {
        el: ".swiper-pagination",
      },*/
      slidesPerView: "auto",
      speed: 700,
    });
    swiper.on('slideChange', function () {
      this.randomMovieMore();
    }.bind(this));

  },
  beforeUnmount() {
    // Supprimez l'écouteur d'événement lorsque le composant est détruit
    window.removeEventListener('scroll', this.updateScrollDistance);
  },
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$back-color: #15161b;
$dark-red: #E60036;
$soft-grey: #2D2E32;

@keyframes slide {
  from {
    opacity: 0;
    transform: translateZ(-30px);
  }

  to {
    opacity: 1;
    transform: translateZ(0px);
  }
}

@keyframes infiniteSlide {
  0% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-70%);
    rotate: -5deg;
  }

  50% {
    transform: translateX(0%);
    rotate: 0deg;
  }

  75% {
    transform: translateX(70%);
    rotate: 5deg;
  }

  100% {
    transform: translateX(0%);
    rotate: 0deg;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes slideHorizontal {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.homePage {
  position: relative;

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: $dark-red rgba(255, 255, 255, 0.178);
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.178);
  }

  *::-webkit-scrollbar-thumb {
    background-color: $dark-red;
    border-radius: 5px;
    border: 0px none #ffffff;
  }

  width: 100%;
  min-height: 100vh;
  background: $dark-color;

  .noResult {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 60px;
      height: 60px;
    }

    p {
      font-size: 20px;
      color: rgba(255, 255, 255, 0.5);
      font-family: 'Lato';
      font-display: swap;
    }
  }

  /*
  .loaderWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 0px;

      button {
        box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 15px;
        border-radius: 50px;
        border: none;
        background: rgba(0, 0, 0, 0.1);
        color: white;
        font-size: 16px;

        &:hover {
          cursor: pointer;
        }

        i {
          color: $dark-red;
        }
      }
    }
  }*/

  /*.noFilmContainer {
    width: 100%;
    padding: 60px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 20px;

    p {
      max-width: 50%;
      color: white;
      font-family: 'Montserrat';
    }
  }*/

  /*.random {
    width: 100%;
    height: 80vh;
    overflow: none;
    position: relative;

    .randomContainer {
      height: 100%;
      width: 100%;
      position: relative;

      img {
        &:first-child {
          width: 100%;
          height: 100%;
          filter: brightness(30%);
          z-index: 0;
          //animation: appear 2s;
          object-fit: cover;
          object-position: top;
        }
      }

      .poster {
        position: absolute;
        bottom: 10px;
        left: 5%;
        z-index: 1;
        color: $light-color;
        display: flex;
        flex-direction: column;
        gap: 5px;

        img {
          width: 230px;
          height: 330px;
          object-fit: cover;
          filter: blur(0px) brightness(100%);
          border-radius: 10px;
          box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.7);
        }

        h4 {
          font-family: 'Lato';
          font-display: swap;
          font-weight: bold;
          font-size: 20px;
          padding: 5px;
        }

        .randomCategory {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;

          span {
            font-family: 'Montserrat';
            font-display: swap;
            font-size: 16px;
            width: fit-content;
            background: $dark-red;
            padding: 8px 16px;
            border-radius: 50px;
          }
        }

        .randomDuration {
          font-family: 'Montserrat';
          font-display: swap;
          font-weight: bold;
          font-size: 16px;
          padding: 5px;
        }

        .randomDescription {
          font-family: 'Montserrat';
          font-display: swap;
          font-size: 16px;
          overflow-y: auto;
          max-height: 100px;
          width: 90%;
          padding-right: 20px;
          background: rgba(255, 255, 255, 0.03);
          backdrop-filter: blur(2px);
          border-radius: 10px;
          padding: 5px;
          -webkit-box-shadow: 0px 0px 36px 5px rgba(255, 255, 255, 0.03);
          box-shadow: 0px 0px 36px 5px rgba(255, 255, 255, 0.03);
        }

        .posterInteraction {
          padding: 5px 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;

          .buttons {
            border: none;
            padding: 5px;
            font-size: 22px;
            color: $light-color;
            background: transparent;
            transition-duration: 0.5s;
            border-bottom: 2px solid transparent;

            &:hover {
              cursor: pointer;
              color: $dark-red;
              border-bottom: 2px solid $dark-red;
              transition-duration: 0.5s;
            }
          }

          .player {
            width: 225px;
            color: $dark-red;
            background: white;
            backdrop-filter: blur(2px);
            padding: 10px 5px;
            border-radius: 10px;
          }
        }
      }
    }
  }*/

  .searchBarContainer {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0 20px 0;

    .searchBar {
      width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $light-color;
      gap: 10px;
      padding: 5px 10px;
      border-radius: 20px;

      svg {

        &:hover {
          cursor: pointer;
        }
      }

      input {
        width: 100%;
        outline: none;
        padding: 5px 10px;
        background: transparent;
        border: none;
        border-right: 1px solid $dark-color;
      }
    }
  }

  .search {
    position: relative;
    width: 100%;
    display: flex;
    padding: 10px 0;
    background: #0d0e12;
    justify-content: flex-end;
    align-items: center;

    .adminSection {
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 15px;
      font-family: 'Lato';
      font-display: swap;
      margin-right: 3vw;

      .opsBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        padding: 5px 10px;
        background: transparent;
        color: $dark-red;
        font-weight: bold;
        border-radius: 5px;
        font-size: 14px;
        transition-duration: 0.5s;

        i {
          font-size: 25px;
        }

        &:hover {
          cursor: pointer;
          color: white;
          transition-duration: 0.5s;
        }
      }

      .ops {
        position: relative;
        display: inline-block;

        &:hover .dropdown {
          display: block;
        }

        .dropdown {
          display: none;
          position: absolute;
          background-color: #f1f1f1;
          min-width: 160px;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          z-index: 1;
          top: 100%;
          right: 5%;
          animation: appear 1s forwards;

          span {
            color: black;
            text-decoration: none;
            display: block;
            padding: 5px;

            a {
              padding: 5px;
              font-size: 16px;
              text-decoration: none;
              color: black;
              height: 100%;
            }

            button {
              border: none;
              background: transparent;
              color: $dark-red;
              padding: 5px;
              height: 100%;
              font-size: 16px;

              &:hover {
                cursor: pointer;
              }
            }

            &:hover {
              background-color: #ddd;
            }
          }
        }
      }

      .home {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        padding: 5px 10px;
        background: transparent;
        font-weight: bold;
        border-radius: 5px;
        position: relative;

        .total {
          position: absolute;
          top: -6px;
          right: -5px;
          background: $dark-red;
          padding: 2px;
          border-radius: 5px;
          font-size: 12px;
          z-index: -1;
        }

        a {
          color: white;
          text-decoration: none;
          font-size: 14px;
          z-index: 1;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .displayer {
    width: 100%;
    height: 100vh;
    background: rgba(20, 22, 28, 0.95);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: none;

    .displayerContainer {
      width: 40%;
      height: 100%;
      background: $back-color;
      color: white;
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;

      h3 {
        margin: 10px 0;
      }

      span {
        font-family: 'Montserrat';
        font-display: swap;
      }

      p {
        overflow-y: auto;
        margin-top: 10px;
        font-family: 'Montserrat';
        font-display: swap;

        &:first-child {
          color: white;
        }

        &:last-child {
          margin-top: 20px;
          width: 95%;
          height: fit-content;
        }

        color: rgba(255, 255, 255, 0.61);
      }

      .cat {
        width: fit-content;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        gap: 10px;

        span {
          width: fit-content;
          height: auto;
          background: $dark-red;
          font-size: 15px;
          padding: 10px 15px;
          border-radius: 50px;
          font-weight: bold;
          color: $light-color;
          font-family: 'Lato';
          font-display: swap;
        }
      }

      .exit {
        position: absolute;
        top: 10px;
        right: 20px;
        z-index: 3;
        background-color: transparent;

        button {
          border: none;
          background: transparent;
          color: $light-color;
          font-size: 30px;

          &:hover {
            cursor: pointer;
            transform-origin: center center;
            animation: spin 4s forwards;
          }

          i {
            color: white;
            filter: brightness(200%);
            filter: drop-shadow(0px 0px 0 black) drop-shadow(0px 0px 0 black);
          }
        }
      }

      .upper {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        gap: 10px;

        img {
          z-index: 1;
          width: 100%;
          height: 500px;
          object-fit: cover;
          box-shadow: 0px 0px 25px -1px rgba(0, 0, 0, 0.7);
          filter: brightness(0.6);
        }
      }

      h3 {
        font-family: 'Lato';
        font-display: swap;
        font-size: 24px;
      }

      .lower {
        width: 95%;
        padding: 15px;

        .section {
          width: auto;
          display: flex;
          justify-content: end;
          align-items: center;
          margin-right: 20px;

          button {
            margin: 0 10px;
            border: none;
            background: transparent;
            color: $light-color;
            font-size: 32px;
            transition-duration: 1s;
            filter: drop-shadow(0px 0px 0 black) drop-shadow(0px 0px 0 black);

            &:hover {
              cursor: pointer;
              transition-duration: 1s;
              color: $dark-red;
              filter: drop-shadow(0px 0px 0 transparent) drop-shadow(0px 0px 0 transparent);
            }
          }
        }

        .players {
          margin-top: 10px;
          display: flex;
          gap: 15px;

          button {
            padding: 8px 0;
            width: 140px;
            font-family: 'Montserrat';
            font-display: swap;
            font-size: 18px;
            border: none;
            font-weight: bold;
            color: $light-color;
            background: transparent;
            border-bottom: 1px solid $dark-red;
            border-radius: 0px;
            transition-duration: 0.4s;

            i {
              margin-right: 3px;
              font-size: 18px;
            }

            &:hover {
              color: $back-color;
              background: $light-color;
              border-bottom: 1px solid $dark-red;
              border-radius: 10px;
              transition-duration: 0.4s;
              cursor: pointer;
            }
          }
        }

        .suggestions {
          width: 100%;
          height: auto;
          margin: auto;
          padding: 40px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          h3 {
            padding-bottom: 20px;
            margin-left: 30px;
            font-size: 20px;
            font-family: 'Lato';
            font-display: swap;
            font-weight: 0;
          }

          .suggestionsContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 40px;

            .suggestionsCard {
              width: 260px;
              height: auto;

              img {
                width: 100%;
                height: 350px;
                object-fit: cover;
                z-index: 1;
                box-shadow: 0px 0px 25px -1px rgba(0, 0, 0, 0.7);
                border-radius: 10px;
                transition-duration: 0.5s;

                &:hover {
                  transition-duration: 0.5s;
                  filter: brightness(0.4);
                  cursor: pointer;
                }
              }

              .suggestName {
                display: block;
                margin-top: 5px;
                font-family: 'Montserrat';
                font-weight: 600;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }

  .goTop {
    position: fixed;
    right: 20px;
    bottom: 30px;
    width: auto;
    aspect-ratio: 1/1;
    z-index: 10;

    button {
      color: white;
      background: $dark-red;
      padding: 5px;
      border: none;
      border-radius: 5px;
      display: block;
      animation: appear 1s forwards;

      i {
        font-size: 25px;
      }

      &:hover {
        cursor: pointer;

        i {
          animation: bounce 1s infinite;
        }
      }
    }
  }

  .order {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    animation: appear 2s;
    font-family: 'Lato';
    font-display: swap;

    select {
      width: 15%;
      border: none;
      background-color: $dark-red;
      border-radius: 5px;
      color: white;
      padding: 10px;
      outline: none;
      text-indent: 1px;
      text-overflow: '';
      margin: 10px;
      -webkit-appearance: none;

      &:hover {
        cursor: pointer;
      }

      option {
        background: white;
        color: $dark-color;
        padding: 5px 10px;
      }
    }

    button {
      margin: 10px;
      padding: 5px 10px;
      border: none;
      animation: appear 2s;
      font-family: 'Lato';
      font-display: swap;
      font-size: 16px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3px;
      background: $dark-red;
      color: white;
      position: relative;
      transition-duration: 1s;

      img {
        width: 20px;
        height: 20px;
      }

      &:hover {
        cursor: pointer;
        transform: scale(1.05);
        transition-duration: 1s;
      }
    }
  }

  .filmsSection {
    padding: 20px 0;
    width: 90%;
    min-height: 70vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;

    .card {
      background: transparent;
      width: 275px;
      margin: 10px 0;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      font-family: 'Lato';
      font-display: swap;
      border-radius: 15px;
      color: white;
      margin: 20px;

      img {
        width: 100%;
        height: 410px;
        object-fit: cover;
        image-rendering: auto;
        border-radius: 15px;
        box-shadow: 4px 4px 17px 5px rgba(0, 0, 0, 0.72);
        cursor: pointer;
        transition-duration: 0.3s;

        &:hover {
          transition-duration: 0.3s;
          filter: brightness(0.5);
        }
      }

      .bigZone {
        margin-top: 10px;
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 5px;
        position: relative;

        .pres {
          padding: 3px;
          width: 95%;
          height: fit-content;
          display: flex;
          flex-direction: column;

          h4 {
            width: 95%;
            font-size: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          span {
            font-family: 'Montserrat';
            font-display: swap;
            font-size: 15px;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: rgba(255, 255, 255, 0.61);
          }
        }
      }

      .interactions {
        width: fit-content;
        display: flex;
        justify-content: space-between;

        button {
          width: auto;
          display: flex;
          align-items: center;
          background: transparent;
          border: none;
          animation: reverseViewerRotation 1s forwards;

          &:hover {
            cursor: pointer;
            animation: viewerRotation 1s forwards;
          }

          i {
            color: $dark-red;
            font-size: 25px;
          }
        }

        .options {
          margin: 0 10px;
          font-size: 21px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 7px;

          .heart {
            width: fit-content;
            position: relative;
            transition-duration: 1s;
            color: $dark-red;

            &:hover {
              cursor: pointer;
              transition-duration: 1s;

              i {
                color: white;
                transition-duration: 1s;
              }
            }
          }
        }

        .resume {
          width: 80%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .homePage {
    .searchBarContainer {
      width: 90%;
      order: -1;

      .searchBar {
        width: 100%;
      }
    }

    .random {
      width: 100%;
      height: 80vh;
    }

    .displayer {
      .displayerContainer {
        width: 90%;
      }
    }

    .order {
      width: 90%;
      align-items: flex-start;
      flex-wrap: wrap;

      select {
        width: 25%;
      }
    }

    .search {
      .adminSection {
        width: fit-content;
      }
    }

    .filmsSection {
      width: 95%;

      .card {
        min-width: 25%;
        margin: 20px;

        img {
          width: 100%;
          height: 410px;
          object-fit: cover;
          image-rendering: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .homePage {
    .searchBarContainer {
      width: 90%;
      order: -1;

      .searchBar {
        width: 100%;
      }
    }

    .random {
      width: 100%;
      height: 100vh;
    }

    .displayer {
      .displayerContainer {
        width: 90%;
      }
    }

    .order {
      width: 90%;
      align-items: flex-start;
      flex-wrap: wrap;

      select {
        width: 25%;
      }
    }

    .search {
      .adminSection {
        width: fit-content;
      }
    }

    .filmsSection {
      width: 100%;

      .card {
        min-width: 290px;
        margin: 20px;

        img {
          width: 100%;
          height: 430px;
          object-fit: cover;
          image-rendering: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .homePage {
    .noFilmContainer {

      p {
        max-width: 80%;
        color: white;
        font-family: 'Montserrat';
      }
    }

    .random {
      .randomContainer {
        img {
          &:first-child {
            filter: brightness(20%);
          }
        }

        .poster {
          img {
            filter: brightness(100%);
          }
        }
      }
    }

    .displayer {
      width: 100%;
      align-items: flex-start;
      background: #15161bc5;

      .displayerContainer {
        width: 100%;
        height: 100vh;
        border-radius: 0;
        overflow-y: auto;

        .upper {
          width: 100%;
          min-height: 450px;
          height: fit-content;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          position: relative;

          img {
            border-radius: 0;
            position: relative;

            &:first-child {
              display: block;
              filter: blur(0px);
            }

            &:nth-child(2) {
              z-index: 1;
              position: absolute;
              bottom: 5%;
              left: 5%;
              width: 50%;
              height: auto;
              object-fit: cover;
              object-position: center;
              margin: 0;
              box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.7);
              animation: appear 2s;
              border-radius: 15px;
            }
          }

          .section {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 1;
            transform: translateY(50px);
            margin-right: 5px;

            button {
              width: fit-content;
              margin: 0 6px;

              i {
                font-size: 30px;
              }
            }
          }
        }

        .lower {
          margin-top: 50px;
          height: fit-content;
          padding: 0;
          margin-left: 15px;
          padding-bottom: 30px;

          p {
            overflow-y: auto;
            margin-top: 10px;
            font-family: 'Montserrat';
            font-display: swap;

            &:first-child {
              color: white;
              height: fit-content;
            }

            &:last-child {
              height: 90%;
              max-height: 90%;
            }

            color: rgba(255, 255, 255, 0.61);

            i {
              margin-right: 3px;
            }
          }

          .suggestions {
            width: 100%;
            height: auto;
            margin: auto;
            padding: 40px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            h3 {
              padding-bottom: 20px;
              margin-left: 30px;
              font-size: 20px;
              font-family: 'Lato';
              font-display: swap;
              font-weight: 0;
            }

            .suggestionsContainer {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-wrap: wrap;
              gap: 40px;

              .suggestionsCard {
                width: 90%;
                height: auto;

                img {
                  width: 90%;
                  height: 90%;
                  object-fit: cover;
                  z-index: 1;
                  box-shadow: 0px 0px 25px -1px rgba(0, 0, 0, 0.7);
                  border-radius: 10px;
                  transition-duration: 0.5s;

                  &:hover {
                    transition-duration: 0.5s;
                    filter: brightness(0.5);
                    cursor: pointer;
                  }
                }

                span {
                  width: 80%;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  font-family: 'Montserrat';
                  font-display: swap;
                }
              }
            }
          }
        }
      }

      .order {
        align-items: flex-start;
        flex-wrap: wrap;

        select {
          width: 90%;
        }
      }

      .searchBarContainer {
        order: -1;
        width: 100%;

        .searchBar {
          width: 100%;
        }
      }

      .search {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .searchBarContainer {
          width: 85%;
        }

        .adminSection {
          width: fit-content;
        }
      }

      .filmsSection {
        width: 100%;

        .card {
          width: 80%;
          max-height: fit-content;
          margin: 20px;

          img {
            height: auto;
          }
        }
      }
    }
  }
}
</style>
