<template>
  <div class="homePage" id="home">
    <div class="random" v-if="randomFilm && randomFilm.length == 1">
      <div class="randomContainer" v-for="element in randomFilm" v-bind:key="element.id">
        <img :src="element.backgroundURL">
        <div class="poster" data-aos="fade-right" data-aos-anchor-placement="center-bottom" data-aos-duration="500">
          <img :src="element.posterURL" @click="seenSerie(element.id)" loading="lazy" alt="Poster de la série"
            @error="this.src = 'https\://api.heavenflix.fr/images/error.webp'">

        </div>
      </div>
    </div>
    <div class="noFilmContainer" v-else>
      <p>Chargement..</p>
    </div>
    <NavBar />
    <div class="goTop" v-show="scrollDistance > 775">
      <button :onclick="() => goTop()" title="Remonter" id="topButton"><svg xmlns="http://www.w3.org/2000/svg"
          width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevrons-up">
          <polyline points="17 11 12 6 7 11"></polyline>
          <polyline points="17 18 12 13 7 18"></polyline>
        </svg></button>
    </div>
    <div class="displayer" id="displayer">
      <div class="displayerContainer" v-for="element in selectedSerie" v-bind:key="element.id" data-aos="fade-in"
        data-aos-duration="2000">
        <div class="exit">
          <button :onclick="() => closeSeenSerie()" title="Fermer l'aperçu"><i class="fa-solid fa-xmark"></i></button>
        </div>
        <div class="upper">
          <img class="back" :src="element.backgroundURL">
          <img :src="element.posterURL">
          <div class="section">
            <div v-if="isAdmin > 0"><button :onclick="() => disableSerie(element.id)" title="Désactiver"><i class="fa-solid
                fa-square-xmark"></i></button></div>
            <div v-if="isAdmin > 0"><button :onclick="() => editSerie(element.id)" title="Éditer"><i
                  class="fa-regular fa-pen-to-square"></i></button></div>
            <div v-if="isAdmin > 0"><button :onclick="() => deleteShowTV(element.id)" title="Supprimer"><i
                  class="fa-regular fa-trash-can"></i></button></div>
          </div>
        </div>
        <div class="lower">
          <h3>{{ element.name }}</h3>
          <div class="cat">
            <p>{{ element.categories }}</p>
          </div>
          <p>{{ element.description }}</p>
          <!--<div class="loginContainer">
            <div class="login">
              <p>Pour vous connecter sur le site de l'hébergeur, utilisez les identifiants suivants :</p>
              <ul>
                <li>Nom d'utilisateur : <b>{{ loginUsername }}</b></li>
                <li>Mot de passe : <b>{{ loginPassword }}</b></li>
              </ul>
            </div>
          </div>-->
          <div class="lastEpisode" v-if="lastEpisodeMessage && !element.link">
            <p>{{ lastEpisodeMessage }}</p>
          </div>
          <select class="seasonSelector" id="seasonSelector" v-model="selectedSeason" @change="selectSeason()"
            v-if="selectedSeasons && selectedSeasons.length > 0">
            <option v-for="season in selectedSeasons" :key="season.id" :value="season.season">
              Saison {{ season.season }} ({{ season.year }})
            </option>
          </select>
          <div class="showTVPlayer" v-else-if="element.link != undefined">
            <button :onclick="() => playSerie(element.link, 1, element.id)" title="Voir la série"><i
                class="fa-solid fa-circle-play"></i> Voir l'émission</button>
          </div>
          <div class="lastEpisode" v-else>
            <p>Aucune saison trouvée, revenez ultérieurement. <span v-if="isAdmin > 0"><router-link
                  to='/addShowTVSeason'><i class="fa-solid fa-plus"></i></router-link></span></p>
          </div>
          <div class="episodes" v-if="selectedEpisodes && selectedEpisodes.length">
            <div class="episode" v-for="(episode, index) in selectedEpisodes" v-bind:key="episode.id">
              <span v-if="episode.includes('darkibox')">
                <button :onclick="() => playSerie(episode, index + 1, selectedSerie[0].id)" title="Voir l'épisode"><i
                    class="fa-solid fa-circle-play"></i> Episode {{ index + 1 }}</button>
              </span>
              <span v-else>
                <button title="Voir l'épisode" disabled><i class="fa-solid fa-circle-play"></i> Episode
                  indisponible</button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NotificationsContainer />
    <div class="order" id="top">
      <select name="triage" id="triage" @change="sortSeries()">
        <option selected>Trier</option>
        <option value="name.asc">De A à Z</option>
        <option value="name.desc">De Z à A</option>
        <option value="id.desc">Les derniers ajouts</option>
        <option value="id.asc">Les premiers ajouts</option>
        <option value="annee.desc">Les plus récentes</option>
        <option value="annee.asc">Les plus anciennes</option>
      </select>
      <select name="category" id="categories" :onchange="() => getAllByCategory()">
        <option value="" selected>Catégorie</option>
        <option v-bind:key="element.id" v-for="element in categories" :value="element.name">{{ element.name }}</option>
      </select>
      <div class="searchBarContainer">
        <div class="searchBar">
          <input type="search" id="searchBar" placeholder="Rechercher..">
          <svg :onclick="() => search()" alt="Icone de recherche" xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-search" width="30" height="30" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
            <path d="M21 21l-6 -6" />
          </svg>
        </div>
      </div>
    </div>
    <div class="noResult" v-if="!series.length">
      <p>Aucun résultat</p>
    </div>
    <!--<div class="filmsSection" v-if="series && series.length > 0">
      <div class="card" v-for="item in series.slice(0, limit)" v-bind:key="item.id" :id="'card-' + item.id"
        data-aos="fade-up" data-aos-anchor-placement="center" data-aos-duration="2000">
        <img :src="item.posterURL" :onclick="() => seenSerie(item.id)">
        <div class="bigZone">
          <div class="pres">
            <h4 class="titles">{{ item.name }}</h4>
            <a class="categories" v-if="item.categories">{{ item.categories }}</a>
            <a v-if="item.annedesortie">{{ item.annedesortie }}</a>
          </div>
        </div>
      </div>
    </div>-->
    {{ wantWideCards }}
    <div class="filmsSection" v-if="series && series.length && wantWideCards == true">
      <CardMovieContainer v-for="item in series.slice(0, limit)" v-bind:key="item.id" :id="'card-' + item.id"
        data-aos="fade-up" data-aos-anchor-placement="center" data-aos-duration="2000" :movie="item" :type="'showTV'"
        @click="seenSerie(item.id)" />
    </div>
    <div class="filmsSection" v-else-if="series && series.length && wantWideCards == false">
      <CardMoviePosterContainer v-for="item in series.slice(0, limit)" v-bind:key="item.id" :movie="item"
        :type="'showTV'" data-aos="fade-up" data-aos-anchor-placement="center" data-aos-duration="2000" v-cloak
        @click="seenSerie(item.id)" />
    </div>
    <div class="loaderWrapper">
      <div class="loader" v-if="limit < series.length">
        <button class="loaderBtn" :onclick="() => loadMore()">Charger d'avantage <i
            class="fa-solid fa-arrow-turn-down"></i></button>
      </div>
    </div>
    <FooterContainer />
  </div>
</template>

<script>
import AOS from 'aos';
import NavBar from './NavBarContainer.vue';
import CardMovieContainer from './CardMovieContainer.vue';
import CardMoviePosterContainer from './CardMoviePosterContainer.vue';
import FooterContainer from './FooterContainer.vue';
import NotificationsContainer from './NotificationsContainer.vue';

export default {
  name: 'SeriesContainer',
  data() {
    return {
      selectedSerie: undefined,
      selectedSeasons: [],
      selectedEpisodes: [],
      selectedSeason: 1,
      series: [],
      categories: undefined,
      randomFilm: undefined,
      isAdmin: 0,
      limit: localStorage.getItem('limit'),
      link: "https://api.heavenflix.fr",
      codes: [],
      scrollDistance: 0,
      loginUsername: 'ValhallaBarbare',
      loginPassword: 'forqueen',
      lastEpisodeMessage: '',
      wantWideCards: JSON.parse(localStorage.getItem('wantWideCards')) == true ? true : false,
    }
  },
  components: {
    NavBar,
    CardMovieContainer,
    CardMoviePosterContainer,
    FooterContainer,
    NotificationsContainer,
  },
  methods: {
    async disableSerie(id) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/disableShowTV/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        location.reload();
      })
    },
    async sortSeries() {
      let select = document.getElementById('triage');
      let value = select.value;
      if (!value) return this.getAll();
      if (value == 'random') return this.getAll();
      const [sortBy, sortOrder] = value.split('.');
      this.series = [];
      this.resetLoading();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      const seriePromise = await fetch(this.link + '/getShowTVSorted/' + sortBy + '/' + sortOrder, requestOptions).then((res) => res.json());
      const series = await seriePromise;
      series.forEach(element => {
        element.categories = JSON.parse(element.categories);
        if (element.categories[0] == element.categories[1]) element.categories = element.categories.splice(1, 1);
        element.categories = element.categories.join(' & ');
        this.series.push(element);
      });
      setTimeout(() => {
        this.darkModeVerification();
      }, 200);
    },
    updateScrollDistance() {
      // Mettez à jour scrollDistance avec la distance entre le haut de la page et la position actuelle de l'utilisateur
      this.scrollDistance = window.scrollY;
    },
    defineSeasonSelector() {
      const seasonSelector = document.getElementById('seasonSelector');
      seasonSelector.selectedIndex = 0;
    },
    selectSeason: function () {

      /*setTimeout(() => {
        this.defineSeasonSelector();
      }, 200);*/

      // Vérifier si la saison sélectionnée est valide
      const seasonExists = this.selectedSeasons.some(season => season.season === this.selectedSeason);

      // Si la saison n'existe pas, réinitialiser à la première saison
      if (!seasonExists) {
        this.selectedSeason = this.selectSeasons && this.selectedSeasons[0].season ? this.selectedSeasons[0].season : 0;
      }

      // Triez le tableau selectedSeasons par numéro de saison
      this.selectedSeasons.sort((a, b) => a.season - b.season);
      this.selectedEpisodes = [];
      this.selectedEpisodes = this.selectedSeasons
        .find(season => season.season === this.selectedSeason)
        .episodesLinks;
    },
    search: async function search() {
      let input = document.getElementById('searchBar').value;
      this.getFromSearch(input);
    },
    resetLoading: async function resetLoading() {
      this.limit = localStorage.getItem('limit');
    },
    loadMore: async function loadMore() {
      this.limit = parseInt(this.limit) + parseInt(localStorage.getItem('limit'));
      setTimeout(() => { this.darkModeVerification(); }, 200);
    },
    closeSeenSerie: async function closeSeenSerie() {
      let display = document.getElementById('displayer');
      display.style.display = "none";
      document.body.style.overflow = 'auto';
      this.selectedSeries = undefined;
      this.selectedSeasons = [];
      this.selectedEpisodes = [];
      this.selectedSeason = 1;
    },
    randomSerie: async function randomSerie() {
      this.resetLoading();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getShowTVRandom', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.randomFilm = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].categories) {
            let newCat = JSON.parse(data[i].categories);
            if (data[i].categories[0] == data[i].categories[1]) data[i].categories = data[i].categories.splice(1, 1);
            data[i].categories = newCat.join(' & ');
            this.randomFilm.push(data[i]);
          }
          if (!data[i].categories) return this.randomFilm = data;
        }
      })
      setTimeout(() => { this.darkModeVerification(); }, 200);
    },
    seenSerie: async function seenSerie(value) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getShowTV/' + value, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        let display = document.getElementById('displayer');
        display.style.display = "flex";
        document.body.style.overflow = 'hidden';
        this.selectedSerie = [];
        data[0].categories = JSON.parse(data[0].categories);
        if (data[0].categories[0] == data[0].categories[1]) data[0].categories = data[0].categories.splice(1, 1);
        data[0].categories = data[0].categories.join(' & ');
        this.selectedSerie.push(data[0]);
        this.getSeasonsFromSerieId(value);
        this.getLastHistoryFromSerie(value);
      })
    },
    getLastHistoryFromSerie: async function getLastHistoryFromSerie(serie_id) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getLastHistoryFromSerie/' + localStorage.getItem('userId') + '/' + serie_id, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        if (data && data.length > 0) {
          this.lastEpisodeMessage = 'Dernier épisode regardé : Saison ' + data[0].season + ' episode ' + data[0].episode;
        } else {
          this.lastEpisodeMessage = '';
        }
      })
    },
    getSeasonsFromSerieId: async function getSeasonsFromSerieId(id) {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/allShowTVSeasonsFromShowTVId/' + id, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.selectedSeasons = [];
        for (let i = 0; i < data.length; i++) {
          if (data[i].episodesLinks) {
            data[i].episodesLinks = JSON.parse(data[i].episodesLinks);
            this.selectedSeasons.push(data[i]);
          }
        }
        setTimeout(() => {
          this.selectSeason();
        }, 200);
      })
    },
    goTop: async function goTop() {
      let anchor = document.getElementById('top');
      let top = anchor.offsetTop;
      window.scrollTo(0, top - 100);
    },
    playSerie: async function playSerie(link, episode, id) {
      //this.addToHistorical(id, episode, false);
      window.open(link, '_blank');
      this.seenSerie(id);
    },
    addToHistorical: async function addToHistorical(id, episode, isMovie) {
      let movieVerification = 0;
      if (isMovie) movieVerification = 1;
      const date = new Date();
      let time = date.toLocaleString('fr-FR');
      let newTime = JSON.stringify(time);
      newTime = newTime.replace(/\//g, '-');
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          movieID: id,
          time: newTime,
          userID: localStorage.getItem('userId'),
          isMovie: movieVerification,
          episode: episode,
          season: this.selectedSeason,
        })
      };
      await fetch(this.link + '/addHistorySerie', requestOptions).then((res) => res.json()).then(data => {
        if (data.error) return console.log(data.error);
      });
    },
    request: async function request(id) {
      localStorage.setItem('request', id);
      this.$router.push('/request');
    },
    deleteShowTV: async function deleteShowTV(value) {
      let text = "Etes vous sur de vouloir supprimer ce show TV ? Cette action est irréversible, les saisons associées seront elles-aussi supprimées.";
      if (confirm(text) == false) return alert('Vous avez annulé la suppresion du film.');
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/deleteShowTV/' + value, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        location.reload();
      })
    },
    editSerie: async function editSerie(value) {
      localStorage.setItem('edit', value);
      localStorage.setItem('editType', 'showTV');
      this.$router.push('/edit');
    },
    getAll: async function getAll() {
      this.series = [];
      this.resetLoading();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      try {
        const seriePromise = await fetch(this.link + '/allShowTV', requestOptions).then((res) => res.json());
        const series = await seriePromise;
        series.forEach(element => {
          element.categories = JSON.parse(element.categories);
          if (element.categories[0] == element.categories[1]) element.categories = element.categories.splice(1, 1);
          element.categories = element.categories.join(' & ');
          this.series.push(element);
        });
      } catch (error) {
        console.error(error);
      }
      setTimeout(() => { this.darkModeVerification(); }, 200);
    },
    getAllByCategory: async function getAllByCategory() {
      this.series = [];
      this.resetLoading();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      let category = document.getElementById("categories").value;
      if (!category) return this.getAll();
      const seriePromise = await fetch(
        this.link + "/allShowTVFromCategory/" + category,
        requestOptions
      ).then((res) => res.json());
      const series = await seriePromise;
      series.forEach(element => {
        element.categories = JSON.parse(element.categories);
        if (element.categories[0] == element.categories[1]) element.categories = element.categories.splice(1, 1);
        element.categories = element.categories.join(' & ');
        this.series.push(element);
      });
      setTimeout(() => {
        this.darkModeVerification();
      }, 200);
    },
    getCategories: async function getCategories() {
      this.resetLoading();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getShowTVCategories', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.categories = data;
      })
    },
    admin: async function admin() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      };
      await fetch(this.link + '/getAdmin/' + localStorage.getItem('userId'), requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then(data => {
          if (data.msg) return console.log(data.msg);
          this.isAdmin = data[0].isAdmin;
        })
    },
    infos: async function infos() {
      let string = "Tous les films sont en français (il se peut qu'un changement de langue soit nécessaire). La qualité est réglable directement sur le lecteur.\n\nAfin de profiter du PREMIUM sur le lecteur, entrez le compte suivant : \n\nNom d'utilisateur: ValhallaBarbare\nMot de passe: forqueen\n\nPour avoir la possibilité de caster les films, il est nécessaire d'être sur Google Chrome peu importe la plateforme.\n\nEn cas de problème, pensez à le signaler en utilisant le drapeau sur le film qui pose problème.";
      alert(string);
    },
    disconnect: async function disconnect() {
      localStorage.clear();
      this.$router.push('/login');
    },
    getFromSearch: async function getFromSearch(name) {
      this.series = [];
      this.resetLoading();
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      const seriePromise = await fetch(this.link + '/getShowTVSearch/' + name, requestOptions).then((res) => res.json());
      const series = await seriePromise;
      series.forEach(element => {
        element.categories = JSON.parse(element.categories);
        if (element.categories[0] == element.categories[1]) element.categories = element.categories.splice(1, 1);
        element.categories = element.categories.join(' & ');
        this.series.push(element);
      });
      setTimeout(() => {
        this.darkModeVerification();
      }, 200);
    },
    lightMode: async function lightMode() {
      var titles = document.getElementsByClassName('titles');
      var categories = document.getElementsByClassName('categories');
      var background = document.getElementsByClassName('homePage');
      var loaderBtn = document.getElementsByClassName('loaderBtn');
      var searchBar = document.getElementById('searchBar');
      var classSearchBar = document.getElementsByClassName('searchBar');
      for (var i = 0; i < titles.length; i++) {
        titles[i].style.color = "#15161b";
      }
      for (var o = 0; o < categories.length; o++) {
        categories[o].style.color = "#515151";
      }
      for (var u = 0; u < loaderBtn.length; u++) {
        loaderBtn[0].style.color = "#ff003c";
      }
      for (var y = 0; y < loaderBtn.length; y++) {
        classSearchBar[0].style.backgroundColor = "#E0E0E0";
      }
      searchBar.style.backgroundColor = "#E0E0E0";
      background[0].style.backgroundColor = "#F0F0F0";
    },
    darkModeVerification: async function darkModeVerification() {
      if (JSON.parse(localStorage.getItem('darkMode')) == false) return this.lightMode();
    },
  },
  created: function () {
    this.admin();
    this.getCategories();
    this.getAll();
    this.randomSerie();
  },
  mounted: function () {
    // Ajoutez un écouteur d'événement de défilement pour mettre à jour scrollDistance
    window.addEventListener('scroll', this.updateScrollDistance);
    AOS.init();
    document.body.overflow = "auto";
    setTimeout(() => { this.darkModeVerification() }, 200);
    var searchBar = document.getElementById('searchBar');
    const selectElement = document.querySelector('#triage');
    searchBar.addEventListener('change', () => {
      if (searchBar.value) return this.getFromSearch(searchBar.value);
      this.getAll();
    });
    selectElement.addEventListener('change', () => {
      if (selectElement.value == -1) return this.getAll();
      if (selectElement.value == -2) return this.getAllFromZ();
      if (selectElement.value == -3) return this.getAllFromLong();
      if (selectElement.value == -4) return this.getAllFromShort();
      if (selectElement.value == -5) return this.getAllRandom();
      if (selectElement.value == -6) return this.getAllFromRecent();
      if (selectElement.value == -7) return this.getAllFromOld();
      if (selectElement.value == -8) return this.getAllFromAnciens();
      if (selectElement.value == -9) return this.getAllFromYoung();
    });
  }
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$back-color: #15161b;
$dark-red: #E60036;
$soft-grey: #2D2E32;

@keyframes slide {
  from {
    opacity: 0;
    transform: translateZ(-30px);
  }

  to {
    opacity: 1;
    transform: translateZ(0px);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes slideHorizontal {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }

  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.homePage {

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: $dark-red rgba(255, 255, 255, 0.178);
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }

  *::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.178);
  }

  *::-webkit-scrollbar-thumb {
    background-color: $dark-red;
    border-radius: 5px;
    border: 0px none #ffffff;
  }

  width: 100%;
  min-height: 100vh;
  background: $dark-color;

  .noResult {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 60px;
      height: 60px;
    }

    p {
      font-size: 20px;
      color: rgba(255, 255, 255, 0.5);
      font-family: 'Lato';
      font-display: swap;
    }
  }

  .loaderWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 0px;

      button {
        box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 15px;
        border-radius: 50px;
        border: none;
        background: rgba(0, 0, 0, 0.1);
        color: white;
        font-size: 16px;

        &:hover {
          cursor: pointer;
        }

        i {
          color: $dark-red;
        }
      }
    }
  }

  .noFilmContainer {
    width: 100%;
    padding: 60px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 20px;

    p {
      max-width: 80%;
      color: white;
      font-family: 'Montserrat';
    }
  }

  .random {
    width: 100%;
    height: 93vh;
    overflow: none;

    .randomContainer {
      width: 100%;
      height: 100%;
      position: relative;

      img {
        &:first-child {
          width: 100%;
          height: 100%;
          filter: brightness(30%);
          z-index: 0;
          //animation: appear 2s;
          object-fit: cover;
          object-position: top;
        }
      }

      .poster {
        position: absolute;
        bottom: 10%;
        left: 15%;
        z-index: 1;
        color: $light-color;
        display: flex;
        flex-direction: column;
        gap: 5px;

        img {
          width: 330px;
          aspect-ratio: 9/13;
          object-fit: cover;
          filter: blur(0px) brightness(100%);
          border-radius: 10px;
          box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.7);
          transition-duration: 0.4s;

          &:hover {
            transition-duration: 0.4s;
            cursor: pointer;
            filter: brightness(70%);
          }
        }

        h4 {
          font-family: 'Lato';
          font-weight: bold;
          font-size: 20px;
          padding: 5px;
        }

        .randomCategory {
          font-family: 'Montserrat';
          font-size: 16px;
          width: fit-content;
          background: $dark-red;
          padding: 5px;
          border-radius: 5px;
        }

        .randomDuration {
          font-family: 'Montserrat';
          font-weight: bold;
          font-size: 16px;
          padding: 5px;
        }

        .randomDescription {
          font-family: 'Montserrat';
          font-size: 16px;
          overflow-y: auto;
          max-height: 100px;
          width: 90%;
          padding-right: 20px;
        }

        .posterInteraction {
          padding: 5px 0 5px 5px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;

          .buttons {
            border: none;
            padding: 5px;
            font-size: 22px;
            color: $light-color;
            background: transparent;
            transition-duration: 0.5s;
            border-bottom: 2px solid transparent;

            &:hover {
              cursor: pointer;
              color: $dark-red;
              border-bottom: 2px solid $dark-red;
              transition-duration: 0.5s;
            }
          }

          .favorite {
            color: $dark-red;
            font-size: 25px;
          }

          .player {
            border-bottom: 2px solid $dark-red;
          }
        }
      }
    }
  }

  .searchBarContainer {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0 20px 0;

    .searchBar {
      width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $light-color;
      gap: 10px;
      padding: 5px 10px;
      border-radius: 20px;

      img {
        width: 20px;
        height: 20px;
        order: 1;

        &:hover {
          cursor: pointer;
        }
      }

      input {
        width: 100%;
        outline: none;
        padding: 5px 10px;
        background: transparent;
        border: none;
        border-right: 1px solid $dark-color;
      }
    }
  }

  .displayer {
    width: 100%;
    height: 100vh;
    background: rgba(20, 22, 28, 0.95);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    display: none;
    justify-content: center;
    align-items: center;

    //animation: slideHorizontal 1s;
    .displayerContainer {
      width: 60%;
      height: 100%;
      background: $back-color;
      color: white;
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;

      h3 {
        margin: 10px 0;
      }

      a {
        font-family: 'Montserrat';
      }

      p {
        margin-top: 10px;
        font-family: 'Montserrat';

        &:first-child {
          color: white;
        }

        &:last-child {
          height: 200px;
        }

        color: rgba(255, 255, 255, 0.61);
      }

      ul {
        height: fit-content;
        overflow: auto;
        font-family: 'Montserrat';
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        li {
          button {
            width: max-content;
            min-width: 130px;
            border: none;
            background: $light-color;
            color: $dark-color;
            font-size: 16px;
            padding: 10px 0;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .cat {
        color: $light-color;
        font-family: 'Lato';
        width: 100%;
        margin: 10px 0;

        p {
          width: fit-content;
          height: auto;
          background: $dark-red;
          font-size: 15px;
          padding: 5px 10px;
          border-radius: 5px;
          font-weight: bold;
        }
      }

      .exit {
        position: absolute;
        top: 10px;
        right: 20px;
        z-index: 3;
        background-color: transparent;

        button {
          border: none;
          background: transparent;
          color: $light-color;
          font-size: 30px;

          &:hover {
            cursor: pointer;
            animation: spin 2s forwards;
          }

          i {
            color: white;
            filter: brightness(200%);
            filter: drop-shadow(0px 0px 0 black) drop-shadow(0px 0px 0 black);
          }
        }
      }

      .upper {
        width: 100%;
        height: 60vh;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        gap: 10px;

        img {
          &:first-child {
            z-index: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            filter: brightness(40%);
            object-fit: cover;
            animation: appear 2s;
          }

          &:nth-child(2) {
            z-index: 1;
            width: 250px;
            aspect-ratio: 9/13;
            object-fit: cover;
            margin: 30px;
            box-shadow: 0px 0px 25px -1px rgba(0, 0, 0, 0.7);
            border-radius: 10px;
          }
        }

        .section {
          width: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          transform: translateY(-15px);
          margin-right: 20px;

          button {
            margin: 0 10px;
            border: none;
            background: transparent;
            color: $light-color;
            font-size: 32px;
            transition-duration: 1s;
            filter: drop-shadow(0px 0px 0 black) drop-shadow(0px 0px 0 black);

            &:hover {
              cursor: pointer;
              transition-duration: 1s;
              color: $dark-red;
              filter: drop-shadow(0px 0px 0 transparent) drop-shadow(0px 0px 0 transparent);
            }
          }
        }
      }

      h3 {
        font-family: 'Lato';
        font-size: 24px;
      }

      .lower {
        width: 95%;
        padding: 15px;


        .showTVPlayer {
          margin-top: 10px;

          button {
            border: none;
            background: $light-color;
            color: $dark-color;
            font-size: 16px;
            padding: 10px 20px;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            transition-duration: 0.4s;

            &:hover {
              transition-duration: 0.4s;
              cursor: pointer;
              background: $dark-red;
              color: $light-color;
            }

            &:disabled {
              background: $dark-red;
              color: $light-color;
              filter: brightness(0.5);
              cursor: not-allowed;
            }

          }
        }

        .lastEpisode {
          margin-top: 10px;
          padding: 5px 10px;
          background: $dark-red;
          max-width: 100%;
          width: fit-content;
          border-radius: 10px;

          p {
            margin: 0;
            height: auto;
            color: $light-color;
            font-family: 'Montserrat';
            font-size: 16px;

            span {
              a {
                color: $light-color;
                font-size: 16px;
              }
            }
          }
        }

        .seasonSelector {
          margin-top: 20px;
          min-width: 200px;
          height: 40px;
          background: $light-color;
          border: none;
          border-radius: 5px;
          padding: 5px;
          font-family: 'Montserrat';
          font-size: 16px;
          outline: none;
          color: $dark-color;
        }

        .loginContainer {
          margin: 20px 0 0 0;
          background: $soft-grey;
          max-width: 100%;
          padding: 5px 15px;
          border-radius: 15px;

          p {
            font-family: 'Montserrat';
            font-size: 18px;
            color: $light-color;
          }

          ul {
            margin-top: 5px;
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 2px;

            li {
              font-style: 'Montserrat';

              b {
                font-size: 18px;
              }
            }
          }
        }

        .episodes {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          gap: 10px;
          margin-top: 30px;
          padding-bottom: 30px;

          .episode {
            button {
              border: none;
              background: black;
              color: $light-color;
              font-size: 18px;
              padding: 10px 20px;
              width: 100%;
              border-radius: 10px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 10px;

              &:hover {
                cursor: pointer;
              }

              &:disabled {
                background: $dark-red;
                color: $light-color;
                filter: brightness(0.5);
                cursor: not-allowed;
              }
            }
          }

        }
      }
    }
  }

  .goTop {
    position: fixed;
    right: 20px;
    bottom: 30px;
    width: auto;
    aspect-ratio: 1/1;
    z-index: 10;

    button {
      color: white;
      background: $dark-red;
      padding: 5px;
      border: none;
      border-radius: 5px;
      display: block;
      animation: appear 1s forwards;

      i {
        font-size: 25px;
      }

      &:hover {
        cursor: pointer;

        i {
          animation: bounce 1s infinite;
        }
      }
    }
  }

  .order {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    animation: appear 2s;
    font-family: 'Lato';

    select {
      width: 15%;
      border: none;
      background-color: $dark-red;
      border-radius: 5px;
      color: white;
      padding: 10px;
      outline: none;
      text-indent: 1px;
      text-overflow: '';
      margin: 10px;
      -webkit-appearance: none;

      &:hover {
        cursor: pointer;
      }

      option {
        background: white;
        color: $dark-color;
        padding: 5px 10px;
      }
    }

    button {
      margin: 10px;
      padding: 5px 10px;
      border: none;
      animation: appear 2s;
      font-family: 'Lato';
      font-size: 16px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3px;
      background: $dark-red;
      color: white;
      position: relative;
      transition-duration: 1s;

      img {
        width: 20px;
        height: 20px;
      }

      &:hover {
        cursor: pointer;
        transform: scale(1.05);
        transition-duration: 1s;
      }
    }
  }

  .filmsSection {
    padding: 60px 0 100px 0;
    width: 90%;
    min-height: 70vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
  }
}

@media screen and (max-width: 1025px) {
  .homePage {
    .searchBarContainer {
      width: 90%;
      order: -1;

      .searchBar {
        width: 100%;
      }
    }

    .random {
      width: 100%;
      height: 80vh;
    }

    .displayer {
      .displayerContainer {
        width: 90%;
      }
    }

    .order {
      width: 90%;
      align-items: flex-start;
      flex-wrap: wrap;

      select {
        width: 25%;
      }
    }

    .filmsSection {
      width: 95%;

      .card {
        min-width: 25%;
        margin: 20px;

        img {
          width: 100%;
          height: 410px;
          object-fit: cover;
          image-rendering: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .homePage {
    .searchBarContainer {
      width: 90%;
      order: -1;

      .searchBar {
        width: 100%;
      }
    }

    .random {
      width: 100%;
      height: 80vh;
    }

    .displayer {
      .displayerContainer {
        width: 90%;
      }
    }

    .order {
      width: 90%;
      align-items: flex-start;
      flex-wrap: wrap;

      select {
        width: 25%;
      }
    }

    .filmsSection {
      width: 100%;

      .card {
        min-width: 290px;
        margin: 20px;

        img {
          width: 100%;
          height: 430px;
          object-fit: cover;
          image-rendering: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 426px) {
  .homePage {
    .random {
      .randomContainer {
        .poster {
          bottom: 5%;
          left: 5%;

          img {
            width: 250px;
            filter: brightness(100%);
          }
        }
      }
    }

    .noFilmContainer {

      p {
        max-width: 80%;
        color: white;
        font-family: 'Montserrat';
      }
    }

    .displayer {
      width: 100%;
      align-items: flex-start;

      .displayerContainer {
        width: 100%;
        height: 100vh;
        border-radius: 0;

        .lower {
          height: fit-content;
          margin-top: 35px;
          padding-bottom: 50px;

          p {
            overflow-y: auto;
            margin-top: 10px;
            font-family: 'Montserrat';

            &:first-child {
              color: white;
              height: fit-content;
            }

            &:last-child {
              height: 90%;
              max-height: 90%;
            }

            color: rgba(255, 255, 255, 0.61);

            i {
              margin-right: 3px;
            }
          }

          ul {
            max-height: fit-content;
            height: fit-content;

            li {
              margin: 5px 0;
            }
          }
        }

        .upper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          position: relative;
          height: fit-content;

          img {
            border-radius: 0;

            &:first-child {
              display: none;
            }

            &:nth-child(2) {
              z-index: 1;
              position: relative;
              width: 100%;
              height: auto;
              object-fit: cover;
              object-position: center;
              margin: 0;
              box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.7);
              animation: appear 2s;
              border-radius: 0;
            }
          }

          .section {
            position: absolute;
            bottom: 0;
            right: 10px;
            z-index: 1;
            transform: translateY(50px);
            margin-right: 5px;

            button {
              width: fit-content;
              margin: 0 6px;

              i {
                font-size: 30px;
              }
            }
          }
        }
      }
    }

    .order {
      align-items: flex-start;
      flex-wrap: wrap;

      select {
        width: 90%;
      }
    }

    .searchBarContainer {
      order: -1;
      width: 100%;

      .searchBar {
        width: 100%;
      }
    }

    .filmsSection {
      width: 100%;

      .card {
        width: 80%;
        max-height: fit-content;
        margin: 20px;

        img {
          height: auto;
        }
      }
    }
  }
}
</style>
