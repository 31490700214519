<template>
  <div class="home">
    <AddSerie />
  </div>
</template>

<script>
import AddSerie from '../components/AddSerie.vue';

export default {
  name: 'AddSerieView',
  components: {
    AddSerie
  }
}
</script>