<template>
  <div class="homePage">
    <NavbarTop />
    <div class="reportModalContainer" v-if="showReportModal">
      <div class="reportModal">
        <span class="reportClose" @click="showReportModal = false"><i class="fa-solid fa-times"></i></span>
        <label for="reportSelectorVideo">Problème à signaler</label>
        <select id="reportSelectorVideo" v-model="reportReason">
          <option v-bind:value="undefined">Sélectionnez un problème</option>
          <option v-bind:value="'La vidéo n\'est pas disponible'">La vidéo n'est pas disponible</option>
          <option v-bind:value="'La vidéo a besoin de sous-titres'">La vidéo a besoin de sous-titres</option>
          <option v-bind:value="'La vidéo ne se lance pas ou ne charge pas'">La vidéo ne se lance pas ou ne charge pas
          </option>
          <option v-bind:value="'La vidéo est de trop mauvaise qualité visuelle ou sonore'">La vidéo est de trop
            mauvaise
            qualité visuelle ou sonore</option>
          <option v-bind:value="'La vidéo n\'a pas de son'">La vidéo n'a pas de son</option>
          <option v-bind:value="'La vidéo n\'est pas complète ou se coupe avant la fin'">La vidéo n'est pas complète ou
            se
            coupe avant la fin</option>
          <option v-bind:value="'La vidéo est dans la mauvaise langue'">La vidéo est dans la mauvaise langue</option>
          <option v-bind:value="'Le son est décalé avec l\'image'">Le son est décalé avec l'image
          </option>
          <option v-bind:value="'La vidéo affichée ne correspond pas à la vidéo choisie'">La vidéo affichée ne
            correspond
            pas à la vidéo choisie</option>
          <option v-bind:value="'La bande annonce ne se lance pas ou ne charge pas'">La bande annonce ne se lance pas ou
            ne charge pas</option>
          <option v-bind:value="'La bande annonce est de trop mauvaise qualité visuelle ou sonore'">La bande annonce est
            de trop mauvaise qualité visuelle ou sonore</option>
          <option v-bind:value="'La bande annonce n\'est pas complète ou se coupe avant la fin'">La bande annonce n'est
            pas complète ou se coupe avant la fin</option>
          <option v-bind:value="'La bande annonce est dans la mauvaise langue'">La bande annonce est dans la mauvaise
            langue</option>
          <option v-bind:value="'La bande annonce affichée ne correspond pas'">La bande annonce
            affichée ne correspond pas</option>
          <option v-bind:value="'Les informations de la fiche ne correspondent pas'">Les informations de la fiche ne
            correspondent pas</option>
          <option v-bind:value="'Autre'">Autre</option>
        </select>
        <button @click="sendTicket()">Signaler le problème</button>
      </div>
    </div>
    <div class="noResult" v-if="!article">
      <img src="../assets/loading.svg" alt="404" />
      <p id="loaderText">Chargement du film..</p>
    </div>
    <div class="playerHeaven" v-if="streamingReady">
      <video ref="videoPlayer" class="video-js vjs-default-skin" controls aspectRatio="16:9" width="1024" preload fluid
        language='fr' :poster="this.posterPlayer" download="false" @error="handleVideoError">
        <source :src="this.streamingLink + '?token=' + this.userToken" type='video/mp4' />
      </video>
      <button id="skipIntroButton" v-if="showSkipIntro" @click="skipIntro">Passer l'intro</button>
      <div class="playerActions" v-if="startTime != 0 && showRestartButton">
        <span v-if="startTime != 0" class="restart"><button @click="restartPlayer()">Revenir au début <i
              class="fa-solid fa-arrows-rotate"></i></button></span>
      </div>
    </div>
    <div class="upperPlayer" v-if="article">
      <div class="qualityContainer" v-if="article.quality">
        <span v-if="article.details && article.details.season">Saison {{ article.details.season }}</span>
        <span v-if="article.details && article.details.episode_number">Episode {{ article.details.episode_number }}</span>
        <span v-if="article.annedesortie && type == 'movie'"><i class="fa-regular fa-calendar-days"></i> {{
          article.annedesortie }}</span>
        <span v-if="article.anneedesortie == article.annedefin && type == 'serie'"><i
            class="fa-regular fa-calendar-days"></i> {{ article.anneedesortie }}</span>
        <span v-else-if="article.anneedesortie && article.annedefin && type == 'serie'"><i
            class="fa-regular fa-calendar-days"></i> {{ article.anneedesortie }} <span v-if="annedefin">- {{
              article.annedefin }}</span></span>
        <span v-if="article.duration"><i class="fa-regular fa-clock"></i> {{ article.duration }}</span>
        <span v-if="article.quality == '4K'">4K (UHD)</span>
        <span v-if="article.quality == '1080p'">1080p (HD)</span>
        <span v-if="article.quality == '720p'">720p (DVD)</span>
        <span v-if="article.quality == '480p'">480p (SD)</span>
        <span v-if="article.quality == '360p'">360p (SD)</span>
        <span v-if="article.quality == '240p'">240p (SD)</span>
        <span v-if="article.filesize"><i class="fa-solid fa-cloud-arrow-down"></i> {{
          convertToGigabytes(article.filesize) + 'Go' }}</span>
        <span v-if="article.movie_credits && isAdmin || article.credits_start && isAdmin">Crédits marqués</span>
        <span v-else-if="!article.movie_credits && isAdmin || !article.credits_start && isAdmin"><i
            class="fa-solid fa-circle-exclamation"></i> Crédits non
          marqués</span>
        <span v-if="article.intro_start && isAdmin">Intro start: {{ convertToTimecode(article.intro_start) }}</span>
        <span v-if="article.intro_end && isAdmin">Intro end: {{ convertToTimecode(article.intro_end) }}</span>
        <span v-if="article.credits_start && isAdmin">Credits start: {{ convertToTimecode(article.credits_start)
          }}</span>
      </div>
      <h1 class="movieName" v-if="article.name && type == 'movie'">{{ article.name }}</h1>
      <h1 v-if="article.details && article.details.name">{{ article.details.name }}</h1>
      <span class="episodeName" v-if="article.name && type == 'serie'">{{ article.name }}</span>
      <p v-if="article.details && article.details.description">{{ article.details.description }}</p>
      <p v-else-if="article.description && type == 'movie'">{{ article.description }}</p>
    </div>
    <p class="warningContainer" v-if="article && article.quality">
      <span class="warn" v-if="article.quality == '4K'">Attention, {{ type == 'movie' ? 'ce film' : 'cet épisode' }} est en 4K, veillez à ce
        que votre
        appareil accepte la 4K. En cas de cast sur un écran, Apple Play supporte ce format,
        mais pour
        un Chromecast, il vous faudra la version 4K du boitier.</span>
      <span class="warn"
        v-else-if="article.quality != '1080p' && article.quality != '4K' && article.quality != '720p'">
        {{ type == 'movie' ? 'Ce film' : 'Cet épisode' }} est
        épinglé comme ayant une qualité inférieure à la norme. {{ type == 'movie' ? '(Les vieux films peuvent être épinglés comme de mauvaise qualité ayant un format ancien)' : '' }}</span>
    </p>
    <p class="warningContainer" v-if="article && article.isCanadian">
      <span class="warn" v-if="article.isCanadian">Ce film est en version canadienne, certaines expressions peuvent être
        différentes de la version française. Nous faisons en sorte d'avoir les versions françaises, mais il peut arriver
        que nous n'ayons pas le choix que de mettre la version canadienne surtout concernant les films récents.
        Bisous.</span>
    </p>
    <div class="interactionsContainer" v-if="isAdmin && article.cat">
      <div class="interactions">
        <button @click="previousMovie()"><i class="fa-solid fa-chevron-left"></i> Film précédent</button>
        <button @click="editArticle()" title="Modifier l'article" id="reporter"><i class="fa-solid fa-edit"></i> Editer
          l'article</button>
        <button @click="setMovieCredits()"><i class="fa-solid fa-thumbtack"></i> Marquer les crédits</button>
        <button @click="nextMovie()">Film suivant <i class="fa-solid fa-chevron-right"></i></button>
      </div>
    </div>
    <div class="interactionsContainer" v-if="isOnPC && this.player">
      <div class="interactions">
        <button @click="toggleShortcuts" title="Voir les raccourcis clavier"><i class="fa-solid fa-keyboard"></i>
          Raccourcis clavier</button>
        <div class="popper" v-if="displayShortcuts">
          <h3>Raccourcis clavier</h3>
          <ul>
            <li>Pause/Lire : <span class="control">P</span> ou <span class="control">ESPACE</span></li>
            <li>Plein écran : <span class="control">F</span></li>
            <li>Avancer/Reculer : <span class="control"><i class="fa-solid fa-arrow-left"></i></span> <span
                class="control"><i class="fa-solid fa-arrow-right"></i></span></li>
            <li>Augmenter/Baisser le volume : <span class="control"><i class="fa-solid fa-arrow-up"></i></span> <span
                class="control"><i class="fa-solid fa-arrow-down"></i></span></li>
            <li>Couper/Remettre le son: <span class="control">M</span></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="interactionsContainer" v-if="type == 'serie'">
      <div class="interactions">
        <button @click="previousEpisode()"><i class="fa-solid fa-chevron-left"></i> Episode précédent</button>
        <button v-if="isAdmin && !article.cat" @click="editArticle()" title="Modifier l'article" id="reporter"><i
            class="fa-solid fa-edit"></i> Editer
          l'article</button>
        <button @click="defineCreditsStart()" v-if="isAdmin"><i class="fa-solid fa-thumbtack"></i> Marquer les crédits</button>
        <button @click="defineIntro('start')" v-if="isAdmin"><i class="fa-solid fa-thumbtact"></i> Début intro</button>
        <button @click="defineIntro('end')" v-if="isAdmin"><i class="fa-solid fa-thumbtact"></i> Fin intro</button>
        <button @click="nextEpisode()">Episode suivant <i class="fa-solid fa-chevron-right"></i></button>
      </div>
    </div>
    <div class="movieInfosContainer"
      v-if="infos || BandeAnnonce || article && article.trailerURL && article.trailerURL.length > 0">
      <div class="bandeAnnonce" v-if="BandeAnnonce || article && article.trailerURL && article.trailerURL.length > 0">
        <iframe width="560" height="475"
          :src="`https://www.youtube.com/embed/${article.trailerURL && article.trailerURL.length > 0 ? article.trailerURL : BandeAnnonce}`"
          frameborder="0" allowfullscreen></iframe>
      </div>
      <div class="movieInfos" v-if="infos">
        <a :href="`https://www.imdb.com/title/${article.imdb_id}/`" target="_blank"><svg id="home_img" class="ipc-logo"
            xmlns="http://www.w3.org/2000/svg" width="64" height="32" viewBox="0 0 64 32" version="1.1">
            <g fill="#F5C518">
              <rect x="0" y="0" width="100%" height="100%" rx="4"></rect>
            </g>
            <g transform="translate(8.000000, 7.000000)" fill="#000000" fill-rule="nonzero">
              <polygon points="0 18 5 18 5 0 0 0"></polygon>
              <path
                d="M15.6725178,0 L14.5534833,8.40846934 L13.8582008,3.83502426 C13.65661,2.37009263 13.4632474,1.09175121 13.278113,0 L7,0 L7,18 L11.2416347,18 L11.2580911,6.11380679 L13.0436094,18 L16.0633571,18 L17.7583653,5.8517865 L17.7707076,18 L22,18 L22,0 L15.6725178,0 Z">
              </path>
              <path
                d="M24,18 L24,0 L31.8045586,0 C33.5693522,0 35,1.41994415 35,3.17660424 L35,14.8233958 C35,16.5777858 33.5716617,18 31.8045586,18 L24,18 Z M29.8322479,3.2395236 C29.6339219,3.13233348 29.2545158,3.08072342 28.7026524,3.08072342 L28.7026524,14.8914865 C29.4312846,14.8914865 29.8796736,14.7604764 30.0478195,14.4865461 C30.2159654,14.2165858 30.3021941,13.486105 30.3021941,12.2871637 L30.3021941,5.3078959 C30.3021941,4.49404499 30.272014,3.97397442 30.2159654,3.74371416 C30.1599168,3.5134539 30.0348852,3.34671372 29.8322479,3.2395236 Z">
              </path>
              <path
                d="M44.4299079,4.50685823 L44.749518,4.50685823 C46.5447098,4.50685823 48,5.91267586 48,7.64486762 L48,14.8619906 C48,16.5950653 46.5451816,18 44.749518,18 L44.4299079,18 C43.3314617,18 42.3602746,17.4736618 41.7718697,16.6682739 L41.4838962,17.7687785 L37,17.7687785 L37,0 L41.7843263,0 L41.7843263,5.78053556 C42.4024982,5.01015739 43.3551514,4.50685823 44.4299079,4.50685823 Z M43.4055679,13.2842155 L43.4055679,9.01907814 C43.4055679,8.31433946 43.3603268,7.85185468 43.2660746,7.63896485 C43.1718224,7.42607505 42.7955881,7.2893916 42.5316822,7.2893916 C42.267776,7.2893916 41.8607934,7.40047379 41.7816216,7.58767002 L41.7816216,9.01907814 L41.7816216,13.4207851 L41.7816216,14.8074788 C41.8721037,15.0130276 42.2602358,15.1274059 42.5316822,15.1274059 C42.8031285,15.1274059 43.1982131,15.0166981 43.281155,14.8074788 C43.3640968,14.5982595 43.4055679,14.0880581 43.4055679,13.2842155 Z">
              </path>
            </g>
          </svg></a>
        <p v-if="infos && infos.Title">Titre international : <span>{{ infos.Title }} - {{ infos.Year }}</span>
        </p>
        <p v-if="infos && infos.BoxOffice && infos.BoxOffice != 'N/A'">Box-Office :
          <span v-if="convertedBoxOffice">{{ convertedBoxOffice }} ({{ infos.BoxOffice }})</span>
          <span v-else>
            {{ infos.BoxOffice }}
          </span>
        </p>
        <p v-if="infos && infos.Production && infos.Production != 'N/A'">Production : <span>{{ infos.Production
            }}</span></p>
        <p v-if="infos && infos.Director && infos.Director != 'N/A'">Réalisateur(s) : <span>{{ infos.Director
            }}</span></p>
        <p v-if="infos && infos.Writer && infos.Writer != 'N/A'">Scénariste(s) : <span>{{ infos.Writer }}</span>
        </p>
        <p v-if="infos && infos.Actors">Acteur(s) : <span>{{ infos.Actors }}</span></p>
        <p v-if="infos && infos.Ratings && infos.Ratings.length > 0">Evaluation(s) :</p>
      </div>
      <div class="rating" v-if="infos && infos.Ratings && infos.Ratings.length > 0">
        <div class="ratingCard" v-for="element in infos.Ratings" v-bind:key="element">
          <div v-if="element.Source == 'Internet Movie Database'">
            <img src="https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/171_Imdb_logo_logos-512.png"
              :alt="'logo de ' + element.Source" loading="lazy">
          </div>
          <div v-else-if="element.Source == 'Rotten Tomatoes'">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5b/Rotten_Tomatoes.svg/1200px-Rotten_Tomatoes.svg.png"
              :alt="'logo de ' + element.Source" loading="lazy">
          </div>
          <div v-else-if="element.Source == 'Metacritic'">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/20/Metacritic.svg/1200px-Metacritic.svg.png"
              :alt="'logo de ' + element.Source" loading="lazy">
          </div>
          <p>{{ element.Source }}</p>
          <span>{{ element.Value }}</span>
        </div>
      </div>
      <div class="help" v-if="article">
        <p>En cas de problème avec la lecture de la vidéo :
        </p>
        <div class="helpButtons">
          <button @click="showReportModal = true" title="Prévenir qu'il y a un soucis" id="reporter"><i
              class="fa-solid fa-flag"></i> Signaler un
            problème</button>
          <div v-if="isAdmin">
            <button @click="editArticle()" title="Modifier l'article" id="reporter"><i class="fa-solid fa-edit"></i>
              Editer l'article</button>
          </div>
          <a v-if="type == 'movie'" class="download"
            :href="`https://server.heavenflix.fr/videos/movies/dl/${slugifyName(article.name, article.annedesortie)}?token=${userToken}`"
            :download='slugifyName(article.name, article.annedesortie)'><i class="fa-solid fa-cloud-arrow-down"></i>
            Télécharger <span v-if="article.filesize">({{ convertToGigabytes(article.filesize) +
              'Go' }})</span></a>
          <a v-else-if="type == 'serie'" class="download"
            :href="`https://server.heavenflix.fr/videos/series/dl/${slugifyTitle(article.download_name)}/S${article.season}E${article.episode_number}_${slugifyTitle(article.download_name)}.mp4?token=${userToken}`"
            :download='slugifyTitle(article.download_name, article.season, article.episode_number)'><i
              class="fa-solid fa-cloud-arrow-down"></i> Télécharger ({{ convertToGigabytes(article.filesize) +
            'Go' }})</a>
          <button @click="refresh()" title="Actualiser la page" id="reporter"><i class="fa-solid fa-rotate-right"></i>
            Actualiser la page</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import NavbarTop from './NavbarTop.vue';
// import the library
import { createToast } from 'mosha-vue-toastify';
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'
//import axios from 'axios';
import slugify from 'slugify';

import 'video.js/dist/video-js.css'; // Importez le fichier CSS de Video.js
import videojs from 'video.js';
import 'videojs-sublime-skin/dist/videojs-sublime-skin.css'; // Importez le fichier CSS de Video.js sublime skin
import 'videojs-markers';
import 'videojs-markers/dist/videojs.markers.css';


videojs.addLanguage('fr', {
  'Play': 'Lecture',
  'Pause': 'Pause',
  'Current Time': 'Temps actuel',
  'Duration Time': 'Durée',
  'Remaining Time': 'Temps restant',
  'Stream Type': 'Type de flux',
  'LIVE': 'EN DIRECT',
  'Loaded': 'Chargé',
  'Progress': 'Progression',
  'Fullscreen': 'Plein écran',
  'Non-Fullscreen': 'Fenêtré',
  'Mute': 'Sourdine',
  'Unmute': 'Son activé',
  'Playback Rate': 'Vitesse de lecture',
  'Subtitles': 'Sous-titres',
  'subtitles off': 'Sous-titres désactivés',
  'Captions': 'Sous-titres',
  'captions off': 'Sous-titres désactivés',
  'Chapters': 'Chapitres',
  'You aborted the media playback': 'Vous avez interrompu la lecture de la vidéo.',
  'A network error caused the media download to fail part-way.': 'Une erreur de réseau a interrompu le téléchargement de la vidéo.',
  'The media could not be loaded, either because the server or network failed or because the format is not supported.': 'Cette vidéo n\'a pas pu être chargée, soit parce que le serveur ou le réseau a échoué ou parce que le format n\'est pas reconnu.',
  'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': 'La lecture de la vidéo a été interrompue à cause d\'un problème de corruption ou parce que la vidéo utilise des fonctionnalités non prises en charge par votre navigateur.',
  'No compatible source was found for this media.': 'Aucune source compatible n\'a été trouvée pour cette vidéo.'
})

export default {
  name: 'PlayerContainer',
  components: {
    NavbarTop,
  },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      articleID: this.$route.params.id,
      type: this.$route.params.type,
      article: undefined,
      serie: undefined,
      season: this.$route.params.season,
      episodes: undefined,
      posterPlayer: 'https://api.heavenflix.fr/images/messages/video_loading.png',
      streamingLink: 'https://server.heavenflix.fr/movies/dunkerque_2017.mp4',
      index: this.$route.params.index,
      isAdmin: undefined,
      serverLink: 'https://server.heavenflix.fr',
      link: "https://api.heavenflix.fr",
      showWarning: true,
      API_KEY: 'AIzaSyBliI_rrX6jueTuKE5M2glCprJ6UZojs4g',
      BandeAnnonce: undefined,
      showBandeAnnonce: false,
      available: undefined,
      serpapi_key: 'f97d7d59109b0a9af515f6c10fc5747099f419fea0ae3b3536ab6def792b5159',
      showAvailable: false,
      infos: undefined,
      convertedBoxOffice: undefined,
      exchangeRateApiKey: '380c6ec2439ac58f95465c2f',
      exchangeRateApiLink: 'https://v6.exchangerate-api.com/v6/380c6ec2439ac58f95465c2f/latest/USD',
      player: null,
      showReportModal: false,
      reportReason: undefined,
      userToken: localStorage.getItem('token'),
      streamingReady: false,
      watchTime: 0,
      startTime: 0,
      showRestartButton: false,
      watchtimeId: undefined,
      watchtimeSaved: 0,
      isWatchtimeDeleted: false,
      isOnPC: this.isUserOnDesktop(),
      displayShortcuts: false,
      user_preferences: undefined,
      canplayFired: false,
      showSkipIntro: false,
      isAlreadySkiped: false,
      nextEpisodeAsked: false,
    }
  },
  setup() {
    const toast = () => {
      createToast('Wow, easy')
    }
    return {
      toast,
    };
  },
  watch: {
    article: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal && newVal.link && !newVal.link.includes('heavenflix')) {
            const player = document.getElementsByClassName('playerHeaven')[0];
            if (player) {
              player.style.display = 'none';
            }
            this.showNotification('danger', 'La vidéo n\'est pas disponible sur notre plateforme');
            return;
          }
          if (this.user_preferences) {
            this.createVideoPlayer();
          }
        }
      }
    },
    user_preferences: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal && this.article) {
          this.createVideoPlayer();
        }
      }
    }
  },
  methods: {
    skipIntro () {
      this.player.currentTime(this.article.intro_end);
      this.showSkipIntro = false;
    },
    async defineIntro(type) {
      // fetch à /episode/intro
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: this.episodeId,
          intro: this.player.currentTime(),
          type: type,
        }),
      };
      await fetch(this.link + '/episode/intro', requestOptions).then((res) => res.json()).then(data => {
        if (data.error) return console.log(data.error);
        if (data.code == 200) {
          this.showNotification('success', 'L\'intro a été marquée');
          this.getSerieByID();
        }
      });
    },
    async defineCreditsStart() {
      //fetch à /episode/credits/start
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: this.episodeId,
          credits_start: this.player.currentTime(),
        }),
      };
      await fetch(this.link + '/episode/credits/start', requestOptions).then((res) => res.json()).then(data => {
        if (data.error) return console.log(data.error);
        if (data.code == 200) {
          this.showNotification('success', 'Les crédits ont été marqués');
          this.getSerieByID();
        }
      });
    },
    async getUserPreferences() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getUserPreferences/' + localStorage.getItem('userId'), requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.user_preferences = JSON.parse(data[0].player_preferences);
      })
    },
    toggleShortcuts() {
      this.displayShortcuts = !this.displayShortcuts;
    },
    // Méthode pour détecter si l'utilisateur est sur un ordinateur
    isUserOnDesktop() {
      return !('ontouchstart' in window || navigator.maxTouchPoints);
    },
    setMovieCredits() {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          movieId: this.articleID,
          credits: this.player.currentTime(),
        }),
      };
      fetch(this.link + '/addMovieCredits', requestOptions).then((res) => res.json()).then(data => {
        if (data.error) return console.log(data.error);
        if (data.code == 200) {
          this.showNotification('success', 'Les crédits ont été marqués');
          this.article.movie_credits = this.player.currentTime();
          this.deleteWatchtime();
        }
      });
    },
    restartPlayer() {
      this.player.currentTime(0);
      this.startTime = 0;
      this.deleteWatchtime();
    },
    async deleteWatchtime() {
      if (this.isWatchtimeDeleted) return;
      if (this.watchtimeId == undefined) return;
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/watchtime/' + this.watchtimeId, requestOptions).then((res) => res.json()).then(data => {
        if (!data) return;
      })
    },
    async getWatchtime() {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: localStorage.getItem('userId'),
          path: this.streamingLink,
        }),
      };
      const response = await fetch(this.link + '/getWatchtime', requestOptions);
      const data = await response.json();
      if (data && data.result && data.result.length > 0 && data.result[0].watchtime) {
        this.watchtimeId = data.result[0].id;
        this.watchtimeSaved = data.result[0].watchtime;
        return data.result[0].watchtime;
      }
      return 0; // Retourne 0 si aucune watchtime n'est trouvée
    },
    async addWatchtime() {
      if (this.user_preferences && this.user_preferences.save_watchtime == false) return;
      if (this.article && this.article.movie_credits && this.player.currentTime() >= this.article.movie_credits) return this.deleteWatchtime();
      if (this.player.currentTime() != 0 && this.player.currentTime() < 10) return;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: localStorage.getItem('userId'),
          path: this.streamingLink,
          time: this.player.currentTime(),
        }),
      };
      await fetch(this.link + '/addWatchtime', requestOptions).then((res) => res.json()).then(data => {
        if (!data) return;
        this.getWatchtime();
      })
    },
    convertToGigabytes(filesize) {
      const gigabytes = filesize / (1024 * 1024 * 1024);
      // Formatez la taille en Go avec deux décimales
      return gigabytes.toFixed(2);
    },
    handleVideoError() {
      this.checkVideoStatus();
    },
    async checkVideoStatus() {
      try {
        const response = await fetch(this.streamingLink + '?token=' + localStorage.getItem('token'));
        if (!response.ok) {
          /*if (response.status === 401) {
            this.showNotification('danger', 'Veuillez vous reconnecter pour lire cette vidéo.');
            this.$router.push({
              name: 'login',
            });
          }
          if (response.status === 403) {
            this.showNotification('danger', 'Veuillez vous reconnecter pour lire cette vidéo.');
            this.$router.push({
              name: 'login',
            });
          }*/
          console.log(response)
        }
      } catch (error) {
        console.error("Erreur de requête HTTP", error);
      }
    },
    slugifyName: function slugifyName(name, year) {
      const sluggedName = slugify(name, {
        replacement: '_',
        lower: true,
        remove: /[^a-zA-Z0-9\s]/g
      });
      return sluggedName + '_' + year + '.mp4';
    },
    slugifyTitle: function slugifyTitle(title) {
      return slugify(title, {
        replacement: '_',
        lower: true,
        remove: /[^a-zA-Z0-9\s]/g
      });
    },
    refresh() {
      window.location.reload();
    },
    // Fonction pour convertir des dollars en euros
    convertUSDToEUR: async function convertUSDToEUR(amountUSD) {
      try {
        // Supprimer le signe dollar et convertir le montant en entier
        const amountInteger = parseInt(amountUSD.replace('$', '').replace(/,/g, ''));

        // Récupérer les taux de change actuels
        const response = await fetch(this.exchangeRateApiLink);
        const data = await response.json();

        // Vérifier si la requête a réussi
        if (response.ok) {
          // Obtenir le taux de change USD vers EUR
          const usdToEurRate = data.conversion_rates.EUR;

          // Convertir le montant de dollars en euros
          const amountEUR = amountInteger * usdToEurRate;

          // Arrondir le montant vers le bas pour retirer les centimes
          const roundedAmountEUR = Math.floor(amountEUR);

          // Formater le montant avec des virgules pour les milliers et sans décimales
          const formattedAmountEUR = roundedAmountEUR.toLocaleString('en-US', { maximumFractionDigits: 0 });

          this.convertedBoxOffice = formattedAmountEUR + '€';
        } else {
          console.error('Erreur lors de la récupération des taux de change');
          return null;
        }
      } catch (error) {
        console.error('Une erreur s\'est produite lors de la conversion:', error);
        return null;
      }
    },
    checkIfAvailable: async function checkIfAvailable() {
      setTimeout(() => {
        this.sendRequest(this.streamingLink);
      }, 500);
    },
    sendRequest: async function sendRequest(link) {
      try {
        const response = await fetch(link);

        // Vérifiez si la réponse contient le message indiquant que le fichier n'est plus disponible
        if (response.ok) {
          const text = await response.text();
          if (text.includes("File is no longer available as it expired or has been deleted.")) {
            console.log("Le fichier n'est plus disponible.");
          } else {
            console.log("Le fichier est disponible.");
          }
        } else {
          console.log("Erreur de la requête HTTP :", response.status);
        }
      } catch (error) {
        console.error("Erreur lors de la requête HTTP :", error);
      }
    },
    nextMovie: function nextMovie() {
      this.$router.push({
        name: 'player',
        params: {
          type: 'movie',
          id: parseInt(this.articleID) + 1,
        }
      });
      this.articleID = parseInt(this.articleID) + 1;
      setTimeout(() => {
        location.reload();
      }, 500);
    },
    previousMovie: function previousMovie() {
      if (this.articleID == 1) return this.showNotification('warning', 'Il n\'y a pas de film avant celui-ci.');
      this.$router.push({
        name: 'player',
        params: {
          type: 'movie',
          id: parseInt(this.articleID) - 1,
        }
      });
      this.articleID = parseInt(this.articleID) - 1;
      setTimeout(() => {
        location.reload();
      }, 500);
    },
    editArticle: function editArticle() {
      localStorage.setItem('edit', this.articleID);
      localStorage.setItem('editType', this.type);
      this.$router.push({
        name: 'edit',
      });
    },
    getOMDBInfos: async function getOMDBInfos() {
      if (!this.article.imdb_id) return;
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getToOMBD/' + this.article.imdb_id + '/movie',
        requestOptions).then((res) => res.json()).then(data => {
          if (data.msg) return console.log(data.msg);
          this.infos = data;
          if (this.infos.BoxOffice && this.infos.BoxOffice != 'N/A') this.convertUSDToEUR(this.infos.BoxOffice);
        })
    },
    async getAvailableFromTitle(title) {
      const clearTitle = title.replace(/ /g, '+');
      const proxyurl = "https://cors-anywhere.herokuapp.com/";
      //const proxyurl = "https://my-proxy-server.com/api/knowledge-graph-search";


      const requestString = `https://www.googleapis.com/customsearch/v1?key=${this.API_KEY}&q=${clearTitle}+watch+online`;
      //const requestString = 'https://serpapi.com/search.json?q=' + clearTitle + '+watch+online&location=France&hl=fr&gl=fr&key=' + this.serpapi_key;
      //const requestString = 'https://kgsearch.googleapis.com/v1/entities?query=' + clearTitle + '+watch+online&key=' + this.API_KEY;

      try {
        const response = await fetch(requestString);
        if (!response.ok) {
          throw new Error('Erreur lors de la requête.');
        }
        const data = await response.json();
        if (data.msg) {
          console.log(data.msg);
        } else {
          if (data.knowledge_graph && data.knowledge_graph.clip && data.knowledge_graph.clip.link) this.BandeAnnonce = data.knowledge_graph.clip.link.replace('https://www.youtube.com/watch?v=', '');
          if (data.available_on) return this.available = data.available_on;
        }
      } catch (error) {
        console.error('Une erreur s\'est produite :', error);
      }
    },
    async getBandeAnnonceFromTitle(title) {
      if (this.article.trailerURL && this.article.trailerURL.length > 0) return;
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      const clearTitle = title.replace(/ /g, '+');
      const requestString = 'https://www.googleapis.com/youtube/v3/search?part=snippet&q=' + clearTitle + '+bande+annonce+vf&type=video&key=' + this.API_KEY;
      await fetch(requestString, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        if (data && data.items) this.BandeAnnonce = data.items[0].id.videoId;
      })
    },
    showNotification(type, text) {
      createToast(text, {
        position: 'top-center',
        type: type,
        transition: 'zoom',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: 3000,
      })
    },
    sendTicket: async function sendTicket() {
      if (this.reportReason == undefined) return this.showNotification('danger', 'Veuillez sélectionner un problème à signaler');
      const reporter = document.getElementById('reporter');
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_ID: localStorage.getItem('userId'),
          request: type == 'movie' ? this.article.name : 'Série : ' + this.article.name + ' Saison ' + this.season + ' Episode ' + (parseInt(this.index) + 1),
          requestedMovie: this.articleID,
          season: this.season != 'noseason' ? this.season : null,
          episode: this.index ? (parseInt(this.index + 1)) : null,
          isMovie: this.type == 'movie' ? 1 : 0,
          reason: this.reportReason,
        }),
      };
      await fetch(this.link + '/addRequest', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        reporter.disabled = true;
        reporter.innerText = 'Merci pour le signalement! ❤️';
        reporter.style.color = '#dddddd';
        this.showNotification('success', this.article.name + ' a été signalé pour : ' + this.reportReason);
        this.showReportModal = false;
        this.reason = undefined;
        setTimeout(() => {
          reporter.disabled = false;
          reporter.innerText = 'Signaler un problème';
          reporter.style.color = 'white';
        }, 3000);
      })
    },
    addToHistorical: async function addToHistorical(id, episode, isMovie, season) {
      let movieVerification = 0;
      if (isMovie) movieVerification = 1;
      const date = new Date();
      let time = date.toLocaleString('fr-FR');
      let newTime = JSON.stringify(time);
      newTime = newTime.replace(/\//g, '-');
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          movieID: id,
          time: newTime,
          userID: localStorage.getItem('userId'),
          isMovie: movieVerification,
          episode: episode,
          season: season,
        })
      };
      await fetch(this.link + '/addHistorySerie', requestOptions).then((res) => res.json()).then(data => {
        if (data.error) return console.log(data.error);
      });
    },
    getCodeFromLink: function getCodeFromLink(link) {
      if (link == 'vide') return this.showNotification('warning', 'Cet épisode n\'est pas encore disponible');
      if (link && link.includes('embed-')) return link.split('embed-')[1].split('.html')[0];
      // Créez un nouvel objet URL
      const urlObject = new URL(link);
      // Accédez à la partie du chemin après le dernier "/"
      const chemin = urlObject.pathname;
      // Divisez le chemin en segments en utilisant "/"
      const segments = chemin.split('/');
      // Le code se trouve dans le dernier segment
      const code = segments[segments.length - 1];
      return code;
    },
    admin: async function admin() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      };
      await fetch(this.link + '/getAdmin/' + localStorage.getItem('userId'), requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then(data => {
          if (data.msg) return console.log(data.msg);
          this.isAdmin = data[0].isAdmin;
        })
    },
    async getMovieFromID() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getMovie/' + this.articleID, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.article = undefined;
        this.article = data[0];
        this.streamingReady = true;
        this.streamingLink = this.article.link;
        this.posterPlayer = this.article.backgroundURL;
        this.getOMDBInfos();
        this.getBandeAnnonceFromTitle(data[0].name);
        //this.getAvailableFromTitle(data[0].name, data[0].annedesortie)
      })
    },
    async getSerieByID() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getSerie/' + this.articleID, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.article = data[0];
        this.article.download_name = data[0].name;
        this.getOMDBInfos();
        this.getEpisodesFromSerie();
        //this.getBandeAnnonceFromTitle(data[0].name)
        //this.getAvailableFromTitle(data[0].name, data[0].annedesortie)
      })
    },
    convertToTimecode(value) {
      //Récupére en entrée une valeur qui correspond au currentTime de la vidéo et la convertit en timecode
      const hours = Math.floor(value / 3600);
      const minutes = Math.floor((value % 3600) / 60);
      const seconds = Math.floor(value % 60);
      return `${hours}:${minutes}:${seconds}`;
    },
    async getEpisodesFromSerie() {
      //Fetch pour get les épisodes à l'URL this.link + '/get/episodes/' + this.articleID
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/get/episodes/' + this.articleID, requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        //Récupérer l'épisode de la saison actuelle et l'index +1 pour récupérer le numéro d'épisode
        const season = this.season;
        const episode = data.filter(episode => episode.season == season && episode.episode_number == (parseInt(this.index) + 1));
        if(episode.length == 0) return this.showNotification('warning', 'Cet épisode n\'est pas encore disponible');
        this.streamingLink = episode[0].link;
        this.posterPlayer = episode[0].episode_poster_url;
        this.episodeId = episode[0].id;
        this.article.quality = episode[0].quality;
        this.article.credits_start = episode[0].credits_start;
        this.article.details = {
          name: this.article.name,
          season: episode[0].season,
          episode_number: episode[0].episode_number,
          description: episode[0].description,
        };
        this.article.name = `S${episode[0].season}E${episode[0].episode_number}. ${episode[0].title}`;
        this.article.intro_start = episode[0].intro_start;
        this.article.intro_end = episode[0].intro_end;
        this.article.season = episode[0].season;
        this.article.episode_number = episode[0].episode_number;
        this.article.filesize = episode[0].filesize;
        this.streamingReady = true;
        setTimeout(() => {
          if (this.streamingLink && !this.streamingLink.includes('heavenflix')) {
            const player = document.getElementsByClassName('playerHeaven')[0];
            player.style.display = 'none';
            this.showNotification('danger', 'La vidéo n\'est pas disponible sur notre plateforme');
            return
          }
          this.createVideoPlayer();
        }, 100);
      })
    },
    async previousEpisode() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      await fetch(this.link + '/get/episodes/' + this.articleID, requestOptions)
        .then((res) => {
          console.log("Réponse de la requête:", res);
          return res.json();
        })
        .then(data => {
          if (data.msg) {
            console.log("Message d'erreur:", data.msg);
            return console.log(data.msg);
          }

          const allEpisodes = data;
          let currentSeason = parseInt(this.season);
          let currentEpisodeNumber = parseInt(this.index) + 1;
          let previousEpisodeNumber = currentEpisodeNumber - 1;

          let previousEpisode = allEpisodes.find(episode => episode.season == currentSeason && episode.episode_number == previousEpisodeNumber);

          if (!previousEpisode) {
            console.log("Aucun épisode précédent dans la saison actuelle.");
            // Si aucun épisode précédent dans la saison actuelle, vérifier la saison précédente
            const previousSeason = currentSeason - 1;
            const lastEpisodePreviousSeason = allEpisodes.filter(episode => episode.season == previousSeason).sort((a, b) => b.episode_number - a.episode_number)[0];

            if (!lastEpisodePreviousSeason) {
              // Aucun épisode dans la saison précédente
              console.log("Aucun épisode dans la saison précédente.");
              return this.showNotification('warning', 'Il n\'y a pas d\'épisode précédent');
            } else {
              // Rediriger vers le dernier épisode de la saison précédente
              currentSeason = previousSeason;
              previousEpisodeNumber = lastEpisodePreviousSeason.episode_number;
            }
          }

          // Mise à jour des paramètres pour l'épisode précédent ou le dernier épisode de la saison précédente
          this.season = currentSeason.toString();
          this.index = previousEpisodeNumber - 1; // Assurez-vous que l'index est une chaîne de caractères

          // Redirection vers l'épisode précédent
          this.$router.push({
            name: 'player',
            params: {
              type: 'serie',
              index: this.index,
              id: this.articleID,
              season: this.season,
            }
          });

          setTimeout(() => {
            location.reload();
          }, 100);
        });
    },
    async nextEpisode() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/get/episodes/' + this.articleID, requestOptions)
        .then((res) => {
          console.log("Réponse de la requête:", res);
          return res.json();
        })
        .then(data => {
          if (data.msg) {
            console.log("Message d'erreur:", data.msg);
            return console.log(data.msg);
          }
          
          const allEpisodes = data;
          let currentSeason = parseInt(this.season);
          let currentEpisodeNumber = parseInt(this.index) + 1;
          let nextEpisodeNumber = currentEpisodeNumber + 1;

          let nextEpisode = allEpisodes.find(episode => episode.season == currentSeason && episode.episode_number == nextEpisodeNumber);

          if (!nextEpisode) {
            console.log("Aucun épisode suivant dans la saison actuelle.");
            // Si aucun épisode suivant dans la saison actuelle, vérifier la saison suivante
            const nextSeason = currentSeason + 1;
            const firstEpisodeNextSeason = allEpisodes.find(episode => episode.season == nextSeason && episode.episode_number == 1);

            if (!firstEpisodeNextSeason) {
              // Aucun épisode dans la saison suivante
              console.log("Aucun épisode dans la saison suivante.");
              return this.showNotification('warning', 'Il n\'y a pas d\'épisode suivant');
            } else {
              // Rediriger vers le premier épisode de la saison suivante
              currentSeason = nextSeason;
              nextEpisodeNumber = 1;
            }
          }

          // Mise à jour des paramètres pour l'épisode suivant ou le premier épisode de la saison suivante
          this.season = currentSeason.toString();
          this.index = nextEpisodeNumber - 1; // Assurez-vous que l'index est une chaîne de caractères

          // Redirection vers l'épisode suivant
          this.$router.push({
            name: 'player',
            params: {
              type: 'serie',
              index: this.index,
              id: this.articleID,
              season: this.season,
            }
          });

          setTimeout(() => {
            location.reload();
          }, 100);
        });
    },
    async createVideoPlayer() {
      const watchtime = await this.getWatchtime();
      this.$nextTick(() => {
        // Options de configuration du lecteur Video.js
        const playerOptions = {
          controls: true,
          autoplay: this.user_preferences.autoplay ? true : false,
          preload: 'auto',
          fluid: true,
          responsive: true,
          aspectRatio: '16:9',
          poster: this.posterPlayer,
          loop: false,
          muted: false,
          playbackRates: [0.25, 0.5, 1, 1.5, 2],
          controlBar: {
            skipButtons: {
              forward: 5
            }
          },
          html5: {
            nativeVideoTracks: false,
            nativeAudioTracks: false,
            nativeTextTracks: false
          },
          notSupportedMessage: 'Désolé c\'est cassé.. Bisous.',
          language: 'fr',
          controlBar: {
            volumePanel: {
              inline: true,
              alwaysShowVolumeHandle: true,
            },
            remainingTimeDisplay: {
              displayNegative: false,
            },
            audioTrackButton: {
              // Affiche le bouton de sélection de la piste audio
              showInvertible: true
            },
            skipButtons: {
              forward: this.user_preferences.fast_forward_duration ? this.user_preferences.fast_forward_duration : 5,
              back: this.user_preferences.rewind_duration ? this.user_preferences.rewind_duration : 5,
            },
          },
          userActions: {
            hotkeys: function (event) {
              // `m` to mute the video
              if (event.which === 77) {
                if (this.muted()) {
                  this.muted(false);
                } else {
                  this.muted(true);
                }
              }

              // `f` to toggle fullscreen
              if (event.which === 70) {
                if (this.isFullscreen()) {
                  this.exitFullscreen();
                } else {
                  this.requestFullscreen();
                }
              }

              // right arrow to skip forward 5 seconds
              if (event.which === 39) {
                this.currentTime(this.currentTime() + (this.user_preferences && this.user_preferences.fast_forward_duration ? this.user_preferences.fast_forward_duration : 5));
              }

              // left arrow to skip back 5 seconds
              if (event.which === 37) {
                this.currentTime(this.currentTime() - (this.user_preferences && this.user_preferences.rewind_duration ? this.user_preferences.rewind_duration : 5));
              }

              // up arrow to increase volume 5%
              if (event.which === 38) {
                this.volume(this.volume() + 0.05);
              }

              // down arrow to decrease volume 5%
              if (event.which === 40) {
                this.volume(this.volume() - 0.05);
              }

              // `p` to pause the video or play the video if it's paused
              if (event.which === 80) {
                if (this.paused()) {
                  this.play();
                } else {
                  this.pause();
                }
              }

              // `space` to pause the video or play the video if it's paused
              if (event.which === 32) {
                if (this.paused()) {
                  this.play();
                } else {
                  this.pause();
                }
              }
            }
          }
        };

        this.player = videojs(this.$refs.videoPlayer, playerOptions, () => {
          this.player.on('canplay', async () => {
            if (!this.canplayFired) {
              const watchtimeOffset = this.user_preferences && this.user_preferences.watchtime_offset ? this.user_preferences.watchtime_offset : 0;
              if (!watchtime || watchtime == 0) return;
              if (isFinite(watchtime) && isFinite(watchtimeOffset)) {
                const currentTime = watchtime > 0 ? watchtime - watchtimeOffset : watchtime;
                this.player.currentTime(currentTime);
              }
              //Si y a un watchtime qui correspond à la durée de la vidéo, on supprime le watchtime et on met la vidéo à 0
              if (watchtime == this.player.duration()) {
                this.deleteWatchtime();
                this.player.currentTime(0);
              }
              this.canplayFired = true;
            }
          });
        });

        // Événement appelé à chaque changement de temps dans la vidéo
        this.player.on('timeupdate', () => {
          // Update the watchtime every 10 seconds to avoid too many requests
          if (this.player.currentTime() % 10 === 0) {
            this.updateWatchTime();
          }

        if(this.article.intro_start && this.article.intro_end) {

          // Si l'utilisateur a déjà passé l'intro, on définie la variable isAlreadySkiped à true pour que l'utilisateur puisse revenir en arrière même dans l'intro
          if(this.player.currentTime() >= this.article.intro_end) {
            this.isAlreadySkiped = true;
          }

          // Si l'utilisateur est sur PC et que l'intro est en cours, on affiche le bouton pour skip l'intro
          if(this.player.currentTime() >= this.article.intro_start && this.player.currentTime() < this.article.intro_end && this.isOnPC && this.user_preferences.skip_serie_intro_credits == false && this.isAlreadySkiped == false) {
            this.showSkipIntro = true;
          }

          // Si l'utilisateur est sur mobile ou PC et qu'il a activé le skip automatique de l'intro, on skip l'intro
          if(this.player.currentTime() >= this.article.intro_start && this.player.currentTime() <= this.article.intro_end && this.isAlreadySkiped == false && this.user_preferences.skip_serie_intro_credits) {
            this.isAlreadySkiped = true;
            this.player.currentTime(this.article.intro_end);
          }
        }

        // Si l'utilisateur arrive à la fin de l'épisode et qu'il a activé le skip des crédits, on skip les crédits
        if(this.article.credits_start && this.player.currentTime() >= this.article.credits_start && this.user_preferences.skip_serie_intro_credits == true) {
          // Envoyer à la fin de l'épisode
          this.player.currentTime(this.player.duration());
        }
        });

        this.player.on('play', () => {
          if (this.startTime > 0) {
            this.showRestartButton = true;
            setTimeout(() => {
              this.showRestartButton = false;
            }, 10000);
          }
        });

        // Événement appelé à chaque changement de temps dans la vidéo
        this.player.on('timeupdate', () => {
          if (this.article && this.article.movie_credits && this.article.movie_credits != undefined && this.player.currentTime() >= this.article.movie_credits) {
            if (this.isWatchtimeDeleted) return;
            this.deleteWatchtime();
            this.isWatchtimeDeleted = true;
            if (this.user_preferences && this.user_preferences.fullscreen_auto) {
              this.player.exitFullscreen(); // Minimize the fullscreen instead of requesting it
            }
          }
        });

        // Événement appelé lorsque la vidéo est terminée
        this.player.on('ended', () => {
          if(this.type == 'movie') this.deleteWatchtime();
          if(this.type == 'serie' && this.user_preferences.autoplay && this.nextEpisodeAsked == false) {
            this.nextEpisode();
            this.nextEpisodeAsked = true;
          }
        });

        this.player.on('error', () => {
          if (this.player) {
            this.addWatchtime();
          }
        });

        // Événement appelé lorsque la vidéo est en pause
        this.player.on('pause', this.videoPaused);

        this.player.on('loadeddata', () => {
          
        });

        this.player.on('seeked', () => {
          if (this.player) {
            this.addWatchtime();
          }
        });

        this.player.on('volumechange', () => {
          if (this.player) {
            //localStorage.setItem('volume', this.player.volume());
          }
        });

        window.scrollTo(0, 0);
        if (this.user_preferences && this.user_preferences.volume) this.player.volume(this.user_preferences.volume);
        if (this.user_preferences && this.user_preferences.playback_speed) this.player.playbackRate(this.user_preferences.playback_speed);
        if (this.user_preferences && this.user_preferences.fullscreen_auto) this.player.requestFullscreen();
      });
    },
    updateWatchTime() {
      // Mettre à jour le watchtime à chaque événement timeupdate
      this.watchTime = this.player.currentTime();
    },
    videoEnded() {
      console.log('La vidéo est terminée. Watchtime final:', this.watchTime);
    },
    videoPaused() {
      this.addWatchtime();
    },
  },
  created: function () {
    this.getUserPreferences();
    if (this.type == 'movie') return this.getMovieFromID();
    if (this.type == 'serie') return this.getSerieByID();
  },
  mounted: function () {
    document.body.style.overflowY = "auto";
    this.admin();
  },
  beforeDestroy() {
    // Détruisez le lecteur vidéo avant de quitter le composant pour éviter des problèmes de fuite de mémoire
    if (this.player) {
      this.addWatchtime();
      this.player.dispose();
    }
  },
}
</script>

<style lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;
$soft-grey: #2D2E32;

* {
  padding: 0;
  margin: 0;
}

.video-js .vjs-menu-button-inline.vjs-slider-active,
.video-js .vjs-menu-button-inline:focus,
.video-js .vjs-menu-button-inline:hover,
.video-js.vjs-no-flex .vjs-menu-button-inline {
  width: 10em
}

.video-js .vjs-controls-disabled .vjs-big-play-button {
  display: none !important
}

.video-js .vjs-control {
  width: 3em
}

.video-js .vjs-menu-button-inline:before {
  width: 1.5em
}

.vjs-menu-button-inline .vjs-menu {
  left: 3em
}

.video-js.vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started.video-js .vjs-big-play-button {
  display: block;
  cursor: pointer;
}

.video-js .vjs-load-progress div,
.vjs-seeking .vjs-big-play-button,
.vjs-waiting .vjs-big-play-button {
  display: none !important
}

.video-js .vjs-mouse-display:after,
.video-js .vjs-play-progress:after {
  padding: 0 .4em .3em
}

.video-js.vjs-ended .vjs-loading-spinner {
  display: none
}

.video-js.vjs-ended .vjs-big-play-button {
  display: block !important
}

.video-js *,
.video-js:after,
.video-js:before {
  box-sizing: inherit;
  font-size: inherit;
  color: inherit;
  line-height: inherit
}

.video-js.vjs-fullscreen,
.video-js.vjs-fullscreen .vjs-tech {
  width: 100% !important;
  height: 100% !important
}

.vjs-tech {
  width: 100% !important;
  height: 100% !important
}

.video-js {
  font-size: 14px;
  overflow: hidden
}

.video-js .vjs-control {
  color: inherit
}

.video-js .vjs-menu-button-inline:hover,
.video-js.vjs-no-flex .vjs-menu-button-inline {
  width: 8.35em
}

.video-js .vjs-volume-menu-button.vjs-volume-menu-button-horizontal:hover .vjs-menu .vjs-menu-content {
  height: 3em;
  width: 6.35em
}

.video-js .vjs-control:focus:before,
.video-js .vjs-control:hover:before {
  text-shadow: 0 0 1em #fff, 0 0 1em #fff, 0 0 1em #fff
}

.video-js .vjs-spacer,
.video-js .vjs-time-control {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

.video-js .vjs-time-control {
  -webkit-box-flex: 0 1 auto;
  -moz-box-flex: 0 1 auto;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: auto
}

.video-js .vjs-time-control.vjs-time-divider {
  width: 14px
}

.video-js .vjs-time-control.vjs-time-divider div {
  width: 100%;
  text-align: center
}

.video-js .vjs-time-control.vjs-current-time {
  margin-left: 1em
}

.video-js .vjs-time-control .vjs-current-time-display,
.video-js .vjs-time-control .vjs-duration-display {
  width: 100%
}

.video-js .vjs-time-control .vjs-current-time-display {
  text-align: right
}

.video-js .vjs-time-control .vjs-duration-display {
  text-align: left
}

.video-js .vjs-play-progress:before,
.video-js .vjs-progress-control .vjs-play-progress:before,
.video-js .vjs-remaining-time,
.video-js .vjs-volume-level:after,
.video-js .vjs-volume-level:before,
.video-js.vjs-live .vjs-time-control.vjs-current-time,
.video-js.vjs-live .vjs-time-control.vjs-duration,
.video-js.vjs-live .vjs-time-control.vjs-time-divider,
.video-js.vjs-no-flex .vjs-time-control.vjs-remaining-time {
  display: none
}

.video-js.vjs-no-flex .vjs-time-control {
  display: table-cell;
  width: 4em
}

.video-js .vjs-progress-control {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: .5em;
  top: -.5em
}

.video-js .vjs-progress-control .vjs-load-progress,
.video-js .vjs-progress-control .vjs-play-progress,
.video-js .vjs-progress-control .vjs-progress-holder {
  height: 100%
}

.video-js .vjs-progress-control .vjs-progress-holder {
  margin: 0
}

.video-js .vjs-progress-control:hover {
  height: 10px;
}

.video-js .vjs-control-bar {
  -webkit-transition: -webkit-transform .1s ease 0s;
  -moz-transition: -moz-transform .1s ease 0s;
  -ms-transition: -ms-transform .1s ease 0s;
  -o-transition: -o-transform .1s ease 0s;
  transition: transform .1s ease 0s
}

.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-control-bar,
.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-control-bar,
.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-control-bar,
.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar,
.video-js.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-control-bar {
  visibility: visible;
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateY(3em);
  -moz-transform: translateY(3em);
  -ms-transform: translateY(3em);
  -o-transform: translateY(3em);
  transform: translateY(3em);
  -webkit-transition: -webkit-transform 1s ease 0s;
  -moz-transition: -moz-transform 1s ease 0s;
  -ms-transition: -ms-transform 1s ease 0s;
  -o-transition: -o-transform 1s ease 0s;
  transition: transform 1s ease 0s
}

.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active .vjs-progress-control,
.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive .vjs-progress-control,
.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active .vjs-progress-control,
.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control,
.video-js.vjs-has-started.vjs-playing.vjs-user-inactive .vjs-progress-control {
  height: .25em;
  top: -.25em;
  pointer-events: none;
  -webkit-transition: height 1s, top 1s;
  -moz-transition: height 1s, top 1s;
  -ms-transition: height 1s, top 1s;
  -o-transition: height 1s, top 1s;
  transition: height 1s, top 1s
}

.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-active.vjs-fullscreen .vjs-progress-control,
.video-js.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive.vjs-fullscreen .vjs-progress-control,
.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-active.vjs-fullscreen .vjs-progress-control,
.video-js.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control,
.video-js.vjs-has-started.vjs-playing.vjs-user-inactive.vjs-fullscreen .vjs-progress-control {
  opacity: 0;
  -webkit-transition: opacity 1s ease 1s;
  -moz-transition: opacity 1s ease 1s;
  -ms-transition: opacity 1s ease 1s;
  -o-transition: opacity 1s ease 1s;
  transition: opacity 1s ease 1s
}

.video-js.vjs-live .vjs-live-control {
  margin-left: 1em
}

.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-left: -1em;
  width: 2em;
  border: none;
  color: #fff;
  -webkit-transition: border-color .4s, outline .4s, background-color .4s;
  -moz-transition: border-color .4s, outline .4s, background-color .4s;
  -ms-transition: border-color .4s, outline .4s, background-color .4s;
  -o-transition: border-color .4s, outline .4s, background-color .4s;
  transition: border-color .4s, outline .4s, background-color .4s;
  background-color: rgba(0, 0, 0, .5);
  font-size: 2em;
  border-radius: 20%;
  height: 1.4em !important;
  line-height: 1.4em !important;
  margin-top: -.7em !important
}

.video-js .vjs-menu-button-popup .vjs-menu {
  left: -3em
}

.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  background-color: transparent;
  width: 12em;
  left: -1.5em;
  padding-bottom: .5em
}

.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item,
.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-title {
  background-color: rgba(0, 0, 0, .7) !important;
  margin: .3em 0;
  padding: .2em;
  border-radius: .3em
}

.video-js .vjs-menu-button-popup .vjs-menu .vjs-menu-item.vjs-selected {
  background-color: #ff003c !important;
  color: white;
  font-size: 1.05rem;
}

.video-js .vjs-big-play-button:active,
.video-js .vjs-big-play-button:focus,
.video-js:hover .vjs-big-play-button {
  background-color: #ff003c !important;
}

.video-js .vjs-loading-spinner {
  border-color: #ff003c !important;
}

.video-js .vjs-control-bar2 {
  background-color: #000;
}

.video-js .vjs-control-bar {
  background-color: rgba(0, 0, 0, .7) !important;
  color: #fff;
  font-size: 12px
}

.video-js .vjs-play-progress,
.video-js .vjs-volume-level {
  background-color: #ff003c;
  border-radius: 0 5px 5px 0;
}

.video-js .vjs-load-progress {
  background: rgba(255, 255, 255, .3)
}

@keyframes appear-slide {
  from {
    transform: translateX(100%);
    opacity: 0.5;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.homePage {
  width: 100%;
  min-height: 100vh;
  background: $dark-color;
  padding-bottom: 50px;

  .playerHeaven {
    width: 70%;
    margin: 50px auto 0 auto;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    position: relative;

    video {
      height: auto;
      margin: 0 auto;
    }

    #skipIntroButton {
      position: absolute;
      right: 0px;
      bottom: 50px;
      padding: 10px 20px;
      background-color: rgba(0, 0, 0, 0.5);
      color: white;
      border: none;
      cursor: pointer;
      font-size: 13px;
      z-index: 10;
      padding-right: 20px;
      border-radius: 5px 0 0 5px;
    } 

    .playerActions {
      position: absolute;
      bottom: 10%;
      right: 0;
      transform: translateY(-50%);

      .restart {
        width: 100%;

        button {
          padding: 15px 30px 15px 20px;
          border: none;
          border-radius: 5px 0 0 5px;
          background: rgba(0, 0, 0, 0.7);
          color: white;
          font-size: 15px;
          font-family: 'Montserrat';
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          transition: 0.3s;
          animation: appear-slide 1s forwards;

          &:hover {
            background: $dark-red;
            transition: 0.3s;
          }

        }
      }
    }

  }

  .darkiContainer {
    max-width: 60%;
    margin: 20px auto 0 auto;

    .switchMovies {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding-bottom: 20px;

      button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background: $dark-color;
        color: white;
        font-size: 15px;
        font-family: 'Montserrat';
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        transition: 0.3s;

        &:hover {
          background: $dark-red;
          transition: 0.3s;
        }
      }
    }
  }

  .warning {
    max-width: 100%;
    height: auto;
    background: #ff003c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;

    p {
      color: white;
      font-size: 16px;
      font-family: 'Montserrat';
      display: flex;
      justify-content: start;
      align-items: center;
    }

    i {
      color: white;
      font-size: 20px;
      font-family: 'Montserrat';
      cursor: pointer;
    }
  }

  .availableContainer {
    width: 70%;
    margin: 30px auto 0 auto;
    height: auto;
    background: $soft-grey;
    padding: 15px 15px;
    border-radius: 10px;

    .availableWrapper {
      width: 100%;
      height: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      align-items: center;
      column-count: 2;

      .availableCard {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 5px;
        gap: 10px;
        min-width: 48%;

        .availableTitle {
          display: flex;
          gap: 10px;
        }

        a {
          padding: 5px 10px;
          border-radius: 25px;
          border: 2px solid $dark-color;
          color: white;
          font-size: 15px;
          font-family: 'Montserrat';
          cursor: pointer;
          text-decoration: none;
          transition: 0.3s;

          &:hover {
            background: $dark-red;
            border: 2px solid $dark-red;
            transition: 0.3s;
          }
        }

        p {
          color: white;
          font-size: 15px;
          font-family: 'Montserrat';
          font-weight: bold;
        }

        span {
          color: white;
          font-size: 15px;
          font-family: 'Montserrat';
        }
      }
    }
  }

  .optionsContainer {
    width: 70%;
    margin: 30px auto 0 auto;
    height: auto;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    button {
      margin: 10px 0 0 0;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      background: $dark-color;
      color: white;
      font-size: 15px;
      font-family: 'Montserrat';
      cursor: pointer;

      i {
        margin-right: 5px;
      }
    }
  }

  .annonceContainer {
    width: 70%;
    margin: 30px auto 0 auto;
    height: auto;

    iframe {
      margin-top: 20px;
      width: 100%;
      height: 600px;
      border-radius: 10px;
    }
  }

  .warningContainer {
    width: 70%;
    margin: 0px auto 0 auto;

    .warn {
      border: 1px solid $dark-red;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: white;
      background: #ff003c65;
      font-size: 15px;
      font-family: 'Montserrat';
    }
  }

  .interactionsContainer {
    width: 70%;
    margin: 30px auto 0 auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .interactions {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      position: relative;

      .popper {
        min-width: 200px;
        position: absolute;
        background: $dark-color;
        border-radius: 10px;
        padding: 10px 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        color: white;
        top: 0;
        transform: translateY(-100%);

        h3 {
          font-size: 18px;
          font-family: 'Montserrat';
          font-weight: bold;
          color: rgba(255, 255, 255, 0.842);
          padding-bottom: 10px;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            font-size: 15px;
            font-family: 'Montserrat';
            font-weight: bold;
            color: white;
            padding: 5px 0;
            display: flex;
            align-items: center;
            gap: 10px;

            .control {
              display: flex;
              justify-content: center;
              align-items: center;
              min-width: 10px;
              height: 20px;
              padding: 0 5px;
              border-radius: 5px;
              border: 2px solid rgba(255, 255, 255, 0.644);
              color: rgba(255, 255, 255, 0.644);
            }
          }
        }
      }

      button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background: $back-color;
        color: white;
        font-size: 15px;
        font-family: 'Montserrat';
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }
    }
  }

  .help {
    max-width: 100%;
    height: auto;
    padding: 15px 15px;
    border-radius: 10px;
    background: $back-color;

    .helpButtons {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .download {
        margin: 10px 0 0 0;
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background: $dark-color;
        color: white;
        font-size: 15px;
        font-family: 'Montserrat';
        font-weight: normal;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          background: $dark-red;
          transition: 0.3s;
        }
      }

      button {
        margin: 10px 0 0 0;
        padding: 5px 10px;
        border: none;
        border-radius: 5px;
        background: $dark-color;
        color: white;
        font-size: 15px;
        font-family: 'Montserrat';
        cursor: pointer;
        transition: 0.3s;

        &:last-child {
          background: rgb(2, 94, 2);
        }

        &:hover {
          background: $dark-red;
          transition: 0.3s;
        }
      }
    }

    p {
      color: white;
      font-size: 18px;
      font-family: 'Montserrat';

      i {
        font-size: 16px;
        font-family: 'Montserrat';
        font-weight: bold;
      }
    }

    a {
      font-weight: bold;
      color: $dark-red;
      text-decoration: none;
    }

    ul {
      list-style: none;
      margin: 5px 0 0 5px;

      li {
        color: white;
        font-size: 15px;
        font-family: 'Montserrat';

        b {
          font-size: 18px;
        }
      }
    }
  }

  .movieInfosContainer {
    width: 70%;
    max-width: 70%;
    background: $back-color;
    border-radius: 10px;
    margin: 10px auto 35px auto;

    .bandeAnnonce {
      width: 95%;
      margin: 10px auto;
      padding-top: 35px;

      iframe {
        width: 100%;
        aspect-ratio: 3/2;
        border-radius: 10px;
      }
    }

    .movieInfos {
      width: 90%;
      max-width: 90%;
      height: auto;
      padding: 10px 20px;
      gap: 5px;
      display: flex;
      flex-direction: column;

      a {
        width: fit-content;
      }

      p {
        margin: 0;
        font-family: 'Montserrat';
        font-display: swap;
        font-size: 16px;
        font-weight: bold;
        color: $light-color;

        span {
          font-weight: normal;
          color: rgba(240, 240, 240, 0.863);
        }
      }
    }

    .rating {
      width: 100%;
      height: auto;
      margin: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 10px;
      padding-bottom: 30px;

      .ratingCard {
        width: fit-content;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 33%;
        color: $light-color;

        div {
          img {
            width: 50px;
            height: 50px;
            object-fit: cover;
          }
        }

        p {
          padding-bottom: 3px;
          font-family: 'Montserrat';
          font-display: swap;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
        }

        span {
          font-family: 'Montserrat';
          font-display: swap;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          color: rgba(240, 240, 240, 0.774);

        }
      }
    }
  }

  .reportModalContainer {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);

    .reportModal {
      width: fit-content;
      height: auto;
      background: $back-color;
      border-radius: 10px;
      padding: 50px 30px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: relative;

      .reportClose {
        position: absolute;
        top: 20px;
        right: 20px;
        color: white;
        font-size: 24px;
        cursor: pointer;
      }

      p {
        color: white;
        font-size: 20px;
        font-family: 'Montserrat';
        text-align: center;
      }

      label {
        color: white;
        font-size: 20px;
        font-family: 'Montserrat';
        text-align: left;
      }

      select {
        padding: 10px 10px 10px 5px;
        border: none;
        border-radius: 5px;
        background: $dark-color;
        color: white;
        font-size: 15px;
        font-family: 'Montserrat';
        cursor: pointer;
      }

      button {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        background: $dark-color;
        color: white;
        font-size: 15px;
        font-family: 'Montserrat';
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          background: $dark-red;
          transition: 0.3s;
        }
      }
    }
  }

  .noResult {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 100px;
      height: 100px;
    }

    p {
      color: white;
      font-size: 20px;
      font-family: 'Montserrat';
    }
  }

  .upperPlayer {
    width: 70%;
    margin: 10px auto 20px auto;

    h1 {
      color: rgba(255, 255, 255, 0.911);
      font-size: 24px;
      font-family: 'Montserrat';
      margin: 15px 0 5px 0;
    }

    .movieName {
      color: rgba(255, 255, 255, 0.911);
      font-size: 24px;
      font-family: 'Montserrat';
      margin: 5px 0;
    }

    .episodeName {
      color: rgba(255, 255, 255, 0.911);
      font-size: 20px;
      font-family: 'Montserrat';
      margin: 5px 0;
    }

    p {
      color: rgba(255, 255, 255, 0.788);
      font-size: 16px;
      font-family: 'Montserrat';
      margin: 5px 0;
    }

    .qualityContainer {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 10px;
      margin: 5px 0 0 0;

      span {
        color: white;
        font-size: 16px;
        font-family: 'Montserrat';
        background: $dark-red;
        padding: 5px 10px;
        border-radius: 10px;

      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .homePage {
    .playerHeaven {
      width: 85%;
    }

    .upperPlayer {
      width: 85%;
    }

    .darkiContainer {
      max-width: 80%;
      margin: 20px auto 0 auto;
    }

    .interactionsContainer {
      width: 85%;
      margin: 0;

      .interactions {
        flex-wrap: wrap;
      }
    }

    .warningContainer {
      width: 85%;
      margin: 0 auto 20px auto;

      .warn {
        flex-wrap: wrap;
      }
    }

    .optionsContainer {
      width: 85%;
    }

    .availableContainer {
      width: 80%;

      .availableWrapper {
        column-count: 1;

        .availableCard {
          min-width: 100%;
        }
      }
    }

    .annonceContainer {
      width: 85%;

      iframe {
        width: 100%;
        height: 400px;
      }
    }

    .help {
      max-width: 80%;

      p {
        font-size: 16px;

        a {
          font-size: 18px;
        }
      }
    }

    .movieInfosContainer {
      width: 85%;
      max-width: 85%;

      .rating {
        flex-wrap: wrap;
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .homePage {
    .darkiContainer {
      max-width: 90%;
      margin: 50px auto 0 auto;
    }

    .playerHeaven {
      width: 90%;
    }

    .interactionsContainer {
      width: 90%;
      margin: 0;

      .interactions {
        flex-wrap: wrap;
      }
    }

    .warningContainer {
      width: 90%;
      margin: 0 auto 20px auto;

      .warn {
        flex-wrap: wrap;
      }
    }

    .help {
      max-width: 90%;
    }

    .movieInfosContainer {
      width: 90%;
      max-width: 90%;

      .rating {
        flex-wrap: wrap;
      }
    }

    .upperPlayer {
      width: 90%;
    }
  }
}

@media screen and (max-width: 426px) {
  .homePage {
    .darkiContainer {
      max-width: 90%;
      margin: 20px auto 0 auto;
    }

    .qualityContainer {
      flex-wrap: wrap;
    }

    .playerHeaven {
      width: 95%;
    }

    .interactionsContainer {
      width: 95%;
      margin: 0;

      .interactions {
        flex-wrap: wrap;
      }
    }

    .warningContainer {
      width: 95%;
      margin: 0 auto 20px auto;

      .warn {
        flex-wrap: wrap;
      }
    }

    .optionsContainer {
      width: 90%;
    }

    .availableContainer {
      width: 85%;

      .availableWrapper {
        column-count: 1;

        .availableCard {
          min-width: 100%;
        }
      }
    }

    .annonceContainer {
      width: 90%;

      iframe {
        width: 100%;
        height: 300px;
      }
    }

    .help {
      max-width: 88%;

      .helpButtons {
        gap: 0;
      }

      p {
        font-size: 16px;

        a {
          font-size: 18px;
        }
      }
    }

    .upperPlayer {
      width: 90%;
    }
  }
}
</style>
