<template>
  <div class="mainContainer">
    <NavbarTop />
    <AddMenuNavigation />
    <!--Formulaire d'ajout d'une série-->
    <div class="form" v-if="indexSelection == 1">
      <div class="entry">
        <label for="posterURL">Affiche </label>
        <input id="posterURL" name="posterURL" type="text" placeholder="http://www.pornhub.com/galery" />
      </div>
      <div class="entry">
        <label for="background">Background </label>
        <input id="background" name="background" type="text" placeholder="http://www.pornhub.com/galery" />
      </div>
      <div class="entry">
        <label for="imdb">IMDB ID </label>
        <input id="imdb" name="imdb" type="text" :placeholder="'Entrer un ID ou le lien de la page'" />
        <div v-if="name != undefined">
          <a :href="`http://www.imdb.com/find?s=all&q=${encodeURIComponent(name)}`" target="_blank">IMBD
            page</a>
        </div>
      </div>
      <div class="entry">
        <label for="name">Titre </label>
        <input id="name" name="name" type="text" placeholder="Titre" @change="getImdbPage()" />
      </div>
      <div class="entry">
        <label for="description">Description </label>
        <input id="description" name="question" type="text" placeholder="Description" />
      </div>
      <div class="entry">
        <label for="annee">Année de sortie </label>
        <input id="annee" name="annee" type="number" placeholder="Année de sortie" />
      </div>
      <div class="entry">
        <label for="anneedefin">Année de fin</label>
        <input id="anneedefin" name="anneedefin" type="number" placeholder="2023 si pas encore terminée" />
      </div>
      <div class="entry">
        <label for="category1">Catégories</label>
        <select name="category1" id="categories1">
          <option v-bind:key="element.id" v-for="element in categories" :value="element.name">{{ element.name }}
          </option>
        </select>
      </div>
      <div class="entry">
        <select name="category2" id="categories2">
          <option v-bind:key="element.id" v-for="element in categories" :value="element.name">{{ element.name }}
          </option>
        </select>
      </div>
      <div class="entry">
        <button :onclick="() => addSerie()">Ajouter cette série</button>
      </div>
    </div>
  </div>
</template>

<script>
// import the library
import { createToast } from 'mosha-vue-toastify'
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'
import NavbarTop from '../components/NavbarTop.vue'
import AddMenuNavigation from './AddMenuNavigation.vue'
import io from "socket.io-client"

export default {
  name: 'AddMovie',
  components: {
    NavbarTop, AddMenuNavigation
  },
  data() {
    return {
      link: "https://api.heavenflix.fr",
      categories: undefined,
      collections: undefined,
      indexSelection: 1,
      episodes: 1,
      showCollections: true,
      isLoading: false,
      fileName: undefined,
      loadingMessage: 'Envoi du fichier..',
      step: 0,
      name: undefined,
    }
  },
  setup() {
    const toast = () => {
      createToast('Wow, easy')
    }
    return { toast }
  },
  methods: {
    getImdbPage: async function getImdbPage() {
      var name = document.getElementById('name').value;
      if (!name) return this.name = undefined;
      this.name = name;
    },
    displayFileName: function displayFileName() {
      var fileInput = document.getElementById('file');
      if (!fileInput.files[0]) return this.fileName = undefined;
      this.fileName = fileInput.files[0].name;
    },
    getEbooksCategories: async function getEbooksCategories() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + "/getEbooksCategories", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.msg) return console.log(data.msg);
          this.ebooksCategories = undefined;
          this.ebooksCategories = data;
        });
    },
    toggleShowCollections() {
      this.showCollections = !this.showCollections;
    },
    showNotification(type, text) {
      createToast(text, {
        position: 'top-center',
        type: type,
        transition: 'zoom',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: 3000,
        //toastBackgroundColor: '#2D2E32',
      })
    },
    showNotificationWarning(text) {
      createToast(text, {
        position: 'top-center',
        type: 'warning',
        transition: 'zoom',
        showIcon: 'false',
        hideProgressBar: 'false',
        swipeClose: 'true',
        showCloseButton: 'false',
        timeout: 3000,
        //toastBackgroundColor: '#2D2E32',
      })
    },
    getCollections: async function getCollections() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getCollections', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.collections = data;
      })
    },
    adjustEpisodes: async function adjustEpisodes() {
      const inputElement = document.getElementById('totalEpisodes');
      if (inputElement.value <= 1) return this.episodes = 1;
      this.episodes = parseInt(inputElement.value);
    },
    oneEpisodeMore: async function oneEpisodeMore() {
      this.episodes++;
    },
    oneEpisodeLess: async function oneEpisodeLess() {
      if (this.episodes > 1) return this.episodes--;
    },
    switchSelection: async function switchSelection(index) {
      this.indexSelection = index;
      if (index == 1) {
        let button1 = document.getElementById('button-1');
        let button2 = document.getElementById('button-2');
        let button3 = document.getElementById('button-3');
        button1.style.background = 'rgba(0,0,0,0.5)';
        button2.style.background = 'rgba(255,255,255,0.2)';
        button3.style.background = 'rgba(255,255,255,0.2)';
      } else if (index == 2) {
        let button1 = document.getElementById('button-1');
        let button2 = document.getElementById('button-2');
        let button3 = document.getElementById('button-3');
        button1.style.background = 'rgba(255,255,255,0.2)';
        button2.style.background = 'rgba(0,0,0,0.5)';
        button3.style.background = 'rgba(255,255,255,0.2)';
      } else {
        let button1 = document.getElementById('button-1');
        let button2 = document.getElementById('button-2');
        let button3 = document.getElementById('button-3');
        button1.style.background = 'rgba(255,255,255,0.2)';
        button2.style.background = 'rgba(255,255,255,0.2)';
        button3.style.background = 'rgba(0,0,0,0.5)';
      }
    },
    admin: async function admin() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      };
      await fetch(this.link + '/getAdmin/' + localStorage.getItem('userId'), requestOptions)
        .then((res) => {
          if (res.status == 401) {
            this.$router.push("/login");
          }
          return res.json()
        })
        .then(data => {
          if (data.msg) return console.log(data.msg);
          if (data[0].isAdmin == 0) return this.$router.push('/home');
        })
    },
    getCategories: async function getCategories() {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      await fetch(this.link + '/getCategories', requestOptions).then((res) => res.json()).then(data => {
        if (data.msg) return console.log(data.msg);
        this.categories = data;
      })
    },
    addSerie: async function addSerie() {
      var posterURL = document.getElementById('posterURL').value;
      var name = document.getElementById('name').value;
      var description = document.getElementById('description').value;
      var background = document.getElementById('background').value;
      var annee = document.getElementById('annee').value;
      var anneedefin = document.getElementById('anneedefin').value;
      var categories1 = document.getElementById("categories1").value;
      var categories2 = document.getElementById("categories2").value;
      var imdb = document.getElementById("imdb").value;
      if (!imdb) return this.showNotificationWarning('ID IMDB manquant!');
      if (!posterURL) return this.showNotificationWarning('Lien de l\'affiche de la série manquant!');
      if (!background) return this.showNotificationWarning('Lien de l\'image de fond manquant!');
      if (!name) return this.showNotificationWarning('Titre de la série manquant!');
      if (!description) return this.showNotificationWarning('Description de la série manquante!');
      if (!anneedefin) return this.showNotificationWarning("Liens d'épisodes manquants!");
      if (!annee) return this.showNotificationWarning('Année de sortie manquante!');
      if (categories1 === categories2) return this.showNotificationWarning('Catégorie dupliquée ou non renseignée!');
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: name,
          description: description,
          anneedesortie: annee,
          anneedefin: anneedefin,
          posterURL: posterURL,
          backgroundURL: background,
          category1: document.getElementById("categories1").value,
          category2: document.getElementById("categories2").value,
          imdb_id: imdb,
        })
      };
      console.log(requestOptions.body)
      await fetch(this.link + '/addSerie', requestOptions).then((res) => res.json()).then(data => {
        if (data.error != null) {
          console.log(data.error);
        } else {
          this.showNotification('success', 'Série ajoutée');
        }
      });
    },
    toggleShowLoading() {
      this.isLoading = !this.isLoading;
    },
    addEbook: async function addEbook() {
      var type = document.getElementById('type').value;
      var coverURL = document.getElementById('coverURL').value;
      var name = document.getElementById('name').value;
      var description = document.getElementById('description').value;
      var auteur = document.getElementById('auteur').value;
      var tome = document.getElementById('tome').value;
      var fileInput = document.getElementById('file');
      var annee = document.getElementById('annee').value;
      var categories1 = document.getElementById("categories1").value;
      var categories2 = document.getElementById("categories2").value;
      if (!type && type == 'Choisissez un type') return this.showNotificationWarning('Le type n\'est pas renseigné!');
      if (!coverURL) return this.showNotificationWarning('Le lien de la couverture n\'est pas renseigné!');
      if (!name) return this.showNotificationWarning('Titre manquant!');
      if (!description) return this.showNotificationWarning('Description manquante!');
      if (!auteur) return this.showNotificationWarning('Auteur manquant!');
      if (!tome) return this.showNotificationWarning('Numéro de tome manquant!');
      if (!fileInput.files[0]) return this.showNotificationWarning('Fichier .cbz à upload manquant!');
      if (!annee) return this.showNotificationWarning('Année de sortie du film manquante!');
      if (!categories1 || !categories2) return this.showNotificationWarning('Catégorie non renseignée!');
      // Créez un nouvel objet FormData
      const formData = new FormData();
      formData.append('type', type)
      formData.append('coverURL', coverURL);
      formData.append('title', name);
      formData.append('description', description);
      formData.append('author', auteur);
      formData.append('tome', tome);
      formData.append('file', fileInput.files[0]);
      formData.append('annee', annee);
      formData.append('category1', categories1);
      formData.append('category2', categories2);
      this.toggleShowLoading();
      this.step++;
      try {
        const response = await fetch(this.link + '/addEbook', {
          method: 'POST',
          body: formData, // Utilisez FormData comme corps
        });
        const data = await response.json();
        if (response.ok) {
          this.loadingMessage = name + ' ajouté, merci!';
          this.showNotification('success', 'Ebook : ' + name + ' ajouté');
        } else if (response.status == 400) {
          this.showNotification('danger', 'Erreur : ' + data.error)
        } else {
          this.showNotification('danger', 'Erreur lors de l\'ajout de l\'ebook : ' + name)
        }
      } catch (error) {
        this.showNotification('danger', 'Erreur lors de l\'ajout de l\'ebook : ' + name)
      }
      setTimeout(() => {
        this.toggleShowLoading();
        this.loadingMessage = 'Envoi du fichier..';
        this.step = 0;
      }, 3000);
    }

  },
  created: function () {
    this.admin();
    this.getCategories();
    this.getCollections();
    this.getEbooksCategories();
    // Établissez la connexion WebSocket avec votre serveur
    this.socket = io("https://api.heavenflix.fr")

    // Écoutez l'événement "message" pour recevoir des messages WebSocket
    this.socket.on("message", (message) => {
      this.loadingMessage = message;
      if (message == 'Fichier reçu par le serveur') this.step++;
      if (message == 'Répertoire temporaire créé') this.step++;
      if (message == 'Extraction du fichier') this.step++;
      if (message == 'Conversion des images') this.step++;
      if (message == 'Suppression du fichier d\'origine') this.step++;
      if (message == 'Sauvegarde de la couverture') this.step++;
      if (message == 'Ajout en base de données') this.step++;
    });
  },
  beforeUnmount() {
    // Fermez la connexion WebSocket lorsque le composant est détruit
    if (this.socket) {
      this.socket.close()
    }
  },
  mounted: function () {
    document.body.style.overflowY = "auto";
  }
}
</script>

<style scoped lang="scss">
$dark-color: #090a0e;
$light-color: #f0f0f0;
$dark-red: #ff003c;
$back-color: #15161b;
$soft-grey: #2D2E32;

@mixin background-shadow($main-color) {
  background: $main-color;
  box-shadow: 0 0 10px $main-color;
}

.buton {

  border: none;
  padding: 15px;
  border-radius: 50%;

  &:first-child {
    @include background-shadow(dodgerblue);
  }

  &:last-child {
    @include background-shadow(red);
  }
}

.mainContainer {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: $back-color;

  label {
    margin-left: 5px;
    font-size: 18px;
    font-family: 'Lato';
    color: $light-color;
  }

  .loadingContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(5px);

    .loading {
      background: $dark-color;
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      flex-direction: column;
      padding: 20px 25px;

      img {
        width: 100px;
      }

      p {
        color: $light-color;
        font-family: 'Montserrat';
        margin: 5px 0;
      }

      span {
        color: rgba(255, 255, 255, 0.6);
        font-family: 'Montserrat';
        font-size: 14px;
      }
    }
  }

  .selector {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;

    .selectorContainer {
      display: flex;
      border-radius: 5px;
      overflow: hidden;

      button {
        background: rgba(255, 255, 255, 0.2);
        border: none;
        font-size: 20px;
        font-family: 'Lato';
        color: white;
        padding: 10px 20px;

        &:hover {
          cursor: pointer;
        }

        &:last-child {
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .form {
    margin: auto;
    width: 60%;
    padding: 0 0 50px 0;

    .boxes {
      padding: 20px 5px;
      margin-top: 10px;
      background: $soft-grey;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      border-radius: 10px;

      .box {
        list-style: none;
        column-count: 4;

        li {
          display: flex;
          justify-content: space-between;
          padding: 2.5px 10px;
          border-radius: 5px;

          label {
            margin: 0 4px;
            font-family: 'Montserrat';
          }

          input {
            cursor: pointer;
          }

          &:hover {
            transition-duration: 0.4s;
            background: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }

    .entry {
      width: 100%;
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 1;

      div {
        margin-top: 20px;

        a {
          width: fit-content;
          background: $dark-color;
          color: $light-color;
          text-decoration: none;
          padding: 10px 20px;
          border-radius: 10px;
          transition-duration: 0.7s;
          font-size: 16px;
          font-family: 'Montserrat';

          &:hover {
            cursor: pointer;
            transition-duration: 0.7s;
            background: $dark-red;
            color: white;
          }
        }
      }

      label {
        margin-bottom: 5px;
        font-size: 20px;
        font-family: 'Lato';
        color: $light-color;
      }

      select {
        width: 100%;
        background: $dark-red;
        border: none;
        color: white;
        font-family: 'Montserrat';
        font-size: 16px;
        padding: 10px 10px;
        outline: none;
        border-radius: 10px;

        option {
          background: $light-color;
          color: $back-color;
        }

        &:hover {
          cursor: pointer;
        }
      }

      input[type="text"] {
        width: 100%;
        height: 40px;
        outline: none;
        border: none;
        padding-left: 10px;
        background: #ffffff;
        font-size: 16px;
        font-family: 'Montserrat';
        border-radius: 10px;
      }

      input[type="number"] {
        width: 100%;
        height: 40px;
        outline: none;
        border: none;
        padding-left: 10px;
        background: #ffffff;
        font-size: 16px;
        font-family: 'Montserrat';
        border-radius: 10px;
      }

      .inputFileName {
        display: flex;
        align-items: center;
        gap: 10px;

        div {
          color: $light-color;
          font-family: 'Montserrat';
          font-size: 16px;
        }
      }

      .label-file {
        margin-top: 5px;
        cursor: pointer;
        background: $dark-color;
        width: fit-content;
        padding: 10px 15px;
        border-radius: 10px;
        transition-duration: 0.4s;

        &:hover {
          background: $dark-red;
          transition-duration: 0.4s;
        }
      }

      input[type="file"] {
        display: none;
      }

      button {
        margin: auto;
        width: 50%;
        border: none;
        background: rgb(194, 194, 194);
        color: $back-color;
        padding: 10px 0;
        transition-duration: 0.7s;
        font-size: 16px;
        font-family: 'Lato';
        border-radius: 10px;

        &:hover {
          cursor: pointer;
          transition-duration: 0.7s;
          background: $dark-red;
          color: white;
        }
      }

      ul {
        list-style: none;
        padding: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        li {
          background: $dark-color;
          padding: 10px 15px;
          border-radius: 10px;
          transition: 0.4s;

          a {
            text-decoration: none;
            color: $light-color;
            font-family: 'Montserrat';
          }

          &:hover {
            cursor: pointer;
            background: $dark-red;
            transition: 0.4s;
          }
        }
      }

      &:last-child {
        margin-top: 20px;
      }
    }

    .boxesContainer {
      width: 100%;
      margin-top: 30px;

      .boxesDisplayer {
        width: 98%;
        color: white;
        background: $soft-grey;
        border: none;
        font-size: 17px;
        font-family: 'Lato';
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 15px;
        cursor: pointer;
      }
    }
  }
}

//Affichage mobile
@media screen and (max-width: 426px) {
  .mainContainer {
    .form {
      width: 90%;

      .entry {
        input {
          max-width: 98%;
        }
      }

      .boxes {
        padding: 20px 0;

        .box {
          columns: 1;
          width: 100%;

          li {
            margin: auto;
            width: 92%;
            padding: 2.5px 0;
          }
        }
      }

      .boxesContainer {
        .boxesDisplayer {
          width: 92% !important;
        }
      }
    }
  }
}
</style>
