<template>
  <div class="home">
    <SeriesContainer/>
  </div>
</template>

<script>
import SeriesContainer from '../components/SeriesContainer.vue';

export default {
  name: 'SeriesView',
  components: {
    SeriesContainer
  }
}
</script>